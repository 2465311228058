import React ,{useEffect, useState } from "react";
import { Card, Accordion,Form,InputGroup, Row, Col } from "react-bootstrap";
import AddIndicatorView from "./AddIndicatorView";
import SliderView from "./SliderView";
import TutorialPopup from "../../help/TutorialPopup";
import * as HelpContent from "../../../utils/HelpContent";
import StopLossView from "./stoploss/StopLossView";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  CHANGE_MIN_TP,
  CHANGE_TAKE_PROFIT,
  CLOSE_DEAL_END_OF_TRADING_DAY,
  SET_CLOSE_AFTER_DEAL,
  SET_STOP_AFTER_DEAL,
  TRIGGER_DAISY_BOT
} from "../../../actions/create-bot-type";
import { BotType } from "../../../Const";
import TakeProfitView from "./takeprofit/TakeProfitView";
import LeftRightForm from "./modules/LeftRightForm";
import CustomCheckbox from "./modules/CustomCheckbox";

const ExitConditionsView = (props) => {
  const { newBot,t ,editing} = props;
  const maxVal = 100
  const isPremiumBot = (newBot.botType === BotType.advance || newBot.botType === BotType.exit)
  const dispatch = useDispatch();
  const closeBotAfterDeal = newBot.closeBotAfterDeal;
  const stopBotAfterDeal = newBot.stopBotAfterDeal?true:false;
  const endDayDealClose = newBot.closeDealEndOfTradingDay?true:false;
  const daisyBots = useSelector((state) => state.bots.daisyBots)||[];
  const daisyChain = newBot.daisyChain;
  const triggerBotDaisyChainId = newBot.triggerBotDaisyChain;
  const triggerBotDaisyChain = (daisyBots && triggerBotDaisyChainId) && daisyBots.find(
    (el) => el.id == triggerBotDaisyChainId 
  );
  const handleSelectDaisyBot = (bot) => {
    dispatch({
      type: TRIGGER_DAISY_BOT,
      payload: bot.value,
    });
  };
  useEffect(()=>{
    if(!editing && daisyChain==1 && daisyBots[0])
    {
        dispatch({
          type: TRIGGER_DAISY_BOT,
          payload: daisyBots[0].id,
        });
    }
  },[daisyChain])
  const takeProfitChanged = (val) => {
    if(val > maxVal)
    return
    dispatch({
      type: CHANGE_TAKE_PROFIT,
      payload: val,
    });
  };
  const setDealCloseValue = (val) => {
    dispatch({
      type: SET_CLOSE_AFTER_DEAL,
      payload: val,
    });
  };

  const minTpChanged = (val) => {
    if(val > maxVal)
    return
    dispatch({
      type: CHANGE_MIN_TP,
      payload: val,
    });
  };
  const selectedTriggerBot = {
    value: triggerBotDaisyChain ? triggerBotDaisyChain.id : daisyBots[0] && daisyBots[0].id,
    label: triggerBotDaisyChain ? triggerBotDaisyChain.name : daisyBots[0] && daisyBots[0].name,
  };
  const daisyBotItems =
    (daisyBots &&
      daisyBots.map((item) => ({
        value: item.id,
        label: item.name,
      }))) ||
    [];
  const setDealStopValue = (val) => {
    dispatch({
      type: SET_STOP_AFTER_DEAL,
      payload: val?1:0,
    });
  };
  const setAutoCloseDealValue = (val) => {
    dispatch({
      type: CLOSE_DEAL_END_OF_TRADING_DAY,
      payload: val?1:0
    });
  };
  const formatTakeProfit = (value) => (value == 0 ? t("disable") : `${value}%`);
  const formatDecimal = (value,decimals) => parseFloat(Number(value).toFixed(decimals));
  return (
    <>
      
            {daisyChain==1 && 
            <LeftRightForm
              left={<Form.Label className="bot-asset-tour">
              {t("trigger-daisy-bot")}
            </Form.Label>}
              right={ <Select
                value={selectedTriggerBot}
                options={daisyBotItems}
                onChange={handleSelectDaisyBot}
                classNamePrefix='react-select'
              />}
            >
            </LeftRightForm>
            }
            <TakeProfitView t={t}></TakeProfitView>
            <div style={{ marginTop: 20 }}>
              <StopLossView t={t}/>
            </div>
            <div style={{ marginTop: 20 }}>
              <AddIndicatorView newBot={newBot} isEntry={false} t={t}/>
            </div>
            {isPremiumBot && (
              <div style={{ marginTop: 20 }}>
                <SliderView
                  title={t("min-profit-indiactor")}
                  step={0.5}
                  min={0}
                  max={maxVal}
                  value={formatDecimal(newBot.minTp,2)}
                  onChange={minTpChanged}
                  formatLabel={formatTakeProfit}
                  hideLabel={true}
                  withInput={true}
                />
                <div style={{ marginTop: 20 }}>
                <LeftRightForm
                left={<Form.Label>{t("d-bot-if-stoploss")}</Form.Label>}
                right={<div className="d-flex align-items-center">
                  <div><input
                      type="text"
                      className="form-control input-text small-text-box"
                      value={closeBotAfterDeal}
                      onChange={(e) => setDealCloseValue(e.target.value)}
                    /> </div>
                  <div><Form.Label>in a row.</Form.Label></div>
                  
                </div>}
                >

                </LeftRightForm>
                </div>
                <div className="d-flex align-items-center dis-bot-stop-loss mt-3">
                 
                  <div style={{width:"100%"}}>
                  <CustomCheckbox 
                    val={stopBotAfterDeal} 
                    checked={stopBotAfterDeal} 
                    callback={setDealStopValue}
                    label={t("stop-bot-after-deal-ends")}
                    id={'stop-bot-after-deal-ends'}
                    >
                </CustomCheckbox>
                  {/* <Form.Check
                  type="checkbox"
                  label={t("stop-bot-after-deal-ends")}
                  value={stopBotAfterDeal}
                  checked={stopBotAfterDeal}
                  onChange={(e) => setDealStopValue(Boolean(e.target.checked))}
                  /> */}
                  </div>
                 
                </div>
              </div>
            )}
             <div className="d-flex align-items-center dis-bot-stop-loss mt-3">
                 
                 <div style={{width:"100%"}}>
                 <CustomCheckbox 
                    val={endDayDealClose} 
                    checked={endDayDealClose} 
                    callback={setAutoCloseDealValue}
                    label={t("auto-close-trading")}
                    id={'auto-close-trading'}
                    >
                </CustomCheckbox>
                 </div>
                
               </div>
    </>
  );
};

export default ExitConditionsView;
