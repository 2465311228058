import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import AffiliateLayout from "../common/AffliateLayout";
import "./Affiliate.scss";

const Affiliate = (props) => {
  const {t} = useTranslation()
  return (
    <AffiliateLayout t={t}>
      <div className="affiliate">
        {/* Top */}
        <div className="top">
          <div className="top1">
            <p className="top-title">
              {/* <span className="title">Our Affiliate Program</span>
              <br /> consists of the{" "}
              <span style={{ color: "#73CEE2" }}>Advocates Program</span>
              <br /> and the{" "}
              <span style={{ color: "#73CEE2" }}>Ambassador Program.</span> */}
              <Trans i18nKey={"aff-heading"}>
              <span className="title"></span>
              <span style={{ color: "#73CEE2" }}></span>
              <span style={{ color: "#73CEE2" }}>Ambassador Program.</span>
              </Trans>
              
            </p>
            <p>
              {t("aff-para-1")}
            </p>
            <p>
              {t("aff-para-2")}
            </p>
            <ul>
              <li>
              {t("aff-para-3")}
              </li>
              <li> {t("aff-para-4")}</li>
              <li>{t("aff-para-5")}
                
              </li>
            </ul>
            <br />
            <p>{t("aff-para-6")}
              
            </p>
          </div>
          <div className="top2">
            <img src="/images/pp-1.png" width={400} />
          </div>
        </div>
        {/* Ambassador */}
        <div className="ambassador">
          <p className="title">{t("ambass-prog")}</p>
          <p className="frag1">{t("aff-para-7")}
            
          </p>
          <p className="frag2">{t("aff-para-8")}
            
          </p>
          <p>{t("aff-para-9")}</p>
          <p className="frag3">{"20% > 10% > 5% > 3%"}</p>
          <p>{t("aff-para-10")}
            
          </p>
          <p>{t("aff-para-11")}
            
          </p>
          <p>{t("aff-para-12")}
            
          </p>
          <p>{t("aff-para-13")}
            
          </p>
          <div className="faq-btn">
            <a href="#faq">{t("aff-para-14")}</a>
          </div>
          <p>{t("aff-para-15")}
           
          </p>
        </div>
        {/* FAQ */}
        <div id="faq" className="faq">
          <p className="title">{t("aff-para-16")}</p>
          <p className="question">{t("aff-para-17")}
            
          </p>
          <p>{t("aff-para-18")}
            
          </p>
          <p className="question">{t("aff-para-19")}
            
          </p>
          <p>{t("aff-para-20")}
            
          </p>
          <p className="question">{t("aff-para-21")}
            
          </p>
          <p>{t("aff-para-22")}
            
          </p>
          <p className="question">{t("aff-para-23")}
           
          </p>
          <p>{t("aff-para-24")}
           
          </p>
          <p className="question">{t("aff-para-25")}</p>
          <p>{t("aff-para-26")}
            
          </p>
          <p className="question">{t("aff-para-27")}
            
          </p>
          <p>{t("aff-para-28")}
           
          </p>
          <p className="question">{t("aff-para-29")}
           
          </p>
          <p>{t("aff-para-30")}
           
          </p>
          <p className="question">{t("aff-para-31")}
            
          </p>
          <p>
            <Trans i18nKey={"aff-para-32"}>
            <a
              href="https://stockhero.ai/stockhero-affiliate-program-terms-and-conditions/"
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              
            </a>
            </Trans>
           
          </p>
          <p className="question">{t("aff-para-33")}
           
          </p>
          <p>{t("aff-para-34")}
           
          </p>
          <p className="question">{t("aff-para-35")}
           
          </p>
          <p>{t("aff-para-36")}
           
          </p>
        </div>
      </div>
    </AffiliateLayout>
  );
};

export default Affiliate;
