import React, { useState, useEffect } from "react";
import ContentContainer from "../common/ContentContainer";

import { connect, useSelector } from "react-redux";


import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const ExchageRedirect = (props) => {
const {t} = useTranslation()
const history = useHistory();
const url =  useSelector((state) => state.settings.exchangeRedirectUrl) || '';



  return (
    
      <ContentContainer url="add-brokerage" title={t("Add New Brokerage")} showExchange={true}>
        <div className="data-page">
        <button
        type="button"
                className="btn btn-primary mb-2"
                onClick={() => history.push("/setting?loc=3")}
        >Back to settings</button>
        <iframe src={url} width="100%" height={550} allowtransparency="true" style={{background: "#FFFFFF"}} ></iframe>
        </div>
        
      </ContentContainer>
  );
};

export default connect(null, { })(ExchageRedirect);
