import React from "react";
import { DcaTime, WeekTime, WeekTimeName } from "../../../../Const";
import { useDispatch } from "react-redux";
import { CHANGE_DCA_TIME } from "../../../../actions/create-bot-type";
import CustomCheckbox from "../modules/CustomCheckbox";
import CustomRadio from "../modules/CustomRadio";
import { Col, Row } from "react-bootstrap";

const DcaTimeEntry = (props) => {
  const { newBot,t } = props;
  const dcaTime = { ...newBot.dcaTime };
  const dispatch = useDispatch();
  const changeType = (val) => {
    dispatch({
      type: CHANGE_DCA_TIME,
      payload: {
        ...dcaTime,
        type: val,
      },
    });
  };

  const changeWeekValue = (val) => {
    dispatch({
      type: CHANGE_DCA_TIME,
      payload: {
        ...dcaTime,
        weekValue: val,
      },
    });
  };

  const changeMonthValue = (val) => {
    dispatch({
      type: CHANGE_DCA_TIME,
      payload: {
        ...dcaTime,
        monthValue: val,
      },
    });
  };

  const weekOptions = Object.keys(WeekTime).map((el, id) => (
    <option key={id} value={WeekTime[el]}>
      {WeekTimeName[el]}
    </option>
  ));

  const monthOptions = Array.from({ length: 31 }, (_, i) => String(i + 1)).map(
    (el, id) => (
      <option key={id} value={el}>
        {el}
      </option>
    )
  );
  return (
    <Row>
      <Col xl={12} className="offset-xl-3">
      <div className="d-flex justify-content-between flex-column">
      <div>
        <CustomRadio 
            val={DcaTime.daily}
            checked={dcaTime.type === DcaTime.daily}
            callback={changeType}
            label={t("Every Day")}
            name="time">
          </CustomRadio>
      </div>
      <div>
      <div className="d-flex ">
          <CustomRadio 
              val={DcaTime.weekly}
              checked={dcaTime.type === DcaTime.weekly}
              callback={changeType}
              label={t("Every")}
              name="time">
            </CustomRadio>
          <select
            value={dcaTime.weekValue}
            onChange={(e) => changeWeekValue(e.target.value)}
            className="select-normal dca-sm-select"
          >
            {weekOptions}
          </select>
        </div>
      </div>
      <div>
        <div className="d-flex ">
        <CustomRadio 
            val={DcaTime.monthly}
            checked={dcaTime.type === DcaTime.monthly}
            callback={changeType}
            label={t("Every")}
            name="time">
          </CustomRadio>
          <div>
          <select
            value={dcaTime.monthValue}
            onChange={(e) => changeMonthValue(e.target.value)}
            className="select-normal dca-sm-select"
          >
            {monthOptions}
          </select>
          {" "} day of the month
          </div>
        </div>
       
       
      </div>
    </div>
      </Col>
    </Row>
    
  );
};

export default DcaTimeEntry;
