import {
  PaperBtcUsdt,
  PaperExchange,
  StoplossType,
  BotType,
  DcaType,
  DcaTime,
  WeekTime,
  TakeProfitType,
} from "../../../../Const";

export const adaptBotPreset = (
  exchange = PaperExchange,
  exchangePair = {},
  config = {},
  defaultIndicators
) => {
  const pair = exchangePair.pair || {};
  const entryIds = config.entryIds || "";
  const exitIds = config.exitIds || "";
  const entryIndicators = defaultIndicators.filter((el) =>
    entryIds.includes(String(el.indicator_id))
  );
  const exitIndicators = defaultIndicators.filter((el) =>
    exitIds.includes(String(el.indicator_id))
  );
  return {
    id: null,
    name: "New Bot",
    exchange: exchange,
    exchangePairId: exchangePair.id, //exchange_pair.id
    pair: exchangePair.pair,
    strategy: config.strategy || "Long",
    initFund: 0,
    baseOrderType: "static",
    baseOrder: 50, //%
    extraOrderNum: 2, //%
    frequency: Number(config.frequency || 1440),
    takeProfit: config.takeProfit || 10,
    stoploss: config.stopLoss || 0,
    timeFrame: 365,
    entryNum: config.numberEntry || 0,
    exitNum: config.numberExit || 0,
    tradeOrderType: "market",
    tradeOrderTypeExit: "market",
    entryIndicators,
    exitIndicators,
    tvAlertsEntry: [],
    tvAlertsExit: [],
    baseCoin: pair.from,
    quoteCoin: pair.to,
    applyCurrentDeal: false,
    closeBotAfterDeal: 0,
    stoplossType: StoplossType.absolute,
    variableTSL: [{ triggerAt: 2, stopAt: 1 }],
    fixedTSL: { triggerAt: 2, stopAt: 1 },
    takeProfitType: TakeProfitType.absolute,
    variableTP: [{ triggerAt: 2, stopAt: 1 }],
    fixedTP: { triggerAt: 2, stopAt: 1 },
    minTp: 0,
    botType: BotType.simple,
    dcaType: DcaType.time,
    dcaTime: {
      type: DcaTime.daily,
      weekValue: WeekTime.mon,
      monthValue: "1",
    },
    dcaPercent: "2",
    dcaTrigger: "0",
    dcaExit: 0,
    dcaNoLimitFund: false,
    dcaOrder: "100",
    dcaMartingale: 0,
    gridRangeKlineDirection: 0,
    tvAlertEntryStatus: false,
    tvAlertExitStatus: false,
    minPriceGapProfit:0,
    isEditing:false,
    backtestCustom: false,
    backTestStart: new Date().getTime(),
    backTestEnd: new Date().getTime(),
  };
};
