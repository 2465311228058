import React, { useState, useEffect } from "react";
import ContentContainer from "../common/ContentContainer";
import { Row, Col, Form, Button } from "react-bootstrap";
import { DataGroup } from "./data-api/ApiGroup";
import useInput from "../hooks/useInput";
import { requestInfo } from "./data-api/ApiRequest";
import { ApiParam, ApiTier } from "./data-api/ApiType";
import Loading from "../common/Loading";
import { connect, useSelector } from "react-redux";
import { checkSub } from "../../utils/SubUtils";
import { SUB_TIER } from "../../Const";
import { getData } from "../../actions/data-action";
import AsyncSelect from "react-select/async";
import "./DataPage.scss";

const DataPage = (props) => {
  const [groupId, bindGroupId] = useInput("0");
  const [subId, bindSubId, setSubId] = useInput("0");
  const [symbol, setSymbol] = useState();
  const [exchange, setExchange] = useState();
  const [currency, setCurrency] = useState();
  const [amount, bindAmount] = useInput("0");
  const [answer, setAnswer] = useState(["Please select data group"]);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dataList = (DataGroup[groupId] && DataGroup[groupId].subData) || [];
  const subData = dataList.length && dataList[subId];
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  const { subTier } = checkSub(subscription);

  useEffect(() => {
    setSubId("0");
  }, [groupId]);

  useEffect(() => {
    props.getData();
  }, []);

  const exchanges = useSelector((state) => state.data.exchanges) || [];
  const symbols = useSelector((state) => state.data.symbols) || [];
  const currencies = useSelector((state) => state.data.currencies) || [];

  const requestData = async () => {
    if (!subData) return;
    if (subTier == SUB_TIER.free && subData.tier !== ApiTier.free) {
      setAnswer(["Please upgrade your stockhero plan to view this data"]);
      setIsError(true);
      return;
    }
    var params = {};
    subData.params.forEach((param, id) => {
      switch (param.type) {
        case ApiParam.exchange:
          params[param.id] = (exchange && exchange.value.toLowerCase()) || "";
          break;
        case ApiParam.symbol:
          params[param.id] = (symbol && symbol.value) || "";
          break;
        case ApiParam.currency:
          params[param.id] = (currency && currency.value) || "";
          break;
        case ApiParam.amount:
          params[param.id] = amount;
          break;
        default:
          break;
      }
    });
    setLoading(true);
    requestInfo(subData, params)
      .then((res) => {
        setLoading(false);
        setAnswer(res);
        setIsError(false);
      })
      .catch((err) => {
        setLoading(false);
        setAnswer(err);
        setIsError(true);
      });
  };

  const groupItems = DataGroup.map((el) => el.name).map((el, id) => (
    <option key={id} value={id}>
      {el}
    </option>
  ));

  const subItems = dataList.map((el, id) => (
    <option key={id} value={id}>
      {el.name}
    </option>
  ));

  const symbolItems = (inputValue, callback) => {
    const filterList = symbols.filter((el, id) => {
      if (inputValue.length == 0) return id < 200;
      return el.includes(inputValue.toUpperCase());
    });
    callback(filterList.map((el) => ({ value: el, label: el })));
  };

  const exchangeItems = (inputValue, callback) => {
    const filterList = exchanges.filter((el, id) => {
      if (inputValue.length == 0) return id < 200;
      return el.includes(inputValue.toUpperCase());
    });
    callback(filterList.map((el) => ({ value: el, label: el })));
  };

  const currencyItems = (inputValue, callback) => {
    const filterList = currencies.filter((el, id) => {
      if (inputValue.length == 0) return id < 200;
      return el.includes(inputValue.toUpperCase());
    });
    callback(filterList.map((el) => ({ value: el, label: el })));
  };

  const paramItems =
    subData &&
    subData.params.map((el, id) => {
      switch (el.type) {
        case ApiParam.symbol:
          return (
            <Form.Group
              key={`${id} ${symbols.length} symbol`}
              style={{ marginTop: 20 }}
            >
              <Form.Label>Symbols</Form.Label>
              <AsyncSelect
                defaultOptions
                cacheOptions
                loadOptions={symbolItems}
                onChange={setSymbol}
              />
            </Form.Group>
          );
        case ApiParam.currency:
          return (
            <Form.Group key={`${id} currency`} style={{ marginTop: 20 }}>
              <Form.Label>To Currency</Form.Label>
              <AsyncSelect
                defaultOptions
                cacheOptions
                loadOptions={currencyItems}
                onChange={setCurrency}
              />
            </Form.Group>
          );
        case ApiParam.exchange:
          return (
            <Form.Group key={`${id} exchange`} style={{ marginTop: 20 }}>
              <Form.Label>Exchange</Form.Label>
              <AsyncSelect
                defaultOptions
                cacheOptions
                loadOptions={exchangeItems}
                onChange={setExchange}
              />
            </Form.Group>
          );
        default:
          return (
            <Form.Group key={id} style={{ marginTop: 20 }}>
              <Form.Label>Amount</Form.Label>
              <Form.Control as="input" type="number" {...bindAmount} />
            </Form.Group>
          );
      }
    });
  return (
    <Loading show={loading} onHide={() => setLoading(false)}>
      <ContentContainer url="data" title="Data" showExchange={false}>
        <div className="data-page">
          <Row style={{ marginTop: 30 }}>
            <Col xs={5}>
              <Form.Group>
                <Form.Label>Data Group</Form.Label>
                <Form.Control as="select" {...bindGroupId}>
                  {groupItems}
                </Form.Control>
              </Form.Group>

              <Form.Group style={{ marginTop: 20 }}>
                <Form.Label>Sub Data</Form.Label>
                <Form.Control as="select" {...bindSubId}>
                  {subItems}
                </Form.Control>
              </Form.Group>

              {paramItems}

              <div
                className="text-center"
                style={{ marginTop: 30, marginBottom: 30 }}
              >
                <Button
                  className="w-75"
                  variant="success"
                  onClick={requestData}
                >
                  DONE
                </Button>
              </div>
            </Col>
            <Col xs={7}>
              <div className="h-100 answer-box round-bg">
                {answer.map((el, id) => (
                  <p
                    key={id}
                    className={isError ? "text-danger" : "text-primary"}
                  >
                    {el}
                  </p>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </ContentContainer>
    </Loading>
  );
};

export default connect(null, { getData })(DataPage);
