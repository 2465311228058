import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import PositionTab from "./PositionTab";
import OpenOrderTab from "./OpenOrderTab";
import "./OrderPanel.scss";
import { connect, useSelector } from "react-redux";
import OrderHistoryTab from "./OrderHistoryTab";

const OrderPanel = (props) => {
  const { account ,t} = props;

  const [activeTab, setActiveTab] = useState(0);

  const tabTitles = [t("holdings"), t("open-orders"), t("th")];
  const tabHeaders = tabTitles.map((item, index) => {
    return (
      <Col xs={4} key={index}>
        <a
          onClick={() => setActiveTab(index)}
          className={index == activeTab ? "tab-active" : "tab-inactive"}
        >
          {item}
        </a>
      </Col>
    );
  });

  const tabs = [
    <PositionTab account={account} t={t}/>,
    <OpenOrderTab account={account} t={t}/>,
    <OrderHistoryTab account={account} t={t}/>,
  ];

  return (
    <div className="order-panel">
      <Row>{tabHeaders}</Row>
      {tabs[activeTab]}
    </div>
  );
};

export default connect(null)(OrderPanel);
