
import React, {  } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

const CancelRentalConfirm = (props) => {
  const {
    bot,
    onHide,
    onConfirm
  } = props;
  return (
    <>
    
      <Modal show={true} onHide={onHide} size="xs" dialogClassName="modal-90w">
        <Modal.Header  className="no-border" closeButton></Modal.Header>
        <Modal.Body style={{padding:"10px 50px",height: "280px"}} className="text-center">
        <img src="images/alert.png" width={50}></img>
        <div className="text-center mt-3 mb-3"><p>You are cancelling the {bot.title} rental. Please note that after the cancellation, all active bots which run on Ulti Bot’s settings will immediately be deactivated and deleted. All existing inactive bots will be deleted. There is no refund for any unused period.</p></div> 
        <button className="btn btn-primary mt-2 no-decoration mr-3" onClick={onConfirm}>Confirm</button>
        <button className="btn btn-secondary mt-2 no-decoration" onClick={onHide}>Cancel</button>
        </Modal.Body>
       
      </Modal>
      </>
  );
};

export default connect(null, {
})(CancelRentalConfirm);
