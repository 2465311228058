import React, { useState, useEffect, useMemo } from "react";
import {
  Modal,
  Row,
  Col,
  Button,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";

import { connect, useSelector } from "react-redux";
import { paperDeposit, paperWithdraw } from "../../actions/setting-action";
import Loading from "../common/Loading";
import useInput from "../hooks/useInput";
import { toast } from "react-toastify";
import "./PaperDepositWithdraw.scss";
import PaperWalletSymbolSearch from "../bots/new-bot/modules/PaperWalletSymbolSearch";

const PaperDepositWithdraw = (props) => {
  const { show, onHide, isDeposit, setIsDeposit, paperDeposit, paperWithdraw,t } =
    props;
  const [loading, setLoading] = useState(false);
  const [amount, bindAmount, resetAmount] = useInput("0");

  const coins =
    useSelector(
      (state) => state.settings.paperWallet && state.settings.paperWallet.coins
    ) || [];
  const [selectedCoin, setSelectedCoin] = useState("");
  useEffect(() => {
    if (coins && coins.length) {
      setSelectedCoin(coins[0].coin);
    }
  }, [coins]);

  const coin = useMemo(
    () => coins.find((el) => el.coin === selectedCoin),
    [selectedCoin, coins]
  );

  const clickConfirm = () => {
    if (!coin) return;
    if (!isDeposit && coin.free < Number(amount))
      return toast.error("Insufficient balance");
    setLoading(true);
    const paperAction = isDeposit ? paperDeposit : paperWithdraw;
    paperAction({ coin: selectedCoin, amount })
      .then(() => {
        setLoading(false);
        toast.success("Successful" + (isDeposit ? " Deposit" : " Withdrawal"));
        onHide();
        resetAmount();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const coinItems = coins.map((el) => (
    <Dropdown.Item key={el.coin} eventKey={el.coin}>
      <img src={el.image} width={20} className="mr-2" />{" "}
      {el.coin}
    </Dropdown.Item>
  ));
  return (
    <Loading show={loading} onHide={() => setLoading(false)}>
      <Modal show={show} onHide={onHide} className="paper-withdraw">
        <Modal.Header closeButton>
          <Modal.Title>{isDeposit ? t("ADD") : t("REDUCE")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ margin: "5px 30px" }}>
            <Row>
              <Col>
                <Button
                  variant={isDeposit ? "primary" : "outline-primary"}
                  onClick={() => setIsDeposit(true)}
                  className="w-100"
                >
                   {t("ADD")}
                </Button>
              </Col>
              <Col>
                <Button
                  variant={isDeposit ? "outline-primary" : "primary"}
                  onClick={() => setIsDeposit(false)}
                  className="w-100"
                >
                 {t("REDUCE")}
                </Button>
              </Col>
            </Row>
            <Form.Group className="mt-3">
              <Form.Label>Stock</Form.Label>
              <PaperWalletSymbolSearch coins={coins} setSelectedCoin={setSelectedCoin} selectedCoin={selectedCoin}></PaperWalletSymbolSearch>
            </Form.Group>
            <Form.Group className="mt-3 mb-2">
              <Form.Label>{t("amount")}</Form.Label>
              <Form.Control type="number" {...bindAmount} />
            </Form.Group>
            {!isDeposit && (
              <p className="text-right">
                {t("available")}: {coin && coin.free} {selectedCoin}
              </p>
            )}
            <div className="my-3 text-center">
              <Button
                variant="success"
                className="w-100"
                onClick={clickConfirm}
              >
                CONFIRM
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Loading>
  );
};

export default connect(null, { paperDeposit, paperWithdraw })(
  PaperDepositWithdraw
);
