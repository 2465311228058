import React from "react";
import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";
import { roundNumber } from "../../utils/Utils";
import { CryptoAssetColors } from "../../Const";

import "./DistributionPanel.scss";

const DistributionPanel = (props) => {
  const { t } = props;
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const coins = useSelector((state) => state.portfolio.coins);
  
  const totalBalance =
  coins && coins.total &&
    coins.total.reduce((balance, item) => {
      return balance + item.balance_usd;
    }, 0);
  const totalCoins =
  coins && coins.total &&
    coins.total
      .filter((item) => item.balance > 0)
      .map((item) => {
        return {
          name: item.coin_name,
          symbol: item.coin_symbol,
          holdings:
            totalBalance === 0 ? 0 : (item.balance_usd * 100) / totalBalance,
          amount: item.balance,
          total: item.balance_usd.toFixed(2),
          image: item.image,
          change: item.change24h,
          price: item.price_usd,
        };
      });
  var sortCoins = totalCoins;
  if (sortCoins == null) {
    return <></>;
  }
  sortCoins.sort((coin1, coin2) => coin2.holdings - coin1.holdings);
  var topCoins = sortCoins.slice(0, 5);
  const otherCoins = sortCoins.slice(5);
  if (otherCoins.length > 0) {
    const otherHolding = otherCoins.reduce(
      (acc, item) => acc + item.holdings,
      0
    );
    topCoins.push({
      symbol: "Others",
      holdings: otherHolding,
    });
  }

  const labels = topCoins && topCoins.map((item) => item.symbol);
  const holdings =
    topCoins && topCoins.map((item) => roundNumber(item.holdings));
  return (
    <div className="portfolio-pie text-center">
      
      <Pie data={data(holdings, labels,theme)} options={options(holdings)}/>
    </div>
          
    
  );
};

export default DistributionPanel;

const data = (holdings, labels,theme) => ({
  labels,
  datasets: [
    {
      data: holdings,
      backgroundColor: CryptoAssetColors[theme],
    },
  ],
});

const options = (holdings) => {
  const fontColor = "#fff";
  return {
    maintainAspectRatio: true,
    legend: {
      position: "right",
      fullSize:false,
      labels: {
        fontColor,
        padding: 25,
        pointStyle:'circle',
        usePointStyle:true,
        pointStyleWidth:1,
        //align:'left',
        textAlign:'left'
      },
     
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          var label = holdings[tooltipItem.index] || 0;
          return label + "%";
        },
      },
    }
  };
};
