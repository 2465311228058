import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import {useSelector } from "react-redux";
import { Frequencies, FrequencyValues,BotTypeName } from "../../../Const";
import LeftRightForm from "../new-bot/modules/LeftRightForm";

const QuickStartSetting = (props) => {
  const { bot,t } = props;
  const exchangePair = bot.exchange_pair || {};
  const exchange = exchangePair.exchange || {};
  const pair = exchangePair.pair || {};
  const indicators = bot.bot_indicators || [];
  const stoploss = bot.stop_loss || 0;
  const theme = useSelector((state) => state.settings.selected_theme);
  

  return (
      <div className="card-white mpd-card">
          <div className="cw-body bot-detail">
              <LeftRightForm 
                  left={t("exchange")}
                  right={<div>
                    <img
                      src={exchange.image}
                      width={20}
                      height={20}
                      style={{ marginRight: 10 }}
                    />
                    <span style={{ fontWeight: "bold" }}>{exchange.name}</span>
                  </div>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("pair")}
                  right={<>{pair && `${pair.from}/${pair.to}`}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("bot-type")}
                  right={<>{bot && BotTypeName[bot.type]}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("strategy")}
                  right={<>{bot && bot.strategy}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("Frequency")}
                  right={<>{bot && Frequencies[FrequencyValues.indexOf(bot.frequency)]}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
          </div>
      </div>
  );
};

export default QuickStartSetting;
