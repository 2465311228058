import React, { useEffect, useState } from "react";
import ModalContainer from "../../../common/ModalContainer";
import "./IndicatorSetting.scss";
import RsiEntrySetting from "./RsiEntrySetting";
import RsiExitSetting from "./RsiExitSetting";
import StochRsiEntrySetting from "./StochRsiEntrySetting";
import StochRsiExitSetting from "./StochRsiExitSetting";
import BbEntrySetting from "./BbEntrySetting";
import BbExitSetting from "./BbExitSetting";
import EmaEntrySetting from "./EmaEntrySetting";
import { useDispatch } from "react-redux";
import {
  CHANGE_INDICATOR_SETTING,
  FINISHED_INDICATOR_SETTING,
} from "../../../../actions/create-bot-type";
import { Button } from "react-bootstrap";
import { isLong } from "../../../../utils/Utils";
import { IndicatorType } from "../../../../Const";
import MacdSetting from "./MacdSetting";
import VolumeSettings from "./VolumeSettings";
import { useTranslation } from "react-i18next";

const IndicatorSetting = (props) => {
  const {t} = useTranslation()
  const { isEntry, indicator, strategy, defaultIndicators,baseCoin } = props;
  const [editingIndicator, setEditingIndicator] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setEditingIndicator(indicator);
  }, [indicator]);

  const saveChange = () => {
    dispatch({
      type: CHANGE_INDICATOR_SETTING,
      payload: {
        isEntry,
        indicator: editingIndicator,
      },
    });
  };

  const closeDialog = () => {
    dispatch({
      type: FINISHED_INDICATOR_SETTING,
    });
  };

  const resetDefault = () => {
    let defaultSetting = defaultIndicators.find(
      (el) => el.indicator_id == indicator.indicator_id
    );
    if (defaultSetting) {
      setEditingIndicator(
        {...defaultSetting,ind_key:indicator.ind_key},
      );
    }
  };
  const composeComponent = (Component) => {
    return (
      <Component
        indicator={editingIndicator}
        updateIndicator={setEditingIndicator}
        isLong={isLong(strategy)}
        baseCoin={baseCoin}
        t={t}
      />
    );
  };
  let SettingComponent;
  switch (editingIndicator.indicator_id) {
    case IndicatorType.BB:
      SettingComponent = composeComponent(
        isEntry ? BbEntrySetting : BbExitSetting
      );
      break;
    case IndicatorType.EMA:
      SettingComponent = composeComponent(EmaEntrySetting);
      break;
    case IndicatorType.SRSI:
      SettingComponent = composeComponent(
        isEntry ? StochRsiEntrySetting : StochRsiExitSetting
      );
      break;
    case IndicatorType.RSI:
      SettingComponent = composeComponent(
        isEntry ? RsiEntrySetting : RsiExitSetting
      );
      break;
    case IndicatorType.MACD:
      SettingComponent = composeComponent(MacdSetting);
      break;
    case IndicatorType.VOL:
      SettingComponent = composeComponent(VolumeSettings);
      break;
    default:
      break;
  }

  return (
    <ModalContainer
      show={true}
      onHide={closeDialog}
      title={`${isEntry ? t("entry") : t("exit")} ${indicator.name} Setting`}
    >
      <div className="indicator-setting">
        {SettingComponent}
        <div
          className="d-flex justify-content-between"
          style={{ marginTop: 25 }}
        >
          <Button onClick={resetDefault} variant="secondary w-50 mr-1">
            {t("reset-def")}
          </Button>
          <Button onClick={saveChange} variant="success w-50">
          {t("save")}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default IndicatorSetting;
