import React, { useState } from "react";
import ContentContainer from "../common/ContentContainer";
import { Card, Row, Form, Button } from "react-bootstrap";
import SupportCard from "./SupportCard";
import { connect, useSelector } from "react-redux";
import useInput from "../hooks/useInput";
import { toast } from "react-toastify";
import { updateContactForm } from "../../actions/setting-action";
import Loading from "../common/Loading";
import SocialCard from "./SocialCard";
import "./Support.scss";
import { useTranslation } from "react-i18next";

const Support = (props) => {
  const { updateContactForm } = props;
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false);
  const email = useSelector((state) => state.auth.user.email) || "";
  const [title, bindTitle, resetTitle] = useInput("");
  const [detail, bindDetail, resetDetail] = useInput("");
  const [replyTo, bindReplyTo] = useInput(email);

  const clickSubmit = () => {
    setLoading(true);
    updateContactForm({
      subject: title,
      message: detail,
      reply_to: replyTo,
    })
      .then(() => {
        resetTitle();
        resetDetail();
        setLoading(false);
        toast.info(
          t("thanks-for-contacting")
        );
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };
  const supportCards = supportItems.map((el, id) => (
    <SupportCard key={id} icon={el.icon} text={el.text} link={el.link} t={t} />
  ));
  const socialCards = socialItems.map((el, id) => (
    <SocialCard key={id} icon={el.icon} text={el.text} link={el.link} t={t}/>
  ));
  return (
    <Loading show={loading} onHide={() => setLoading(false)}>
      <ContentContainer url="help" title="  " showExchange={false}>
        <div className="support-page">
          <div>
            <h5>{t("support")}</h5>
            <Row style={{ marginTop: 20 }}>{supportCards}</Row>
          </div>
          {/* <div style={{ marginTop: 50 }}>
            <h5>{t("community")}</h5>
            <Row style={{ marginTop: 20 }}>{socialCards}</Row>
          </div> */}
          {/* <div className="col-6 p-0" style={{ marginTop: 50 }}>
            <h5>{t("contact-form")}</h5>
            <Form.Group style={{ marginTop: 20 }}>
              <Form.Label>{t("subject")}</Form.Label>
              <Form.Control {...bindTitle} />
            </Form.Group>
            <Form.Group style={{ marginTop: 20 }}>
              <Form.Label>{t("reply-to")}</Form.Label>
              <Form.Control type="email" {...bindReplyTo} />
            </Form.Group>
            <Form.Group style={{ marginTop: 20 }}>
              <Form.Label>{t("content")}</Form.Label>
              <Form.Control
                as="textarea"
                {...bindDetail}
                style={{ height: 300 }}
              />
            </Form.Group>
            <div className="text-center" style={{ marginTop: 20 }}>
              <Button
                variant="success"
                className="w-50 mt-3"
                onClick={clickSubmit}
                disabled={title == "" || detail == "" || replyTo == ""}
              >
                {t("submit")}
              </Button>
            </div>
          </div> */}
        </div>
      </ContentContainer>
    </Loading>
  );
};

export default connect(null, { updateContactForm })(Support);

const supportItems = [
  {
    icon: "whatsapp",
    text: "Whatsapp",
    link: "https://wa.me/message/XNLAUGBUB2IRJ1",
  },
  {
    icon: "user-guide",
    text: "User Guide",
    link: "https://docs.stockhero.ai/",
  },
  {
    icon: "faq",
    text: "FAQ",
    link: "https://docs.stockhero.ai/faq/frequently-asked-questions",
  },
  {
    icon: "email",
    text: "email",
    link: "mailto:admin@stockhero.ai",
  },
];

const socialItems = [
  {
    icon: "fab fa-telegram navbar-icon support-telegram",
    text: "Telegram",
    link: "https://t.me/stockheroai",
  },
  {
    icon: "fab fa-twitter navbar-icon support-twitter",
    text: "Twitter",
    link: "https://twitter.com/stockheroAI",
  },
  {
    icon: "fab fa-facebook navbar-icon support-facebook",
    text: "Facebook",
    link: "https://www.facebook.com/stockheroai",
  },
  {
    icon: "fab fa-instagram navbar-icon support-instagram",
    text: "Instagram",
    link: "https://www.instagram.com/stockheroai",
  },
  {
    icon: "fab fa-linkedin navbar-icon support-linkedin",
    text: "LinkedIn",
    link: "https://www.linkedin.com/company/stockheroai/",
  },
];
