import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { getBotDetail, deleteBot, forceEnterOrder } from "../../../actions/bot-action";
import { getDeals } from "../../../actions/deal-action";
import { Row, Col, Modal, Table, Container, Button } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import BotDealTab from "./BotDealTab";
import TradeTab from "./TradeTab";
import { decimalOf, normalizeTime } from "../../../utils/Utils";
import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import { toast } from "react-toastify";
import SimpleBotSetting from "./SimpleBotSetting";
import { BotType } from "../../../Const";
import AdvanceBotSetting from "./AdvanceBotSetting";
import DcaBotSetting from "./DcaBotSetting";
import CustomPagination from "../../common/CustomPagination";
import RentBotSetting from "./RentBotSetting";
import GridBotSetting from "./GridBotSetting";
import QuickStartSetting from "./QuickStartSetting";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContentContainer from "../../common/ContentContainer";
import LeftRightForm from "../new-bot/modules/LeftRightForm";
import BackButton from "../../common/BackButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const BotDetail = (props) => {
  const {t} = useTranslation()
  const { show, getBotDetail, getDeals, deleteBot,itemsPerPage,pageNum,selectedTab,selectedExchangeId,forceEnterOrder } = props;

  const [activeTab, setActiveTab] = useState(0);
  const { botId } = useParams();
  const history = useHistory()
  const dealPerPage = 5;
  const [setLoading, bindLoading] = useLoading(false);
  const bot = useSelector((state) => state.bots.bot) || {};
  const deals = useSelector((state) => state.deals.deals) || [];
  const startItem = useSelector((state) => state.deals.startItem) || 1;
  const totalDeals = useSelector((state) => state.deals.totalDeals);
  const theme = useSelector((state) => state.settings.selected_theme);
  const botType = bot.type;
  const rfr = useSelector((state) => state.settings.risk_free_rate) || 4;

  //const filterDeals = (deals || []).filter((item) => item.bot_id == botId);
  const filterDeals = (deals || []);
  const exchangePair = bot && bot.exchange_pair;
  const pair = exchangePair && exchangePair.pair;
  const backtest = bot && bot.deals_analysis;
  const totalTrade = (backtest && backtest.total_position) || 0;
  const winTrade = (backtest && backtest.profitable_position) || 0;
  const wlRatio = totalTrade > 0 ? (winTrade * 100) / totalTrade : 0;
  const performance = (backtest && parseFloat(backtest.total_performance)) || 0;
  const returnValue = (backtest && parseFloat(backtest.return_value_avg)) || 0;
  const avgPerformance = totalTrade > 0 ? performance / totalTrade : 0;
  const avgReturn = totalTrade > 0 ? returnValue / totalTrade : 0;
  const strategy = (bot && bot.strategy) || "Long";
  const baseCoin =
    strategy.toLowerCase() == "long" ? pair && pair.to : pair && pair.from;
  const decimalNumber = decimalOf(baseCoin || "USDT");
  const tradeInterval = normalizeTime(
    (backtest && backtest.trade_interval) || 0
  );
  const dealDuration = normalizeTime(
    (backtest && backtest.avg_deal_duration) || 0
  );
  const onHide = ()=>{
    history.goBack()
  }

  //var equities = [(bot && bot.initial_fund) || 0];
  var equities = [0];
  backtest &&
    backtest.positions.forEach((item) => {
      equities.push(item.return_value);
    });

  if (equities.length == 1) {
    equities.push(equities[0]);
  }

  useEffect(() => {
    if (botId != -1) {
      setLoading(true);
      getBotDetail(botId)
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    }
  }, [botId]);

  useEffect(() => {
    getDeals(selectedExchangeId,5,pageNum,"completed",botId);
  }, []);

  const clickDeleteBot = () => {
    setLoading(true);
    deleteBot(botId,selectedExchangeId,itemsPerPage,pageNum,selectedTab)
      .then(() => {
        setLoading(false);
        onHide();
        toast.success(t("bot-deleted-msg"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };
  const clickForceEnterOrder = (type) => {
    setLoading(true);
    forceEnterOrder(botId,type)
      .then(() => {
        setLoading(false);
        onHide();
        toast.success(t("success"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const tabTitles = [t("deals"), t("th")];
  const tabHeaders = tabTitles.map((item, index) => {
    return (
        <a
          onClick={() => setActiveTab(index)}
          key={index}
          className={index == activeTab ? "list-tab tab-active" : "list-tab tab-inactive"}
        >
          {item}
        </a>
    );
  });
  const getSettingsView = ()=>{
    switch (botType) {
      case BotType.simple:
        return <SimpleBotSetting bot={bot} t={t}/>
      case BotType.advance:
        return <AdvanceBotSetting bot={bot} t={t}/>
      case BotType.exit:
        return <AdvanceBotSetting bot={bot} t={t}/>
      case BotType.price:
        return <AdvanceBotSetting bot={bot} t={t}/>
      case BotType.dca :
        return <DcaBotSetting bot={bot} t={t}/>
      case BotType.grid :
        return <GridBotSetting bot={bot} t={t}/>
      case BotType.quick_start :
        return <QuickStartSetting bot={bot} t={t}/>
      default:
        return <SimpleBotSetting bot={bot} t={t}/>
    }
  }
  const tabItems = [
    <BotDealTab 
    deals={filterDeals} 
    startItem={startItem}
    itemsPerPage={dealPerPage}
    selectedExchangeId={selectedExchangeId}
    pageNum={pageNum}
    selectedTab={selectedTab}
    />,
    <TradeTab deals={filterDeals} 
    startItem={startItem}
    itemsPerPage={dealPerPage}
    selectedExchangeId={selectedExchangeId}
    pageNum={pageNum}
    selectedTab={selectedTab}
    />,
  ];

  return (
    <Loading {...bindLoading}>
      <ContentContainer url="bots" title={t("bots")} showExchange={true}>
            <div className="dashboard-content">
                <div className="bot-title-div text-center">
                  <BackButton></BackButton>
                  <span className="bot-name-text">{bot.name}</span>
                  </div>
                <div style={{ padding: 30 }}>
                  <Row>
                      <Col lg={6}>
                    {!bot.rent_id ? (
                      getSettingsView()
                      )  : (
                        <RentBotSetting bot={bot} />
                      )}
                      </Col>
                      <Col lg={6}>
                        <div className="card-white mpd-card">
                            <div className="cw-body bot-detail">
                                <LeftRightForm 
                                    left={t("return")}
                                    right={<>{performance.toFixed(decimalNumber)} {baseCoin} (
                                      {returnValue.toFixed(2)}%)</>}
                                    leftCol={4}
                                    rightCol={8}
                                  ></LeftRightForm>
                                <LeftRightForm 
                                    left={t("w-l-ratio")}
                                    right={<>{wlRatio.toFixed(2)}%</>}
                                    leftCol={4}
                                    rightCol={8}
                                  ></LeftRightForm>
                                <LeftRightForm 
                                    left={t("deals")}
                                    right={<>{totalDeals}</>}
                                    leftCol={4}
                                    rightCol={8}
                                  ></LeftRightForm>
                                <LeftRightForm 
                                    left={t("a-deal-prof")}
                                    right={<>{avgPerformance.toFixed(decimalNumber)} {baseCoin} (
                                      {avgReturn.toFixed(2)}%)</>}
                                    leftCol={4}
                                    rightCol={8}
                                  ></LeftRightForm>
                                <LeftRightForm 
                                    left={t("a-d-inter")}
                                    right={<> {tradeInterval.time.toFixed(1)}
                                    {tradeInterval.unit}</>}
                                    leftCol={4}
                                    rightCol={8}
                                  ></LeftRightForm>
                                <LeftRightForm 
                                    left={t("a-deal-dur")}
                                    right={<>{dealDuration.time.toFixed(1)}
                                    {dealDuration.unit}</>}
                                    leftCol={4}
                                    rightCol={8}
                                  ></LeftRightForm>
                                <LeftRightForm 
                                    left={t("sharpe-ratio")}
                                    right={<>{bot.deals_analysis && bot.deals_analysis.sharpe_ratio}</>}
                                    leftCol={4}
                                    rightCol={8}
                                  ></LeftRightForm>
                            </div>
                        </div>
                      </Col>

                </Row>
             

                <Row className="mt-4">
                <Col lg={12}>
                <h2>Returns</h2>
                    <div className="card-white mpd-card">
                        <div className="cw-body bot-detail">
                          <Line height={70} data={data(equities)} options={options} />
                          </div>
                    </div>
                </Col>
                </Row>
                <div className="mt-4">
                  <div className="bot-tabs">
                  {tabHeaders}
                  </div>
                  {tabItems[activeTab]}
                  
                  <CustomPagination
                    startItem={startItem}
                    botId={botId}
                    onChange={getDeals}
                    totalCount={totalDeals}
                    selectedTab={"completed"}
                    itemsCountPerPage={dealPerPage}
                  />
                </div>

                <Row className="my-4 justify-content-center">
                  <Button
                    variant="danger"
                    onClick={clickDeleteBot}
                    className="mr-4"
                  >
                    {t("del-bot")}
                  </Button>
                  {bot.force_enter_order =="base" && 
                  <Button
                    variant="primary"
                    onClick={()=>clickForceEnterOrder("base")}
                    className="mr-4"
                  >
                    {t("enter-b-order")}
                  </Button>}
                  {bot.force_enter_order =="extra" && 
                  <Button
                    variant="primary"
                    onClick={()=>clickForceEnterOrder("extra")}
                    className="mr-4"
                  >
                    {t("enter-extra-order")}
                  </Button>}
                  {/* <a href={`/bots?edit=${botId}`} className="btn btn-info">
                    Edit Bot
                  </a> */}
                </Row>
              </div>
        </div>
        </ContentContainer>
    </Loading>
  );
};

export default connect(null, {
  getBotDetail,
  getDeals,
  deleteBot,
  forceEnterOrder
})(BotDetail);

const data = (equities) => {
  return {
    labels: Array(equities.length).fill(""),
    datasets: [
      {
        type: "line",
        label: "Returns ",
        data: equities,
        fill: false,
        backgroundColor: "#9381FF",
        borderColor: "#9381FF",
        hoverBackgroundColor: "#9381FF",
        hoverBorderColor: "#9381FF",
      },
    ],
  };
};

const options = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [{
        ticks: {
            display: false //this will remove only the label
        },
        gridLines: {
          color: "#999"
        }
    }],
    yAxes: [{
        ticks: {
          fontColor: "#999",
        },
        gridLines: {
          color: "#999"
        }
    }]
}
 
};
