import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import Switch from "react-switch";
import { toast } from "react-toastify";
import {
  getSettings,
  updateNotificationStatus,
  updateEmailAlertStatus,
  updateSmsAlertStatus,
} from "../../actions/setting-action";
import { SmsCountries, SmsCountriesCode, SmsCountriesNames, SUB_TIER } from "../../Const";
import { checkSub } from "../../utils/SubUtils";
import { restrictAlphabets } from "../../utils/Utils";

const NotificationsPanel = (props) => {
  const { getSettings, updateNotificationStatus,updateEmailAlertStatus,updateSmsAlertStatus } = props;
  const {t} = useTranslation()
  const countryName = useSelector(
    (state) => state.settings.order_filled_sms_alert_country_name
  );
  const countryCode = useSelector(
    (state) => state.settings.order_filled_sms_alert_country_code
  );
  const [isOn, setIsOn] = useState(false);
  const [isOrderFilledAlertOn, setIsOrderFilledAlertOn] = useState(false);
  const [isSmsAlertOn, setSmsAlertOn] = useState(false);
  const [countryNameDefault, setCountryName] = useState(countryName?countryName:"australia");
  const updateProfileStart = useSelector((state) => state.settings.updateProfileStart);
  const smsPhoneNumber = useSelector(
    (state) => state.settings.order_filled_sms_alert_phone?.replace(countryCode,'')
  );
  const [phoneNumber, setPhoneNumber] = useState(smsPhoneNumber);
  const notificationStatus = useSelector(
    (state) => state.settings.bot_notification_enabled
  );
  
  const orderFilledAlertStatus = useSelector(
    (state) => state.settings.order_filled_email_alert
  );
  const orderFilledSmsAlertStatus = useSelector(
    (state) => state.settings.order_filled_sms_alert
  );
  const orderFilledSmsAlert = useSelector(
    (state) => state.settings.order_filled_sms_alert
  );
  
  const subscription =
    useSelector((state) => state.auth.user.user_subscription) || {};
  const { subPremium, subTier } = checkSub(subscription);
  const pro = subPremium && subTier === SUB_TIER.pro
  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (notificationStatus != null) {
      setIsOn(notificationStatus == 1);
    }
    if (orderFilledAlertStatus != null) {
      setIsOrderFilledAlertOn(orderFilledAlertStatus == 1);
    }
    if (orderFilledSmsAlertStatus != null) {
      setSmsAlertOn(orderFilledSmsAlertStatus == 1);
    }
    if (countryName != null) {
      setCountryName(countryName);
    }
    if (smsPhoneNumber != null) {
      setPhoneNumber(smsPhoneNumber);
    }
   
  }, [notificationStatus,orderFilledAlertStatus,orderFilledSmsAlertStatus,smsPhoneNumber,countryName]);

  const changeNotification = (isOn) => {
    setIsOn(isOn);
    updateNotificationStatus(isOn ? 1 : 0);
  };
  const changeEmailAlert = (isOn) => {
    setIsOrderFilledAlertOn(isOn);
    updateEmailAlertStatus(isOn ? 1 : 0);
  };
  const changeSmsAlert = (isOn) => {
    setSmsAlertOn(isOn);
    if(!isOn){
      updateSmsAlertStatus(0,countryNameDefault,phoneNumber)
    }
    //updateEmailAlertStatus(isOn ? 1 : 0);
  };
  const changePhoneNumber = (val) => {
    //setSmsAlertOn(isOn);
    setPhoneNumber(val);
  };
  const updatePhoneNumber = ()=>{
    updateSmsAlertStatus(isSmsAlertOn?1:0,countryNameDefault,phoneNumber).then(()=>{
      toast.success(t("success-msg"))
    })
  }
  const countryItems = Object.keys(SmsCountriesNames).map(function(key, index) {
    return (
      <option key={key} value={key}>
      {SmsCountriesNames[key]}
    </option>
    );
  });

  return (
    <>
      
          <Row>
            <Col>
            <h5>{t("turn-off-notif")}</h5>
              <div className="card-white">
                    <div className="cw-body settings-paper-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <p>
                            {t("turn-off-notif-desc")}
                          </p>
                        </div>
                        <div>
                        <Switch
                          checkedIcon={false}
                          uncheckedIcon={false}
                          checked={isOn}
                          onChange={changeNotification}
                        />
                        </div>
                      </div>
                </div>
                </div>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <Row>
            <Col>
            <h5>{t("turn-off-filled-emails")}</h5>
              <div className="card-white">
                    <div className="cw-body settings-paper-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <p>
                          {t("turn-off-filled-emails-desc")}
                          </p>
                        </div>
                        <div>
                        <Switch
                            checkedIcon={false}
                            uncheckedIcon={false}
                            checked={isOrderFilledAlertOn}
                            onChange={changeEmailAlert}
                          />
                        </div>
                      </div>
                </div>
                </div>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <Row>
            <Col>
            <h5>{t("turn-on-sms-notification")}  
              {!pro?<>({t("only-for-pro-plan")})</>:<></> }
              </h5>
              <div className="card-white">
                    <div className="cw-body settings-paper-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <p>
                          {t("turn-on-sms-notification-desc")}
                          </p>
                        </div>
                        <div>
                        {pro?
                         
                          <Switch
                            checkedIcon={false}
                            uncheckedIcon={false}
                            checked={isSmsAlertOn}
                            onChange={changeSmsAlert}
                          />
                        :<></>}
                        </div>
                      </div>
                      {isSmsAlertOn && 
                          <>
                          <Col xs={12} xl={10}>
                            <Form.Group as={Row} className="mt-4">
                            <Form.Label column sm={3}>
                              {t("country-code")}
                            </Form.Label>
                            <Col sm={9} style={{paddingLeft: "0px"}}>
                              <Form.Control
                                as="select"
                                onChange={(e) => setCountryName(e.target.value)}
                                value={countryNameDefault}
                                className="input-text"
                              >
                                {countryItems}
                              </Form.Control>
                            </Col>
                          </Form.Group>
                          </Col>
                          <Col xs={12} xl={10}>
                            <Form.Group as={Row} className="mt-4">
                            <Form.Label column sm={3}>
                              {t("enter-your-mobile-number")} {" "} 
                              
                            </Form.Label>
                            <Col sm={9}>
                              <Row>
                              <Col sm={2} style={{paddingLeft:"0px"}}> 
                              <input type="text" readonly="readonly" className="form-control input-text country_code" value={SmsCountriesCode[countryNameDefault]}></input>
                              {/* <span className="country_code">{}</span> */}
                              </Col>
                                <Col sm={10}><Form.Control
                                  as="input"
                                  value={phoneNumber}
                                  onKeyPress={(e) => {
                                    restrictAlphabets(e);
                                }}
                                  onChange={(e) => changePhoneNumber(e.target.value)}
                                  className="input-text"
                                /></Col>
                              </Row>
                                
                              
                                
                            </Col>
                          </Form.Group>
                          </Col>
                          <Col xs={12} xl={10}>
                          <Row className="justify-content-center mt-4">
                              <Button onClick={updatePhoneNumber} variant="success">
                                {updateProfileStart ? t("updating") : t("update")}
                              </Button>
                            </Row>
                          </Col>
                          </> }
                </div>
                </div>
            </Col>
          </Row>
      
       
    </>
  );
};

export default connect(null, {
  getSettings,
  updateNotificationStatus,
  updateEmailAlertStatus,
  updateSmsAlertStatus
})(NotificationsPanel);
