import React from "react";

const CustomRadio = (props)=>{
    
    return(
        <>
           <label class="radiobtn-container">{props.label}
            <input type="radio" 
            checked={props.checked} 
            name={props.name} 
            onChange={(e) => props.callback(e.target.value)} 
            value={props.val}></input>
            <span class="checkmark-radio"></span>
            </label>
        </>
    )
}

export default CustomRadio;