import React, { useRef, useState } from "react";
import { useOnHoverOutside } from "../../utils/useOnHoverOutside";
import { BotType, BotTypeName, SUB_TIER } from "../../Const";
import { checkSub, isPremium } from "../../utils/SubUtils";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_BOT_TYPE, CREATE_BOT_START } from "../../actions/create-bot-type";
import { useHistory, useLocation } from "react-router-dom";

const BotTypeList = (props)=>{
    const dropdownRef = useRef(null)
    const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);
    const subscription =
    useSelector((state) => state.auth.user.user_subscription) || {};
    const isPaid = isPremium(subscription);
    const exchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
    const isFuture = useSelector((state) => state.settings.isFuture);
    const { subPremium, subTier } = checkSub(subscription);
    const closeHoverMenu = () => {
        setMenuDropDownOpen(false);
    };
    const dispatch = useDispatch();
    const history = useHistory();
    const clickCreateBot = (botType,item) => {
      const {pro,master,isArbitrage,disabled,disabledItems} = checkEligible(item)
     if(disabledItems || disabled)
     return false
      dispatch({
        type: CHANGE_BOT_TYPE,
        payload: botType ?? BotType.simple,
      });
      dispatch({
        type: CREATE_BOT_START,
        payload: true,
      });
      history.push('/bots/create')

    };
    useOnHoverOutside(dropdownRef, closeHoverMenu); // Call the hook
    const checkEligible = (item)=>{
        const disabled = BotType.simple !== BotType[item] && (!isPaid || subTier === SUB_TIER.lite);
        const pro = subPremium && subTier === SUB_TIER.pro
        const disabledItems = (BotType.grid==BotType[item] && !(pro)) 
        return {pro,disabled,disabledItems}
    }

    const botItems = Object.keys(BotType).map((item, id) => {
        const {pro,disabled,disabledItems} = checkEligible(item)
        //const disabledItems = false;
        //const disabled = BotType.simple !== BotType[item] && !isPaid;
        
        return (
          <li key={id} disabled={disabledItems?true:disabled} className="bot-types pointer bot-type-li" onClick={()=>clickCreateBot(BotType[item],item)}>
            <img src={`/images/icons/bots/svg/${item}.svg`} className="bot-icon-list mr-2" width={20}></img>
            <span className="bot-name">{BotTypeName[item]} {(disabled && (BotType.grid !=BotType[item])) && `(Premium)`} {((BotType.grid ==BotType[item]) && !(pro) ) && `(Pro Plan)`}</span>
          </li>
        );
      });
    return(
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-4 v-pad d-flex justify-content-center" ref={dropdownRef}>
            <div className="pos-relative" >
            <div className="create-bot-icon" onMouseOver={() => setMenuDropDownOpen(true)}>
            <img src="/images/icons/add-bot.svg" className="add-bot-icon"></img>
            </div>
            {isMenuDropDownOpen && 
            <div className="bot-type-list">
            <ul className="bot-list-ul">
               {botItems}
            </ul>
            </div>
            }
        </div>  
      </div>
    )
}

export default BotTypeList;