import React from "react";
import CustomCheckbox from "./CustomCheckbox";
import { SET_DISABLE_FRACTION, SET_DISABLE_FRACTION_RENT} from "../../../../actions/create-bot-type";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { disableFractionExchanges } from "../../../../utils/Utils";
import LeftRightForm from "./LeftRightForm";

const DisableFractionalPurchase = (props)=>{
    const {rent} = props
    const newBot = useSelector((state) => state.createBot.newBot);
    const rentBot = useSelector((state) => state.createBot.rentBot);
    const {t} = useTranslation()
    const selectedExchange = newBot.exchange;
    const disableFractionPurchaseRent =isNaN(rentBot.disableFractionPurchase)?0:parseInt(rentBot.disableFractionPurchase);
    const disableFractionPurchase =isNaN(newBot.disableFractionPurchase)?0:parseInt(newBot.disableFractionPurchase);
    const dispatch = useDispatch()
    const setDisableFraction = (val) => {
        dispatch({
          type: rent?SET_DISABLE_FRACTION_RENT:SET_DISABLE_FRACTION,
          payload: val?1:0,
        });
      };
    return(<>
        {disableFractionExchanges(selectedExchange) && 
        
            <LeftRightForm 
            left={<></>}
            right={ <CustomCheckbox 
                val={rent?disableFractionPurchaseRent:disableFractionPurchase} 
                checked={rent?disableFractionPurchaseRent:disableFractionPurchase} 
                callback={setDisableFraction}
                label={t("dis-frac-purchase")}
                id={'dis-frac-purchase'}
                >
                </CustomCheckbox>}
            >
            </LeftRightForm>
        
           }
    </>)
}

export default DisableFractionalPurchase;