import { Modal, Form, Button } from 'react-bootstrap';
import React, { useState } from 'react';
import './ContactForm.scss';
import { connect, useSelector } from 'react-redux';
import useInput from '../hooks/useInput';
import { toast } from 'react-toastify';
import { updateContactForm } from '../../actions/setting-action';
import Loading from '../common/Loading';
import { useHistory } from 'react-router-dom';

const ContactForm = (props) => {
  const { show, onHide, updateContactForm, defaultTitle, defaultDetail } =
    props;
  const [loading, setLoading] = useState(false);
  const email = useSelector((state) => state.auth.user.email) || '';
  const [title, bindTitle, resetTitle] = useInput(defaultTitle);
  const [detail, bindDetail, resetDetail] = useInput(defaultDetail);
  const [replyTo, bindReplyTo] = useInput(email);
  const history = useHistory();

  const clickSubmit = () => {
    setLoading(true);
    updateContactForm({
      subject: title,
      message: detail,
      reply_to: replyTo,
    })
      .then(() => {
        resetTitle();
        resetDetail();
        setLoading(false);
        onHide();
        toast.info(
          'Thank you for contacting us. We will reply you as soon as possible.'
        );
        history.push(
          "/bots"
        );
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };
  const redirectBack = () => {
    history.goBack();
  };

  return (
    <Loading show={loading} onHide={() => redirectBack()}>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Support</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Subject</Form.Label>
            <Form.Control {...bindTitle} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Reply to</Form.Label>
            <Form.Control type="email" {...bindReplyTo} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Content</Form.Label>
            <Form.Control
              as="textarea"
              {...bindDetail}
              style={{ height: 150 }}
            />
          </Form.Group>
          <div className="text-center">
            <Button
              variant="success"
              className="w-50 mt-3"
              onClick={clickSubmit}
              disabled={title == '' || detail == '' || replyTo == ''}
            >
              Submit
            </Button>
          </div>
          <div style={{ marginTop: 20 }} className="text-center text-primary">
            {/* <a
              href="https://www.nftforum.io/viewforum.php?f=20"
              target="_blank"
            >
              * Please visit StockHero
            </a> */}
            <a
              href="https://docs.StockHero.ai/faq/frequently-asked-questions"
              target="_blank"
              style={{ marginLeft: 20 }}
            >
              * FAQ
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </Loading>
  );
};

export default connect(null, { updateContactForm })(ContactForm);
