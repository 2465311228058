import React, { useState, useEffect, useMemo } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { connect, useSelector, useDispatch } from "react-redux";
import { GET_TICKERS, RESET_TICKERS, SELECT_PAIR } from "../../actions/types";
import ReactScrollableList from "react-scrollable-list";
import "./WatchList.scss";
import { roundNumber } from "../../utils/Utils";
import useInput from "../hooks/useInput";
import binance from "./tickers/binance";
import { ScreenSize } from "../../Const";
import { getWatchList, updateWatchList } from "../../actions/terminal-action";
import SegmentController from "../common/SegmentController";
import useScreenSize from "../hooks/useScreenSize";

const TabType = {
  star: <i className="fas fa-star" style={{ fontSize: 12 }}></i>,
  usd: "USD"
};

const WatchList = (props) => {
  const [tab, setTab] = useState(TabType.usd);
  const [search, bindSearch] = useInput("");

  const { usdPairs } = useSelector((state) => state.bots);
  const tickers = useSelector((state) => state.terminal.tickers);
  const lastTickers = useSelector((state) => state.terminal.lastTickers);
  const exchangeId = useSelector((state) => state.settings.selectedExchangeId);
  const [selectedExchangePairs, setSelectedExchangePairs] = useState([]);
  const [totalPairs, setTotalPairs] = useState([]);
  const [connection, setConnection] = useState(binance);
  const dispatch = useDispatch();

  const screenSize = useScreenSize();

  let watchListHeight;

  switch (screenSize) {
    case ScreenSize.medium:
      watchListHeight = 380;
      break;
    case ScreenSize.large:
      watchListHeight = 600;
      break;
    case ScreenSize.xlarge:
      watchListHeight = 700;
      break;
    default:
      watchListHeight = 350;
  }

  // Update data from exchange
  useEffect(() => {
    if (selectedExchangePairs.length && connection) {
      connection.connect(selectedExchangePairs, (loadTickers) => {
        loadTickers.forEach((el) => {
          dispatch({ type: GET_TICKERS, payload: { ...el, exchangeId } });
        });
      });
      return () => {
        connection.disconnect();
      };
    }
  }, [selectedExchangePairs, connection]);

  // Get favourite pairs
  useEffect(() => {
    dispatch({ type: RESET_TICKERS });
    props.getWatchList().then((watchList) => {
      if (watchList && watchList.length) {
        setTab(TabType.star);
      }
    });
  }, []);

  // Save pairs for first time
  useEffect(() => {
    if (usdPairs) {
      usdPairs.forEach((el) => {
        const pair = el.pair;
        dispatch({
          type: GET_TICKERS,
          payload: {
            type: TabType.usd,
            pairId: pair.id,
            realPairId: el.pair_id,
            exchangeId: el.exchange_id,
            from: pair.from,
            to: pair.to,
            s: pair.from+pair.to,
            c: 0,
            p: 0,
            P: 0,
            v: 0,
            l: 0,
            h: 0,
          },
        });
      });
      
      
      setTotalPairs([...usdPairs]);
    }
  }, [usdPairs]);

  // Filter current exchange Pairs, if paper choose from Binance
  useEffect(() => {
    const pairs = totalPairs
      .filter((el) => {
        return el.exchange_id === exchangeId;
      })
      .map((el) => {
        return { from: el.pair.from, to: el.pair.to };
      });

    setSelectedExchangePairs(pairs);
  }, [totalPairs, exchangeId]);

  // Render UI
  const filterTickers = useMemo(
    () =>
      tickers
        .filter((el) => el.c != null)
        .filter((el) => {
          return el.exchangeId === exchangeId;
        })
        .filter((el) => {
          if (tab === TabType.star) {
            return el.star;
          } else {
            return el.type === tab;
          }
        })
        .filter((el) => {
          if (search.length) {
            return el.from.toLowerCase().indexOf(search.toLowerCase()) >= 0;
          } else {
            return true;
          }
        })
        .sort((el1, el2) => (el1.from > el2.from ? 1 : -1)),
    [tickers, exchangeId, tab, search]
  );
  const tickerItems = useMemo(
    () =>
      filterTickers.map((el, id) => {
        const selectPair = () => {
          dispatch({
            type: SELECT_PAIR,
            payload: el,
          });
        };

        const toggleStar = () => {
          props
            .updateWatchList({
              exchange_id: el.exchangeId,
              pair_name: el.s,
              status: el.star ? 0 : 1,
            })
            .then(() => {});
        };

        const lastTicker = lastTickers.find((item) => item.s === el.s) || {
          c: 0,
        };

        return {
          id,
          content: (
            <Row noGutters className="text-primary p-1 align-items-center">
              <i
                className={`${el.star ? "fas" : "far"} fa-star star`}
                onClick={toggleStar}
                style={{ width: "10%" }}
              ></i>
              <Row onClick={selectPair} style={{ width: "90%" }} noGutters>
                <Col className="text-pair" xl={6}>
                  {el.from}/{el.to}
                </Col>
                <Col
                  className={
                    "text-price" +
                    (lastTicker.c < el.c
                      ? "-up"
                      : lastTicker.c > el.c
                      ? "-down"
                      : "")
                  }  xl={3}
                >
                  {roundNumber(Number(el.c))}
                </Col>
                <Col xl={3} className={"text-change" + (el.P < 0 ? "-down" : "-up")}>
                  {roundNumber(Number(el.P), 2)}%
                </Col>
              </Row>
            </Row>
          ),
        };
      }),
    [filterTickers]
  );

  return (
    <div className="watchlist">
      <div>
        <Form.Control placeholder="Search" {...bindSearch} />
      </div>
      <SegmentController
        tabs={Object.values(TabType)}
        activeTab={tab}
        changeTab={setTab}
        style={{ marginTop: 10, marginBottom: 10 }}
      />
      <ReactScrollableList
        listItems={tickerItems}
        heightOfItem={30}
        maxItemsToRender={30}
        style={{ color: "#333", height: watchListHeight }}
      />
    </div>
  );
};

export default connect(null, { getWatchList, updateWatchList })(WatchList);
