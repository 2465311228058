import React from "react";
import { Col, Row } from "react-bootstrap";

const LeftRightForm = (props)=>{
    const { left, right,leftCol,rightCol,className } = props
    return(
        <>
            <Row className={`mt-2 mb-2 form-row-div ${className}` }>
                <Col xl={leftCol??4} className="cb-form-label">
                    {left}
                </Col>
                <Col xl={rightCol??8}>
                    {right}
                </Col>
            </Row>
        </>
    )
}
export default LeftRightForm;