
import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col, Button, Accordion, Form } from "react-bootstrap";
import "./AddBotMarketplaceModal.scss";
import useLoading from "../../hooks/useLoading";
import BotSelectionView from "./BotSelectionView"
import BotStatisticsView from "./BotStatisticsView"
import BotSubmitDescriptionView from "./BotSubmitDescriptionView"
import BotImageUploadView from "./BotImageUploadView"
import { RESET_TEMPLATE_MODAL, SET_BOT_SELECTED} from "../../../actions/types"
import { toast } from "react-toastify";

import Loading from "../../common/Loading";
import { getAvailableBots } from "../../../actions/marketplace-action";
import { submitForReview } from "../../../actions/bot-template-action";
import TemplateSubmitSuccess from "./TemplateSubmitSuccess";
import { setShowUploadImageModal } from "../../../actions/image-upload-action";
import { marketplaceTerms } from "../../../actions/const";
import { Link, useHistory } from "react-router-dom";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import ContentContainer from "../../common/ContentContainer";
import CustomCheckbox from "../../bots/new-bot/modules/CustomCheckbox";

const AddBotMarketplace = (props) => {
  const {
    onHide,
    getAvailableBots,
    submitForReview,
    setShowUploadImageModal
  } = props;
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const diplayTemplateImage = useSelector((state) => state.newTemplate.displayTemplateImg) || '';
  const botSelected = useSelector((state) => state.newTemplate.botSelected) || '';
  const [setLoading, bindLoading] = useLoading(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [showSubmitSuccessModal, setshowSubmitSuccessModal] = useState(false);
  const [showNoBotsAvailableModal, setNoBotsAvailableModal] = useState(true);
  const [checked,setChecked] = useState(false)
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const history = useHistory();
  useEffect(() => {
    //getAvailableBots()
    dispatch({
      type: RESET_TEMPLATE_MODAL,
      payload: {},
    });
    dispatch({
      type: SET_BOT_SELECTED,
      payload: {},
    });
  }, []);
  const botsAvailable = useSelector((state)=>state.newTemplate.botsAvailable) || []
  const clicksubmitForReview = () => {
    if(!botSelected.id){
      toast.error(t("pls-sel-bot"));
      return false
    }
    if(!enableSubmit)
    return false
    setLoading(true);
      submitForReview()
      .then(() => {
        setLoading(false);
        setshowSubmitSuccessModal(true)
        //toast.success("Bot has been updated");
        //onHide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };
  const closeAllModals = ()=>{
    setshowSubmitSuccessModal(false);
    history.push("/manage-bot?tab=1")
  }
  const closeNoBotsAvailableModal = ()=>{
    setNoBotsAvailableModal(false);
    history.push("/marketplace")
  }
  const hideModals = ()=>{
    setShowUploadImageModal(false)
    onHide();
    dispatch({
      type: RESET_TEMPLATE_MODAL,
      payload: {},
    });
    
  }
  //const botsAvailable =  []
  

  return (
    <>
    {(botsAvailable && botsAvailable.length > 0) ? (<>
      <ContentContainer url="marketplace" title={t("marketplace")} >
      <div className="dashboard-content">
        <div>
          
          <Row>
            <Col xl={6} className="marketplace-bot-selection">
                <div className="card-white">
                <div className="cw-body">
                  <BotSelectionView bots={botsAvailable} t={t}></BotSelectionView>
                  <BotStatisticsView bot={botSelected} t={t}></BotStatisticsView>
                </div>
              </div>
              {botSelected && botSelected.deals_analysis && 
              <div className="card-white mt-4">
               <div className="cw-body">
               <BotImageUploadView />
               </div>
             </div>}
            </Col>
            {botSelected && botSelected.deals_analysis && 
            <>
            <Col xl={6} className="marketplace-bot-selection">
            <div className="card-white">
              <div className="cw-body">
                <BotSubmitDescriptionView bot={botSelected} t={t}></BotSubmitDescriptionView>
                <Row>
                  <Col xl={12} className="mt-3 agree-terms-marketplace">
                  {/* <Form.Check
                    label=
                    className="terms"
                    value={checked}
                    onChange={(e) => setEnableSubmit(e.target.checked)}
                  /> */}
                  <CustomCheckbox 
                    val={checked} 
                    callback={setEnableSubmit}
                    label={
                      <Trans i18nKey='mp-disclaimer'>
                        <a href={marketplaceTerms} target="_blank"></a>
                      </Trans>
                      
                    }
                    id={'mp-disclaimer'}
                    >
                  </CustomCheckbox>
                  </Col>
              </Row>
              <Row className="text-center">
              <Col xs={12} >
                  <Button
                    className={`w-50 blue-btn mb-3 ${enableSubmit?'':'disabled'}`}
                    onClick={clicksubmitForReview}
                  >
                    {t("sub-for-review")}
                </Button>
                  </Col>
              </Row>
              <Row>
              <Col xl={12} className="marketplace-submit-info">
                    <p>{t("mp-disclaimer-1")}</p>
                  </Col>
              </Row>
                </div>
                </div>
            </Col>
             
           </>
            }
          </Row>
          
        </div>
        </div>
      </ContentContainer>
      </>):(<>
        <Modal show={showNoBotsAvailableModal} onHide={()=>closeNoBotsAvailableModal()} size="md" dialogClassName="modal-90w">
        <Modal.Header closeButton className="align-items-center no-bottom-border">
        </Modal.Header>
        <Modal.Body className="no-bots-available-modal" >
        <p>{t("no-eligible-bots")}</p>
        </Modal.Body>
        </Modal>
        </>)}
     
      {showSubmitSuccessModal && <TemplateSubmitSuccess 
        onHide={()=>closeAllModals(false)}
        /> }
      
      <Loading {...bindLoading} />
    </>
  );
};

export default connect(null, {getAvailableBots,submitForReview,setShowUploadImageModal
})(AddBotMarketplace);
