import React, { useEffect } from "react";
import useCheckbox from "../../../hooks/useCheckbox";
import useInput from "../../../hooks/useIndicatorInput";
import IsRequiredModule from "./IsRequiredModule";
import { restrictAlphabets } from "../../../../utils/Utils";
const BbEntrySetting = (props) => {
  const { indicator, updateIndicator, isLong,t } = props;
  const [lowerLong, bindLowerLong, setLowerLong] = useInput(indicator.value2);
  const [period, bindPeriod, setPeriod] = useInput(indicator.period_num, 0);
  const [upperShort, bindUpperShort, setUpperShort] = useInput(
    indicator.value3
  );
  

  useEffect(() => {
    updateIndicator({
      ...indicator,
      value2: lowerLong,
      value3: upperShort,
      period_num: period,
    });
  }, [lowerLong, period, upperShort]);

  useEffect(() => {
    setLowerLong(indicator.value2);
    setPeriod(indicator.period_num);
    setUpperShort(indicator.value3);
  }, [indicator]);

  return (
    <div>
      {isLong ? (
        <>
          <h6>{t("long-strategy")}</h6>
          {/* <div>
            <label>{t("lower-band-dev")}:</label>
            <input type="number" {...bindLowerLong} step="0.01"  className="input-bg-color"/>
          </div> */}
          <div className="d-flex justify-content-between indicator-setting-form align-items-center">
          <div><label className="indicator-setting-label">{t("lower-band-dev")}</label></div>
          <div> <input type="number" {...bindLowerLong} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
        </>
      ) : (
        <>
          <h6>{t("short-strategy")}</h6>
          <div className="d-flex justify-content-between indicator-setting-form align-items-center">
          <div><label className="indicator-setting-label">{t("upper-band-dev")}</label></div>
          <div> <input type="number" {...bindUpperShort} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
        </>
      )}
      <div className="d-flex justify-content-between indicator-setting-form align-items-center">
          <div><label className="indicator-setting-label">{t("period")}</label></div>
          <div> <input type="number" {...bindPeriod} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
      <IsRequiredModule indicator={indicator} updateIndicator={updateIndicator} t={t}></IsRequiredModule>
    </div>
  );
};

export default BbEntrySetting;
