import React from "react";
import SliderView from "../SliderView";
import TutorialPopup from "../../../help/TutorialPopup";
import * as HelpContent from "../../../../utils/HelpContent";
import { Card, Accordion,Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CHANGE_DCA_EXIT,SET_CLOSE_AFTER_DEAL,CHANGE_TAKE_PROFIT, SET_STOP_AFTER_DEAL, CLOSE_DEAL_END_OF_TRADING_DAY } from "../../../../actions/create-bot-type";
import TakeProfitView from "../takeprofit/TakeProfitView";
import CustomCheckbox from "../modules/CustomCheckbox";

const DcaExitView = (props) => {
  const { newBot,t } = props;
  const dispatch = useDispatch();
  const stopBotAfterDeal = newBot.stopBotAfterDeal?true:false;
  const endDayDealClose = newBot.closeDealEndOfTradingDay?true:false;
  const changeDcaExit = (val) =>
    dispatch({
      type: CHANGE_DCA_EXIT,
      payload: val,
    });
  
  const setDealStopValue = (val) => {
    dispatch({
      type: SET_STOP_AFTER_DEAL,
      payload: val,
    });
  };
  const setAutoCloseDealValue = (val) => {
    dispatch({
      type: CLOSE_DEAL_END_OF_TRADING_DAY,
      payload: val?1:0,
    });
  };
  const formatDcaExit = (val) => (val === 0 ? t("no-limit") : String(val));
  return (
    <>
      
            <SliderView
              title={t("no-of-ext-order")}
              step={1}
              min={0}
              max={500}
              value={newBot.dcaExit}
              onChange={changeDcaExit}
              formatLabel={formatDcaExit}
            />
            <TakeProfitView t={t}></TakeProfitView>
            {/* <Form.Check
                  type="checkbox"
                  label={t("stop-bot-after-deal-ends")}
                  value={stopBotAfterDeal}
                  checked={stopBotAfterDeal}
                  onChange={(e) => setDealStopValue(Boolean(e.target.checked))}
            /> */}
             <CustomCheckbox 
                    val={stopBotAfterDeal} 
                    checked={stopBotAfterDeal} 
                    callback={setDealStopValue}
                    label={t("stop-bot-after-deal-ends")}
                    id={'stop-bot-after-deal-ends'}
                    ></CustomCheckbox>
            <div className="d-flex align-items-center dis-bot-stop-loss mt-3">
                 
                 <div style={{width:"100%"}}>
                 {/* <Form.Check
                 type="checkbox"
                 label={t("auto-close-trading")}
                 value={endDayDealClose}
                 checked={endDayDealClose}
                 onChange={(e) => setAutoCloseDealValue(Boolean(e.target.checked))}
                 /> */}
                 <CustomCheckbox 
                    val={endDayDealClose} 
                    checked={endDayDealClose} 
                    callback={setAutoCloseDealValue}
                    label={t("auto-close-trading")}
                    id={'auto-close-trading'}
                    ></CustomCheckbox>
                 </div>
                
               </div>
    </>
  );
};

export default DcaExitView;
