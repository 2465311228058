import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import Pagination from "../common/CustomPagination";
import { connect, useSelector } from "react-redux";
import usePagination from "../hooks/usePagination";
import { formatNumberToString, roundNumber } from "../../utils/Utils";
import { getPositions } from "../../actions/terminal-action";

const PositionTab = (props) => {
  const { getPositions, account,t } = props;
  //const [range, bindPagination] = usePagination();
  const positions = useSelector((state) => state.terminal.positions) || [];
  const tickers = useSelector((state) => state.terminal.tickers) || [];
  const selectedExchangeId = useSelector(
    (state) => state.settings.selectedExchangeId
  );
  useEffect(() => {
    getPositions({ exchange_id: selectedExchangeId, account }).then(
      console.log
    );
  }, [account, selectedExchangeId]);
  const rows = positions.map((item, index) => {
    let price;
    // if (item.coin_name === "USDT") {
    //   price = 1;
    // } else {
    //   const ticker =
    //     tickers.find(
    //       (el) =>
    //         el.from === item.coin_name &&
    //         el.to === "USDT" &&
    //         selectedExchangeId == el.exchangeId
    //     ) || {};
    //   price = ticker.c || 0;
    // }

    //const totalUSDT = price * item.amount;
    return (
      <tr key={index} className="theme-border">
        <td className="text-left">
          <img src={item.coin_image} width={15} className="mr-2 ml-4"></img>
          {item.coin_name}
        </td>
        {/* <td>{roundNumber(price)} USDT</td> */}
        <td>
          {formatNumberToString(item.amount)} {item.coin_name}
        </td>
        {/* <td>{roundNumber(totalUSDT)} USDT</td> */}
      </tr>
    );
  });
  return (
    <div className="mt-3" style={{ minHeight: 200 }}>
      <Table responsive className="theme-bg">
        <thead>
          <tr>
            <th>Stock</th>
            {/* <th>Last</th> */}
            <th>{t('amount')}</th>
            {/* <th>Total</th> */}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      {/* <Pagination {...bindPagination} totalCount={positions.length} /> */}
    </div>
  );
};

export default connect(null, { getPositions })(PositionTab);
