import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { restrictAlphabets } from "../../../../utils/Utils";
import TutorialPopup from "../../../help/TutorialPopup";
import useInput from "../../../hooks/useIndicatorInput";
import IsRequiredModule from "./IsRequiredModule";
import CustomCheckbox from "../modules/CustomCheckbox";

const StochRsiEntrySetting = (props) => {
  const { indicator, updateIndicator, isLong,t } = props;
  const [boLong, bindBoLong, setBoLong] = useInput(indicator.value2);
  const [eoLong, bindEoLong, setEoLong] = useInput(indicator.value5);
  const [boShort, bindBoShort, setBoShort] = useInput(indicator.value3);
  const [eoShort, bindEoShort, setEoShort] = useInput(indicator.value6);
  const [isAverage, setIsAverage] = useState(indicator.value7?1:0);
  const [periodLong, bindPeriodLong, setPeriodLong] = useInput(
    indicator.period_num,
    0
  );
  
  const [boAvgPeriod, bindBoAvgPeriod, setBoAvgPeriod] = useInput(
    indicator.value7,
    0
  );
  const [eoAvgPeriod, bindEoAvgPeriod, setEoAvgPeriod] = useInput(
    indicator.value8,
    0
  );
  const [trailingLong, setTrailingLong] = useState(indicator.value4);

  useEffect(() => {
    updateIndicator({
      ...indicator,
      value2: boLong,
      value5: eoLong,
      value3: boShort,
      value6: eoShort,
      value7: boAvgPeriod,
      value8: eoAvgPeriod,
      period_num: periodLong,
      value4: trailingLong
    });
  }, [boLong, eoLong, boShort, eoShort, periodLong, trailingLong,boAvgPeriod,eoAvgPeriod]);

  useEffect(() => {
    setBoLong(indicator.value2);
    setEoLong(indicator.value5);
    setBoShort(indicator.value3);
    setEoShort(indicator.value6);
    setBoAvgPeriod(indicator.value7);
    setEoAvgPeriod(indicator.value8);
    setPeriodLong(indicator.period_num);
    setTrailingLong(indicator.value4);
  }, [indicator]);
  const changeStrategy = (val)=>{
    
    if(val==0){
      updateIndicator({
        ...indicator,
        value7: 0,
        value8: 0,
      })
    }
    setIsAverage(val)
  }

  return (
    <div>
     
      <div className="d-flex justify-content-between indicator-setting-form align-items-center ">
              <div><label className="indicator-setting-label">{isLong ? t("long") : t("short")} {t("strategy")}</label></div>
              <div> <select className="form-control select-normal" onChange={(e)=>{changeStrategy(e.target.value)}}>
                  <option value={0} key={1} selected={isAverage==0}>Current</option>
                  <option value={1} key={2} selected={isAverage==1}>Average</option>
              </select></div>
            
            </div>
      {isAverage==0 ? 
      <>
        {isLong ? (
          <>
            {/* <h6>{t("long-strategy")}</h6> */}
            
            <div className="d-flex justify-content-between indicator-setting-form align-items-center ">
              <div><label className="indicator-setting-label">{t("buy-stoch-rsi-bo")}</label></div>
              <div> <input type="number" {...bindBoLong} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                          restrictAlphabets(e);
                        }}/></div>
            
            </div>
           
            <div className="d-flex justify-content-between indicator-setting-form align-items-center ">
              <div><label className="indicator-setting-label">{t("buy-stoch-rsi-eo")}</label></div>
              <div> <input type="number" {...bindEoLong} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                          restrictAlphabets(e);
                        }}/></div>
            
            </div>
          </>
        ) : (
          <>
            {/* <h6>{t("short-strategy")}</h6> */}
            
            <div className="d-flex justify-content-between indicator-setting-form align-items-center ">
              <div><label className="indicator-setting-label">{t("sell-stoch-rsi-bo")}</label></div>
              <div> <input type="number" {...bindBoShort} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                          restrictAlphabets(e);
                        }}/></div>
            
            </div>
           
            <div className="d-flex justify-content-between indicator-setting-form align-items-center ">
              <div><label className="indicator-setting-label">{t("sell-stoch-rsi-eo")}</label></div>
              <div> <input type="number" {...bindEoShort} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                          restrictAlphabets(e);
                        }}/></div>
            
            </div>
          </>
        )}
      
      
      <div>
         <CustomCheckbox 
                    //val={stopBotAfterDeal} 
                    checked={trailingLong == 1}
                    callback={setTrailingLong}
                    label={<>{t("activate-t-s-rsi")} <TutorialPopup
                      content={isLong ? t("trailingSRSILong") : t("trailingSRSIShort")}
                    /></>}
                    id={'activate-t-s-rsi'}
                    >
          </CustomCheckbox>
      </div>
      </>
      :
      <>
       {isLong ? (
          <>
            {/* <h6>{t("long-strategy")}</h6> */}
            <div className="d-flex justify-content-between indicator-setting-form">
                <div>
                  <label className="indicator-setting-label"> {t("buy-srsi-level-bo-avg")}:</label>
                </div>
                <div className="mt-3"> 
                  <input type="number" {...bindBoLong} className="input-bg-color form-control indicator-setting-input "  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/>
                </div>
                <div className="break"><label className="indicator-setting-label ml-3"> {t("srsi-based-on-periods")}:</label></div>
                <div className="mt-3"> <input type="number" {...bindBoAvgPeriod} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
                <div className="mt-3"><label className="indicator-setting-label ml-3 ">{t("periods")}</label></div>
            </div>
            <div className="d-flex justify-content-between indicator-setting-form">
              <div>
                <label className="indicator-setting-label"> {t("buy-srsi-level-eo-avg")}:</label>
              </div>
              <div className="mt-3"> 
                <input type="number" {...bindEoLong} className="input-bg-color form-control indicator-setting-input "  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/>
              </div>
              <div className="break"><label className="indicator-setting-label ml-3"> {t("rsi-based-on-periods")}:</label></div>
              <div className="mt-3"> <input type="number" {...bindEoAvgPeriod} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
              <div className="mt-3"><label className="indicator-setting-label ml-3 ">{t("periods")}</label></div>
            </div>
          </>
        ) : (
          <>
            {/* <h6>{t("short-strategy")}</h6> */}
            {/* <div>
              <label>{t("sell-stoch-rsi-bo")}:</label>
              <input type="number" {...bindBoShort} className="input-bg-color"/>
            </div>
            <div>
              <label>{t("sell-stoch-rsi-eo")}:</label>
              <input type="number" {...bindEoShort} className="input-bg-color" />
            </div> */}
            <div className="d-flex justify-content-between indicator-setting-form">
                <div>
                  <label className="indicator-setting-label"> {t("sell-srsi-level-bo-avg")}:</label>
                </div>
                <div className="mt-3"> 
                  <input type="number" {...bindBoShort} className="input-bg-color form-control indicator-setting-input "  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/>
                </div>
                <div className="break"><label className="indicator-setting-label ml-3"> {t("srsi-based-on-periods")}:</label></div>
                <div className="mt-3"> <input type="number" {...bindBoAvgPeriod} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
                <div className="mt-3"><label className="indicator-setting-label ml-3 ">{t("periods")}</label></div>
            </div>
            <div className="d-flex justify-content-between indicator-setting-form">
              <div>
                <label className="indicator-setting-label"> {t("sell-srsi-level-eo-avg")}:</label>
              </div>
              <div className="mt-3"> 
                <input type="number" {...bindEoShort} className="input-bg-color form-control indicator-setting-input "  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/>
              </div>
              <div className="break"><label className="indicator-setting-label ml-3"> {t("srsi-based-on-periods")}:</label></div>
              <div className="mt-3"> <input type="number" {...bindEoAvgPeriod} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
              <div className="mt-3"><label className="indicator-setting-label ml-3 ">{t("periods")}</label></div>
            </div>
          </>
        )}
      </>}
      
      <div className="d-flex justify-content-between indicator-setting-form align-items-center mb-2">
              <div><label className="indicator-setting-label">{t("period")}</label></div>
              <div> <input type="number" {...bindPeriodLong} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                          restrictAlphabets(e);
                        }}/></div>
            
            </div>
      <IsRequiredModule indicator={indicator} updateIndicator={updateIndicator} t={t}></IsRequiredModule>
    </div>
  );
};

export default StochRsiEntrySetting;
