import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import ContentContainer from "../common/ContentContainer";
import { getTrending, saveQuestion, submitQuery } from "../../actions/ai-action";
import Loading from "../common/Loading";
import { Dropdown, DropdownButton, Tab, Tabs } from "react-bootstrap";
import { Line } from "react-chartjs-2";



const Report = (props) => {
  const tabTitles = ['Total','Stocks','Bots'];
  const [activeTab,setActiveTab] = useState(0);
  const changeTab = (index,tab) => {
    setActiveTab(index);
  };
  const tabHeaders = tabTitles.map((item, index) => {
    return (
      
        <a
          onClick={() => changeTab(index,index==0?'open':'close')}
          className={(index == activeTab ? "list-tab tab-active" : "list-tab tab-inactive" )}
          key={index}
        >
          {item}
        </a>
      
    );
  });
  const stocks = ['AAPL','AAPL']
  const [key, setKey] = useState('home');
  const stockList = stocks.map((item, index) => {
    return (
      <Dropdown.Item key={index} eventKey={item}>
        {item}
      </Dropdown.Item>
    );
  });
  return (
    <ContentContainer url="artificial-intelligence" title={"Report"} showExchange={true}>
    
      <div className="dashboard-content">
      <div className="d-flex">
          <div className="bot-tabs">
          {tabHeaders}
          </div>
          <div className="">
            {activeTab == 1 && (<div className="d-flex justify-content-between mt-20">
                  <div className="" >
                  <DropdownButton
                    title={'Select Stock'}
                    //onSelect={(index) => setTradeStatus(index)}
                    variant=""
                    className="deals-filter-btn report-select-btn"
                  >
                    {stockList}
                  </DropdownButton>
                  </div>
                </div>
              )}
            {activeTab == 2 && (<div className="d-flex justify-content-between mt-20">
                  <div className="" >
                  <DropdownButton
                    title={'Select Bot'}
                    //onSelect={(index) => setTradeStatus(index)}
                    variant=""
                    className="deals-filter-btn report-select-btn"
                  >
                    {stockList}
                  </DropdownButton>
                  </div>
                </div>
              )}
            </div>
      </div>
      <div className="card-white mpd-card mt-4">
          <div className="cw-body bot-detail">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab title="Return ($)" eventKey="return-dol">
              
            </Tab>
            <Tab title="Return (%)" eventKey="return-perc">
              
            </Tab>
            <Tab title="Trading Volume" eventKey="trading-vol">
             
            </Tab>
            <Tab title="Portfolio" eventKey="portfolio">
             
            </Tab>
            <Tab title="APY" eventKey="apy">
             
            </Tab>
          </Tabs>
            {/* <Line height={70} data={data(equities)} options={options} /> */}
            </div>
      </div>
     </div>
     
    </ContentContainer>
  );
};

export default connect(null,{submitQuery,getTrending,saveQuestion})(Report);
