import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./TVChartContainer.css";
import Datafeed from "./api/index";
import { checkSub } from "../../utils/SubUtils";
import { SUB_TIER, TradingViewTimeZones } from "../../Const";
import { getCurrentTimeZone } from "../../utils/Utils";
import { minimalTimezoneSet } from "compact-timezone-list";
import { widget } from '../../charting_library/charting_library'

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

const TVChartContainer = (props) => {
  const chartContainerRef = useRef();
  var tvWidget = null;
  const timezone = localStorage.getItem('timezone');
  const [offset, setOffset] = useState(timezone || "+00:00");
  const currentTimezone = TradingViewTimeZones.find((item) => item.offset === offset) ||
  minimalTimezoneSet[0];
  const selectedExchange = useSelector((state) => state.createBot.newBot.exchange);
  const supportedResolutions = ["15", "60", "240", "1D"];
  const supportedResolutionsPro = ["1","5","15", "60", "240", "1D"];
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  const { subPremium, subTier } = checkSub(subscription);
  const config = {
    supported_resolutions:(subPremium && subTier === SUB_TIER.pro)?supportedResolutionsPro:supportedResolutions,
    supports_marks: true,
    timezone: currentTimezone.tzCode,
    exchanges:[{value: selectedExchange.name, name: selectedExchange.name, desc: selectedExchange.name}]
  };
  const theme = useSelector((state) => state.settings.selected_theme);
  const widgetOptions = {
    debug: false,
    symbol: props.symbol || "",
    theme:theme=='light'?theme:'dark',
    datafeed: Datafeed(config),
    interval: props.interval || "60",
    //timeframe: props.timeframe || "3M",
    timezone: currentTimezone.tzCode?currentTimezone.tzCode.toString():'Etc/UTC',
    container: "tv_chart_container",
    library_path: props.libraryPath || "/charting_library/",
    locale: getLanguageFromURL() || "en",
    disabled_features: ["use_localstorage_for_settings"],
    // enabled_features: ["study_templates"],
    charts_storage_url:
      props.chartsStorageUrl || "https://saveload.tradingview.com",
    charts_storage_api_version: props.chartsStorageApiVersion || "1.1",
    client_id: props.clientId || "tradingview.com",
    user_id: props.userId || "public_user_id",
    fullscreen: props.fullscreen || false,
    autosize: props.autosize || true,
    studies_overrides: props.studiesOverrides || {},
    overrides: {
      "chart-page.background-color":"#000",
      "paneProperties.background": "#000",
      "paneProperties.vertGridProperties.color": "#363c4e",
      "paneProperties.horzGridProperties.color": "#363c4e",
      "symbolWatermarkProperties.transparency": 30,
      "scalesProperties.textColor": "#AAA",
      "mainSeriesProperties.candleStyle.wickUpColor": "#336854",
      "mainSeriesProperties.candleStyle.wickDownColor": "#7f323f",
    },
  };
  useEffect(() => {
    window.pairId = props.pairId;
    const tvWidget =  new widget(
      widgetOptions
    );
    tvWidget.onChartReady(() => {
			
		});
  }, [
    props.pairId,
    props.interval,
    props.symbol,
    props.reload,
    props.timeframe,
  ]);

  return (
    <div
      id="tv_chart_container"
      className={"TVChartContainer"}
      style={{ height: "100%" }}
      ref={chartContainerRef}
    />
  );
};

export default TVChartContainer;
