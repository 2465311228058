import React from "react";
import { connect, useSelector } from "react-redux";
import ContentContainer from "../common/ContentContainer";
import { useTranslation } from "react-i18next";
import DashboardOverview from "./DashboardOverview";
import ActiveDeals from "./ActiveDeals";
import TopPerformanceBots from "./TopPerformanceBots";
import WelcomeWizard from "../tour/WelcomeWizard";
import TigerBrokerPopup from "../tour/popups/TigerBrokerPopup";


const Dashboard = () => {
  const { t } = useTranslation()
  const newUserIntroPupup = useSelector((state) => state.settings.newUserIntroPupup);
  const tigerBrokerModal = useSelector((state) => state.auth.user.tiger_broker_saw);
  const showTigerBrokerModal = useSelector((state) => state.dashboard.showTigerBrokerModal);
  return (
    <ContentContainer url="dashboard" title={t("dashboard")} showChat={true}>
      <div className="dashboard-content">
        <DashboardOverview t={t}/>
        <div className="row">
          {newUserIntroPupup ? <WelcomeWizard></WelcomeWizard> : <ActiveDeals/>}
          {showTigerBrokerModal && tigerBrokerModal==0? <TigerBrokerPopup></TigerBrokerPopup> : <></>}
          <TopPerformanceBots/>
          
        </div>
     </div>
    </ContentContainer>
  );
};

export default connect(null)(Dashboard);
