import React, { useState, useEffect } from "react";
import { Row, Col, Button,InputGroup } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { connect, useDispatch, useSelector } from "react-redux";
import { getPortfolio } from "../../actions/portfolio-action";
import TutorialPopup from "../help/TutorialPopup";
import {PortfolioColors} from "../../Const";
import { useTranslation } from "react-i18next";
import { getDashboardAssets } from "../../actions/dashboard-action";

const TimeFrames = ["1D", "3D", "1W", "1M", "3M", "1Y", "ALL"];

const PortfolioPanel = (props) => {
  const { getPortfolio, height ,t,getDashboardAssets} = props;
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const [timeframe, setTimeframe] = useState("1M");
  const dispatch = useDispatch()
  const {
    equities,
    volumes,
    trading_volume,
    portfolio_value,
    portfolio_changed,
  } = useSelector((state) => state.portfolio);
  const currency = useSelector((state) => state.settings.currency);
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;

  const portfolioValues = equities && equities.map((item) => item.value);
  const graphLabel =
    equities &&
    equities.map((item, index) => {
      // if (index % 2 == 0 || pointNumber < 5) {
      return item.time+(" UTC");
      // } else {
      //   return '';
      // }
    });

  useEffect(() => {
    getPortfolio(timeframe, selectedExchangeId);
    getDashboardAssets(selectedExchangeId);
  }, [timeframe, selectedExchangeId]);

  const btnItems = TimeFrames.map((item, index) => {
    return (
      <Col key={index}>
        <Button
          className={timeframe == item ? "button-active" : "button-inactive"}
          onClick={() => setTimeframe(item)}
        >
          {item}
        </Button>
      </Col>
    );
  });
  return (
    <div className="portfolio-panel">
      <hr />
      
      <Row className="text-title">
        <Col className="portfolio-value-tour">{t("portf-val")}</Col>
        <Col>{timeframe} {t("change")}</Col>
        <Col>
          {timeframe} {t("t-volume")} <TutorialPopup content="Cumulative" />
        </Col>
      </Row>
      <Row className="text-value">
        <Col>
          {portfolio_value && portfolio_value.toFixed(2)}{" "}
          {currency && currency.name}
        </Col>
        <Col>{portfolio_changed && portfolio_changed.toFixed(2)}%</Col>
        <Col>
          {trading_volume && trading_volume.toFixed(2)}{" "}
          {currency && currency.name}
        </Col>
      </Row>
      
      <hr />
      
      <div className="graph-container round-bg">
        <div className="d-flex justify-content-between">
        
          <Row noGutters className="profit-panel-interval w-50">
            {btnItems}
          </Row>
          <div className="d-flex align-items-center graph-label profit-panel-labels">
            <span className={`portfolio-label`}>&#8226;</span>
            <span>{t("portf-val")}</span>
            <span className={`trade-volume-label`}>&#8226;</span>
            <span>{t("t-volume")}</span>
          </div>
        </div>
        <div className="d-flex align-items-center graph-label profit-panel-labels-mob">
        <span className={`portfolio-label`}>&#8226;</span>
        <span>{t("portf-val")}</span>
        <span className={`trade-volume-label`}>&#8226;</span>
        <span>{t("t-volume")}</span>
      </div>
        <Row className="graph">
          <Line
            data={dataMix(portfolioValues, volumes,theme,t)}
            options={optionsMix(graphLabel)}
          ></Line>
        </Row>
      </div>
     
    </div>
  );
};

export default connect(null, { getPortfolio,getDashboardAssets })(PortfolioPanel);

const dataMix = (portfolio, volume,theme,t) => ({
  datasets: [
    {
      type: "line",
      label: t("portf-val"),
      data: portfolio,
      backgroundColor: PortfolioColors[theme][1],
      borderColor: PortfolioColors[theme][1],
      yAxisID: "y-axis-1",
      lineTension: 0.3,
      borderWidth: 1.8,
    },
    {
      label: t("t-volume"),
      type: "line",
      data: volume,
      borderColor: PortfolioColors[theme][2],
      backgroundColor: PortfolioColors[theme][2],
      yAxisID: "y-axis-2",
      lineTension: 0.3,
      borderWidth: 1.8,
    },
  ],
});

const optionsMix = (xLabel) => {
  const fontSize = 9;
  const fontColor = "#4c556a";
  return {
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: "bottom",
      labels: {
        fontColor,
        boxWidth: 15,
        padding: 25,
      },
    },
    responsive: true,
    tooltips: {
      mode: "label",
    },
    elements: {
      line: {
        fill: false,
      },
      point: {
        radius: 2,
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
          },
          labels: {
            show: true,
          },
          labels: xLabel,
          ticks: {
            fontColor,
            fontSize,
          },
        },
      ],
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          labels: {
            show: true,
          },
          gridLines: {
            display: true,
          },
          ticks: {
            fontColor,
            fontSize,
          },
        },
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-2",
          gridLines: {
            display: false,
          },
          labels: {
            show: false,
          },
          ticks: {
            fontColor,
            fontSize,
          },
        },
      ],
    },
  };
};
