import React from "react";
import { useDispatch } from "react-redux";
import { UPDATE_VARIABLE_TP } from "../../../../actions/create-bot-type";
import { Button, Col, Form, Row } from "react-bootstrap";
import { isLong, restrictAlphabets } from "../../../../utils/Utils";

const VariableTP = (props) => {
  const { newBot } = props;
  const tps = newBot.variableTP;
  const long  = isLong(newBot.strategy)
  const dispatch = useDispatch();
  const updateTP = (list) => {
    let total = list.reduce((n, {stopAt}) => n + parseInt(stopAt), 0)
    if(total<=100){
      dispatch({
        type: UPDATE_VARIABLE_TP,
        payload: list,
      });
    }
    
  };

  const addVariabletSL = () => {
    updateTP([...tps, { triggerAt: 2, stopAt: 1 }]);
  };
  const tslItems = tps.map((item, id) => {
    const changeStopAt = (val) => {
      
      const tempTsl = [...tps];
      const trailing = tempTsl[id];
      trailing.stopAt = val;
      updateTP(tempTsl);
    };

    const changeTriggerAt = (val) => {
      const tempTsl = [...tps];
      const trailing = tempTsl[id];
      trailing.triggerAt = val;
      updateTP(tempTsl);
    };

    const deleteTP = () => {
      updateTP(tps.filter((_, index) => index !== id));
    };
    return (
      <>
      <Row key={id} className="trailing-item">
      <Col xl={12} className="mt-4">

      <div className="trailing-item d-flex align-items-center">
        <div> <i
          className="fas fa-trash-alt text-danger"
          style={{ marginRight: 10, fontSize: 13 }}
          onClick={deleteTP}
        ></i></div>
        <div>{id + 1}. {long?"Sell":"Buy"} {" "}</div>
        <div> 
            <label data-text={'%'} className="dca input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={item.stopAt}
                  onChange={(e) => changeStopAt(e.target.value)}
                  min={0}
                  onKeyPress={(e) => {
                    restrictAlphabets(e);
                  }}
                  className="input-text input-gp-tex dca-subseq-text"
                ></Form.Control>
            </label>
          </div>
        
        <div>of asset when price hits</div>
        <div> 
            <label data-text={'%'} className="dca input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={item.triggerAt}
                  onChange={(e) => changeTriggerAt(e.target.value)}
                  min={0}
                  onKeyPress={(e) => {
                    restrictAlphabets(e);
                  }}
                  className="input-text input-gp-tex dca-subseq-text"
                ></Form.Control>
            </label>
          </div>
        <div> {long?"above":"below"} {long?"Buy":"Sell"}</div>
        <div>&nbsp; Price</div>
        
      </div>
      </Col>
      </Row>
      </>
      
    );
  });
  return (
    <div>
      {tslItems}
      {tslItems.length <= 9 && (
        <div className="d-flex justify-content-end">
          <span className="add-ind-button"  onClick={() => addVariabletSL() }> 
            Add
          </span>
      </div> 
      )}
    </div>
  );
};

export default VariableTP;
