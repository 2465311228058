export const exchangeInfo = (data) => {
  const list = Object.values(data) || [];
  const exchange = (list.length && list[0]) || {};
  const info = [
    `Name: ${exchange.name}`,
    `Description: ${exchange.description}`,
    `Taker fee: ${exchange.taker_fee}`,
    `Maker fee: ${exchange.maker_fee}`,
    `Spot volume (USD): ${exchange.spot_volume_usd}`,
    `Fiats: ${exchange.fiats.join(', ')}`,
  ];

  return info;
};
