import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOtp, resetOtpPage } from "../../actions/auth-action";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Card,
} from "react-bootstrap";
import "./ForgetPass.scss";
import Header from "../common/HeaderGuest";
import Footer from "../common/Footer";
import HeaderGuest from "../common/HeaderGuest";
import GuestContainer from "./GuestContainer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const ForgetPass = (props) => {
  const { getOtp, resetOtpPage } = props;
  const {t} = useTranslation()

  const [email, setEmail] = useState("");

  const { getOtpStart, getOtpSuccess, getOtpError } = useSelector(
    (state) => state.auth
  );
  const history = useHistory();

  useEffect(() => {
    if (getOtpSuccess) {
      setTimeout(() => {
        history.push("/reset-pass");
      }, 300);
    }
  }, [getOtpSuccess]);

  useEffect(() => {
    resetOtpPage();
    return () => {
      resetOtpPage();
    };
  }, []);

  const clickGetOtp = (e) => {
    e.preventDefault();
    getOtp(email);
  };

  return (
    <>
      
      <GuestContainer>
      <Row className="justify-content-center auth-panel">
          <Col className="auth-panel-content">
          <div className="signup-panel text-center">
              <Link to="/"><img src="/images/named-logo.png"  className="mb-3 auth-logo"></img></Link>
              <h6 className="header-text text-center">Reset Password</h6>
              <Form className="mt-5 text-center">
                <Form.Group className="ch-form">
                  <Form.Control
                    type="email"
                    placeholder="Input your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input-text"
                  />
                </Form.Group>
                <Button
                  type="submit"
                  variant="success"
                  onClick={clickGetOtp}
                  className="blue-btn mt-4"
                >
                  {getOtpStart ? "Sending..." : "Get OTP"}
                </Button>
                <Alert
                  show={getOtpSuccess || getOtpError != null}
                  variant={getOtpError ? "danger" : "success"}
                  className="mt-3"
                >
                  {" "}
                  {getOtpError || "Otp has been sent!"}
                </Alert>
              </Form>
            </div>
            <div className="text-center mt-5">
            {/* <span className="mr-3 mb-4">{t("have-acc")}</span> */}
            <Link to="/login" className="link-text">{t("login")}</Link>
            </div>
            
              
          </Col>
        </Row>
      </GuestContainer>
    </>
  );
};
export default connect(null, { getOtp, resetOtpPage })(ForgetPass);
