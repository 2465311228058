import React, { useState, useEffect, useMemo } from "react";
import { Button, Col, Form, FormLabel, Modal, Row } from "react-bootstrap";
import * as HelpContent from "../../utils/HelpContent"
import { connect, useDispatch, useSelector } from "react-redux";
import TutorialPopup from "../help/TutorialPopup";
import Select from "react-select";
import { PRICE_ALERT_PAIR } from "../../actions/types";
import useInput from "../hooks/useInput";
import { updatePriceAlert } from "../../actions/price-alert-action";
import Loading from "../common/Loading";
import { toast } from "react-toastify";
const PriceAlertEditModal = (props)=>{
    const {onHide,editAlert,updatePriceAlert,t} = props
    const [loading, setLoading] = useState(false);
   
    const [baseCoin, setBaseCoin] = useState("usd");
    const dispatch = useDispatch();
    const [condition, bindCondition, setCondition] = useInput(editAlert.condition?editAlert.condition:"le");
    const [price, bindPrice, setPrice] = useInput(editAlert.price?editAlert.price:0);
    const  restrictAlphabets = (e)=>{
    var x = e.which || e.keycode;
    if(!(x>=46 && x<=57))
        e.preventDefault()
    
    }
    const submitPriceAlert = ()=>{
        setLoading(true)
        let params = {
            id:editAlert.id,
            price:price,
            condition:condition,
        }
        updatePriceAlert(params).then((res)=>{
            setLoading(false)
            toast.success("success")
            onHide()
        }).catch((err)=>{
            toast.error(err.message)
            setLoading(false)
        })
    }
    return (
        <Loading show={loading} onHide={() => setLoading(false)}>
        <Modal show={true} onHide={onHide} size="lg" dialogClassName="modal-90w">
            <Modal.Header closeButton className="align-items-center">
              <Modal.Title>
               {t("edit-p-alert")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
                <Row>
                <Col>
                <div className="d-flex align-items-center price-alert-form mt-3 justify-content-between">
                    <div>
                        <p>{t("alert-me-when-price")}</p>
                    </div>
                    <div>
                        <select {...bindCondition} className="form-control select-normal">
                            <option value={`le`}>{t("less-than")}</option>
                            <option value={`ge`}>{t("greater-than")}</option>
                        </select>
                    </div>
                    <div>
                        <label data-text={editAlert.pair.to} className="input-gp-label">
                            <Form.Control
                                as="input"
                                type="number"
                                {...bindPrice} onKeyPress={(e) => {
                                restrictAlphabets(e,e.target.value);
                                }}
                                className="input-text input-gp-text"
                                ></Form.Control>
                        </label>
                    </div>
                   
                </div>
                </Col>
                </Row>
                <div className="d-flex justify-content-around mt-5">
                        <Button onClick={()=>onHide()}  className="w-50 mr-1">
                        {t('cancel')}
                        </Button>
                        <Button onClick={()=>submitPriceAlert()}  className="w-50 blue-btn">
                        {t("save")}
                        </Button>
                        </div>
                  
              </Modal.Body>
            </Modal>
            </Loading>
    )

}
export default connect(null,{updatePriceAlert})(PriceAlertEditModal)
