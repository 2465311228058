import Axios from 'axios';
import { dataCurrencyUrl, dataExchangesUrl, dataSymbolUrl } from './const';
import {
  GET_DATA_CURRENCIES,
  GET_DATA_EXCHANGE,
  GET_DATA_SYMBOL,
} from './types';

export const getData = () => (dispatch) => {
  Axios.get(dataExchangesUrl).then((res) => {
    const data = res.data || [];
    const list = data
      .map((el) => el.toUpperCase())
      .sort((str1, str2) => str1.localeCompare(str2));
    dispatch({
      type: GET_DATA_EXCHANGE,
      payload: list,
    });
  });
  Axios.get(dataSymbolUrl).then((res) => {
    const data = res.data || [];
    const list = data.map((el) => el.toUpperCase());
    dispatch({
      type: GET_DATA_SYMBOL,
      payload: list,
    });
  });
  Axios.get(dataCurrencyUrl).then((res) => {
    const data = res.data || [];
    const list = data
      .map((el) => el.toUpperCase())
      .sort((str1, str2) => str1.localeCompare(str2));
    dispatch({
      type: GET_DATA_CURRENCIES,
      payload: list,
    });
  });
};
