import React from "react";
import { Button, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { createRentBot, updateBot, updateRentBot } from "../../../../actions/create-bot-action";
import useLoading from "../../../hooks/useLoading";
import { toast } from "react-toastify";
import Loading from "../../../common/Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CreateRentBotSection = (props)=>{
    const {t} = useTranslation();
    const history = useHistory();
    const [setLoading, bindLoading] = useLoading(false);
    const {updateRentBot,createRentBot} = props
    const itemsPerPage = 10;
    const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
    const isEditing = 
    useSelector((state) => state.createBot.rentBot.isEditing) || false;
    const selectedTab = 'active';
    
    const clickCreateBot = () => {
      setLoading(true);
      createRentBot(itemsPerPage,1,selectedTab,selectedExchangeId)
        .then(() => {
          setLoading(false);
          toast.success(t("bot-created-msg"));
          history.push('/bots');
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    };
    
    const clickUpdateBot = () => {
      setLoading(true);
      updateRentBot(itemsPerPage,1,selectedTab,selectedExchangeId)
        .then(() => {
          setLoading(false);
          toast.success(t("bot-updated-msg"));
          history.push('/bots');
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    };
      
    return (<>
        <Col xl={12} xs={12} className="d-flex justify-content-center mt-4">
            <button type="button" className="btn btn-primary btn-lg btn-block"  onClick={isEditing==true? clickUpdateBot : clickCreateBot}>{isEditing==true?t("update-bot"):t("create-bot")}</button>
        </Col>
        <Loading {...bindLoading} />
    </>);
}

export default connect(null,{updateRentBot,createRentBot})(CreateRentBotSection)