import React from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import "./Navbar.scss";
import { Row, Col, Navbar, Badge } from "react-bootstrap";
import { UserRole } from "../../Const";
import { useTranslation } from 'react-i18next'
import { Trans, withTranslation } from 'react-i18next';
import TopMenu from "./TopMenu";
import SideMenu from "./SideMenu";
import SettingsDropDown from "./SettingsDropDown";
import HelpDropDown from "./HelpDropDown";
import ExchangeDropDown from "./ExchangeDropDown";

const Header = (props) => {
  const { t } = useTranslation();

  return (
    <>
        <header>
          <div id="logo"><img src="/img/logo.png" alt=""/></div>
          <TopMenu url={props.url}/>
          <SideMenu url={props.url}/>
          <SettingsDropDown/>
          <HelpDropDown/>
          <ExchangeDropDown/>
    </header>
    </>
  );
};

export default withTranslation(null)(Header);
