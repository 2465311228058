import React, { useState, useMemo, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
//import "./AssetView.scss";
import TutorialPopup from "../../help/TutorialPopup";
import * as HelpContent from "../../../utils/HelpContent";
import { CHANGE_PAIR, CHANGE_RENT_PAIR, CHANGE_STRATEGY_RENT } from "../../../actions/create-bot-type";
import { getBalance, getPairBalance } from "../../../actions/bot-action";
import SymbolSearchBox from "../../bots/new-bot/modules/SymbolSearchBox";
import LeftRightForm from "../../bots/new-bot/modules/LeftRightForm";
import { Trans } from "react-i18next";

const AssetViewRent = (props) => {
  const { rentBot,getBalance,t,isEditing} = props;
  const Exchange = useSelector((state) => state.settings.selectedExchange) || 0;
  const selectedExchange = rentBot.isEditing?rentBot.exchange:Exchange;
  const [baseCoin, setBaseCoin] = useState("usd");
  const usdPairs = useSelector((state) => state.bots.usdPairs);
  const strategy = isEditing?rentBot.strategy:rentBot.rental && rentBot.rental.template && rentBot.rental.template.strategy;
  const dispatch = useDispatch();
  //const selectedExchange = rentBot.exchange;
  const pairList = useMemo(() => {
    return baseCoin === "usd"
      ? usdPairs
      : {};
  }, [baseCoin]);

  const pair = rentBot.pair;
  const selectedPair = {
    value: rentBot.exchangePairId,
    label: `${pair.from}/${pair.to}`,
    from: pair.from,
    to: pair.to,
  };

  const handleSelectPair = (newPair) => {
    getBalance(selectedExchange.id)
    //const newPair = pairList.find((el) => item.value == el.id) || {};
    dispatch({
      type: CHANGE_RENT_PAIR,
      payload: newPair,
    });
  };
  const strategyChanged = (strategy) => {
    dispatch({
      type: CHANGE_STRATEGY_RENT,
      payload: strategy,
    });
  };
  useEffect(() => {
    if(selectedExchange.id ==18 && strategy=="Short" || selectedExchange.id ==18 && strategy=="Margin Short"){
      strategyChanged("Margin Short")
    }
  }, [strategy]);
  


  return (
    <>
      
       <LeftRightForm
        left={<><Form.Label>
          {t("exchange")} <TutorialPopup content={t("exchange-help")} />
          </Form.Label></>}
          right={<><div className="mt-1 mb-3">
          <img
            src={selectedExchange.image}
            width={20}
            height={20}
            style={{ marginRight: 10 }}
          />
          <span style={{ fontWeight: "bold", fontSize: 16 }}>
            {selectedExchange.name}
          </span>
        </div></>}
       ></LeftRightForm>
       <LeftRightForm
       left={<><Form.Label className="bot-asset-tour">
            {t("t-pair")} <TutorialPopup content={t("tradingpair-help")} />
          </Form.Label></>}
       right={<><div style={{ maxWidth: "80%" }}>
            <SymbolSearchBox selectedExchange={selectedExchange} selected={handleSelectPair} selectedPair={selectedPair}></SymbolSearchBox>
            </div>
            <Form.Label className="mt-2">
              <Trans i18nKey={'miss-pair-note'}><a className="link" href="mailto:admin@stockhero.ai"></a></Trans>
            </Form.Label></>}
       ></LeftRightForm>
       {selectedExchange.id ==18 && strategy=="Short" || selectedExchange.id ==18 && strategy=="Margin Short" ? 
            <LeftRightForm
              //left={<> {t("Strategy")} <TutorialPopup content={t("strategy-help")} /></>}
              left={<>  <Form.Label className="bot-asset-tour">
              {t("Strategy")} <TutorialPopup content={t("strategy-help")} />
            </Form.Label></>}
              right={<> <div className="d-flex justify-content-between bw-option-div-1">
                
             <span
               className={rentBot.strategy == "Margin Short" ?"success" : ""}
               onClick={() => strategyChanged("Margin Short")}
             >
               MARGIN SHORT
             </span>
           </div></>}
            ></LeftRightForm>:<></>}
    </>
  );
};

export default connect(null,{getBalance,getPairBalance})(AssetViewRent);
