import React from "react";
import { Badge, Container } from "react-bootstrap";
import "./NavPanel.scss";
import { connect, useSelector } from "react-redux";
import { UserRole } from "../../Const";


const NavPanel = (props) => {
  const { selectedCom, onSelectCom,t } = props;
  const links = [
    {
      name: t("general"),
      icon: "fas fa-cog",
    },
    {
      name: t("account"),
      icon: "fas fa-user-circle",
    },
    {
      name: t("subscription"),
      icon: "fas fa-shopping-cart",
    },
  
    {
      name: t("exch-api"),
      icon: "fas fa-key",
    },
    {
      name: t("formula"),
      icon: "fas fa-square-root-alt",
    },
    {
      name: t("paper-wallet"),
      icon: "fas fa-wallet",
    },
    {
      name: t("security"),
      icon: "fas fa-shield-alt",
    },
    {
      name: t("notifications"),
      icon: "fas fa-bell",
    },
  ];

  const clickLink = (e, index) => {
    e.preventDefault();
    onSelectCom(index);
  };
  const isActive = (i) => {
    return i === selectedCom ? "active" : "";
  };
  const linkItems = links.map((item, index) => {
    return (
      <li key={index} className="mb-2">
        <a
          className={isActive(index)}
          href="#"
          onClick={(e) => clickLink(e, index)}
        >
          <i className={item.icon + " fa-lg mr-4 settings-navbar-icon"}></i>
          {item.name}
        </a>
      </li>
    );
  });
  return (
    <div className="card-white">
        <div className="cw-body settings-menu-body">
          <ul className="settings-menu">{linkItems}</ul>
        </div>
    </div>
  );
};

export default connect(null)(NavPanel);
