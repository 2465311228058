import { GET_PORTFOLIO, PORTFOLIO_DETAIL } from "../actions/types";
import { roundNumber, formatDate, compareDate } from "../utils/Utils";

const initialState = {};
const portfolioReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PORTFOLIO:
      const { equities, interval, volumes } = payload;
      const fixedEquities = equities
        .sort((item1, item2) => {
          return compareDate(item1.time, item2.time) ? -1 : 1;
        })
        .map((item) => {
          var format = "DD MMM HH:mm";
          // if (["1M", "3M", '1Y', 'ALL'].includes(interval)) {
          //   format = "DD MMM";
          // }
          return {
            time: formatDate(item.time, undefined, format),
            value: roundNumber(item.value, 2),
          };
        });
      const fixedVolumes = volumes
        .sort((item1, item2) => {
          return compareDate(item1.time, item2.time) ? -1 : 1;
        })
        .map((item) => {
          return roundNumber(item.value, 2);
        });
      return {
        ...state,
        ...payload,
        equities: fixedEquities,
        volumes: fixedVolumes,
      };
    case PORTFOLIO_DETAIL:
      return {
        ...state,
        coins: payload,
      };
    default:
      return state;
  }
};

export default portfolioReducer;
