
import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col, Button, Accordion, Form, Carousel } from "react-bootstrap";
import { SHOW_TIGER_BROKER_MODAL } from "../../../actions/types";
import { userSetting } from "../../../actions/setting-action";

const TigerBrokerPopup = (props) => {
  const {
    onHide
  } = props;
  
  const dispatch = useDispatch();
  const setShow=()=>{ 
    dispatch({
        type: SHOW_TIGER_BROKER_MODAL,
        payload:false
    });
  }
  const dontShowAgain=()=>{ 
    setShow();
    dispatch(userSetting("tiger_broker_saw",1));
  }
   
  
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  return (
    <>
    
      <Modal show={true} onHide={()=>setShow(false)} size="lg" dialogClassName="modal-90w">
        <Modal.Header  className="no-border" closeButton></Modal.Header>
        <Modal.Body style={{padding:"10px 50px",height: "400px"}}>
                <div className="text-center">
                <a href="https://www.itiger.com/sg/marketing/stockhero?lang=en_US&invite=STOCKHERO" target="_blank"><img src="/images/tiger-broker-popup.jpg" style={{width:'75%'}}></img> </a>
                </div>
           
        </Modal.Body>
        <Modal.Footer className="text-center justify-center no-border"><a href="#" onClick={()=>dontShowAgain()}>Don’t show this again</a></Modal.Footer>
      </Modal>
      </>
  );
};

export default connect(null, {
})(TigerBrokerPopup);
