import React from "react";
import { Form, InputGroup, Modal, Button } from "react-bootstrap";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { RentSubscriptions, SUB_TIER } from "../../../Const";
import {
  usdtAddress,
  UsdtQRcode,
} from "../../../Const"
import { connect } from "react-redux";
import { useState } from "react";
import { sendCryptoPaymentConfirmRequest } from "../../../actions/subscription-action";
import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import { useHistory } from "react-router-dom";
import useInput from "../../hooks/useInput";

const BotRentCryptoPayment = (props) => {
  const { show, onHide, sendEmail,monthly,amount ,
    sendCryptoPaymentConfirmRequest,template,plan
  } = props;
  const [network,setNetwork] = useState('ERC20')
  const [setLoading, bindLoading] = useLoading(false);
  const [qrcode,setQrcode] = useState(UsdtQRcode[network])
  const [address,setAddress] = useState(usdtAddress[network])
  const [confirmPayment,setConfirmPayment] = useState(false)
  const [paymentCompleted,setPaymentCompleted] = useState(false)
  const [showContctFormButton,setShowContctFormButton] = useState(false)
  const [showconfirmPayment,setShowConfirmPayment] = useState(true)
  const [transactionId, bindTransactionId, resetTransactionId] = useInput("");
  const [adminNote, bindAdminNote, resetAdminNote] = useInput();
  const history = useHistory();
  const clickCopy = () => {
    copy(address);
    toast.success("Address copied.");
  };
  const changeNetwork = (network) => {
    setNetwork(network)
    setAddress(usdtAddress[network])
    setQrcode(UsdtQRcode[network])
  };
  const redirectBack = () => {
    history.goBack();
  };
  const clickSendEmail = () => {
   //history.push('/bots');
    sendEmail();
  };
  const hideModal = () => {
    if(paymentCompleted)
    history.push('/bots?rental=true');
    onHide();
  };
  const clickPaymentDone = () => {
    if(!transactionId){
      toast.error('please enter transaction Id.')
      return false
    }
    setLoading(true)
    const params = {
      template_id:template.id,
      type:plan,
      payment_method:'usdt',
      usdt_address:address,
      usdt_network:network,
      note:adminNote,
      usdt_tnx:transactionId,
    };
    sendCryptoPaymentConfirmRequest(params).then(()=>{
      setLoading(false)
      setShowContctFormButton(true)
      setShowConfirmPayment(false)
      setPaymentCompleted(true)
      toast.success('Your payment request has been received. You will receive an email once payment has been approved.')
      //history.push('/bots');
    }).catch((err)=>{
      toast.error(err)
      setLoading(false)
      redirectBack()
    })
    
  };
  return (
    <Loading {...bindLoading}>
    <Modal show={show} onHide={hideModal}>
      <Modal.Header closeButton>
      
        <Modal.Title>
          <img
            src={'/images/coins/USDT.png'}
            width={25}
            style={{ marginRight: 10 }}
          />
          Pay With USDT 
        </Modal.Title>
      </Modal.Header>
      
      <Modal.Body className="text-center" style={{ fontSize: 15 }}>
      <div className="mb-2 flex-evenly">
            <button className={network=='ERC20'?'btn btn-primary':'btn btn-secondary'} onClick={()=>changeNetwork('ERC20')}>USDT-ERC20</button>
            <button className={network=='TRC20'?'btn btn-primary':'btn btn-secondary'} onClick={()=>changeNetwork('TRC20')}>USDT-TRC20</button>
            <button className={network=='BEP20'?'btn btn-primary':'btn btn-secondary'} onClick={()=>changeNetwork('BEP20')}>USDT-BEP20</button>
        </div>
        <p>
          Please make a payment with{" "}
          <b>{template[RentSubscriptions[plan]]} USDT</b> to the
          following address:
        </p>
        <img src={qrcode} width={180} />
        <br />
        <div>
          <InputGroup>
            <Form.Control value={address} disabled style={{textAlign:'center'}}/>
            
         
            <InputGroup.Append>
              <Button variant="secondary" onClick={clickCopy}>
                Copy
              </Button>
            </InputGroup.Append>
          </InputGroup>
          <Form.Group>
            <Form.Label>Transaction ID</Form.Label>
            <Form.Control type="text" {...bindTransactionId} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Note to Admin</Form.Label>
            <Form.Control as="textarea" rows={3} size="lg" {...bindAdminNote} />
          </Form.Group>
          <br/>
          <p> <b>Please ensure that you bear all transfer charges. <br />Network: {network=='TRC20'?'TRON TRC20':network}</b></p>
        </div>
        <br />
        {showconfirmPayment && (
         <>
          <Form.Check
              label={
              <p>
                  Confirm you made payment
              </p>
              }
              className="terms"
              value={true}
              onChange={(e) => setConfirmPayment(e.target.checked)}
          /><br />
          <Button variant="success" onClick={clickPaymentDone} className={!confirmPayment?'disabled':''}>
            Payment Done
          </Button>
          </>
        )}
        {showContctFormButton && (
        <>
        <p>
          Email <a href="mailto:admin@stockhero.ai">admin@stockhero.ai</a>{" "}
          with the transaction ID and the user's account email to apply the
          payment to.
        </p>
        
        <Button variant="success" onClick={clickSendEmail}>
          Send Email
        </Button></> )}
        <br /> <br />
      </Modal.Body>
    </Modal>
    </Loading>
  );
};

export default connect(null, { sendCryptoPaymentConfirmRequest })(BotRentCryptoPayment);
