import axios from 'axios';
import { exchangeInfo } from './adapter/adapter';
import { ApiType } from './ApiType';

export const requestInfo = async (subData, params) => {
  try {
    const res = await axios.get(subData.url, { params });
    const data = res.data || '';
    if (typeof data === 'object' && data['Error']) {
      return Promise.reject(['No information or input invalid']);
    }
    switch (subData.type) {
      case ApiType.coinInfo:
      case ApiType.priceChanged:
      case ApiType.fcas:
        return data;
      case ApiType.exchangeInfo:
        return exchangeInfo(data);
      default:
        return [data];
    }
  } catch (error) {
    return Promise.reject([String(error)]);
  }
};
