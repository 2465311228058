import { ApiParam, ApiTier, ApiType } from './ApiType';

export const latestPrice = {
  type: ApiType.latestPrice,
  name: 'Latest Price',
  url: 'https://stockheroapi.herokuapp.com/api/basic/p/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.free,
};
export const coinInfo = {
  type: ApiType.coinInfo,
  name: 'Crypto Info',
  url: 'https://stockheroapi.herokuapp.com/api/basic/i/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.free,
};
export const priceChanged = {
  type: ApiType.priceChanged,
  name: 'Price Changes',
  url: 'https://stockheroapi.herokuapp.com/api/basic/change/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.free,
};
export const convert = {
  type: ApiType.convert,
  name: 'Convert',
  url: 'https://stockheroapi.herokuapp.com/api/advance/convert/',
  params: [
    { id: 'amt', label: 'Amount', type: ApiParam.amount },
    { id: 'sym', label: 'Crypto Symbol', type: ApiParam.symbol },
    { id: 'curr', label: 'Fiat Currency', type: ApiParam.currency },
  ],
  tier: ApiTier.free,
};

export const fcas = {
  type: ApiType.fcas,
  name: 'FCAS',
  url: 'https://stockheroapi.herokuapp.com/api/advance/fcas/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.premium,
};
export const tvlStaked = {
  type: ApiType.tvlStaked,
  name: 'TVL Staked',
  url: 'https://stockheroapi.herokuapp.com/api/advance/eth2staked',
  params: [],
  tier: ApiTier.free,
};
export const totalValidators = {
  type: ApiType.totalValidators,
  name: 'Total Validators',
  url: 'https://stockheroapi.herokuapp.com/api/advance/eth2validators',
  params: [],
  tier: ApiTier.free,
};
export const purposeBtcFlow = {
  type: ApiType.purposeBtcFlow,
  name: 'Purpose Bitcoin ETF Flow',
  url: 'https://stockheroapi.herokuapp.com/api/advance/purposebtc',
  params: [],
  tier: ApiTier.premium,
};
export const grayscaleBtcFlow = {
  type: ApiType.grayscaleBtcFlow,
  name: 'Grayscale BTC Flow',
  url: 'https://stockheroapi.herokuapp.com/api/advance/gbtc/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.premium,
};
export const pricePerformance = {
  type: ApiType.pricePerformance,
  name: 'Price Performance',
  url: 'https://stockheroapi.herokuapp.com/api/advance/performance/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.premium,
};
export const exchangeInfo = {
  type: ApiType.exchangeInfo,
  name: 'Exchange Infomation',
  url: 'https://stockheroapi.herokuapp.com/api/advance/metadata/',
  params: [{ id: 'exchange', label: 'Exchange ID', type: ApiParam.exchange }],
  tier: ApiTier.free,
};
export const grayscaleHolding = {
  type: ApiType.grayscaleHolding,
  name: 'Grayscale Holdings',
  url: 'https://stockheroapi.herokuapp.com/api/advance/grayholdings/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.premium,
};
export const grayscalePremium = {
  type: ApiType.grayscalePremium,
  name: 'Grayscale Premium',
  url: 'https://stockheroapi.herokuapp.com/api/advance/grayprem/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.premium,
};
export const grayscaleAum = {
  type: ApiType.grayscalePremium,
  name: 'Grayscale AUM',
  url: 'https://stockheroapi.herokuapp.com/api/advance/grayaum/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.premium,
};
export const grayscalePrice = {
  type: ApiType.grayscalePrice,
  name: 'Grayscale Price',
  url: 'https://stockheroapi.herokuapp.com/api/advance/grayprice/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.premium,
};
export const purposeBtcQty = {
  type: ApiType.purposeBtcQty,
  name: 'Purpose Bitcoin ETF - BTC Qty',
  url: 'https://stockheroapi.herokuapp.com/api/advance/purposeholdings',
  params: [],
  tier: ApiTier.premium,
};
export const btcFundQty = {
  type: ApiType.btcFundQty,
  name: 'The Bitcoin Fund: BTC Qty',
  url: 'https://stockheroapi.herokuapp.com/api/advance/qbtcholding',
  params: [],
  tier: ApiTier.premium,
};
export const btcFundFlow = {
  type: ApiType.btcFundFlow,
  name: 'The Bitcoin Fund Flows',
  url: 'https://stockheroapi.herokuapp.com/api/advance/qbtcflows',
  params: [],
  tier: ApiTier.premium,
};
export const btcFundPremiumToNav = {
  type: ApiType.btcFundPremiumToNav,
  name: 'The Bitcoin Fund Premium to BTC NAV',
  url: 'https://stockheroapi.herokuapp.com/api/advance/qbtcprem',
  params: [],
  tier: ApiTier.premium,
};
export const btcFundAum = {
  type: ApiType.btcFundAum,
  name: 'The Bitcoin Fund AUM',
  url: 'https://stockheroapi.herokuapp.com/api/advance/qbtcaum',
  params: [],
  tier: ApiTier.premium,
};
export const btcFundPrice = {
  type: ApiType.btcFundPrice,
  name: 'The Bitcoin Fund Price',
  url: 'https://stockheroapi.herokuapp.com/api/advance/qbtcprice',
  params: [],
  tier: ApiTier.premium,
};
export const miningDifficulty = {
  type: ApiType.miningDifficulty,
  name: 'Mining Difficulty',
  url: 'https://stockheroapi.herokuapp.com/api/advance/difficulty/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.free,
};
export const miningHashrate = {
  type: ApiType.miningHashrate,
  name: 'Mining Hashrate',
  url: 'https://stockheroapi.herokuapp.com/api/advance/hashrate/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.free,
};
export const uniqueSender = {
  type: ApiType.uniqueSender,
  name: 'Unique Senders',
  url: 'https://stockheroapi.herokuapp.com/api/addresses/deposit/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.free,
};
export const uniqueReceiver = {
  type: ApiType.uniqueReceiver,
  name: 'Unique Receivers',
  url: 'https://stockheroapi.herokuapp.com/api/addresses/withdraw/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.free,
};
export const activeSenderReceiver = {
  type: ApiType.activeSenderReceiver,
  name: 'Active Senders and Receivers',
  url: 'https://stockheroapi.herokuapp.com/api/addresses/active/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.free,
};
export const uniqueTotal = {
  type: ApiType.uniqueTotal,
  name: 'Unique Totals',
  url: 'https://stockheroapi.herokuapp.com/api/addresses/total/',
  params: [{ id: 'symbol', label: 'Crypto Symbol', type: ApiParam.symbol }],
  tier: ApiTier.free,
};
