import React, { useState, useEffect, useRef } from "react";
import { searchExchangePair } from "../../../../actions/bot-action";
import { connect } from "react-redux";
import "./SymbolSearchBox.scss";

const PaperWalletSymbolSearch = (props) => {
  const {
    coins,
    setSelectedCoin,
    selectedCoin
  } = props;
  const [searchTerm, setSearchTerm] = useState(selectedCoin ? selectedCoin:"");
  const [results, setResults] = useState([]);
  const [emptyResult, setEmptyResult] = useState(false);
  const searchBoxRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target) &&
        event.target.tagName !== "LI" // Check if clicked element is an <li> element
      ) {
        resetSearch();
      }
  };
  const resetSearch = ()=>{
    setResults([]);
    setEmptyResult(false);
    //setSearchTerm(selectedPair ? selectedPair.label : "")
  }

  const handleInputChange = (event) => {
    let symbol = event.target.value;
    setSearchTerm(symbol.toUpperCase());
    if (symbol) {
      const filteredItems = coins.filter((coin) =>
        coin.coin.toLowerCase().includes(symbol.toLowerCase())
      );
      if (filteredItems.length > 0) {
        setResults(filteredItems);
      } else {
        setResults([]);
        setEmptyResult(true);
      }
    }
    
  };

  const handleResultClick = (symbol) => {
    setResults([]);
    setEmptyResult(false);
    if (symbol) {
      setSelectedCoin(symbol.coin);
      setSearchTerm(symbol.coin);
    }
  };

  return (
    <>
      <div className="search-box" >
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          placeholder="Enter stock symbol"
          className="form-control input-text"
          ref={searchBoxRef}
        />
        <div >
            {results.length > 0 && (
                <ul className="search-results">
                    {results.map((result, index) => (
                    <li
                        key={index}
                        onClick={(event) => {
                        event.stopPropagation(); // Prevent event propagation
                        handleResultClick(result);
                        }}
                    >
                        {result.coin}
                    </li>
                    ))}
                </ul>
            )}
            {results.length === 0 && emptyResult && (
            <ul className="search-results">
                <li onClick={()=>resetSearch()}>No stocks found</li>
            </ul>
            )}
        </div>
        
      </div>
    </>
  );
};

export default connect(null, { searchExchangePair })(PaperWalletSymbolSearch);
