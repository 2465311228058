import React from "react";
import Pagination from "react-js-pagination";
import "./CustomPagination.scss";
import { useDispatch } from "react-redux";
import { BOT_PAGE_ACTIVE, BOT_PAGE_INACTIVE, DEALS_PAGE_CLOSE, DEALS_PAGE_OPEN } from "../../actions/types";

const ReactStatePagination = (props) => {
  const { startItem, onChange, itemsCountPerPage, totalCount,forPage,selectedTab,botId } = props;
  const items = {
    bots:{
      active:BOT_PAGE_ACTIVE,
      inactive:BOT_PAGE_INACTIVE
    },
    deals:{
      open:DEALS_PAGE_OPEN,
      close:DEALS_PAGE_CLOSE,
    }
  }
  const dispatch = useDispatch();
  const action = (num)=>{
    dispatch({
      type: items[forPage][selectedTab],
      payload: num
    })
    onChange(num)
  }
  const activePage = ((startItem-1) / itemsCountPerPage) + 1;
  return (
    <>
      <div className="row justify-content-center mt-auto">
        {totalCount <= itemsCountPerPage ? (
          <></>
        ) : (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage || 10}
            totalItemsCount={totalCount}
            pageRangeDisplayed={5}
            onChange={(num) => action(num)}
            innerClass="pagination"
            activeLinkClass="text-white bg-info"
            itemClass="page-item"
            linkClass="page-link"
            prevPageText="Prev"
            nextPageText="Next"
          />
        )}
      </div>
    </>
  );
};

export default ReactStatePagination;
