import React, { useState } from "react";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import "./RentBotSubscription.scss";
import RentBotPaymentPanel from "./RentBotPaymentPanel";
import RentBotPremiumInfo from "./RentBotPremiumInfo";
import { useTranslation } from "react-i18next";
import ContentContainer from "../../common/ContentContainer";
import BackButton from "../../common/BackButton";

const RentBotSubscription = () => {
  const template = useSelector((state)=>state.subscription.template)
  const location = useLocation();
  const { search } = location;
  const query = queryString.parse(search);
  const plan = query.plan;
  const tier = query.tier;
  const history = useHistory();
  const {t} = useTranslation()

  //const [selectPl, setSelectMonthly] = useState(plan);

  return (
    <>
    <ContentContainer url="marketplace" title={t("marketplace")} showExchange={false}>
        <div className="dashboard-content">
        <BackButton></BackButton>
          <Row>
            <Col xl={3} className="offset-xl-2" md={6} xs={12}><RentBotPremiumInfo readOnly  template={template} plan={plan}/></Col>
            <Col xl={4} md={6} xs={12}>
              <RentBotPaymentPanel
            template={template}
            plan={plan}
            //hideSelection={monthly != null}
            //onChange={setSelectMonthly}
            //tier={tier}
          /></Col>
          </Row>
          </div>
    </ContentContainer>
     

      {/* <Row className="justify-content-around mt-5" noGutters>
        <Col xs={12} md={6} xl={5} className="px-0 px-sm-3 px-xl-5">
        <Button
            style={{ marginBottom: 10 }}
            className="py-2"
            variant="primary"
            onClick={() => history.goBack()}
          >{t("back")}</Button>
          <RentBotPremiumInfo readOnly  template={template} plan={plan}/>
        </Col>
        <Col
          xs={12}
          md={6}
          xl={5}
          className="px-0 px-sm-3 px-xl-5 mt-5 mt-md-0"
          style={{ maxWidth: 800 }}
        >
          <RentBotPaymentPanel
            template={template}
            plan={plan}
            //hideSelection={monthly != null}
            //onChange={setSelectMonthly}
            //tier={tier}
          />
        </Col>
      </Row> */}
    </>
    
  );
};

export default connect(null)(RentBotSubscription);
