import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { PLAN_NAME, PLAN_TYPE, SUBSCRIPTION_PLAN, SUB_STATUS, SUB_TIER } from "../../Const";
import { getMonthlyPrice, getYearlyPrice } from "../../utils/SubUtils";
import LitePlanDetails from "./LitePlanDetails";
import PremiumDetails from "./PremiumDetails";
import ProfessionalDetails from "./ProfessionalDetails";

const PremiumInfo = (props) => {
  const {
    isYearly,
    readOnly,
    onUpgrade,
    subStatus,
    subPremium,
    subTier,
    tier,
    plan,
    t
  } = props;
  console.log(subTier,tier)
  const history = useHistory();

  // const clickUpgrade = () => {
  //   if (subStatus == SUB_STATUS.canceled) {
  //     onUpgrade && onUpgrade();
  //   } else if (
  //     !subPremium ||
  //     (subPremium && subTier === SUB_TIER.premium && tier === SUB_TIER.pro)
  //   ) {
  //     history.push(
  //       "/subscription?plan=" +
  //         (isYearly ? "yearly" : "monthly") +
  //         "&tier=" +
  //         tier
  //     );
  //   }
  // };
  const getPlanInfo = (plan)=>{
    switch(plan){
      case "lite":
        return <LitePlanDetails t={t}></LitePlanDetails>
      case "premium":
        return <PremiumDetails t={t}></PremiumDetails>
      case "pro":
        return <ProfessionalDetails t={t}></ProfessionalDetails>
    }
  }
  const clickUpgrade = () => {
    history.push(
      "/subscription?plan=" +
        plan +
        "&tier=" +
        tier
    );
  };
  return (
    <div className="round-bg premium-info text-center no-pad">
      <div className="sub-info-div">
        <div className="header-row">
          <h5 className="">
            {PLAN_NAME[tier]}
          </h5>
          <div className="row justify-content-center align-items-center h-100">
            <div className="">
               <span>US$</span> <span className="plan-price-text">{SUBSCRIPTION_PLAN[plan] && SUBSCRIPTION_PLAN[plan][tier] }</span>
            </div>
            <span className=" ml-2">
              / {PLAN_TYPE[plan]}
            </span>
          </div>
        </div>
        <div className="sub-features-info">
          {getPlanInfo(tier)}
        </div>
      </div>
      {readOnly ? (
        <></>
      ) : (
        <div className="subscribe-btn-div w-100 mb-4">
          <h6 className="promotion-text">
            <a href="https://www.stockhero.ai/pricing" target="_blank">{t("free-info-5")}</a>
         </h6>
          <Button  onClick={clickUpgrade} className="subscribe-btn blue-btn">
            {subPremium && subStatus == SUB_STATUS.active
              ? subTier == tier
                ? "CURRENT PLAN" : subTier=== SUB_TIER.pro?"INCLUDED":subTier=== SUB_TIER.premium && tier==SUB_TIER.lite?"INCLUDED":"UPGRADE"
                
              : "SUBSCRIBE"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default PremiumInfo;
