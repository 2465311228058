import Bots from "../components/bots/Bots";
import Deals from "../components/deals/Deals";
import Setting from "../components/settings/Setting";
import Portfolio from "../components/portfolio/Portfolio";
import Dashboard from "../components/dashboard/Dashboard";
import TradingTerminal from "../components/terminal/TradingTerminal";
import Subscription from "../components/subscription/Subscription";
import SubscriptionSuccess from "../components/subscription/SubscriptionSuccess";
import GAPage from "../components/common/GAPage";
import Support from "../components/support/Support";
import DataPage from "../components/data/DataPage";
import ReferNow from "../components/affiliate/refer-now/ReferNow";
import MyReferrals from "../components/affiliate/my-refers/MyReferrals";
import MyRewards from "../components/affiliate/my-rewards/MyRewards";
import RewardsRedeem from "../components/affiliate/my-rewards/RewardsRedeem";
import RewardsHistory from "../components/affiliate/my-rewards/RewardsHistory";
import ReferralsRedeem from "../components/affiliate/my-refers/ReferralsRedeem";
import ReferralsHistory from "../components/affiliate/my-refers/ReferralsHistory";
import Marketplace from "../components/marketplace/dashboard/Marketplace";
import BotTemplateDetail from "../components/marketplace/dashboard/BotTemplateDetail";
import ManageBot from "../components/marketplace/manage/ManageBotTemplates";
import RentBotSubscription from "../components/marketplace/subscription/RentBotSubscription";
import TestComponent from "../components/TestComponent";
import EditRentBot from "../components/marketplace/bots/EditRentBot";
import PriceAlert from "../components/priceAlert/PriceAlert";
import ExchageRedirect from "../components/settings/ExchageRedirect";
import CreateBot from "../components/bots/new-bot/CreateBot";
import BotDetail from "../components/bots/bot-detail/BotDetail";
import DealDetail from "../components/deals/DealDetail";
import CreateRentBot from "../components/bots/new-bot/CreateRentBot";
import AddBotMarketplace from "../components/marketplace/create/AddBotMarketplace";
import ArtificialIntelligence from "../components/ai/ArtificialIntelligence";
import ArtificialIntelligenceSaved from "../components/ai/ArtificialIntelligenceSaved";
import Report from "../components/report/Report";

const AllRoute = [
  // {
  //   path: "/dashboard",
  //   component: Dashboard,
  // },
  {
    path: "/bots",
    component: Bots,
    exact: true,
  },
  // {
  //   path: "/deals",
  //   component: Deals,
  // },
  // {
  //   path: "/portfolio",
  //   component: Portfolio,
  // },
  // {
  //   path: "/setting",
  //   component: Setting,
  // },
  // {
  //   path: "/subscription",
  //   component: Subscription,
  //   exact: true,
  // },
  // {
  //   path: "/bot-subscription",
  //   component: RentBotSubscription,
  //   exact: true,
  // },
  // {
  //   path: "/subscription/success",
  //   component: SubscriptionSuccess,
  //   exact: true,
  // },
  // {
  //   path: "/subscription/success/yearly",
  //   component: SubscriptionSuccess,
  //   exact: true,
  // },
  // {
  //   path: "/terminal",
  //   component: TradingTerminal,
  //   exact: true,
  // },
  // {
  //   path: "/ga/confirm",
  //   component: GAPage,
  //   exact: true,
  // },
  // {
  //   path: "/help",
  //   component: Support,
  //   exact: true,
  // },
  // {
  //   path: "/price-alert",
  //   component: PriceAlert,
  //   exact: true,
  // },
  // {
  //   path: "/refer-now",
  //   component: ReferNow,
  //   exact: true,
  // },
  // {
  //   path: "/my-referrals",
  //   component: MyReferrals,
  //   exact: true,
  // },
  // {
  //   path: "/my-rewards",
  //   component: MyRewards,
  //   exact: true,
  // },
  // {
  //   path: "/my-rewards/redeem",
  //   component: RewardsRedeem,
  //   exact: true,
  // },
  // {
  //   path: "/my-rewards/history",
  //   component: RewardsHistory,
  //   exact: true,
  // },
  // {
  //   path: "/my-referrals/redeem",
  //   component: ReferralsRedeem,
  //   exact: true,
  // },
  // {
  //   path: "/my-referrals/history",
  //   component: ReferralsHistory,
  //   exact: true,
  // },
  // {
  //   path: "/marketplace",
  //   component: Marketplace,
  //   exact: true,
  // },
  // {
  //   path: "/marketplace/add-bot",
  //   component: AddBotMarketplace,
  //   exact: true,
  // },
  // {
  //   path: "/marketplace-detail/:id",
  //   component: BotTemplateDetail,
  // },
  // {
  //   path: "/manage-bot",
  //   component: ManageBot,
  // },
  // {
  //   path: "/bots/rent",
  //   component: EditRentBot,
  // },
  // {
  //   path: "/test-pintura",
  //   component: TestComponent,
  // },
  // {
  //   path: "/add-brokerage",
  //   component: ExchageRedirect,
  // },
  {
    path: "/bots/create",
    component: CreateBot,
    exact: true,
  },
  // {
  //   path: "/rent/create",
  //   component: CreateRentBot,
  //   exact: true,
  // },
  // {
  //   path: "/bot-detail/:botId",
  //   component: BotDetail,
  //   exact: true,
  // },
  // {
  //   path: "/deal-detail/:dealId",
  //   component: DealDetail,
  //   exact: true,
  // },
  // {
  //   path: "/artificial-intelligence",
  //   component: ArtificialIntelligence,
  //   exact: true,
  // },
  // {
  //   path: "/artificial-intelligence/saved",
  //   component: ArtificialIntelligenceSaved,
  //   exact: true,
  // },
  // {
  //   path: "/reports",
  //   component: Report,
  //   exact: true,
  // },
];

export default AllRoute;
