import React, { useEffect, useState } from "react";
import { Card, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import {
  getCurrencies,
  updateCurrency,
  getSettings,
  updateNotificationStatus,
  updateUserRole,
  switchTheme,
  userSetting
} from "../../actions/setting-action";
import { connect, useDispatch, useSelector } from "react-redux";
import { minimalTimezoneSet } from "compact-timezone-list";
import Switch from "react-switch";
import { UserRole,Themes, LanguageName } from "../../Const";
import { toast } from "react-toastify";
import "./GeneralPanel.scss";
import { useTranslation } from "react-i18next";
import { APP_LANGUAGE } from "../../actions/types";
import Cookies from "universal-cookie";
import LeftRightForm from "../bots/new-bot/modules/LeftRightForm";
import useInput from "../hooks/useInput";

const GeneralPanel = (props) => {
  const {
    getCurrencies,
    updateCurrency,
    getSettings,
    updateNotificationStatus,
    updateUserRole,
    switchTheme
  } = props;
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const currencies = useSelector((state) => state.settings.currencies);
  const currencySelected = useSelector((state) => state.settings.currencySelected);
  const language = useSelector((state) => state.settings.currentLang);
  const theme = useSelector((state) => state.settings.selected_theme);
  const themeChecked = '/images/icons/check.png'
  const userRole =
    useSelector((state) => state.settings.userRole) || UserRole.normal;

  const [isOn, setIsOn] = useState(false);

  const notificationStatus = useSelector(
    (state) => state.settings.bot_notification_enabled
  );

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (notificationStatus != null) {
      setIsOn(notificationStatus == 1);
    }
  }, [notificationStatus]);

  const changeNotification = (isOn) => {
    setIsOn(isOn);
    updateNotificationStatus(isOn ? 1 : 0);
  };
  const changeLanguage = (lang)=>{
    console.log(lang)
    dispatch({
        type:APP_LANGUAGE,
        payload:lang
    })
    cookies.set('current_lang', lang, { path: '/',maxAge:60 * 60 * 24 * 365 * 10 });
    dispatch(userSetting("language",lang));
    setTimeout(() => {
      window.location.reload()
    }, 2000);
    
}

  useEffect(() => {
    getCurrencies();
  }, []);

  const changeUserRole = (isOn) => {
    updateUserRole(isOn ? UserRole.beta : UserRole.normal)
      .then(() => {})
      .catch((err) => toast.error(err));
  };

  const currencyItems = currencies.map((item) => {
    return (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    );
  });
  const themeItems = Object.keys(Themes).map((item, index) => {
    return (
      <div key={index} className={`color-switch theme-${item}`} onClick={() => switchTheme(item)}>
        {theme==item && (<img src={themeChecked} width={20}></img>)}
      </div>
    );
  });

  const timezone = localStorage.getItem("timezone");
  const [offset, setOffset] = useState(timezone || "+00:00");
  const currentZone =
    minimalTimezoneSet.find((item) => item.offset === offset) ||
    minimalTimezoneSet[0];

  const selectTimezone = (timezone) => {
    setOffset(timezone);
    localStorage.setItem("timezone", timezone);
  };

  const timezoneItems = minimalTimezoneSet.map((item, index) => {
    return (
      <Dropdown.Item key={index} eventKey={item.offset}>
        {item.label}
      </Dropdown.Item>
    );
  });
  const languageItems = Object.keys(LanguageName).map(function(key, index) {
    return (
      <Dropdown.Item key={index} eventKey={key}>
        {LanguageName[key]}
      </Dropdown.Item>
    );
  });

  return (
    <>
      <div className="card-white">
        <div className="cw-body settings-body">
        <LeftRightForm 
          left={t("theme")}
          right={<div className="color-theme-sel-div">{themeItems}</div>}
          leftCol={4}
          rightCol={8}
        ></LeftRightForm>
        <br />
        <br />
        <LeftRightForm 
          left={<>{t("ch-beta")} <p>
          {t("beta-prog")}
        </p></>}
          right={<> <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            checked={userRole !== UserRole.normal}
            onChange={changeUserRole}
            onColor="#05CD99"
          /></>}
          leftCol={4}
          rightCol={8}
        ></LeftRightForm>
         <br />
        <br />
        <LeftRightForm 
          left={t("native-curr")}
          right={<><select
            value={currencySelected}
            onChange={(e) => updateCurrency(Number(e.target.value))}
            className="form-control select-normal  small-select mb-4"
          >
            {currencyItems}
          </select></>}
          leftCol={4}
          rightCol={8}
        ></LeftRightForm>
       
        <br />
        <br />
        <LeftRightForm 
          left={t("timezone")}
          right={<><DropdownButton
            variant="outline-secondary"
            title={
              timezone
                ? currentZone.label
                : Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            className="select-box small-select"
            onSelect={selectTimezone}
          >
            {timezoneItems}
          </DropdownButton></>}
          leftCol={4}
          rightCol={8}
        ></LeftRightForm>
       
        <br />
        <br />
        <LeftRightForm 
          left={t("language")}
          right={<><DropdownButton
            variant="outline-secondary"
            title={
              LanguageName[language]
            }
            onSelect={changeLanguage}
            className="select-box small-select"
          >
            {languageItems}
          </DropdownButton></>}
          leftCol={4}
          rightCol={8}
        ></LeftRightForm>
       
      </div>
      </div>
    </>
  );
};

export default connect(null, {
  getCurrencies,
  updateCurrency,
  getSettings,
  updateNotificationStatus,
  updateUserRole,
  switchTheme
})(GeneralPanel);
