import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const ObsceneWordFilter = ({ setQuestion,clearInput,children }) => {
  const [filteredInput, setFilteredInput] = useState('');
  const obsceneWords = [
    'fuck', 
    'shit', 
    'piss',
    'cunt',
    'cocksucker',
    'motherfucker',
    'tits',
    'fucker',
    'asshole',
    'ass',
    'bitch',
    'bastard',
    'bloody',
    'bollocks',
    'cock',
    'dickhead',
    'dick',
    'pussy',
    'shitfaced',
    'whore',
    'stupid',
    'idiot',
    'hell',
    'damn',
    'arsehole',
    'slut',
    'bullshit',
    'christ',
    'piss',
    'goddamn',
    'crap',
    'nigga',
    'pig'
  ]; 
  const IllogicalWords = [
    'bot', 
    'stockhero', 
    'trendspider',
    'composer',
    'tickeron',
    'tradeideas',
    'stack',
    'scanz',
    'algoriz',
    'kavout',
    'quantconnect'
  ]; 
  useEffect(()=>{
    setFilteredInput("");
  },[clearInput])
  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    // Perform obscene word check
    const { filteredText, hasObsceneWords,hasIllogicalWords } = filterObsceneWords(inputValue);
    setFilteredInput(filteredText);

    if (hasObsceneWords) {
      // Display a warning to the user
      toast.error('Objectionable content has been detected. Please amend your query.');
      setFilteredInput("");
    }
    if (hasIllogicalWords) {
      // Display a warning to the user
      toast.error('Your query contains a non-allowed keyword. Please refine your query and submit it again.');
      setFilteredInput("");
    }
  };

  const filterObsceneWords = (text) => {
    let filteredText = text;
    let hasObsceneWords = false;
    let hasIllogicalWords = false;

    obsceneWords.forEach((obsceneWord) => {
      const regex = new RegExp(obsceneWord, 'gi');
      filteredText = filteredText.replace(regex, (match) => {
        hasObsceneWords = true;
        return ''.repeat(match.length);
      });
    });
    IllogicalWords.forEach((obsceneWord) => {
      const regex = new RegExp(obsceneWord, 'gi');
      filteredText = filteredText.replace(regex, (match) => {
        hasIllogicalWords = true;
        return ''.repeat(match.length);
      });
    });

    setQuestion(filteredText);
    return { filteredText, hasObsceneWords,hasIllogicalWords };
  };
  


  return (
    <div>
      {children(filteredInput, handleInputChange)}
    </div>
  );
};

export default ObsceneWordFilter;
