import React from "react"
import { connect, useSelector } from "react-redux"
import { Link } from "react-router-dom/cjs/react-router-dom";
import { signOut } from "../../actions/auth-action";
import { checkSub, isPremiumOrPro } from "../../utils/SubUtils";

const SettingsDropDown = (props)=>{
    const {signOut}=props
    const email = useSelector((state) => state.auth.user.email);
    const name = useSelector((state) => state.auth.user.name) || email || "User";
    const subscription =
    useSelector((state) => state.auth.user.user_subscription) || {};
    const { subPremium, subTier } = checkSub(subscription);
    const isPaid = isPremiumOrPro(subscription);
    const upperName = name.toUpperCase();
    return (
        <>
            <div className="header-menu">
                <div className="dropdown">
                <div style={{cursor: "pointer", marginTop:"17px"}}>
                    <div className="user-icon">{upperName.charAt(0)}</div> 
                    <i className="fa fa-angle-down"></i>
                </div>
                <div className="dropdown-content">
                    <div className="dropdown-bar"></div>
                    <div className="dropdown-list">
                    <div className="user-title">Hi, {upperName} !</div>
                    {/* <Link to="/setting?loc=1"><img src="/img/icon-user.svg" alt=""/> &nbsp;&nbsp;&nbsp;Account</Link>
                    <br/>
                    <Link to="/setting"><img src="/img/icon-settings.svg" alt=""/> &nbsp;&nbsp;&nbsp;Settings</Link>
                    <br/>
                    <Link to="/price-alert"><img src="/img/settings-pricealert.svg" alt=""/>&nbsp;&nbsp;&nbsp;Price Alert</Link>
                    <br/>
                    <Link to="/affiliate"><img src="/img/settings-affiliate.svg" alt=""/>&nbsp;&nbsp;&nbsp;Affiliate</Link>
                    {isPaid && 
                        <>
                        <br/>
                            <a href="https://community.stockhero.ai/auth/join/An3zBfaUTitEpxFAupOe1" target="_blank"><img src="/img/icon-faqs.svg" alt=""/>&nbsp;&nbsp;&nbsp;Community</a>
                        </>
                      }
                    <br/> */}
                    <a onClick={() => signOut()} className="pointer" href="#"><img src="/img/icon-logout.svg" alt=""/> &nbsp;&nbsp;&nbsp;Logout</a>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default connect(null,{signOut})(SettingsDropDown)