import { GET_PORTFOLIO, PORTFOLIO_DETAIL } from "./types";
import { host, getPortfolioUrl, portfolioDetail } from "./const";
import axios from "axios";
import network from "./network";

export const getPortfolio = (interval, exchangeId) => (dispatch, getState) => {
  network
    .get(
      getPortfolioUrl,
      {
        interval,
        exchange_id: exchangeId,
      },
      dispatch,
      getState
    )
    .then((res) => {
      const {
        equities,
        accumulative_trading_volume,
        trading_volume,
        realized_profits,
        portfolio_value,
        portfolio_changed,
      } = res.data;
      dispatch({
        type: GET_PORTFOLIO,
        payload: {
          equities,
          volumes: accumulative_trading_volume,
          trading_volume,
          realized_profits,
          portfolio_value,
          portfolio_changed,
          interval,
        },
      });
    });
};

export const getPortfolioDetail = (exchangeId) => (dispatch, getState) => {
  network
    .get(
      portfolioDetail,
      {
        exchange_id: exchangeId,
      },
      dispatch,
      getState
    )
    .then((res) => {
      const { total, available, in_orders } = res.data;
      dispatch({
        type: PORTFOLIO_DETAIL,
        payload: { total, available, in_orders },
      });
    });
};
