import React from "react";
import {
  StoplossType,
  StoplossTypeName,
  Frequencies,
  FrequencyValues,
  BotType,
  BotTypeName,
  TakeProfitTypeName,
  TakeProfitType
} from "../../../Const";
import {useSelector } from "react-redux";
import { Col, Row, Table } from "react-bootstrap";
import LeftRightForm from "../new-bot/modules/LeftRightForm";

const AdvanceBotSetting = (props) => {
  const { bot,t } = props;
  const isExitBot = bot.type==BotType.exit;
  const theme = useSelector((state) => state.settings.selected_theme);
  const tsls = bot.tsls || [];
  let stoploss = (bot.stop_loss || 0).toFixed(2) + "%";
  if (tsls.length) {
    stoploss =
      tsls[0].sl_type === StoplossType.fixedTsl
        ? StoplossTypeName.fixedTsl
        : StoplossTypeName.variableTsl;
  }
  const tps = bot.tps || [];
  let takeProfit = (bot.profit || 0).toFixed(2) + "%";
  if (tps.length) {
    takeProfit =
    tps[0].tp_type === TakeProfitType.fixedTP
        ? TakeProfitTypeName.fixedTP
        : TakeProfitTypeName.variableTP;
  }
  const exchangePair = bot.exchange_pair || {};
  const exchange = exchangePair.exchange || {};
  const pair = exchangePair.pair || {};
  const indicators = bot.bot_indicators || [];
  const entryIndicators =
    indicators &&
    indicators
      .filter((item) => item.type == "entry")
      .map((item) => item.name)
      .join(", ");
  const exitIndicators =
    indicators &&
    indicators
      .filter((item) => item.type == "exit")
      .map((item) => item.name)
      .join(", ");
  return (
    
      <div className="card-white mpd-card">
          <div className="cw-body bot-detail">
            <Row>
              <Col lg={6}><LeftRightForm 
                  left={t("exchange")}
                  right={<div>
                    <img
                      src={exchange.image}
                      width={20}
                      height={20}
                      style={{ marginRight: 10 }}
                    />
                    <span style={{ fontWeight: "bold" }}>{exchange.name}</span>
                  </div>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("pair")}
                  right={<div className="d-flex"> <img
                    src={pair.base_image}
                    width={20}
                    height={20}
                    style={{ marginRight: 10 }}
                  /><span>{pair && `${pair.from}/${pair.to}`}</span></div>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("bot-type")}
                  right={<>{bot && BotTypeName[bot.type]}</>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("strategy")}
                  right={<>{bot && bot.strategy}</>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("Frequency")}
                  right={<>{bot && Frequencies[FrequencyValues.indexOf(bot.frequency)]}</>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm>
              {!isExitBot && <LeftRightForm 
                  left={t("entry-indicators")}
                  right={<>{entryIndicators}</>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm>}
                <LeftRightForm 
                  left={t("exit-indicators")}
                  right={<>{exitIndicators}</>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm>
                <LeftRightForm 
                  left={t("tp")}
                  right={<>{bot && takeProfit} {bot.type == BotType.price?'':''}</>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm>
                <LeftRightForm 
                  left={t("stoploss")}
                  right={<>{stoploss}</>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm></Col>
              <Col lg={6}>
              <LeftRightForm 
                  left={t("Extended Trading Hour")}
                  right={<>{bot && parseInt(bot.extended_trading_hour)?"Yes":"No"}</>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("24 Hours Trading")}
                  right={<>{bot && parseInt(bot.trading_hour_247)?"Yes":"No"}</>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm>
                 <LeftRightForm 
                  left={t("auto-close-trading")}
                  right={<>{bot && parseInt(bot.end_day_auto_close)?"Yes":"No"}</>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("bo-limit")}
                  right={<>{bot && bot.base_order_percentage} %</>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("Extra Order")}
                  right={<>{bot && bot.extra_order_percentage} %</>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("min-price-gap-extra")}
                  right={<>{bot && bot.extra_price_gap_min} %</>}
                  leftCol={6}
                  rightCol={6}
                ></LeftRightForm>
              </Col>
            </Row>
              
          </div>
      </div>
  );
};

export default AdvanceBotSetting;
