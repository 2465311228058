import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Container,
  Table,
  Button,
  Alert,
} from "react-bootstrap";
import TVChartContainer from "../bots/TVChartContainer";
import {
  getDealDetail,
  closeDeal,
  resetDealDetail,
  deleteDeal,
} from "../../actions/deal-action";
import { connect, useSelector } from "react-redux";
import {
  formatDate,
  milisecOf,
  roundNumber,
  typeNameOrder,
} from "../../utils/Utils";
import { AllFrequencies, IncompletedDeals } from "../../Const";
import DealWarning from "./DealWarning";
import Loading from "../common/Loading";
import { toast } from "react-toastify";
import { BotType, IndicatorType } from "../../Const";
import { isLong } from "../../utils/Utils";
import GridBotDealTradeHistory from "./GridBotDealTradeHistory";
import { useTranslation } from "react-i18next";
import ContentContainer from "../common/ContentContainer";
import LeftRightForm from "../bots/new-bot/modules/LeftRightForm";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import BackButton from "../common/BackButton";
import DcaDealDetails from "./DcaDealDetails";

const DealDetail = (props) => {
  const {
    show,
    onHide,
    getDealDetail,
    closeDeal,
    resetDealDetail,
    deleteDeal,
    selectedExchangeId,
    itemsPerPage,
    pageNum,
    selectedTab,
  } = props;
  const { dealId } = useParams();
  const {t} = useTranslation();
  const { deal, startCloseDeal, closeDealError, closeDealSuccess } =
    useSelector((state) => state.deals);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const theme = useSelector((state) => state.settings.selected_theme);
  const history = useHistory()

  useEffect(() => {
    if (dealId >= 0) {
      getDealDetail(dealId);
    }
  }, [dealId]);

  useEffect(() => {
    if (closeDealSuccess) {
      setTimeout(() => {
        toast.info(t("Success"));
        history.push('/deals')
      }, 500);
    }
  }, [closeDealSuccess]);

  useEffect(() => {
    return () => {
      resetDealDetail();
    };
  }, [show]);

  const clickDelete = () => {
    setLoading(true);
    deleteDeal(dealId,selectedExchangeId,itemsPerPage,pageNum,selectedTab)
      .then(() => {
        setLoading(false);
        toast.info(t("deal-deleted"));
        history.push('/deals')
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const indicators = (deal && deal.bot && deal.bot.bot_indicators) || [];
  const longStrategy = isLong(deal.strategy);
  const isAdvanced = deal.bot && deal.bot?.type=='advance'?true:false;
  const isGrid = deal.bot && deal.bot?.type=='grid'?true:false;
  const isDca = deal.bot && deal.bot?.type=='dca'?true:false;
  const entryIndicators = indicators
    .filter((el) => el.type === "entry")
    .map((indicator) =>{
      let config = "";
      if(isAdvanced){
        switch (indicator.indicator_id) {
          case IndicatorType.RSI:
          case IndicatorType.SRSI:
            if (longStrategy) {
              config = `${indicator.period_num}, ${indicator.value2}, ${
                indicator.value5
              }, ${indicator.value4 == 1}`
            } else {
              config = `${indicator.period_num}, ${indicator.value3}, ${
                indicator.value6
              }, ${indicator.value4 == 1}`;
            }
            break;
          case IndicatorType.BB:
            config = `${indicator.period_num}, ${
              longStrategy ? indicator.value2 : indicator.value3
            }`
            break;
          case IndicatorType.EMA:
            config = `${indicator.period_num}, ${indicator.value2}`;
            break;
          case IndicatorType.MACD:
            config = `${indicator.period_num}, ${indicator.value2}, ${indicator.value3}`;
            break;
          default:
            break;
          }
          return config?indicator.name+'('+config+')':indicator.name
      }
      return indicator.name
    } )
    .join(", ");
  const exitIndicators = indicators
    .filter((el) => el.type === "exit")
    .map((indicator) => {
      let config = "";
      if(isAdvanced){
        switch (indicator.indicator_id) {
          case IndicatorType.RSI:
          case IndicatorType.SRSI:
            if (longStrategy) {
              config = `${indicator.period_num}, ${indicator.value3}`
            } else {
              config = `${indicator.period_num}, ${indicator.value2}`;
            }
            break;
          case IndicatorType.BB:
            config = `${indicator.period_num}, ${
              !longStrategy ? indicator.value2 : indicator.value3
            }`
            break;
          case IndicatorType.EMA:
            config = `${indicator.period_num}, ${indicator.value2}`;
            break;
          case IndicatorType.MACD:
            config = `${indicator.period_num}, ${indicator.value2}, ${indicator.value3}`;
            break;
          default:
            break;
          }
          return config?indicator.name+'('+config+')':indicator.name
      }
      return indicator.name
    })
    .join(", ");
  //console.log(deal.deal_trade_histories)
  const orders =
    deal &&
    (deal.deal_trade_histories || [])
      .filter((el) => (el.status == "filled" || el.status == "partial_filled"))
      .map((item) => ({
        name: item.name,
        desc: `${item.filled} ${deal.quote_name} @ ${item.average} ${deal.base_name}`,
        created_at: item.status=="filled"?item.time:item.created_at,
        side: item.side,
        status: item.status,
      }));
  window.bot = orders && {
    strategy: deal.strategy,
    orders,
  };

  const tradeHistory = (deal && deal.deal_trade_histories) || [];
  tradeHistory.sort((order1, order2) => {
    return milisecOf(order1.time) - milisecOf(order2.time);
  });
  const tradeItems = tradeHistory.map((item, index) => {
    const isLong = deal.strategy == "Long";
    return (
      <tr key={index}>
        <td>
          {formatDate(item.time, "YYYY-MM-DD HH:mm:ss", "DD MMM YYYY HH:mm:ss")}
        </td>
        <td>{item.side}</td>
        <td>{typeNameOrder(item.name)}</td>
        <td>{item.status.toUpperCase()}</td>
        <td>
          {item.status === "ongoing" ? item.quantity : item.filled}{" "}
          {deal.quote_name}
        </td>
        <td>
          {item.status === "ongoing" ? item.price.toFixed(2) : item.average.toFixed(2)}{" "}
          {deal.base_name}
        </td>
        <td>
          {(item.quantity * item.average).toFixed(2)} {deal.base_name}
        </td>
      </tr>
    );
  });

  const entryOrders = tradeHistory.filter(
    (el) =>
      ["bo", "eo"].includes(el.name.toLowerCase()) && el.status === "filled"
  );
  const { cost, amount } = entryOrders.reduce(
    ({ cost, amount }, cur) => ({
      cost: cost + cur.quote_quantity,
      amount: amount + cur.filled,
    }),
    { cost: 0, amount: 0 }
  );
  const averagePrice =
    entryOrders.length === 1
      ? entryOrders[0].average
      : amount === 0
      ? 0
      : cost / amount;
  return (
    <Loading show={loading} onHide={() => setLoading(false)}>
      <ContentContainer url="deals" title={t("deals")} showExchange={true}>
            <div className="dashboard-content">
            <BackButton ></BackButton>
              <Row>
                <Col lg={6}>
                      <div className="card-white mpd-card mt-5">
                          <div className="cw-body bot-detail">
                          <div className="mt-4" style={{ height: "450px",marginTop:"25px" }}>
                            {deal && 
                            <TVChartContainer
                              pairId={deal && deal.pair_id}
                              symbol={
                                (deal && `${deal.quote_name}/${deal.base_name}`) || "USDT"
                              }
                              interval={deal && deal.bot && deal.bot.frequency}
                            /> }
                          </div>
                          </div>
                      </div>
                </Col>
              <Col lg={6}>
                      <div className="bot-title-div text-left">
                        <span className="bot-name-text">{deal && deal.bot_name}</span>
                      </div>
                      <div className="card-white mpd-card mt-2">
                          <div className="cw-body bot-detail">
                              <LeftRightForm 
                                left={t("exchange")}
                                right={<><img
                                  src={deal.exchange && deal.exchange.image}
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 10 }}
                                /> {deal.exchange && deal.exchange.name}</>}
                                leftCol={4}
                                rightCol={8}
                              ></LeftRightForm>
                              <LeftRightForm 
                              left={t("pair")}
                              right={deal && deal.pair && deal.pair.name}
                              leftCol={4}
                              rightCol={8}
                            ></LeftRightForm>
                              <LeftRightForm 
                              left={t("status")}
                              right={(deal && deal.status_name) || "Error"}
                              leftCol={4}
                              rightCol={8}
                            ></LeftRightForm>
                            {isDca ? <DcaDealDetails deal={deal} t={t}></DcaDealDetails>:
                            <>
                              <LeftRightForm 
                              left={deal && IncompletedDeals.includes(deal.status_key)
                                ? t("upnl")
                                : t("real-pnl")}
                              right={<>
                                {deal && deal.net_profit && deal.net_profit.toFixed(2)}{" "}
                                {deal && deal.base_name} (
                                {deal &&
                                  deal.profit_value &&
                                  deal.profit_value.toFixed(2)}
                                %) {deal.note && <DealWarning note={deal.note} />}
                              </>}
                              leftCol={4}
                              rightCol={8}
                            ></LeftRightForm>
                            <LeftRightForm 
                              left={t("Base Order")}
                              right={<>{deal && deal.bot && deal.bot.base_order_percentage} %</>}
                              leftCol={4}
                              rightCol={8}
                            ></LeftRightForm>
                            <LeftRightForm 
                              left={t("Initial Fund")}
                              right={<>{deal && deal.bot && deal.bot.initial_fund}</>}
                              leftCol={4}
                              rightCol={8}
                            ></LeftRightForm>
                            <LeftRightForm 
                              left={t("Frequency")}
                              right={<>{deal && deal.bot && AllFrequencies[deal.bot.frequency]}</>}
                              leftCol={4}
                              rightCol={8}
                            ></LeftRightForm>
                            <LeftRightForm 
                              left={t("tp")}
                              right={<>{deal && deal.profit}%</>}
                              leftCol={4}
                              rightCol={8}
                            ></LeftRightForm>
                            <LeftRightForm 
                              left={t("stoploss")}
                              right={<>{deal && deal.stop_loss} %</>}
                              leftCol={4}
                              rightCol={8}
                            ></LeftRightForm>
                            {!(deal.bot && deal.bot.rent_id) && 
                            <>
                            {!(deal && deal.bot && deal.bot.type == BotType.grid ) && 
                            <>
                              <LeftRightForm 
                              left={t("strategy")}
                              right={<>{deal && deal.strategy}</>}
                              leftCol={4}
                              rightCol={8}
                            ></LeftRightForm>
                              <LeftRightForm 
                              left={t("entry-indicators")}
                              right={<>{entryIndicators}</>}
                              leftCol={4}
                              rightCol={8}
                            ></LeftRightForm>
                              <LeftRightForm 
                              left={t("exit-indicators")}
                              right={<>{exitIndicators}</>}
                              leftCol={4}
                              rightCol={8}
                            ></LeftRightForm> </>}
                            </>
                            }
                            </>
                            }
                          </div>
                      </div>
                </Col>
              </Row>
            {isGrid?<GridBotDealTradeHistory tradeItems={tradeHistory} deal={deal} t={t}></GridBotDealTradeHistory>:<>
            <Row className="mt-5">
              <Col lg={12}>
              <h5>
                Trade History{" "}
                {!isGrid &&  <span style={{ fontSize: 12, marginLeft: 15 }}>
                  Average Price: {roundNumber(averagePrice)} {deal.base_name}{" "}
                </span> }
              </h5>
              <div className="mt-4 bot-tab">
                <div className="" >
                      <div className="deals-table">
                          <table className="">
                              <thead>
                                  <tr valign="top">
                                    <th>{t("date")}</th>
                                    <th>{t("side")}</th>
                                    <th>{t("order-type")}</th>
                                    <th>{t("status")}</th>
                                    <th>{t("amount")}</th>
                                    <th>{t("price")}</th>
                                    <th>{t("total-val")}</th>
                                  </tr>
                                </thead>
                                <tbody>{tradeItems}</tbody>
                            </table>
                      </div>
                    </div>
                </div>
              </Col>
              </Row></>}
            
            {deal && IncompletedDeals.includes(deal.status_key) ? (
              <>
                <Row className="justify-content-center mt-4">
                  <Button variant="danger" onClick={() => closeDeal(dealId,selectedExchangeId,itemsPerPage,pageNum,selectedTab)}>
                    {startCloseDeal ? "Liquidating..." : "Liquidate"}
                  </Button>
                  <Button variant="danger" onClick={setShowConfirm} className="ml-2">
                      Delete
                    </Button>
                </Row>
                <Row className="justify-content-center mt-2">
                  <Alert
                    variant={closeDealError ? "danger" : "success"}
                    show={closeDealError != null || closeDealSuccess}
                  >
                    {closeDealError || "Liquidated successfully!"}
                  </Alert>
                </Row>
              </>
              
            ) : (
              <>
                {" "}
                {deal && (
                  <Row className="justify-content-center mt-4">
                    <Button variant="danger" onClick={clickDelete}>
                      Delete
                    </Button>
                  </Row>
                  
                )}
              </>
            )}
            <ConfirmDialog show={showConfirm} onHide={() => setShowConfirm(false)} delete={clickDelete}/>
            </div>
        </ContentContainer>
    </Loading>
  );
};

export default connect(null, {
  getDealDetail,
  closeDeal,
  resetDealDetail,
  deleteDeal,
})(DealDetail);
const ConfirmDialog = (props) => {
  const clickClose = () => {
    props.onHide();
  };
  const clickCancel = () => props.onHide();
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>Confirm Dialog</Modal.Title>
      </Modal.Header>
      <Modal.Body>Please confirm that you would like to delete this deal by clicking the Delete button below.</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.delete}>
          Delete
        </Button>
        <Button variant="info" onClick={clickCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
