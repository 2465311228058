import React from "react";
import { Modal } from "react-bootstrap";

const ModalContainer = (props) => {
  const { show, onHide,size } = props;
  return (
    <Modal show={show} onHide={onHide} size={size}>
      {!props.hideClose && <Modal.Header closeButton>
         <Modal.Title>{props.title}</Modal.Title> 
      </Modal.Header> }
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
};

export default ModalContainer;
