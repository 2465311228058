import React from "react";
import ModalContainer from "../common/ModalContainer";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const NonPremiumPrompt = (props)=>{
    const {showNoAccessPrompt,onHide} = props
    return (
        <ModalContainer
            show={showNoAccessPrompt}
            onHide={onHide}
            hideClose={true}
            size="lg"
        >
      <div className="indicator-setting text-center mt-4">
        <h2>This feature is accessible only to users with Premium and Professional plans.</h2>
        <h2>Subscribe now to unlock this feature and enjoy additional benefits.</h2>
        <Link to={'/dashboard'}><Button type="button" className="mt-4 mr-2">Back</Button></Link> 
        <Link to={'/setting?loc=2'}><Button type="button" className="mt-4 ">Subscribe</Button></Link> 
      </div>
    </ModalContainer>
    )
}

export default NonPremiumPrompt;