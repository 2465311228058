import React from "react";
import { DcaTypeName, TPTypeName } from "../../Const";
import LeftRightForm from "../bots/new-bot/modules/LeftRightForm";
const DcaDealDetails = (props) => {
  const {deal,t} = props;
  return(<> 
  <LeftRightForm 
    left={t("Per Order Amount")}
    right={<>{(deal && deal.bot && deal.bot.base_order_percentage)} {deal.base_name} </>}
    leftCol={4}
    rightCol={8}
  ></LeftRightForm>
  <LeftRightForm 
    left={t("Fund Allocation")}
    right={<>{(deal && deal.bot && deal.bot.initial_fund)} {deal.base_name} </>}
    leftCol={4}
    rightCol={8}
  ></LeftRightForm>
  <LeftRightForm 
    left={t("Entry Conditions")}
    right={(deal && deal.bot && DcaTypeName[deal.bot.dca_setting.entry_type]) || ""}
    leftCol={4}
    rightCol={8}
  ></LeftRightForm>
  <LeftRightForm 
    left={t("No. of Extra Orders")}
    right={(deal && deal.bot && deal.bot.extra_order_percentage === 0 ? t("no-limit"):deal.bot.extra_order_percentage) || "0"}
    leftCol={4}
    rightCol={8}
  ></LeftRightForm>
  <LeftRightForm 
    left={t("Take Profit Type")}
    right={(deal && deal.bot && deal.bot.tps.lenght > 0 ?TPTypeName[deal.bot.tps[0].tp_type]:TPTypeName['absolute']) || "Error"}
    leftCol={4}
    rightCol={8}
  ></LeftRightForm>
  
  </>
  );
}
export default DcaDealDetails;