import React, { useEffect, useState } from "react";
import { restrictAlphabets } from "../../../../utils/Utils";
import TutorialPopup from "../../../help/TutorialPopup";
import useInput from "../../../hooks/useIndicatorInput";
import IsRequiredModule from "./IsRequiredModule";
import CustomCheckbox from "../modules/CustomCheckbox";
import CustomRadio from "../modules/CustomRadio";

const RsiEntrySetting = (props) => {
  const { indicator, updateIndicator, isLong,t } = props;
  const [boLong, bindBoLong, setBoLong] = useInput(
    isLong ? indicator.value2 : indicator.value3
  );
  const [eoLong, bindEoLong, setEoLong] = useInput(
    isLong ? indicator.value5 : indicator.value6
  );
  const [periodLong, bindPeriodLong, setPeriodLong] = useInput(
    indicator.period_num,
    0
  );
  const [boAvgPeriod, bindBoAvgPeriod, setBoAvgPeriod] = useInput(
    indicator.value7,
    0
  );
  const [eoAvgPeriod, bindEoAvgPeriod, setEoAvgPeriod] = useInput(
    indicator.value8,
    0
  );
  const [trailingLong, setTrailingLong] = useState(indicator.value4);
  const [isAverage, setIsAverage] = useState(indicator.value7?1:0);
  const [crossDirection, setCrossDirection] = useState(indicator.value9?1:0);
  

  const changeStrategy = (val)=>{
    
    if(val==0){
      updateIndicator({
        ...indicator,
        value7: 0,
        value8: 0,
      })
    }
    setIsAverage(val)
  }

  useEffect(() => {
    updateIndicator({
      ...indicator,
      value2: isLong ? boLong : indicator.value2,
      value3: !isLong ? boLong : indicator.value3,
      value5: isLong ? eoLong : indicator.value5,
      value6: !isLong ? eoLong : indicator.value6,
      value7: boAvgPeriod,
      value8: eoAvgPeriod,
      period_num: periodLong,
      value4: trailingLong,
      value9: crossDirection,
    });
  }, [boLong, eoLong, periodLong, trailingLong,boAvgPeriod,eoAvgPeriod,crossDirection]);

  useEffect(() => {
    setBoLong(isLong ? indicator.value2 : indicator.value3);
    setEoLong(isLong ? indicator.value5 : indicator.value6);
    setBoAvgPeriod(indicator.value7);
    setEoAvgPeriod(indicator.value8);
    setPeriodLong(indicator.period_num);
    setTrailingLong(indicator.value4);
  }, [indicator]);

  const side = isLong ? t("buy") : t("sell");
  return (
    <>
    <div>
      
     
      <div className="d-flex justify-content-between indicator-setting-form">
          <div>
            <label className="indicator-setting-label">{isLong ? t("long") : t("short")} {t("strategy")}:</label>
            </div>
            <div>
              <select className="form-control select-normal" onChange={(e)=>{changeStrategy(e.target.value)}}>
                  <option value={0} key={1} selected={isAverage==0}>Current</option>
                  <option value={1} key={2} selected={isAverage==1}>Average</option>
              </select>
            </div>
      </div>
      {isAverage==0 ? 
      <>
        <div className="d-flex justify-content-between indicator-setting-form">
          <div><label className="indicator-setting-label">{side} {t("rsi-level-bo")}:</label></div>
          <div> <input type="number" {...bindBoLong} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
        <div className="d-flex justify-content-between indicator-setting-form">
          <div><label className="indicator-setting-label">{side} {t("rsi-level-eo")}:</label></div>
          <div><input type="number" {...bindEoLong} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        </div>
        <CustomCheckbox 
                        val={trailingLong} 
                        checked={trailingLong == 1} 
                        callback={setTrailingLong}
                        label={<>{t("activate-t-rsi")} <TutorialPopup content={isLong ? t("trailingRSILong") : t("trailingRSIShort")} /></>}
                        id={'activate-t-rsi'}
                        >
        </CustomCheckbox>
      </>
      :
      <>
        <div className="d-flex justify-content-between align-items-center indicator-setting-form">
          <div>
            <label className="indicator-setting-label">{side} {t("rsi-level-bo-avg")}:</label>
          </div>
          <div className=""> 
            <input type="number" {...bindBoLong} className="input-bg-color form-control indicator-setting-input " onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/>
          </div>
          <div className="break"><label className="indicator-setting-label ml-3"> {t("rsi-based-on-periods")}:</label></div>
          <div className=""> <input type="number" {...bindBoAvgPeriod} className="input-bg-color form-control indicator-setting-input" onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
          <div className=""><label className="indicator-setting-label ml-3 ">{t("periods")}</label></div>
        </div>
        <div className="d-flex justify-content-between align-items-center indicator-setting-form">
          <div>
            <label className="indicator-setting-label">{side} {t("rsi-level-eo-avg")}:</label>
          </div>
          <div className=""> 
            <input type="number" {...bindEoLong} className="input-bg-color form-control indicator-setting-input " onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/>
          </div>
          <div className="break"><label className="indicator-setting-label ml-3"> {t("rsi-based-on-periods")}:</label></div>
          <div className=""> <input type="number" {...bindEoAvgPeriod} className="input-bg-color form-control indicator-setting-input" onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
          <div className=""><label className="indicator-setting-label ml-3 ">{t("periods")}</label></div>
        </div>
       
      </>
      }
      
     
      <div className="d-flex justify-content-between indicator-setting-form align-items-center mb-2">
          <div><label className="indicator-setting-label">{t("period")}</label></div>
          <div> <input type="number" {...bindPeriodLong} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
      <IsRequiredModule indicator={indicator} updateIndicator={updateIndicator} t={t}></IsRequiredModule>
      <br></br>
      {isAverage==0 ? 
      <div className="d-flex jcb w-70">
        <div className="d-flex">
          <CustomRadio
            type="radio"
            name="cross_strategy"
            label={t("cross-up")}
            checked={crossDirection==1} 
            callback={() => setCrossDirection(1)}
          ></CustomRadio>
          <TutorialPopup content={t("cross-up-info")} />
          </div>
        <div className="d-flex">
          <CustomRadio
            type="radio"
            name="cross_strategy"
            label={t("cross-down")}
            checked={crossDirection==0} 
            callback={() => setCrossDirection(0)}
          ></CustomRadio>
          <TutorialPopup content={t("cross-down-info")+'. This is the default'} />
          </div>
      
          
      </div>
      :<></>}
    </div>
  </>
  );
};

export default RsiEntrySetting;
