import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import TradingInforPanel from "./TradingInforPanel";
import OrderPanel from "./OrderPanel";
import BalancePanel from "./BalancePanel";
import NewOrderPanel from "./NewOrderPanel";
import RepayModal from "./RepayModal";
import TransferModal from "./TransferModal";
import { getExchangePairs } from "../../actions/bot-action";
import { connect, useSelector, useDispatch } from "react-redux";
import ContentContainer from "../common/ContentContainer";
import "./TradingTerminal.scss";
import WatchList from "./WatchList";
import MarketPanel from "./MarketPanel";
import { AccountType, TradeSide } from "../../Const";
import Loading from "../common/Loading";
import useLoading from "../hooks/useLoading";
import { CHANGE_ACCOUNT } from "../../actions/types";
import { getExecutedBotOrders } from "../../actions/terminal-action";
import { useTranslation } from "react-i18next";

const TradingTerminal = (props) => {
  const { getExchangePairs,getExecutedBotOrders } = props;
  const {t} = useTranslation()

  const dispatch = useDispatch();
  const [tradeSide, setTradeSide] = useState(TradeSide.buy);
  const [showRepay, setShowRepay] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [setLoading, bindLoading] = useLoading();
  const selectedPair = useSelector((state) => state.terminal.selectedPair);
  const exchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  const account =
    useSelector((state) => state.terminal.account) || AccountType.spot;
  const baseCoin = selectedPair.to;
  const quoteCoin = selectedPair.from;

  useEffect(() => {
    getExchangePairs(exchangeId);
  }, [exchangeId]);

  useEffect(() => {
    console.log(selectedPair)
    getExecutedBotOrders(selectedPair.pairId);
  }, [baseCoin,quoteCoin]);

  const changeAccount = (acc) => {
    dispatch({
      type: CHANGE_ACCOUNT,
      payload: acc,
    });
  };

  return (
    <ContentContainer
      url="terminal"
      title={t("t-terminal")}
      showExchange={true}
    >
      <Row noGutters className="terminal">
        <Col>
          <div>
            <hr />
            <MarketPanel account={account} accountChanged={changeAccount} />
            <hr />
          </div>
          <Row noGutters>
            <Col className="watch-panel">
              <div className="panel">
                <WatchList />
              </div>
            </Col>
            <Col>
              <div className="panel h-100">
                <TradingInforPanel/>
              </div>
            </Col>
          </Row>

          <div>
            <hr />
            <OrderPanel account={account} t={t} />
          </div>
        </Col>
        <Col className="trade-panel vh-100">
          <div className="">
            <NewOrderPanel
              tradeSide={tradeSide}
              tradeSideChanged={setTradeSide}
              quoteCoin={quoteCoin}
              baseCoin={baseCoin}
              account={account}
              t={t}
            />
          </div>
          <hr />
          <div className="">
            <BalancePanel
              account={account}
              onShowRepay={() => setShowRepay(true)}
              onShowTransfer={() => setShowTransfer(true)}
              quoteCoin={baseCoin}
              baseCoin={quoteCoin}
              t={t}
            />
          </div>
        </Col>
      </Row>
      <RepayModal
        show={showRepay}
        onHide={() => setShowRepay(false)}
        baseCoin={baseCoin}
        quoteCoin={quoteCoin}
        setLoading={setLoading}
        pairId={selectedPair.realPairId}
        account={account}
      />
      <TransferModal
        show={showTransfer}
        onHide={() => setShowTransfer(false)}
        baseCoin={baseCoin}
        quoteCoin={quoteCoin}
        pairId={selectedPair.realPairId}
        setLoading={setLoading}
        account={account}
        t={t}
      />
      <Loading {...bindLoading} />
    </ContentContainer>
  );
};

export default connect(null, { getExchangePairs,getExecutedBotOrders })(TradingTerminal);
