import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_BOT_NAME } from "../../../../actions/create-bot-type";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import AssetView from "../AssetView";
import GridBotParamsView from "../grid-bot/GridBotParamsView";
import TradeParamsView from "../TradeParamsView";
import { BotType } from "../../../../Const";
import AssetViewRent from "../../../marketplace/bots/AssetViewRent";
import TradeParamsViewRent from "../../../marketplace/bots/TradeParamsViewRent";

const AssetParamRent = (props)=>{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const rentBot = useSelector((state) => state.createBot.rentBot);
    const isEditing = useSelector((state) => state.createBot.rentBot.isEditing);
    const Exchange = useSelector((state) => state.settings.selectedExchange) || 0;
    const selectedExchange = isEditing?rentBot.exchange:Exchange;
    const tabTitles = [t("asset"), t("t-params")];
    const tourClasses = ["bot-asset-tour", "trade-params-tour"];
    const [activeTab, setActiveTab] = useState(0);
    const [selectedTab, getSelectedTab] = useState('open');
    const changeTab = (index,tab) => {
        setActiveTab(index);
        getSelectedTab(tab)
    };
    const tabHeaders = tabTitles.map((item, index) => {
        return (
          
            <a
              onClick={() => changeTab(index,index==0?'open':'close')}
              className={(index == activeTab ? "list-tab tab-active" : "list-tab tab-inactive" )+ " "+ tourClasses[index]}
              key={index}
            >
              {item}
            </a>
          
        );
    });
    const tabs = [
        <AssetViewRent rentBot={rentBot} selectedExchange={selectedExchange} t={t} isEditing={isEditing}/>,
        <TradeParamsViewRent
            editing={isEditing}
            rentBot={rentBot}
            t={t}
        />
    ];
    return(
        <>
        <Col xl={6} xs={12} className="asset-param-div">
            <Row>
            <Col xl={12} className="section-tabs-bar">{tabHeaders}</Col>
            </Row>
            <div className="card-white cb-section-1">
                <div className="cw-body">
                    {tabs[activeTab]}
                </div>
            </div>
        </Col> 
        </>
    )
}

export default AssetParamRent;