import React, { useState, useCallback, useEffect } from "react";
import { toggleNav } from "../../actions/setting-action";
import { connect, useDispatch, useSelector } from "react-redux";
import "./ContentContainer.scss";
import ContactForm from "../help/ContactForm";
import NewUserTour from "../tour/NewUserTour";
//import ProjectTour from "../tour/ProjectTour";
import Header from "./Header";
import Loading from "./Loading";
import Footer from "./Footer";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


const ContentContainer = (props) => {
  const navExpand = useSelector((state) => state.settings.navExpand);
  const takeTour = useSelector((state) => state.settings.takeTour);
  const loading = useSelector((state) => state.settings.loading);
  const startTakeTour = useSelector((state) => state.settings.startTakeTour);
  const newUserIntroPupup = useSelector((state) => state.settings.newUserIntroPupup);
  
  const [showContact, setShowContact] = useState(false);
  const [showTour, setShowTour] = useState(true);
  const toggleExpand = () => props.toggleNav(!navExpand);
  return (
    <>
      <Header
            url={props.url}
            toggleExpand={toggleExpand}
            showContact={() => setShowContact(true)}
          />
      {props.children}
     <Footer></Footer>
      <ContactForm show={showContact} onHide={() => setShowContact(false)} />
      { props.showChat ?
      <TawkMessengerReact
                widgetId="1ho9adh0i"
                propertyId="64e04096cc26a871b030205c"/>
                :<></>}
      {/* {(newUserIntroPupup && !startTakeTour) ? <NewUserTour setShowTour={setShowTour}></NewUserTour>:<></> } */}
      </>
  );
};

export default connect(null, { toggleNav })(ContentContainer);
