import React from "react";
import {
  StoplossType,
  StoplossTypeName,
  Frequencies,
  FrequencyValues,
  BotType,
  BotTypeName
} from "../../../Const";
import {useSelector } from "react-redux";
import { Col, Row, Table } from "react-bootstrap";
import LeftRightForm from "../new-bot/modules/LeftRightForm";

const GridBotSetting = (props) => {
  const { bot,t } = props;
  const theme = useSelector((state) => state.settings.selected_theme);
  const tsls = bot.tsls || [];
  let stoploss = (bot.stop_loss || 0).toFixed(2) + "%";
  if (tsls.length) {
    stoploss =
      tsls[0].sl_type === StoplossType.fixedTsl
        ? StoplossTypeName.fixedTsl
        : StoplossTypeName.variableTsl;
  }
  const exchangePair = bot.exchange_pair || {};
  const exchange = exchangePair.exchange || {};
  const pair = exchangePair.pair || {};
  const indicators = bot.bot_indicators || [];
  const gridConfigs = bot.configs;
  const entryIndicators =
    indicators &&
    indicators
      .filter((item) => item.type == "entry")
      .map((item) => item.name)
      .join(", ");
  const exitIndicators =
    indicators &&
    indicators
      .filter((item) => item.type == "exit")
      .map((item) => item.name)
      .join(", ");
  return (
    <>
      <div className="card-white mpd-card">
          <div className="cw-body bot-detail">
              <LeftRightForm 
                  left={t("exchange")}
                  right={<div>
                    <img
                      src={exchange.image}
                      width={20}
                      height={20}
                      style={{ marginRight: 10 }}
                    />
                    <span style={{ fontWeight: "bold" }}>{exchange.name}</span>
                  </div>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("pair")}
                  right={<>{pair && `${pair.from}/${pair.to}`}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("bot-type")}
                  right={<>{bot && BotTypeName[bot.type]}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("strategy")}
                  right={<>{bot && bot.strategy}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("Frequency")}
                  right={<>{bot && Frequencies[FrequencyValues.indexOf(bot.frequency)]}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("no-of-ord")}
                  right={<>{gridConfigs.grid_order_num}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
                <LeftRightForm 
                  left={t("per-order-amount")}
                  right={<>{gridConfigs.grid_order_vol}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
                <LeftRightForm 
                  left={t("upper-range")}
                  right={<>{gridConfigs.grid_upper_range}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
                <LeftRightForm 
                  left={t("lower-range")}
                  right={<>{gridConfigs.grid_lower_range}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
                <LeftRightForm 
                  left={t("Exit Wait Time")}
                  right={<>{gridConfigs.grid_exit_wait_time} Minutes</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
                <LeftRightForm 
                  left={t("Exit Strategy Grids filled")}
                  right={<>{gridConfigs.grid_exit_strategy_has_trade}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
                <LeftRightForm 
                  left={t("Exit Strategy No Grids filled")}
                  right={<>{gridConfigs.grid_exit_strategy_no_trade}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
            </div>
      </div>
    </>
  );
};

export default GridBotSetting;
