import React from "react";
import { Form, Button } from "react-bootstrap";
import { Trans } from "react-i18next";

const ProfessionalDetails = (props) => {
  
  const {t} = props;
  const botName = "WallStreetZen"
  const botName2 = "Market Neutral Bot "
  return (
    <>
    <h6 className="text-row">
          <i className="fas fa-check item-check"></i>{" "}
          50 {t("active-bots")}
      </h6>
      {/* <h6 className="text-row">
        <i className="fas fa-check item-check"></i>10 {t("prof-info-1")}
      </h6> */}
      <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        {t("prof-info-2")}
      </h6>
      <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        {t("Extended-hours trading")}
      </h6>
      <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        {t("prof-info-3")}
      </h6>
      <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        {t("prof-info-4")}
      </h6>
      <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        {t("prof-info-5")}
      </h6>
      {/* <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        <Trans i18nKey={"prof-info-6"}>
              <a href="https://www.wallstreetzen.com/plans" target="_blank"></a>
          </Trans>
      </h6> */}
      {/* <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        <Trans i18nKey={"prof-info-7"}>
              <a href="https://blog.stockhero.ai/market-neutral-bot-wins-best-performing-bot-with-nearly-100-win-loss-ratio/" target="_blank">{{botName}}</a>
          </Trans>
      </h6> */}
      <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        {t("prof-info-8")}
      </h6>
      {/* <h6 className="promotion-text">
            <a href="https://www.stockhero.ai/pricing" target="_blank">{t("free-info-5")}</a>
         </h6> */}
    </>
  );
};

export default ProfessionalDetails;
