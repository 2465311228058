import {
  GET_MARKETPLACE_SUMMARY,
    GET_RENT_BOTS_AVAILABLE, LIST_MARKETPLACE_TEMPLATES, OWNER_TEMPLATES, SET_BOT_SELECTED, SET_PAYMENT_HISTORY, SET_TEMPLATE_DETAIL, SET_TEMPLATE_OWNER_DATA, SET_TUTORIAL_POPUP, TOP_TEMPLATES,
  } from "./types";
  import {
    botListUrl, eligibleBots, getMarketplaceSummaryUrl, getTemplatesUrl, marketplaceTutorial, payoutHistoryUrl, requestPaymentUrl
  } from "./const";
  import network from "./network";

  
  export const getAvailableBots = (sort,orderBy,ownerId) => (dispatch, getState) => {
    const params = {
      
    };
  
    network.get(eligibleBots, params, dispatch, getState).then((res) => {
      
      dispatch({
        type: GET_RENT_BOTS_AVAILABLE,
        payload: res.data.bots
      });
      
    });
  };
  export const getTemplates = (pageNum,itemsPerPage,ownerId,orderBy,sort,strategy,baseCoin) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      let params = {
        owner_id:ownerId?state.auth.user.id:null,
        page_size:itemsPerPage,
        page:pageNum,
        sort:sort,
        order:orderBy,
        strategy:strategy,
        family:baseCoin,
      };
    
      network.get(getTemplatesUrl, params, dispatch, getState).then((res) => {
        if (res.data.code === 200) {
          if(ownerId){
            dispatch({
              type: OWNER_TEMPLATES,
              payload: {
                templates:res.data.templates.data,
                featuredTemplates:res.data.featured_templates,
                total:res.data.templates.total,
                start:res.data.templates.from
              }
            });
          }
          else{
            dispatch({
              type: LIST_MARKETPLACE_TEMPLATES,
              payload: {
                templates:res.data.templates.data,
                featuredTemplates:res.data.featured_templates,
                my_template_count:res.data.my_template_count,
                total:res.data.templates.total,
                start:res.data.templates.from
              }
            });
          }
          resolve(true)
        }
        else {
          reject(res.data);
        }
        
      });
    })
  };
  export const getOwnTemplates = (pageNum,itemsPerPage,status) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      let params = {
        owner_id:state.auth.user.id,
        page_size:itemsPerPage,
        page:pageNum,
        status:status
      };
    
      network.get(getTemplatesUrl, params, dispatch, getState).then((res) => {
        if (res.data.code === 200) {
          dispatch({
            type: OWNER_TEMPLATES,
            payload: {
              templates:res.data.templates.data,
              total:res.data.templates.total,
              start:res.data.templates.from,
              my_template_count:res.data.my_template_count,
            }
          });
          resolve(true)
        }
        else {
          reject(res.data);
        }
        
      });
    })
  };
  export const getTopTemplates = (itemsPerPage) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      let params = {
        page_size:itemsPerPage,
        page:1,
        sort:'current_renter',
        order:'desc'
      };
    
      network.get(getTemplatesUrl, params, dispatch, getState).then((res) => {
        if (res.data.code === 200) {
          dispatch({
            type: TOP_TEMPLATES,
            payload: {
              templates:res.data.templates.data,
            }
          });
          resolve(true)
        }
        else {
          reject(res.data);
        }
        
      });
    })
  };
  export const getTemplateDetail = (id) => (dispatch, getState) => {
    const state = getState();
  
    network.get(getTemplatesUrl+'/'+id, {}, dispatch, getState).then((res) => {
      dispatch({
        type: SET_TEMPLATE_DETAIL,
        payload: {
          template:res.data.template,
          template_traded_stocks:res.data.traded_stocks
        }
      });
      
    });
  };
  export const getSummary = (interval, exchangeId) => (dispatch, getState) => {
    network
      .get(
        getMarketplaceSummaryUrl,
        {
          time_frame:interval,
          //exchange_id: exchangeId,
        },
        dispatch,
        getState
      )
      .then((res) => {
        const {
          earning,
          renter
        } = res.data.chart_data;
        dispatch({
          type: GET_MARKETPLACE_SUMMARY,
          payload: {
            earning,
            renter,
            interval,
          },
        });
        dispatch({
          type: SET_TEMPLATE_OWNER_DATA,
          payload: res.data
        });
      });
  };

  export const requestPayment = (params) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      network
        .post(requestPaymentUrl, params, dispatch, getState)
        .then((res) => {
          const { code, message } = res.data;
          if (code === 200) {
            resolve();
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(String(err));
        });
    });
  };

  export const getPaymentHistory = (id) => (dispatch, getState) => {
    const state = getState();
  
    network.get(payoutHistoryUrl, {}, dispatch, getState).then((res) => {
      dispatch({
        type: SET_PAYMENT_HISTORY,
        payload:res.data
      });
      
    });
  };
  export const getTutorialFlag = () => (dispatch, getState) => {
    const state = getState();
  
    network.get(marketplaceTutorial, {}, dispatch, getState).then((res) => {
      dispatch({
        type: SET_TUTORIAL_POPUP,
        payload:res.data.saw_flag
      });
      
    });
  };
  export const hideTutorialFlag = () => (dispatch, getState) => {
    const state = getState();
  
    network.post(marketplaceTutorial, {}, dispatch, getState).then((res) => {
      dispatch({
        type: SET_TUTORIAL_POPUP,
        payload:res.data.saw_flag
      });
      
    });
  };
  