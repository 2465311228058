import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { BotType, DcaType, SUB_TIER, TimeWindow } from "../../../../Const";
import { getTvTimeframe } from "../../../../utils/TvUtils";
import TVChartContainer from "../../TVChartContainer";
import { checkSub, isPro } from "../../../../utils/SubUtils";
import { CHANGE_BACKTEST_CUSTOM, CHANGE_TIME_FRAME, CHANGE_TRASACTION_FEE, SET_APPLY_CURRENT_DEAL } from "../../../../actions/create-bot-type";
import { resetBotConfig, runBackTest } from "../../../../actions/create-bot-action";
import { GET_BACKTEST_SUCCESS } from "../../../../actions/types";
import { restrictAlphabets } from "../../../../utils/Utils";
import useLoading from "../../../hooks/useLoading";
import { toast } from "react-toastify";
import Loading from "../../../common/Loading";
import BotPerformance from "../backtest/BotPerformance";
import CreateBotSection from "./CreateBotSection";
import CustomBackTest from "../backtest/CustomBackTest";
import CustomCheckbox from "./CustomCheckbox";

const BackTestSection = (props)=>{
    const {resetBotConfig,runBackTest} = props;
    const TimeFrame_1D = ["1D", "1W", "1M", "3M", "6M", "1Y"];
    const TimeFrame_1H = ["1D", "1W", "1M", "3M", "6M", "1Y"];
    const TimeFrame_15m = ["1D", "1W", "1M", "3M", "6M", "1Y"];
    const TimeFrame_5m = ["1D", "1W", "1M", "3M", "6M", "1Y"];
    const Frequencies = {
        15: "15",
        60: "60",
        120: "120",
        240: "240",
        1440: "1D",
      };
      const FrequenciesPro = {
        1:"1",
        5: "5",
        15: "15",
        60: "60",
        120: "120",
        240: "240",
        1440: "1D",
      };
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const newBot = useSelector((state) => state.createBot.newBot);
    const [isCustomInterval, setIsCustomInterval] = useState(false);
    const [setLoading, bindLoading] = useLoading(false);
    const baseCoin = newBot.baseCoin;
    const quoteCoin = newBot.quoteCoin;
    const frequency = newBot.frequency;
    const strategy = newBot.strategy;
    const isPercentDca = newBot.dcaType === DcaType.sellUp || newBot.dcaType === DcaType.buyDown
    const pair = newBot.pair;
    const reloadTV = useSelector((state) => state.bots.reloadTV);
    const [timeFrames, setTimeFrames] = useState(TimeFrame_1D);
    const [interval, setInterval] = useState(timeFrames[timeFrames.length - 1]);
    const subscription = useSelector(
        (state) => state.auth.user.user_subscription
      );
    const { subPremium, subTier } = checkSub(subscription);
    const frequencyVal= (subPremium && subTier === SUB_TIER.pro)?FrequenciesPro:Frequencies;
    const formatDecimal = (value,decimals) =>{
      if(value=="")
      return
      return Number(Number(value).toFixed(decimals));
    } 
    const timeFrameButtons = timeFrames.map((item, index) => {
      const buttonClass = ` ${
        (item == interval &&  !isCustomInterval)? "bt-btn-active" : "bt-btn-inactive"
      }`;
      return (
        <div key={index}>
          <div className={buttonClass} onClick={() => clickInterval(item)}>
            {item}
          </div>
        </div>
      );
    });
    useEffect(() => {
        switch (frequency) {
          case 5:
            setTimeFrames(TimeFrame_5m);
            setInterval(TimeFrame_5m[TimeFrame_5m.length - 1]);
            break;
          case 15:
            setTimeFrames(TimeFrame_15m);
            setInterval(TimeFrame_15m[TimeFrame_15m.length - 1]);
            break;
          case 60:
            setTimeFrames(TimeFrame_1H);
            setInterval(TimeFrame_1H[TimeFrame_1H.length - 1]);
            break;
          default:
            setTimeFrames(TimeFrame_1D);
            setInterval(TimeFrame_1D[TimeFrame_1D.length - 1]);
        }
      }, [frequency]);
      const clickInterval = (val) => {
        setIsCustomInterval(false)
        setInterval(val);
      };
      const changeTimeFrame = (val) => {
        dispatch({
          type: CHANGE_TIME_FRAME,
          payload: val,
        });
      };
      useEffect(() => {
        changeTimeFrame(TimeWindow[interval]);
      }, [interval]);
      const changeBacktestTimeframe = (val) => {
        setIsCustomInterval(true)
        if(frequency <=15 && val > 180){
          changeTimeFrame(180);
          return false;
        }
        changeTimeFrame(val);
      };
      useEffect(() => {
        window.bot = { strategy, orders: [] };
        return () => {
          resetBotConfig();
          dispatch({
            type: GET_BACKTEST_SUCCESS,
            payload: null,
          });
        };
      }, []);
    
      const setCustomBacktest = (val) => {
        dispatch({
          type: CHANGE_BACKTEST_CUSTOM,
          payload: val,
        });
      };
      const changeTransactionFee = (val) => {
        console.log(val)
        console.log(newBot.transactionFee)
        dispatch({
          type: CHANGE_TRASACTION_FEE,
          payload: val,
        });
       
        
      };
      const clickBackTest = () => {
        setLoading(true);
        runBackTest()
          .then(() => {
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            toast.error(t(err));
          });
      };
    return(<>
        <Col xl={12} xs={12} className="backtest-param-div mt-4">
            <div className="card-white cb-section-1">
                <div className="cw-body">
                <Row style={{ height: "525px" }}>
                  <Col xl={6}>
                    <TVChartContainer
                      pairId={pair.id}
                      symbol={`${baseCoin}/${quoteCoin}`}
                      interval={((newBot.botType == BotType.dca) && (isPercentDca) )? FrequenciesPro[frequency]:frequencyVal[frequency]}
                      reload={reloadTV}
                      timeframe={getTvTimeframe(interval)}
                      />
                  </Col>
                  <Col xl={6}>
                        <Row noGutters className="mt-4">
                            <div className="timeframe-btn-div">
                              {timeFrameButtons}
                            </div>
                        </Row>
                        <Row noGutters className="mt-4 ml-4">
                        {(subPremium && subTier === SUB_TIER.pro && (newBot.botType !=BotType.grid && newBot.botType !=BotType.sell)) && 
                          <>
                          <Col xs="12">
                          
                            <div className="d-flex">
                              <div><label>{t("backtest-from")} &nbsp;&nbsp;</label></div>
                              <div>
                                <input
                                  type="number"
                                  value={newBot.timeFrame}
                                  onChange={(e) => {
                                    changeBacktestTimeframe(e.target.value);
                                  }}
                                  onKeyPress={(e) => {
                                    restrictAlphabets(e);
                                  }}
                                  className="form-control input-text small-text-box"
                                />
                              </div>
                              <div>{t("backtest-days-ago")}</div>
                            </div>
                          </Col>
                          </>}
                          <Col xs="12 mt-3">
                              <div className="d-flex">
                                <div><label className="mt-2">{t("transaction-fee")} </label></div>
                                <div>
                                <label data-text={"%"} className="dca input-gp-label">
                                    <Form.Control
                                        as="input"
                                        type="number"
                                        value={formatDecimal(newBot.transactionFee?newBot.transactionFee:"",2)}
                                        onChange={(e) => {
                                          changeTransactionFee(e.target.value);
                                        }}
                                        onKeyPress={(e) => {
                                          restrictAlphabets(e);
                                        }}
                                        className="input-text input-gp-tex small-text-box"
                                      ></Form.Control>
                                  </label>
                                </div>
                              </div>
                          </Col>
                          <Col xs="12">
                          <Row>
                          <Col xs={12} className="mt-2 custom-bktest-div">
                          {isPro(subscription) ?
                            <>
                            <Row>

                            <CustomCheckbox 
                                val={newBot.backtestCustom} 
                                checked={newBot.backtestCustom==1} 
                                callback={setCustomBacktest}
                                label={"Use Custom Date"}
                                id={'sccc'}
                                >
                            </CustomCheckbox>
                            </Row>
                             
                         
                            {newBot.backtestCustom &&
                            <CustomBackTest newBot={newBot}></CustomBackTest>
                            }
                            </>:
                            <></>
                }
                          </Col>
                          </Row>
                          </Col>
                          
                          {(newBot.botType !=BotType.grid && newBot.botType !=BotType.sell) && 
                          <Col className="d-flex backtest-btn">
                            <Button onClick={clickBackTest} className="btn backtest-tour">
                              {t("run-backtest")}
                            </Button>
                          </Col> }
                          
                          
                      </Row>
                    </Col>
                </Row>
                {/* <CreateBotSection></CreateBotSection> */}
                </div>
            </div>
        </Col> 
        <Col xl={12}>
          <BotPerformance
            baseCoin={baseCoin}
            quoteCoin={quoteCoin}
            strategy={strategy}
            t={t}
          />
        </Col>
        <Loading {...bindLoading} />
        </>)
}

export default connect(null,{resetBotConfig,runBackTest}) (BackTestSection);