import React from "react";
import { useDispatch } from "react-redux";
import {
  CHANGE_DCA_NO_LIMIT_FUND,
  CHANGE_DCA_ORDER,
  CHANGE_FUND,
} from "../../../../actions/create-bot-type";
import { Col, Form, Row } from "react-bootstrap";
import { restrictAlphabets } from "../../../../utils/Utils";
import CustomCheckbox from "../modules/CustomCheckbox";

const DcaParamsView = (props) => {
  const { newBot, currentCoin,t } = props;
  const dispatch = useDispatch();
  const changeDcaNoLimit = (val) => {
    dispatch({
      type: CHANGE_DCA_NO_LIMIT_FUND,
      payload: val,
    });
  };
  const changeDcaOrder = (val) =>
    dispatch({
      type: CHANGE_DCA_ORDER,
      payload: val,
    });
  return (
    <div>
      <Row className="mt-2">
        <Col xl={4}></Col>
        <Col xl={8} className="cb-form-label">
          <CustomCheckbox 
            val={newBot.dcaNoLimitFund} 
            callback={changeDcaNoLimit}
            label={t("no-limit")}
            id={'no-limit'}
            >
          </CustomCheckbox>
        </Col>
       
      </Row>
      <Row className="mt-2">
        <Col xl={4} className="cb-form-label">
        <Form.Label>{t("per-order-amount")}</Form.Label>
        </Col>
        <Col xl={8}>
          <label data-text={currentCoin} className="input-gp-label">
                <Form.Control
                    as="input"
                    type="number"
                    value={newBot.dcaOrder}
                    onChange={(e) => changeDcaOrder(e.target.value)}
                    onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}
                    className="input-text input-gp-text"
                    min={0}
                  ></Form.Control>
          </label>
        </Col>
      </Row>
      
    </div>
  );
};

export default DcaParamsView;
