import React, { useState } from "react";
import { Form, InputGroup, Modal, Button } from "react-bootstrap";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { CJ_TYPE, SUBSCRIPTION_PLAN, SUB_TIER } from "../../Const";
import {
  ProfessionalSubcriptionAmount,
  PremiumSubcriptionAmount,
  usdtAddress,
  UsdtQRcode,
} from "../../Const";
import useInput from "../hooks/useInput";
import useLoading from "../hooks/useLoading";
import { useHistory, useLocation } from "react-router-dom";
import { cryptoSubscription } from "../../actions/setting-action";
import { connect, useSelector } from "react-redux";
import Loading from "../common/Loading";
import Cookies from "universal-cookie";
import queryString from "query-string";

const CryptoPayment = (props) => {
  
  const { show, onHide, sendEmail, tier,network,monthly,cryptoSubscription,subscriptionType } = props;
  const { search } = useLocation();
  const query = queryString.parse(search);
  const plan = query.plan
  const address = network=='ERC'?usdtAddress.erc:usdtAddress.trc;
  const qrcode = network=='ERC'?UsdtQRcode.erc:UsdtQRcode.trc;
  const [transactionId, bindTransactionId, resetTransactionId] = useInput("");
  const [adminNote, bindAdminNote, resetAdminNote] = useInput();
  const email = useSelector((state) => state.auth.user.email) || '';
  const [replyTo, bindReplyTo] = useInput(email);
  const [confirmPayment,setConfirmPayment] = useState(false)
  const [paymentCompleted,setPaymentCompleted] = useState(false)
  const [showContctFormButton,setShowContctFormButton] = useState(false)
  const [showconfirmPayment,setShowConfirmPayment] = useState(true)
  const [setLoading, bindLoading] = useLoading(false);
  const history = useHistory();
  const cookies = new Cookies();
  const cjevent=cookies.get('cje');

  const clickCopy = () => {
    copy(address);
    toast.success("Address copied.");
  };
  const clickSendEmail = () => {
    onHide();
    sendEmail();
  };
  const hideModal = () => {
    if(paymentCompleted)
    history.push('/dashboard');
    onHide();
  };
  const clickPaymentDone = () => {
    if(!transactionId){
      toast.error('please enter transaction Id.')
      return false
    }
    setLoading(true)
    var price = 0;
    
    if(tier && plan){
      price=SUBSCRIPTION_PLAN[subscriptionType][tier]
    }
    const cjParams = {
      CID: 1564371,
      TYPE: CJ_TYPE[tier],
      METHOD: 'S2S',
      SIGNATURE: 'ALmbaM2SuKFUjFJGnGAv7iMQ',
      CJEVENT:cjevent?cjevent:'',
      eventTime:new Date().toISOString(),
      CURRENCY:'USD',
      ITEM1:tier,
      AMT1:price,
      QTY1:1,
    }; 
    
    const params = {
      tier:tier,
      subscription_type:subscriptionType,
      payment_method:'usdt',
      usdt_address:address,
      usdt_network:network,
      message:adminNote,
      tnx_id:transactionId,
      reply_to:replyTo,
      cjevent:cjevent?cjevent:'',
      cj_params:cjParams
    };
    cryptoSubscription(params).then(()=>{
      setLoading(false)
      //setShowContctFormButton(true)
      setShowConfirmPayment(false)
      setPaymentCompleted(true)
      toast.success('Your payment request has been received. You will receive an email once payment has been approved.')
      setTimeout(() => {
        history.push('/dashboard');
      }, 5000);
     
    }).catch((err)=>{
      toast.error(err)
      setLoading(false)
    })
    
  };
  return (
    <Loading {...bindLoading}>
    <Modal show={show} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <img
            src={'/images/coins/USDT.png'}
            width={25}
            style={{ marginRight: 10 }}
          />
          Pay With USDT ({network=='ERC'?'ERC20 or BEP20':'TRON TRC20 '})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center" style={{ fontSize: 15 }}>
        <p>
          Please make a payment with{" "}
          <b>{SUBSCRIPTION_PLAN[plan][tier]} USDT</b> to the
          following address:
        </p>
        <img src={qrcode} width={180} />
        <br />
        <div>
          <InputGroup>
            <Form.Control value={address} disabled style={{textAlign:'center'}}/>
            <InputGroup.Append>
              <Button variant="secondary" onClick={clickCopy}>
                Copy
              </Button>
            </InputGroup.Append>
          </InputGroup>
          <Form.Group>
            <Form.Label>Transaction ID</Form.Label>
            <Form.Control type="text" {...bindTransactionId} />
          </Form.Group>
          <Form.Group>
          <Form.Group>
            <Form.Label>Reply to</Form.Label>
            <Form.Control type="email" {...bindReplyTo} />
          </Form.Group>
            <Form.Label>Note to Admin</Form.Label>
            <Form.Control as="textarea" rows={3} size="lg" {...bindAdminNote} />
          </Form.Group>
          <br/>
          <p> <b>Please ensure that you bear all transfer charges. <br />Network: {network=='ERC'?'ERC20 or BEP20':'TRON TRC20'}</b></p>
        </div>
        <br />
        {showconfirmPayment && (
         <>
          <Form.Check
              label={
              <p>
                  Confirm you made payment
              </p>
              }
              className="terms"
              value={true}
              onChange={(e) => setConfirmPayment(e.target.checked)}
          /><br />
          <Button variant="success" onClick={clickPaymentDone} className={!confirmPayment?'disabled':''}>
            Payment Done
          </Button>
          </>
        )}
        {showContctFormButton && (
        <>
        <p>
          Email <a href="mailto:admin@stockhero.ai">admin@stockhero.ai</a>{" "}
          with the transaction ID and the user's account email to apply the
          payment to.
        </p>
        
        <Button variant="success" onClick={clickSendEmail}>
          Send Email
        </Button></> )}
       
        <br /> <br />
      </Modal.Body>
    </Modal>
    </Loading>
  );
};

export default connect(null, { cryptoSubscription })(CryptoPayment);
