import React from "react";
import MenuItems from "./MenuItems";

const SideMenu = (props)=>{
    const {url} = props;
    return (
        <div id="sidebarMenu">
            <div className="sidebar-logo"><img src="img/logo-stockhero.png"/></div>
            <ul className="sidebar-nav">
                <MenuItems side={true} url={url}/>
            </ul>
      </div>
    )
}
export default SideMenu;