import axios from "axios";
import { useDispatch } from "react-redux";

import { klineUrl } from "../../../config";


export default {
  getBars: async function (pairId, resolution, periodParams, limit) {
    const { from, to, firstDataRequest } = periodParams;
    var frequency = "1D";
    switch (resolution) {
      case "1":
        frequency = "1m";
        break;
      case "5":
        frequency = "5m";
        break;
      case "15":
        frequency = "15m";
        break;
      case "60":
        frequency = "1h";
        break;
      case "240":
        frequency = "4h";
        break;
      case "1D":
        frequency = "1d";
        break;
      default:
        break;
    }
    const currentMs = Date.now();
    const fromMs = currentMs - 60 * 24 * 3600000;

    const params = {
      pair_id: pairId,
      frequency,
      start_ms: from,
      end_ms: firstDataRequest ? Date.now() : to,
    };
    const res = await axios.get(klineUrl, { params: params });
    const klines = res.data.klines.sort(
      (k1, k2) => k1.kline_start_time - k2.kline_start_time
    );
    const bars = klines.map((item) => ({
      time: item.kline_start_time,
      open: item.open_price,
      close: item.close_price,
      low: item.low_price,
      high: item.high_price,
    }));

    return bars;
  },
};
