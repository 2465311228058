export const ApiType = {
  latestPrice: 0,
  coinInfo: 1,
  priceChanged: 2,
  convert: 3,
  fcas: 4,
  tvlStaked: 5,
  totalValidators: 6,
  purposeBtcFlow: 7,
  grayscaleBtcFlow: 8,
  pricePerformance: 9,
  exchangeInfo: 10,
  grayscaleHolding: 11,
  grayscalePremium: 12,
  grayscaleAum: 13,
  grayscalePrice: 14,
  purposeBtcQty: 15,
  btcFundQty: 16,
  btcFundFlow: 17,
  btcFundPremiumToNav: 18,
  btcFundAum: 19,
  btcFundPrice: 20,
  miningDifficulty: 21,
  miningHashrate: 22,
  uniqueSender: 23,
  uniqueReceiver: 24,
  activeSenderReceiver: 25,
  uniqueTotal: 26,
};

export const ApiTier = {
  free: 0,
  premium: 1,
  pro: 2,
};

export const ApiParam = {
  symbol: 0,
  exchange: 1,
  currency: 2,
  amount: 3,
};
