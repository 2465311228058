import { t } from "i18next";
import React, { useEffect, useState } from "react";
import useInput from "../../../hooks/useIndicatorInput";
import IsRequiredModule from "./IsRequiredModule";
import { restrictAlphabets } from "../../../../utils/Utils";
import CustomRadio from "../modules/CustomRadio";
import TutorialPopup from "../../../help/TutorialPopup";

const RsiExitSetting = (props) => {
  const { indicator, updateIndicator, isLong,t } = props;
  const [exitLong, bindExitLong, setExitLong] = useInput(indicator.value3);
  const [exitShort, bindExitShort, setExitShort] = useInput(indicator.value2);
  const [crossDirection, setCrossDirection] = useState(indicator.value10==0?0:1);
  const [periodLong, bindPeriodLong, setPeriodLong] = useInput(
    indicator.period_num,
    0
  );
  useEffect(() => {
    updateIndicator({
      ...indicator,
      value3: exitLong,
      value2: exitShort,
      period_num: periodLong,
      value10: crossDirection,
    });
  }, [exitLong, exitShort,periodLong,crossDirection]);

  useEffect(() => {
    setExitLong(indicator.value3);
    setExitShort(indicator.value2);
    setPeriodLong(indicator.period_num);
  }, [indicator]);
 

  return (
    <>
    <div>
      {isLong ? (
        <>
          <h6>{t("long-strategy")}</h6>
          <div className="d-flex justify-content-between indicator-setting-form align-items-center ">
          <div><label className="indicator-setting-label">{t("sell-rsi-lev")}</label></div>
          <div> <input type="number" {...bindExitLong} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
        </>
      ) : (
        <>
          <h6>{t("short-strategy")}</h6>
         
          <div className="d-flex justify-content-between indicator-setting-form align-items-center ">
          <div><label className="indicator-setting-label">{t("buy-rsi-lev")}</label></div>
          <div> <input type="number" {...bindExitShort} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
        </>
      )}
    </div>
    
      <div className="d-flex justify-content-between indicator-setting-form align-items-center mb-2">
          <div><label className="indicator-setting-label">{t("period")}</label></div>
          <div> <input type="number" {...bindPeriodLong} className="input-bg-color form-control indicator-setting-input"  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}/></div>
        
        </div>
      <IsRequiredModule indicator={indicator} updateIndicator={updateIndicator} t={t}></IsRequiredModule>
      <br></br>
      <div className="d-flex jcb w-70">
        <div className="d-flex">
          <CustomRadio
            type="radio"
            name="cross_strategy"
            label={t("cross-up")}
            checked={crossDirection==1} 
            callback={() => setCrossDirection(1)}
          ></CustomRadio>
          <TutorialPopup content={t("cross-up-info")+' This is the default'} />
          </div>
        <div className="d-flex">
          <CustomRadio
            type="radio"
            name="cross_strategy"
            label={t("cross-down")}
            checked={crossDirection==0} 
            callback={() => setCrossDirection(0)}
          ></CustomRadio>
          <TutorialPopup content={t("cross-down-info")} />
          </div>
      
          
      </div>
    </>
    
    
  );
};

export default RsiExitSetting;
