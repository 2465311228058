import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SUBSCRIBE_TEMPLATE } from "../../../actions/types";
import ContactForm from "../../help/ContactForm";
import BotRentCryptoPayment from "../subscription/BotRentCryptoPayment";
import { RentPlans, RentSubscriptions, SUB_TIER } from "../../../Const";
import RentCheckboxCard from "./RentCheckboxCard";
import { marketplaceTerms } from "../../../actions/const";
import { Trans } from "react-i18next";
import { checkSub, isPremium } from "../../../utils/SubUtils";
import useInput from "../../hooks/useInput";

const TemplateRentDetails = (props)=>{
    const {template,monthly,t}=props
    const [loading, setLoading] = useState(false);
    const [showCryptoPayment, setShowCryptoPayment] = useState(false);
    const [network, setNetwork] = useState('ERC');
    const [showContactForm, setShowContactForm] = useState(false);
    const [paymentType, bindSetPaymentType] = useInput("yearly");
    //const [performanceDuration, bindPerformanceDuration] = useInput(1);
    const [amount, setAmount] = useState(0);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const subscription =
    useSelector((state) => state.auth.user.user_subscription) || {};
    const { subPremium, subTier } = checkSub(subscription);
    const isPaid = isPremium(subscription);
    const history = useHistory();
    const dispatch = useDispatch();
    const clickUpgrade = () => {
        // dispatch({
        //     type: SUBSCRIBE_TEMPLATE,
        //     payload: template,
        //   });
        // history.push(
        //   "/bot-subscription?plan="+paymentType 
        // );
        if(isPaid && subTier != SUB_TIER.lite){
            dispatch({
                type: SUBSCRIBE_TEMPLATE,
                payload: template,
              });
            history.push(
              "/bot-subscription?plan="+paymentType 
            );
        }
        else{
            history.push(
                "/setting?loc=2"
              );
        }
    };
    
    useEffect(()=>{
        setAmount(template.yearly_fee)
        //bindSetPaymentType('yearly')
    },[template])
    // const showCryptoPaymentModal = (network,qr,address)=>{
        
    //     if (!agreeTerms || parseInt(amount) <= 0){
    //         return false
    //     }
    //     setNetwork(network)
    //     setShowCryptoPayment(true)
    //   }
    const clickPaymentType = (amount,type)=>{
        //bindSetPaymentType(type)
        setAmount(amount)
      }
    const rentPlans = (
        <>
         {Object.keys(RentSubscriptions).map((planName,key)=>(
            <RentCheckboxCard planName={planName} plan={RentSubscriptions[planName]} template={template} clickPaymentType={clickPaymentType} paymentType={paymentType} key={key} t={t}></RentCheckboxCard>
         )  
        )}
        </>
       
       
    );
    const rentOptions = (
        <>
         {Object.keys(RentSubscriptions).map((planName,key)=>(
            <option value={planName}  key={key}>US ${template[RentSubscriptions[planName]]} {t(RentPlans[planName])}</option>
         )  
        )}
        </>
       
       
    );
    return (
        <>
            <div className="round-bg mt-3">
            <Row className="center-align">
                <Col className="">
                    <div>
                    <div className="card-white mpd-card">
                        <div className="cw-body">
                            <div className="d-flex justify-content-between w-100 rent-this-bot-div">
                                <div className="mt-4">{t("rent-this-bot")}</div>
                                <div> <select className='form-control mt-3 mb-3 select-normal' {...bindSetPaymentType}>
                                            {rentOptions}
                                        </select></div>
                            </div>
                            <div className="flex-vertical-center agree-rent-terms ml-3">
                                <input type="checkbox" id="myCheckbox" className="custom-checkbox"  
                                    value={true}
                                    onChange={(e) => setAgreeTerms(e.target.checked)}>
                                    </input>
                                    <label htmlFor="myCheckbox" className="checkbox-label">{
                                        <p>
                                        <Trans i18nKey={"rent-descl"}>
                                        <a
                                            href={marketplaceTerms}
                                            target="_blank"
                                            className="link"
                                        >
                                        </a>
                                        </Trans>
                                        
                                        
                                        </p>
                                    }</label>
                           
                            </div>
                        </div>
                        
                    </div>
                    <Button
                    style={{ marginTop: 10, width: "100%" }}
                    className="w-100 blue-btn"
                    onClick={() => clickUpgrade()}
                    disabled={!agreeTerms}>
                    {t("rent-this-bot")}
                </Button>
                </div>
               
                </Col>
                
            </Row>
            
            
            
            <ContactForm
                show={showContactForm}
                onHide={() => setShowContactForm(false)}
            />
            </div>
        </>
    );
}
export default connect(null,{})(TemplateRentDetails)