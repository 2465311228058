import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import NavPanel from "./NavPanel";
import AccountPanel from "./AccountPanel";
import AddApiPanel from "./AddApiPanel";
import GeneralPanel from "./GeneralPanel";
import SubscriptionPanel from "./SubscriptionPanel";
import NotificationPanel from "./NotificationsPanel";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ContentContainer from "../common/ContentContainer";
import "./Setting.scss";
import PaperWalletPanel from "./PaperWalletPanel";
import SecurityPanel from "./SecurityPanel";
import { useTranslation } from "react-i18next";
import FormulaPanel from "./FormulaPanel";

const modules = [
  GeneralPanel,
  AccountPanel,
  SubscriptionPanel,
  AddApiPanel,
  FormulaPanel,
  PaperWalletPanel,
  SecurityPanel,
  NotificationPanel
];

const Setting = (props) => {
  const { search } = useLocation();
  const {t} = useTranslation()
  const query = queryString.parse(search);
  const [selectedCom, setSelectedCom] = useState(Number(query.loc) || 0);

  const SelectedCom =
    selectedCom >= modules.length ? modules[0] : modules[selectedCom];

  return (
    <ContentContainer url="setting" title={t("settings")} showChat={true}>
      <div className="dashboard-content">
        <Row noGutters>
          <Col className="setting-nav">
            <NavPanel selectedCom={selectedCom} onSelectCom={setSelectedCom} t={t}/>
          </Col>
          <Col className="mt-1 mt-xl-0 pl-0 pl-xl-1 setting-com">
            <SelectedCom />
          </Col>
        </Row>
      </div>
    </ContentContainer>
  );
};

export default connect(null)(Setting);
