import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./PaymentPanel.scss";
import CardForm from "./CardForm";
import { Form, Button } from "react-bootstrap";
import { PAYPAL_ID, STRIPE_PUBLIC } from "../../config";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { connect } from "react-redux";
import { paypalSubscribe } from "../../actions/setting-action";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../common/Loading";
import CryptoPayment from "./CryptoPayment";
import ContactForm from "../help/ContactForm";
import { getPaypalPlan } from "../../utils/SubUtils";

const stripePromise = loadStripe(STRIPE_PUBLIC);

const PaymentPanel = (props) => {
  const { monthly, onChange, hideSelection, paypalSubscribe, tier,subscriptionType,plan } = props;
  const [cardHolder, setCardHolder] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showCryptoPayment, setShowCryptoPayment] = useState(false);
  const [network, setNetwork] = useState('ERC');
  const [showContactForm, setShowContactForm] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
  }, []);

  const subscribePaypal = (subscriptionId) => {
    setLoading(true);
    paypalSubscribe({
      subscription_id: subscriptionId,
      subscription_type: subscriptionType,
      tier,
    })
      .then((orderId) => {
        setLoading(false);
        toast.success("Successful Subscription!");
        history.push(
          `/subscription/success?plan=${subscriptionType}&tier=${tier}&order=${orderId}`,
          { from: location }
        );
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };
  const showCryptoPaymentModal = (network,qr,address)=>{
    setNetwork(network)
    setShowCryptoPayment(true)
  }
  return (
    <Loading show={loading} onHide={() => setLoading(false)}>
      <Elements stripe={stripePromise}>
        {hideSelection ? (
          <></>
        ) : (
          <div className="mb-5 text-primary font-weight-bold plan-select">
            <a onClick={() => onChange(true)}>
              <div
                className={`p-3 top-corner ${
                  monthly ? "plan-active" : "plan-inactive"
                }`}
              >
                <Form.Check
                  type="radio"
                  className="d-inline mr-3"
                  checked={monthly}
                  readOnly
                ></Form.Check>
                <span>Monthly $9.99 / month</span>
              </div>
            </a>
            <a onClick={() => onChange(false)}>
              <div
                className={`p-3 bottom-corner ${
                  monthly ? "plan-inactive" : "plan-active"
                }`}
              >
                <Form.Check
                  type="radio"
                  className="d-inline mr-3"
                  checked={!monthly}
                  readOnly
                ></Form.Check>
                <span>Yearly $94.99 / year (Save 21%)</span>
              </div>
            </a>
          </div>
        )}
        <div className="mb-4">
          <h5>Credit or debit card</h5>
          <img
            src={require("../../images/visa.svg")}
            height={30}
            width={30}
            className="mr-1"
          />
          <img
            src={require("../../images/mastercard.svg")}
            height={30}
            width={30}
            className="mr-1"
          />
          <img
            src={require("../../images/amex.svg")}
            height={30}
            width={30}
            className="mr-1"
          />
          <img
            src={require("../../images/jcb.svg")}
            height={30}
            width={30}
            className="mr-1"
          />
        </div>
        <div>
          <Form.Group>
            <label className="font-weight-bold">
              Card Holder Name
            </label>
            <Form.Control
              className="input"
              value={cardHolder}
              onChange={(e) => setCardHolder(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </div>
        <CardForm subscriptionType={subscriptionType} cardHolder={cardHolder} tier={tier} />
        <p className="text-secondary text-center my-3">OR</p>
        <div className="text-center">
          <PayPalButtons
            style={{
              layout: "horizontal",
              tagline: false,
              height: 35,
              color: "gold",
              label: "subscribe",
              size: "responsive",
            }}
            createSubscription={(data, actions) =>
              actions.subscription.create({
                plan_id: getPaypalPlan(plan, tier),
              })
            }
            onApprove={(data, actions) => {
              
              if (data) {
                const { subscriptionID } = data;
                subscribePaypal(subscriptionID);
              }
            }}
            onInit={(data, actions) => {
              
              setLoading(false);
            }}
          />
        </div>

        {/* {!monthly && <>
        <Button
          style={{ marginTop: 10, width: "100%" }}
          className="py-2"
          variant="secondary"
          onClick={() => setShowCryptoPayment(true)}
        >
          <img
            src="/images/coins/USDT.png"
            width={18}
            style={{ marginRight: 10 }}
          />
          Pay With USDT (ERC20 Or BEP20)
        </Button>
        <Button
          style={{ marginTop: 10, width: "100%" }}
          className="py-2"
          variant="secondary"
          onClick={() => setShowCryptoPayment(true)}
        >
          <img
            src="/images/coins/USDT.png"
            width={18}
            style={{ marginRight: 10 }}
          />
          Pay With USDT (ERC20 Or BEP20)
        </Button>
        </>
         }
        { monthly && <Button
          style={{ marginTop: 10, width: "100%" }}
          className="py-2"
          variant="secondary"
          onClick={() => setShowCryptoPaymentMonthly(true)}
        >
          <img
            src="/images/coins/USDT.png"
            width={18}
            style={{ marginRight: 10 }}
          />
          Pay With USDT (ERC20 Or BEP20)
        </Button> } */}
          {/* <Button
            style={{ marginTop: 10, width: "100%" }}
            className="py-2"
            variant="secondary"
            onClick={() => showCryptoPaymentModal('ERC')}
          >
            <img
              src="/images/coins/USDT.png"
              width={18}
              style={{ marginRight: 10 }}
            />
            Pay With USDT (ERC20 Or BEP20)
          </Button>
          <Button
            style={{ marginTop: 10, width: "100%" }}
            className="py-2"
            variant="secondary"
            onClick={() => showCryptoPaymentModal('TRC')}
          >
            <img
              src="/images/coins/USDT.png"
              width={18}
              style={{ marginRight: 10 }}
            />
            Pay With USDT (TRC20)
          </Button> */}
      </Elements>
      <CryptoPayment
        show={showCryptoPayment}
        monthly={monthly}
        subscriptionType={subscriptionType}
        network={network}
        onHide={() => setShowCryptoPayment(false)}
        sendEmail={() => setShowContactForm(true)}
        tier={tier}
      />
      <ContactForm
        show={showContactForm}
        onHide={() => setShowContactForm(false)}
      />
    </Loading>
  );
};

export default connect(null, { paypalSubscribe })(PaymentPanel);
