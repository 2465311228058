
import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col, Button, Accordion, Form, Carousel } from "react-bootstrap";
import './IntroPopup.scss';
import { GET_INTRO_TOUR_SETTING, NEW_USER_INTRO_POPUP, SET_TUTORIAL_POPUP, TAKE_TOUR_START } from "../../actions/types";
import { Link, useHistory } from "react-router-dom";
import { hideIntroPopup } from "../../actions/setting-action";
import { Trans, useTranslation } from "react-i18next";





const NewUserTour = (props) => {
  const {
    onHide,setShowTour,hideIntroPopup
  } = props;
  const {t} = useTranslation()
  const history = useHistory();
  
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const nextSide = ()=>{
    setIndex(index+1)
  }
  const back = ()=>{
    setIndex(index-1)
  }
  const takeATour = ()=>{
    dispatch({
      type:TAKE_TOUR_START,
      payload:true
    })
    HideIntroPopup()
    history.push('/dashboard')
  }
  const dontShowThisAgain = ()=>{
    hideIntroPopup()
    HideIntroPopup()
  }
  const HideIntroPopup = ()=>{
    dispatch({
      type:NEW_USER_INTRO_POPUP,
      payload:0
    })
  }
  //const nextBtn = 
   
  
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  return (
    <>
    
      <Modal show={true} onHide={()=>HideIntroPopup()} size="lg" dialogClassName="modal-90w">
        <Modal.Header  className="no-border" closeButton></Modal.Header>
        <Modal.Body style={{padding:"10px 50px",height: "600px"}}>
        
          
        <Carousel variant="dark" className="carousel-div-tour pos-relative" 
        
        fade={true} 
        indicators={true}
        controls = {false}
        activeIndex={index}
        interval={null}
        onSelect={handleSelect}
        >
            <Carousel.Item>
            <div className="text-center mt-3 mb-3"><h4>{t("intro-h-1")}</h4></div> 
                <div
                className="d-block text-center carousel-div-img"
                >
                <img
                
                src="/images/intro/slide1.png"
                alt="First slide"
                style={{width:"100%",marginTop:"10%",marginBottom:"10%"}}
                />
                
                <Carousel.Caption bsPrefix="marketplace-captio">
                <p className="text-title intropopup-title">{t("intro-1")}</p>
                </Carousel.Caption>
                
                </div>
                {index<=2 && <Button className="button-next next-btn" onClick={()=>nextSide()}>{t("next")}</Button> }
            </Carousel.Item>
            <Carousel.Item>
            <div className="text-center mt-3 mb-3"><h4>{t("intro-h-2")}</h4></div> 
                <div
                className="d-block text-center carousel-div-img"
                >
               <img
                
                src="/images/intro/slide2.png"
                alt="First slide"
                style={{width:"94%",marginBottom:"2%"}}
                />
                
                <Carousel.Caption bsPrefix="marketplace-captio">
                <h3>1. {t("intro-risk-free")}</h3>
                <p className="text-title intropopup-title">{t("intro-2")}</p>
                </Carousel.Caption>
                <div className="start-tour-div d-flex justify-content-around">
                          <Button className="start-tour-div-btn" onClick={()=>back()}>{t("Back")}</Button>
                          <Button className="start-tour-div-btn" onClick={()=>nextSide()} variant="success">{t("next")}</Button>
                      </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div
                className="d-block text-center carousel-div-img"
                >
                <img
                
                src="/images/intro/slide3.png"
                alt="First slide" style={{width:"66%",marginBottom:"5%"}}
                />
                <Carousel.Caption bsPrefix="marketplace-captio">
                <h3>2. {t("trade-now")}</h3>
                <p className="text-title intropopup-title"> <Trans i18nKey={"intro-3"}><Link to="setting?loc=3"></Link></Trans></p>
                </Carousel.Caption>
                <div className="start-tour-div d-flex justify-content-around">
                          <Button className="start-tour-div-btn" onClick={()=>back()}>{t("Back")}</Button>
                          <Button className="start-tour-div-btn" onClick={()=>nextSide()} variant="success">{t("next")}</Button>
                      </div>
                </div>
                
            </Carousel.Item>
            <Carousel.Item>
                <div
                      className="d-block text-center carousel-div-img"
                      >
                      <img
                      
                      src="/images/intro/slide4.png"
                      alt="First slide" style={{width:"60%",marginBottom:"5%"}}
                      />
                      <Carousel.Caption bsPrefix="marketplace-captio">
                      <h3>3. Bots Marketplace</h3>
                      <p className="text-title intropopup-title"> {t("marketplace-p1")}</p>
                      </Carousel.Caption>
                      <div className="start-tour-div d-flex justify-content-around">
                      <Button className="start-tour-div-btn" onClick={()=>back()}>{t("Back")}</Button>
                          <Button className="start-tour-div-btn" onClick={()=>takeATour()} variant="success">{t("take-tour")}</Button>
                      </div>
                </div>
            </Carousel.Item>
            
            </Carousel>
          
        </Modal.Body>
        
        <Modal.Footer className="text-center justify-center no-border"><a href="#" onClick={dontShowThisAgain}>{t("dont-show-again")}</a></Modal.Footer>
      </Modal>
      </>
  );
};

export default connect(null, {hideIntroPopup
})(NewUserTour);
