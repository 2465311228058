import network from "./network";
import axios from "axios";
import { aiQaSaveUrl, aiQuestionDeleteUrl, aiQuestionListUrl, aiQuestionUrl, aiTrendingUrl } from "./const";
import { AI_QUESTIONS_LIST, AI_TRENDING_LIST } from "./types";

export const submitQuery = (question) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        network
        .post(aiQuestionUrl, {question:question},dispatch,
            getState)
        .then((res) => {
            const { code} = res.data;
            if (code == 200) {
                resolve(res.data.bard_qa)
            } else {
                reject()
            }
        })
        .catch((err) => {
            reject(err)
        });
    });
};
export const saveQuestion = (question,answer) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        network
        .post(aiQaSaveUrl, {question:question,answer:answer},dispatch,
            getState)
        .then((res) => {
            const { code} = res.data;
            if (code == 200) {
                resolve(res.data)
            } else {
                reject()
            }
        })
        .catch((err) => {
            reject(err)
        });
    });
};
export const fetchQuestions = (exchangeId,pageSize,page) => (dispatch, getState) => {
        network
        .get(aiQuestionListUrl, {"page_size":pageSize,page},dispatch,
            getState)
        .then((res) => {
            const { code,bard_qas} = res.data
            if (code == 200) {
                dispatch({
                    type:AI_QUESTIONS_LIST,
                    payload:{
                        questions:bard_qas.data,
                        startItem:pageSize?bard_qas.from:1,
                        total:bard_qas.total
                    }
                })
            } 
        })
        .catch((err) => {
            //reject(err)
        });
};
export const getTrending = () => (dispatch, getState) => {
        network
        .get(aiTrendingUrl, {},dispatch,
            getState)
        .then((res) => {
            const { code,trending} = res.data
            if (code == 200) {
                dispatch({
                    type:AI_TRENDING_LIST,
                    payload:trending
                })
            } 
        })
        .catch((err) => {
            //reject(err)
        });
};

export const deleteQuery = (id) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        network
        .delete(aiQuestionDeleteUrl+id, {},dispatch,
            getState)
        .then((res) => {
            const { code} = res.data;
            if (code == 200) {
                resolve(res.data)
            } else {
                reject()
            }
        })
        .catch((err) => {
            reject(err)
        });
    });
};
