import {
  AI_QUESTIONS_LIST,
  AI_TRENDING_LIST,
  GET_DATA_CURRENCIES,
  GET_DATA_EXCHANGE,
  GET_DATA_SYMBOL,
} from '../actions/types';

const initState = {
  exchanges: [],
  currencies: [],
  symbols: [],
  aiQuestions:[]
};

const dataReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case GET_DATA_EXCHANGE:
      return {
        ...state,
        exchanges: payload,
      };
    case GET_DATA_CURRENCIES:
      return {
        ...state,
        currencies: payload,
      };
    case GET_DATA_SYMBOL:
      return {
        ...state,
        symbols: payload,
      };
    case AI_QUESTIONS_LIST:
      return {
        ...state,
        aiQuestions: payload.questions,
        aiQuestionsStart:payload.startItem,
        aiQuestionsTotal:payload.total,
      };
    case AI_TRENDING_LIST:
      return {
        ...state,
        trending: payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
