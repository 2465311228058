import React from "react";
import { connect, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { roundNumber } from "../../utils/Utils";
import { AccountType, AccountName } from "../../Const";
import "./MarketPanel.scss";

const MarketPanel = (props) => {
  const { account, accountChanged } = props;
  const selectedPair = useSelector((state) => state.terminal.selectedPair);
  const lastTickers = useSelector((state) => state.terminal.lastTickers) || [];
  const pair = selectedPair;
  const lastTicker = lastTickers.find((el) => el.s === selectedPair.s) || {
    s: selectedPair.s,
    c: 0,
  };
  const symbol = pair && pair.from + "/" + pair.to;

  return (
    <Row noGutters className="align-items-center">
      <Col style={{ maxWidth: "13%" }}>
        <span className="mk-pair">{symbol}</span>
        <br />
        <span
          className={
            lastTicker.c < pair.c
              ? "mk-up"
              : lastTicker.c > pair.c
              ? "mk-down"
              : "mk-value"
          }
        >
          {pair && roundNumber(pair.c)}
        </span>
      </Col>

      <Col style={{ maxWidth: "17%" }}>
        <span className="mk-title">24H Change</span>
        <br />
        <span
          className={pair.p > 0 ? "mk-up" : pair.p < 0 ? "mk-down" : "mk-value"}
        >
          {roundNumber(pair.p)} ({roundNumber(pair.P, 2)}%)
        </span>
      </Col>
      <Col style={{ maxWidth: "10%" }}>
        <span className="mk-title">24H High</span>
        <br />
        <span className="mk-value">{roundNumber(pair.h)}</span>
      </Col>
      <Col style={{ maxWidth: "10%" }}>
        <span className="mk-title">24H Low</span>
        <br />
        <span className="mk-value">{roundNumber(pair.l)}</span>
      </Col>
      <Col style={{ maxWidth: "20%" }}>
        <span className="mk-title">24H Volume</span>
        <br />
        <span className="mk-value">{roundNumber(pair.v)}</span>
      </Col>
      {/* <Col style={{ maxWidth: "30%" }}>
        <SegmentedControlWithoutStyles
          name="account"
          options={[
            {
              label: AccountName.spot,
              value: AccountType.spot,
              default: account === AccountType.spot,
            },
            {
              label: AccountName.cross,
              value: AccountType.cross,
              default: account === AccountType.cross,
            },
            {
              label: AccountName.isolated,
              value: AccountType.isolated,
              default: account === AccountType.isolated,
            },
          ]}
          setValue={accountChanged}
          style={{
            color: "#9381FF",
            fontSize: 12,
            height: 34,
            fontWeight: "bold",
          }}
        />
      </Col> */}
    </Row>
  );
};

export default connect(null)(MarketPanel);
