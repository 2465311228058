import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";

import { useTranslation } from "react-i18next"
import SpotPortfolio from "./SpotPortfolio";
import { getPortfolio } from "../../actions/portfolio-action";
import { getDashboardAssets } from "../../actions/dashboard-action";

const Portfolio = (props) => {
  const {getDashboardAssets} = props
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  useEffect(() => {
    getDashboardAssets(selectedExchangeId);
  }, [selectedExchangeId]);
  return (
    <SpotPortfolio></SpotPortfolio>
  );
};

export default connect(null, { getDashboardAssets })(Portfolio);
