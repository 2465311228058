import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";

const BooleanView = (props) => {
  const isFirst =
  (props.selected && props.selected.toLowerCase()) === (props.firstButton && props.firstButton.toLowerCase());
  const firstVariant = isFirst ? "success" : "";

  const secondVariant = isFirst ? "" : "danger";

  return (
    <>
      <Row>
        <Col xl={4} className="cb-form-label">
          <Form.Label>
            {props.title} {props.tutorial}
          </Form.Label>
        </Col>
        <Col xl={8}>
          <div className="d-flex justify-content-between bw-option-div">
            <span className={firstVariant} onClick={() => props.onSelect(props.firstButton)}>{props.firstButton.toUpperCase()}</span>
            <span className={secondVariant} onClick={() => props.onSelect(props.secondButton)}>{props.secondButton.toUpperCase()}</span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default BooleanView;
