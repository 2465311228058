import React, { useState } from "react";
import { Table, Badge } from "react-bootstrap";
import { formatDate, milisecOf } from "../../../utils/Utils";

import { DealStatus } from "../../../Const";

const BotDealTab = (props) => {
  const { deals,startItem} = props;
  //const itemsPerPage = 10;
  //const [startItem, setStartItem] = useState(0);
  deals.forEach((item) => {
    item.closed_orders &&
      item.closed_orders.length &&
      item.closed_orders.sort((order1, order2) => {
        return milisecOf(order1.date) - milisecOf(order2.date);
      });
  });
  deals.sort((item1, item2) => {
    if (
      item1.closed_orders &&
      item1.closed_orders.length &&
      item2.closed_orders &&
      item2.closed_orders.length
    )
      return (
        milisecOf(item2.closed_orders[0].date) -
        milisecOf(item1.closed_orders[0].date)
      );
    return 0;
  });
  const dealItems = deals
    //.slice(startItem, startItem + itemsPerPage)
    .filter((item) => item.status == "completed")
    .map((item, index) => {
      const status = item.status_key.toLowerCase();
      const statusVariant = [
        //DealStatus.stopLoss,
        DealStatus.liquidating,
        DealStatus.liquidated,
        //DealStatus.stoppingLoss,
      ].includes(status)
        ? "danger"
        : [DealStatus.takeProfit, DealStatus.takingProfit].includes(status)
        ? "success"
        : status == DealStatus.waiting
        ? "secondary"
        : item.net_profit >= 0
        ? "success"
        : "info";
      const numberOrder = item.closed_orders.length;
      return (
        <tr key={index}>
          <td>{startItem + index }</td>
          <td>
            {item.executed_vol.toFixed(2)} {item.base_name}
          </td>
          <td>
            {item.net_profit.toFixed(2)} {item.base_name} (
            {item.profit_value.toFixed(2)}%)
          </td>
          <td>
            <span className="w-75" variant={statusVariant}>
              {item.status_name}
            </span>
          </td>
          <td>
            
            {item.closed_orders[numberOrder - 1] && formatDate(
              item.closed_orders[numberOrder - 1].date,
              "YYYY-MM-DD HH:mm:ss",
              "DD MMM YYYY HH:mm"
            )}
          </td>
        </tr>
      );
    });
  return (
    <div className="mt-4 bot-tab">
    <div className="" >
          {/* <h2>Deals in Progress</h2> */}
          <br/>
          <div className="deals-table">
              <table className="">
                  <tbody>
                      <tr valign="top">
                      <th>#</th>
                      <th>Capital</th>
                      <th>Return</th>
                      <th>Exit Condition</th>
                      <th>Completed</th>
                      </tr>
                      {dealItems}
                  </tbody>
              </table>
          </div>
    </div>
    
  </div>
  );
};

export default BotDealTab;
