import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import Tab1 from "./quickstart/Tab1";
import Tab2 from "./quickstart/Tab2";
import Tab3 from "./quickstart/Tab3";
import Tab4 from "./quickstart/Tab4";
import { resetBotConfig, setupSimpleBot } from "../../actions/create-bot-action";
import { BotType } from "../../Const";
import { NEW_USER_INTRO_POPUP } from "../../actions/types";
const WelcomeWizard = (props) => {
  const {setupSimpleBot} = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
      resetBotConfig();
      setupSimpleBot()
  }, []);
 const [activeTab,setActiveTab] = useState(0);
 const goNext = ()=>{
    setActiveTab(activeTab+1)
 }
 const goPrev = ()=>{
    setActiveTab(activeTab-1)
 }
 const exitWizard = ()=>{
  dispatch({
    type:NEW_USER_INTRO_POPUP,
    payload:0
  })
}

  const tabHeaders = Array.from({length:4,start:1},(_,index)=>{
    return(
        <div key={index}>
            <div className={`step ${activeTab>=index?'active':'' }`} > 
            <div className={`step-icon quickstart${index+1}`}></div>
            </div>
            {index !=3 && 
                <div className="step-line"></div> 
            }
        </div>
    )
       
  })
  const rows = [
    <Tab1 next={goNext} prev={goPrev}></Tab1>,
    <Tab2 next={goNext} prev={goPrev}></Tab2>,
    <Tab3 next={goNext} prev={goPrev}></Tab3>,
    <Tab4 next={goNext} prev={goPrev}></Tab4>
  ]
    
  return (
    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 v-pad">
    <h3>Welcome to StockHero!</h3>
    <br/>
    <div className="dashboard-col">
      <div className="step-container d-flex justify-content-center">
        {tabHeaders}
      </div>
      <div>
        {rows[activeTab]}
      </div>
      <br/>
      <br/>
      <a onClick={()=>exitWizard()}><div className="icon-cross pointer"></div></a>
    </div>
  </div>   
     
  );
};

export default connect(null,{setupSimpleBot})(WelcomeWizard);
