import React from "react";
import { connect, useSelector } from "react-redux";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { formatDateTime, normalizeTime } from "../../../../utils/Utils";
import "./Summary.scss";

const Summary = (props) => {
  const { baseCoin,t } = props;
  const backtest = useSelector((state) => state.bots.backTest);
  const theme = useSelector((state) => state.settings.selected_theme);
  const equities =
  (backtest && backtest.positions && Array.isArray(backtest.positions) && [
    //parseFloat(backtest.initialFund).toFixed(2),
    ...backtest.positions.map((position) => position.accumulated_asset && parseFloat(position.accumulated_asset).toFixed(2)),
  ]) ||
  [];
  const labelTime =
  (backtest && backtest.positions && Array.isArray(backtest.positions) && [
    ...backtest.positions.map((position) => position.deal_trade_histories && position.deal_trade_histories[0].created_at),
  ]) ||
  [];
  const totalTrade = (backtest && backtest.total_position) || 0;
  const winTrade = (backtest && backtest.profitable_position) || 0;
  const wlRatio = totalTrade > 0 ? (winTrade * 100) / totalTrade : 0;
  const performance = (backtest && parseFloat(backtest.total_performance)) || 0;
  const returnValue = (backtest && parseFloat(backtest.return_value_avg)) || 0;
  const avgPerformance = totalTrade > 0 ? performance / totalTrade : 0;
  const avgReturn = totalTrade > 0 ? returnValue / totalTrade : 0;
  const decimalNumber = baseCoin.toLowerCase().includes("usd") ? 2 : 4;
  const tradeInterval = normalizeTime(
    (backtest && backtest.trade_interval) || 0
  );
  const dealDuration = normalizeTime(
    (backtest && backtest.avg_deal_duration) || 0
  );
  const maxDrawdown = (backtest && backtest.max_drawdown) || 0;
  const sharpeRatio = (backtest && backtest.sharpe_ratio) || 0;
  return (
    <>
      <Row>
        <Col>
        <div className="bot-tab">
          <div className="" >
                <div className="deals-table">
                    <table className="">
                        <tbody>
                            <tr valign="top">
                            <th>{t("return")}</th>
                            <th>{t("w-l-ratio")}</th>
                            <th>{t("deals")}</th>
                            <th>{t("a-deal-prof")}</th>
                            <th>{t("a-d-inter")}</th>
                            <th>{t("a-deal-dur")}</th>
                            <th>{t("Max Drawdown")}</th>
                            <th>{t("Sharpe Ratio")}</th>
                            </tr>
                            <tr className="">
                            <td>
                              {parseFloat(performance).toFixed(decimalNumber)} {baseCoin} (
                              {returnValue.toFixed(2)}
                              %)
                            </td>
                            <td>{wlRatio.toFixed(2)}%</td>
                            <td>{totalTrade}</td>
                            <td>
                              {avgPerformance.toFixed(decimalNumber)} {baseCoin} (
                              {avgReturn.toFixed(2)}%)
                            </td>
                            <td>
                              {tradeInterval.time < 1
                                ? "-"
                                : tradeInterval.time.toFixed(1) + " " + tradeInterval.unit}
                            </td>
                            <td>
                              {dealDuration.time < 1
                                ? "-"
                                : dealDuration.time.toFixed(1) + " " + dealDuration.unit}
                            </td>
                            <td>
                              {maxDrawdown}
                            </td>
                            <td>
                              {sharpeRatio}
                            </td>
                          </tr>
                        </tbody>
                    </table>
              </div>
          </div>
        </div>
        </Col>
        
      </Row>
      <Row className="summary-chart">
        <Line data={data(equities,labelTime)} options={options} height={60} />
      </Row>
      </>
  );
};

export default connect(null)(Summary);

const data = (returns,labelTime) => ({
  labels: labelTime.map((item) => formatDateTime(item)),
  datasets: [
    {
      data: returns,
      //type: "line",
      fill: false,
      backgroundColor: "rgba(75,192,192,0.4)",
      borderColor: "rgba(75,192,192,1)",
      label: "Performance",
    },
  ],
});

const options = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [{
        ticks: {
            display: false //this will remove only the label
        },
        gridLines: {
          color: "#999"
        }
    }],
    yAxes: [{
        ticks: {
          fontColor: "#999",
        },
        gridLines: {
          color: "#999"
        }
    }]
}
 
};
