export const ANALYTIC_ID = "UA-185659622-1";

// --- Stage --- //
export const STRIPE_ID = {
  premium_monthly: "price_1LUoLvAlY9R4Wa1pW57ogfAf",
  premium_quarterly: "price_1LUoLvAlY9R4Wa1pvUCJUKq8",
  premium_yearly: "price_1LUoLvAlY9R4Wa1p63xnrDzq",
  pro_monthly: "price_1LUoNVAlY9R4Wa1pasDLUkbq",
  pro_quarterly: "price_1LUoNVAlY9R4Wa1prrRYzPlY",
  pro_yearly: "price_1LUoNVAlY9R4Wa1poNKXsdh0",
  lite_monthly: "price_1LUoKeAlY9R4Wa1pKWnFH3Pt",
  lite_quarterly: "price_1LUoKeAlY9R4Wa1pjHhKUvSz",
  lite_yearly: "price_1LUoKeAlY9R4Wa1pzJ3t9LGo",
};
export const STRIPE_PUBLIC =
  "pk_test_51HWbXkAlY9R4Wa1pNNTATqAnlvpeeXyJ9I85E1B0QYUsIUPs84z84uQM9pVzm7hesVq2w6KQTmxu3tIXoEf0adRl0043MvXJnW";
export const PAYPAL_CLIENT_ID =
  "AQ58lfbo34MvYYhVOxBiLjziBQvAnOO5bN4JUdj9nxjiOKGkDn_03xqacAC8DWEj2vxCUh_8RSm6X_Pp";
export const PAYPAL_ID = {
  lite_monthly: "P-72J29231CS4680612ML7BARA",
  lite_quarterly: "P-5M205282KR506954EML7BBEI",
  lite_yearly: "P-4GF757066W302213UMMHP3GI",
  premium_monthly: "P-76B0268383535172GMCXR7HQ",
  premium_quarterly: "P-76B0268383535172GMCXR7HQ",
  premium_yearly: "P-76B0268383535172GMCXR7HQ",
  pro_monthly: "P-74U918610A5719342MCXR7TY",
  pro_quarterly: "P-72J29231CS4680612ML7BARA",
  pro_yearly: "P-52E196148S466304WMCXR74Y",
};
export const host = "https://api.dev.miraeasset.io/v1/mst/aitrade/api";
export const ws = "wss://api-stage.stockhero.ai";
export const klineUrl = "https://api.dev.miraeasset.io/v1/mst/aitrade/api/klines";



//--- Live --- //
// export const STRIPE_ID = {
//   premium_monthly: "price_1LUn1vAlY9R4Wa1pOB04ax2x",
//   premium_quarterly: "price_1LUn1vAlY9R4Wa1pzfQ9EgAl",
//   premium_yearly: "price_1LUn1vAlY9R4Wa1p2H1tOnVA",
//   pro_monthly: "price_1LUn3gAlY9R4Wa1p42P5r2M7",
//   pro_quarterly: "price_1LUn3gAlY9R4Wa1pamadOaYy",
//   pro_yearly: "price_1LUn3gAlY9R4Wa1pwVImAKHt",
//   lite_monthly: "price_1LUmwPAlY9R4Wa1p4TW3XQoP",
//   lite_quarterly: "price_1LUmz5AlY9R4Wa1pCiNabAbb",
//   lite_yearly: "price_1LUmzcAlY9R4Wa1pL76rZFgV",
// };

// export const STRIPE_PUBLIC =
//   "pk_live_51HWbXkAlY9R4Wa1pGcd1HUcYxePvFEvdo7kktBbLHVdzXzwdk6huL41PTb6KhV8ozYtEv4I95lWZ5yJ4U1u4AXDx00yKzkVoI2";
// export const PAYPAL_CLIENT_ID =
//   "Aej4A5zdJZPIlZYmDHFawL9TMZxQcLQ-3kqnNGdX-eZxiUZIrTYcClH9JDbXd5g5tf9ZhqpEaZWi9s_0";

// export const PAYPAL_ID = {
//   lite_monthly: "P-9L118200PP562405KML5YSOA",
//   lite_quarterly: "P-0FW59885KE210763EML5YTLA",
//   lite_yearly: "P-30665191C4165225LML5YTYQ",
//   premium_monthly: "P-09X7057079277692VML5YVRI",
//   premium_quarterly: "P-76M28851UY896945GML5YV3I",
//   premium_yearly: "P-5FN70962JM7091135ML5YWEY",
//   pro_monthly: "P-9GW04185VC529805LML5YWPI",
//   pro_quarterly: "P-0MA56567RG252941NML5YWZA",
//   pro_yearly: "P-7UN45041H28502235ML5YXBY",
// };
// export const host = "https://api.stockhero.ai/api";

// export const ws = "wss://app-stage.stockhero.ai";
// export const klineUrl = "https://api.stockhero.ai/api/klines";



// --- Local --- //
// export const STRIPE_ID = {
//   premium_monthly: "price_1LUoLvAlY9R4Wa1pW57ogfAf",
//   premium_quarterly: "price_1LUoLvAlY9R4Wa1pvUCJUKq8",
//   premium_yearly: "price_1LUoLvAlY9R4Wa1p63xnrDzq",
//   pro_monthly: "price_1LUoNVAlY9R4Wa1pasDLUkbq",
//   pro_quarterly: "price_1LUoNVAlY9R4Wa1prrRYzPlY",
//   pro_yearly: "price_1LUoNVAlY9R4Wa1poNKXsdh0",
//   lite_monthly: "price_1LUoKeAlY9R4Wa1pKWnFH3Pt",
//   lite_quarterly: "price_1LUoKeAlY9R4Wa1pjHhKUvSz",
//   lite_yearly: "price_1LUoKeAlY9R4Wa1pzJ3t9LGo",
// };
// export const STRIPE_PUBLIC =
//   "pk_test_51HWbXkAlY9R4Wa1pNNTATqAnlvpeeXyJ9I85E1B0QYUsIUPs84z84uQM9pVzm7hesVq2w6KQTmxu3tIXoEf0adRl0043MvXJnW";
// export const PAYPAL_CLIENT_ID =
//   "AQ58lfbo34MvYYhVOxBiLjziBQvAnOO5bN4JUdj9nxjiOKGkDn_03xqacAC8DWEj2vxCUh_8RSm6X_Pp";
// export const PAYPAL_ID = {
//   lite_monthly: "P-72J29231CS4680612ML7BARA",
//   lite_quarterly: "P-5M205282KR506954EML7BBEI",
//   lite_yearly: "P-4GF757066W302213UMMHP3GI",
//   premium_monthly: "P-76B0268383535172GMCXR7HQ",
//   premium_quarterly: "P-76B0268383535172GMCXR7HQ",
//   premium_yearly: "P-76B0268383535172GMCXR7HQ",
//   pro_monthly: "P-74U918610A5719342MCXR7TY",
//   pro_quarterly: "P-72J29231CS4680612ML7BARA",
//   pro_yearly: "P-52E196148S466304WMCXR74Y",
// };
// export const host = "https://stockhero-backend.test/api";
// export const ws = "wss://api-stage.stockhero.ai";
// export const klineUrl = "https://api-stage.stockhero.ai/api/klines";
