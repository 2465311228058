import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import ContentContainer from "../common/ContentContainer";
import { useTranslation } from "react-i18next";
import { deleteQuery, fetchQuestions } from "../../actions/ai-action";
import { Col, Form, Row } from "react-bootstrap";
import useLoading from "../hooks/useLoading";
import Loading from "../common/Loading";
import { toast } from "react-toastify";
import BackButton from "../common/BackButton";
import CustomPagination from "../common/CustomPagination";
import { formatDateTime } from "../../utils/Utils";
import AiDisclaimer from "./AiDisclaimer";


const ArtificialIntelligenceSaved = (props) => {
  const {fetchQuestions,deleteQuery} = props
  const { t } = useTranslation()
  const newUserIntroPupup = useSelector((state) => state.settings.newUserIntroPupup);
  const [setLoading, bindLoading] = useLoading(false);
  const questions = useSelector((state)=>state.data.aiQuestions) || []
  const startItem = useSelector((state)=>state.data.aiQuestionsStart)
  const total = useSelector((state)=>state.data.aiQuestionsTotal)
  const itemsPerPage = 5
  const pageNum=1;
  console.log(questions)
  useEffect(()=>{
    fetchQuestions("",itemsPerPage,pageNum)
  },[])
  //
  const toggleQa = (id) => {
    const collapsibleElement = document.getElementById(`graphCollapse${id}`);
    const collapsibleElementArrow = document.getElementById(`arrow${id}`);
    if (collapsibleElement) {
      collapsibleElement.classList.toggle("show");
    }
    if (collapsibleElementArrow) {
      collapsibleElementArrow.classList.toggle("fa-chevron-up");
    }
  };
  const deleteQa = (id)=>{
    deleteQuery(id).then((res)=>{
        toast.success("Success")
        fetchQuestions()
    }).catch((err)=>{
      toast.error("Error")
    })
  }
  const questionsRows = questions && questions.map((question,id)=>{
    return(
      <Row>
      <Col>
      <div className="d-flex jcb align-items-center">
              <div className="qa-container ">
                <div className="ai-query-div pos-relative">
                    <div className="d-flex jcb align-items-center">
                    <div className="w-30">
                      <span>{formatDateTime(question.created_at)}</span>
                    </div>
                    <div className="w-70 text-left">
                      <h6>{question.question}</h6>
                    </div>
                    
                     <div className="chart-arrow-ai">
                        <a
                        
                            role="button"
                            data-toggle="collapse"
                            data-target="#graphCollapse"
                            aria-expanded="false"
                            aria-controls="graphCollapse"
                            onClick={()=>toggleQa(question.id)}
                        ><i className="fas fa-chevron-right " id={`arrow${question.id}`}></i></a>
                      </div>
                    </div>
                    <br></br>
                    <div className={`transition-div collapse`} id={`graphCollapse${question.id}`}>
                      <div dangerouslySetInnerHTML={{__html:question.answer}}></div>
                      <AiDisclaimer></AiDisclaimer>
                    </div>
                    </div>
                   
              </div>
              <div>
                <a href="#" onClick={()=>deleteQa(question.id)}><i className="far fa-trash-alt fa-lg text-danger"></i></a>
              </div>
              </div>
              <br></br>
          </Col>
        </Row>
      
    )
  })
  
  return (
    <ContentContainer url="artificial-intelligence" title={t("AI")} showExchange={true}>
      <Loading {...bindLoading}>
      <div className="dashboard-content">
        <BackButton></BackButton>
        <Row className="qa-div">
          <Col lg={8} className="offset-xl-2 offset-xl-2">
          {questionsRows}
          <CustomPagination
                  itemsCountPerPage={itemsPerPage}
      
                  startItem={startItem}
                  totalCount={total}
                  onChange={fetchQuestions}
                  itemsPerPage={itemsPerPage}
                />
          </Col>
          
        </Row>
       
        <br></br>
        <br></br>
        
     </div>
     </Loading>
    </ContentContainer>
  );
};

export default connect(null,{fetchQuestions,deleteQuery})(ArtificialIntelligenceSaved);
