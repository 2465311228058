import React from "react";
import { Table } from "react-bootstrap";
import { formatNumberToString, roundNumber } from "../../utils/Utils";
import { useSelector } from "react-redux";
const CoinPanel = (props) => {
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const { coins, isTotal,t } = props;

  const coinItems =
    coins &&
    coins.map((item, index) => {
      return (
        <tr key={index} className={`theme-border ${theme}`}>
          <td className="text-left">
            <img
              src={item.image || require("../../images/coin_pl.png")}
              height={15}
            />
            <span className="ml-2">
              {item.name} ({item.symbol})
            </span>
          </td>
          {isTotal && <td>{roundNumber(item.holdings)}%</td>}
          <td className={item.change >= 0 ? "text-success" : "text-danger"}>
            {roundNumber(item.change)}%
          </td>
          <td>{roundNumber(item.price)} USD</td>
          <td>
            {formatNumberToString(item.amount,2)} {item.symbol}
          </td>
          <td>{roundNumber(item.total)} USD</td>
        </tr>
      );
    });
  return (
    <div className="mt-4 bot-tab">
    <div className="" >
          {/* <h2>Deals in Progress</h2> */}
          <br/>
          <div className="deals-table">
              <table className="">
                  <tbody>
                      <tr valign="top">
                      <th className="text-left">Stock</th>
                      {isTotal && <th>{t("holdings")}</th>}
                      <th>1D {t("change")}</th>
                      <th>{t("price")}</th>
                      <th>{t("amount")}</th>
                      <th>{t("total")}</th>
                      </tr>
                      {coinItems}
                  </tbody>
              </table>
          </div>
    </div>
    
  </div>
  );
};

export default CoinPanel;
