import {
  Button,
  Row,
  Col,
  OverlayTrigger,
  ProgressBar,
} from "react-bootstrap";
import React from "react";
import "./MarketplaceCard.scss";
import { Link, useHistory } from "react-router-dom";
import { getTemplateDetail } from "../../../actions/marketplace-action";
import { connect } from "react-redux";
import { trimString } from "../../../utils/Utils";

const MarketplaceCard = (props) => {
  //console.log(props)
  const {template,getTemplateDetail,t,featured} = props;
  const back_test = template.back_test || {};
  const history = useHistory(); 
  const clickTemplateDetails = (id)=>{
    history.push(`/marketplace-detail/${id}`)
  }
  const checkPercentageStatus = (percentage)=>{
    switch(true){
      case percentage < 10:
        return "Very Low"
        break;
      case percentage < 30:
        return "Low"
        break;
      case percentage < 80:
        return "Medium"
        break;
      case percentage <= 89:
        return "High"
        break;
      case percentage >= 90:
        return "Very High"
        break;
    }
  }
  return (
    <div className="marketplace-card">
       <div className="pos-relative marketplace-card-content">
      {/* {featured && <>
        <i className="fas fa-crown featured-icon"></i>
        <span className="featured-text">Featured</span>
        </> } */}
        <img src={template.image} className=""></img>
        <div className="marketplace-card-info-container">
          <p className="marketplace-card-title">{trimString(template.title,50) }</p>
          
          
          <div className="">
            
            <Row className="mt-2">
              <Col lg={2} xs={2} className="no-padding"> 
                  <div className="w-100 progress-icon">
                    <img src="/images/icons/icon-bot-activity.svg" height={20}></img>
                  </div>
              </Col>
              <Col lg={10} xs={10} className="progress-bar-div">
                <div className="pos-relative mp-card-details-div">
                  <span className="abl-top-left">Bot Activity</span>
                  <ProgressBar now={template.real_performances && template.real_performances.bot_activity}  className=""/>
                  <span className="abl-top-right">{checkPercentageStatus(template.real_performances && template.real_performances.bot_activity)}</span>
                  </div>
                </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={2} xs={2} className="no-padding"> 
                  <div className="w-100 progress-icon">
                    <img src="/images/icons/icon-win.svg" height={20}></img>
                  </div>
              </Col>
              <Col lg={10} xs={10} className="progress-bar-div" >
                <div className="pos-relative mp-card-details-div">
                  <span className="abl-top-left">Win Rate</span>
                  <ProgressBar now={template.real_performances && template.real_performances.win_rate}   className=""/>
                  <span className="abl-top-right">{checkPercentageStatus(template.real_performances && template.real_performances.win_rate)}</span>
                  </div>
                </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={2} xs={2}className="no-padding"> 
                  <div className="w-100 progress-icon">
                    <img src="/images/icons/icon-apy.svg" height={20}></img>
                  </div>
              </Col>
              <Col lg={10} xs={10} className="progress-bar-div">
                <div className="pos-relative mp-card-details-div">
                  <span className="abl-top-left">Track Record</span>
                  <ProgressBar now={template.real_performances && template.real_performances.track_record}   className=""/>
                  <span className="abl-top-right">{checkPercentageStatus(template.real_performances && template.real_performances.track_record)}</span>
                  </div>
                </Col>
            </Row>
            <Row>
              <Col>
              <div className="w-100 d-flex justify-content-between mt-4 mb-2">
                  <div className="mp-label"><i className="fas fa-eye"></i>&nbsp;{template.view_count}</div>
                  <div className="mp-label"><span className={template.strategy=="Long"?"long":"short"}>{template.strategy}</span></div>
                  <div className="mp-label "><i className="fas fa-heart like-icon "></i>&nbsp;{template.like_count}</div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null,{getTemplateDetail})(MarketplaceCard);
