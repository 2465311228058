import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BackButton = (props)=>{
    const history = useHistory()
    return(
        <div className={`${props.className} back-button`} onClick={()=>history.goBack()}>
        <i className="fas fa-arrow-left"></i>
        </div>
    )
}
export default BackButton;