import React from "react";
import { Col, Row } from "react-bootstrap";

const Tab2 = (props)=>{
    const {next,prev} = props;
    return(<>
        <div className="tab qs-tab-div">
            <Row className="qs-tab-des-div">
                <Col className="text-left">
                    <h2>Step 2: Creating Your First Bot</h2>
                    <br/>
                    <p>For this Quick Start Wizard, we will build a Simple bot for you. This bot will trade on our Paper exchange. Trading on our Paper exchange is risk-free and utilizes our Paper (virtual) funds. The goal is to give you a feel for how StockHero monitors and executes trades for you using bots. When you are comfortable with your bots, you can create them to run on your live brokerage.</p>
                    <br></br>
                    <p><b>What is a Paper Brokerage?</b></p>
                    <p className="mt-2">It is a simulated or virtual exchange that monitors real-time stock fluctuations. All bots set up for trading on the Paper exchange do not use actual money. Instead, they use virtual (false) funds that may be easily changed on the Settings page.</p>
                    <br/>
                </Col>
            </Row>
            <Row className="qs-tab-button-div">
                <Col xl={6} className="mt-2">
                    <button type="button" id="prevBtn" className="btn full-btn blue-btn" onClick={prev}>Previous</button>
                </Col>
                <Col xl={6} className="mt-2">
                    <button type="button" id="nextBtn" className="btn full-btn"onClick={next} >Next</button>
                </Col>
            </Row>
          
        </div>
    </>)
}
export default Tab2;