import React from "react";
import { useTranslation } from "react-i18next";

const NoData = (props) => {
  const {t} = useTranslation()
  return (
    <div className="text-center">
      {props.count ? <></> : <h6 className="mt-5">{props.text??t("no-data")}</h6>}
    </div>
  );
};

export default NoData;
