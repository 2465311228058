import React, { useState } from "react";
import { Col, Form,InputGroup, Row } from "react-bootstrap";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "./SliderView.scss";

const SliderView = (props) => {
  const {
    title,
    formatLabel,
    value,
    max,
    min,
    step,
    onChange,
    disabled,
    hideLabel,
    withInput,
    appendText,
    withInputZero,
  } = props;
  
  const  restrictAlphabets = (e)=>{
    var x = e.which || e.keycode;
    if(!(x>=46 && x<=57))
      e.preventDefault()
    
  }
  return (
    <>
      <Row>
        <Col xl={4} className="cb-form-label">
          <Form.Label>
            {title} {props.tutorial}
          </Form.Label>
        </Col>
        <Col xl={8}>
          <div className={props.customClass2}>
          {!hideLabel && <div>{(formatLabel && formatLabel(value)) || value}</div>}
        <InputRange
            step={step || 1}
            minValue={min || 0}
            maxValue={max || 100}
            value={value > max?max:value}
            onChange={(value) => {
              onChange && onChange(value);
            }}
            allowSameValues={true}
            formatLabel={() => ""}
            disabled={disabled || false}
          />
          <br></br>
            {withInput && 
            <>
            <label data-text={appendText?appendText:'%'} className="input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={(value==0)?'':value}
                  onChange={(e) => {
                    onChange && onChange(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    restrictAlphabets(e);
                  }}
                  className="input-text input-gp-text"
                  min={0}
                ></Form.Control>
                </label>
            </>
             }
            {withInputZero &&
            <>
            <label data-text={appendText?appendText:'%'} className="input-gp-label">
            <Form.Control
                as="input"
                type="text"
                value={value}
                onChange={(e) => {
                  onChange && onChange(e.target.value);
                }}
                onKeyPress={(e) => {
                  restrictAlphabets(e,e.target.value);
                }}
                className="input-text input-gp-text"
              ></Form.Control>
                </label>
            </>
             }
          </div>
        
            
        </Col>
      </Row>
    </>
  );
};

export default SliderView;
