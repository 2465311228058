import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ContentContainer from "../../common/ContentContainer";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import { CREATE_BOT_START, SET_BOT_NAME } from "../../../actions/create-bot-type";
import BotTitle from "./modules/BotTitle";
import AssetParamSection from "./modules/AssetParamSection";
import EntryExitSection from "./modules/EntryExitSection";
import BackTestSection from "./modules/BackTestSection";
import { useHistory, useLocation } from "react-router-dom";
import IndicatorSetting from "./indicator-setting/IndicatorSetting";
import IndicatorSettingModal from "./indicator-setting/IndicatorSettingModal";
import CreateBotSection from "./modules/CreateBotSection";
import Loading from "../../common/Loading";

const CreateBot = (props)=>{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const newBot = useSelector((state) => state.createBot.newBot);
    useEffect(()=>{
        if(newBot.createBotStart){
            dispatch({
                type: CREATE_BOT_START,
                payload: false,
            });
        }
        else{
            history.push('/bots')
        }
    },[])
    
    
    return(<div>
        <ContentContainer url="bots" title={t("bots")} showExchange={true}>
            <div className="dashboard-content">
                <Row>
                    <BotTitle></BotTitle>
                </Row>
                <Row>
                    <AssetParamSection></AssetParamSection>
                    <EntryExitSection></EntryExitSection>
                </Row>
                <Row>
                    <BackTestSection></BackTestSection>
                </Row>
                {/* <Row>
                    <CreateBotSection newBot={newBot}></CreateBotSection>
                </Row> */}
            </div>
            <IndicatorSettingModal></IndicatorSettingModal>
        </ContentContainer>
      </div>);
}

export default connect(null,{})(CreateBot)