import "./PaperWalletPanel.scss";
import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { getPaperWallet } from "../../actions/setting-action";
import PaperCrossTab from "./PaperCrossTab";
import PaperDepositWithdraw from "./PaperDepositWithdraw";
import PaperIsolatedTab from "./PaperIsolatedTab";
import PaperSpotTab from "./PaperSpotTab";
import PaperTransferModal from "./PaperTransferModal";
import { connect, useSelector } from "react-redux";

import { AccountName, AccountType } from "../../Const";
import { useTranslation } from "react-i18next";
const tabTitles = [AccountName.spot];
const tabTypes = [AccountType.spot];
const PaperWalletPanel = (props) => {
  const {t} = useTranslation()
  const { getPaperWallet } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [showDeposit, setShowDeposit] = useState(false);
  const [isDeposit, setIsDeposit] = useState(true);
  const [showTransfer, setShowTransfer] = useState(false);
  const {
    coins,
    crossCoins,
    pairs,
    risk,
    totalSpot,
    totalCross,
    totalEquityCross,
    totalDebtCross,
    totalIsolated,
    totalEquityIsolated,
    totalDebtIsolated,
  } = useSelector((state) => state.settings.paperWallet) || {};

  const total = (totalSpot || 0) + (totalCross || 0) + (totalIsolated || 0);
  useEffect(() => {
    tabTypes.forEach((el) => {
      getPaperWallet(el).then(console.log);
    });
  }, []);

  useEffect(() => {
    getPaperWallet(tabTypes[activeTab]).then(console.log);
  }, [activeTab]);

  const tabHeaders = tabTitles.map((item, index) => {
    return (
      <Col xs={4} xl={2} key={index}>
        <a
          onClick={() => setActiveTab(index)}
          className={index == activeTab ? "list-tab tab-active" : "list-tab tab-inactive"}
        >
          {item}
        </a>
      </Col>
    );
  });
  const walletTabs = [
    <PaperSpotTab coins={coins} totalSpot={totalSpot} />
  ];
  const clickDeposit = () => {
    setShowDeposit(true);
    setIsDeposit(true);
  };
  const clickWithdraw = () => {
    setShowDeposit(true);
    setIsDeposit(false);
  };
  return (
      <div>
        <Row>
          <Col>
          <h5>Paper Wallet</h5>
          <div className="card-white">
            <div className="cw-body settings-paper-body">
            <div  className="d-flex justify-content-between">
              <div>
                <div className="title">{t("tb")}</div>
                <div className="value paper-tab-currency">{total.toFixed(6)} USD</div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="mr-2">
                  <Button variant="outline-primary" onClick={clickDeposit}>
                    {t("ADD")}
                  </Button>
                </div>
                <div className="">
                  <Button variant="outline-primary" onClick={clickWithdraw}>
                  {t("REDUCE")}
                  </Button>
                </div>
              </div>
              
             </div>
            </div>
          </div>
          </Col>
        </Row>
       
        
        <Row noGutters className="my-4">
          {tabHeaders}
        </Row>
        {walletTabs[activeTab]}
     
      <PaperDepositWithdraw
        show={showDeposit}
        onHide={() => setShowDeposit(false)}
        isDeposit={isDeposit}
        setIsDeposit={setIsDeposit}
        t={t}
      />
      <PaperTransferModal show={showTransfer} onHide={setShowTransfer} />
    </div>
  );
};

export default connect(null, { getPaperWallet })(PaperWalletPanel);
