import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Button } from "react-bootstrap";
import GaugeChart from "react-gauge-chart";
import { AccountType } from "../../Const";
import { connect, useSelector } from "react-redux";
import { getAccount } from "../../actions/terminal-action";
import "./BalancePanel.scss";
import { roundNumber } from "../../utils/Utils";

const BalancePanel = (props) => {
  const {
    account,
    onShowRepay,
    onShowTransfer,
    baseCoin,
    quoteCoin,
    getAccount,
    t
  } = props;
  const selectedExchangeId = useSelector(
    (state) => state.settings.selectedExchangeId
  );
  const selectedPair = useSelector((state) => state.terminal.selectedPair);
  const baseBal = useSelector((state) => state.terminal.base) || {};
  const quoteBal = useSelector((state) => state.terminal.quote) || {};
  const risk = useSelector((state) => state.terminal.risk) || 999;
  const openOrders = useSelector((state) => state.terminal.openOrders) || [];
  const orderHistory =
    useSelector((state) => state.terminal.orderHistory) || {};
  const orders = orderHistory.data || [];

  useEffect(() => {
    getAccount({
      exchange_id: selectedExchangeId,
      pair_id: selectedPair.realPairId,
      account,
    }).then(console.log);
  }, [
    selectedPair.s,
    selectedExchangeId,
    account,
    openOrders.length,
    orders.length,
  ]);
  const riskLevel = useMemo(() => {
    if (risk >= 2) {
      return { title: t("l-risk"), percent: 0, level: "low" };
    } else if (risk >= 1.5) {
      return { title: t("l-risk"), percent: 2 - risk, level: "low" };
    } else if (risk >= 1.3) {
      return { title: t("m-risk"), percent: 2 - risk, level: "medium" };
    } else {
      return { title: t("h-risk"), percent: 2 - risk, level: "high" };
    }
  }, [risk]);

  return (
    <div className="balance-panel">
      <h5>{t("assets")}</h5>
      {[AccountType.cross, AccountType.isolated].includes(account) && (
        <>
          <Row noGutters>
            <Col>
              <span className="asset-title">{riskLevel.title}</span>
              <br />
              <span className={"risk-" + riskLevel.level}>
                {roundNumber(risk, 2)}
              </span>
            </Col>
            <Col>
              <GaugeChart
                nrOfLevels={20}
                percent={riskLevel.percent}
                hideText={true}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <span className="asset-title">{t("position")} ({baseCoin})</span>
            </Col>
            <Col className="text-right">
              <span className="asset-value">
                {(baseBal.asset_value || 0).toFixed(8)}
              </span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <span className="asset-title">{t("position")} ({quoteCoin})</span>
            </Col>
            <Col className="text-right">
              <span className="asset-value">
                {(quoteBal.asset_value || 0).toFixed(8)}
              </span>
            </Col>
          </Row>
          <Row className="mt-5 mb-2" noGutters>
            <Col>
              <Button
                className="asset-btn w-100"
                variant="outline-primary"
                onClick={onShowTransfer}
              >
                {t("transfer")}
              </Button>
            </Col>
            <Col className="ml-2">
              <Button
                className="asset-btn w-100"
                variant="outline-primary"
                onClick={onShowRepay}
              >
                {t("repay")}
              </Button>
            </Col>
          </Row>
        </>
      )}
      {account === AccountType.spot && (
        <>
          <Row className="mt-4">
            <Col>
              <span className="asset-title">{baseCoin} {t("available")}</span>
            </Col>
            <Col className="text-right">
              <span className="asset-value">
                {(baseBal.asset_value || 0).toFixed(8)}
              </span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <span className="asset-title">{quoteCoin} {t("available")}</span>
            </Col>
            <Col className="text-right">
              <span className="asset-value">
                {(quoteBal.asset_value || 0).toFixed(8)}
              </span>
            </Col>
          </Row>
          <Button
            className="asset-btn w-100 mt-4"
            variant="outline-primary"
            onClick={onShowTransfer}
          >
            {t("transfer")}
          </Button>
        </>
      )}
    </div>
  );
};

export default connect(null, { getAccount })(BalancePanel);
