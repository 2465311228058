import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Modal,
  Alert,
  Badge,
} from "react-bootstrap";
import {
  getExchanges,
  addExchange,
  deleteExchange,
  resetExchange,
  redirectExchange,
  snapTradeSuccessAction,
} from "../../actions/setting-action";
import { connect, useSelector } from "react-redux";
import "./AddApiPanel.scss";
import { ExchangeStatus, ExchangeStatusName } from "../../Const";
import { capitalize } from "../../utils/Utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SnapTradeReact } from 'snaptrade-react';
import { toast } from "react-toastify";
import LeftRightForm from "../bots/new-bot/modules/LeftRightForm";
import NoData from "../common/NoData";
import CustomRadio from "../bots/new-bot/modules/CustomRadio";
import { getExchangePairs } from "../../actions/bot-action";
import TransactionFee from "./TransactionFee";

const labelWidth = 3;
const AddApiPanel = (props) => {
  const { getExchanges, getExchangePairs ,addExchange, deleteExchange, resetExchange,redirectExchange,snapTradeSuccessAction } = props;
  const {t} = useTranslation()
  const history = useHistory();

  const detailCols = [3, 7, 2];
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [passphrase, setPassphrase] = useState("");
  const [exchangeId, setExchangeId] = useState(0);
  const [deleteId, setDeleteId] = useState(0);
  const [open, setOpen] = useState(false);
  const snapLoginUrlUrl =  useSelector((state) => state.settings.exchangeLoginUrl) || '';
  const [snapTradeIframeUrl, setSnapTradeIframeUrl] = useState(snapLoginUrlUrl??"");

  const exchanges = useSelector((state) => state.settings.exchanges) || [];
  const selectedExchange = exchanges.find((el) => el.id == exchangeId) || {};
  
  const snapSuccessRedirectUrl =  useSelector((state) => state.settings.exchangeRedirectUrl) || '';

  useEffect(()=>{
    setSnapTradeIframeUrl(snapLoginUrlUrl)
  },[snapLoginUrlUrl])
  const addExchangeSuccess = useSelector(
    (state) => state.settings.addExchangeSuccess
  );
  const addExchangeError = useSelector(
    (state) => state.settings.addExchangeError
  );
  const addExchangeStart = useSelector(
    (state) => state.settings.addExchangeStart
  );
  const deleteExchangeSuccess = useSelector(
    (state) => state.settings.deleteExchangeSuccess
  );
  const deleteExchangeError = useSelector(
    (state) => state.settings.deleteExchangeError
  );

  const connectedExchanges = useMemo(
    () => exchanges.filter((item) => item.is_connected && item.id != 0),
    [exchanges]
  );
  const disconnectedExchanges = useMemo(
    () => exchanges.filter((item) => !item.is_connected && item.id != 0),
    [exchanges]
  );
  
  useEffect(() => {
    if (disconnectedExchanges.length) {
      setExchangeId(disconnectedExchanges[0].id);
    }
  }, [disconnectedExchanges]);

  useEffect(() => {
    //getExchanges();
    //getExchangePairs(selectedExchange.id)
  }, []);

  useEffect(() => {
    resetExchange();
    return () => {
      resetExchange();
    };
  }, []);

  useEffect(() => {
    if (addExchangeSuccess) {
      setApiKey("");
      setApiSecret("");
      setPassphrase("");
    }
  }, [addExchangeSuccess]);

  const clickAdd = () => {
    const params = {
      id: String(exchangeId),
      api_key: apiKey,
      secret: apiSecret,
      account: passphrase,
    };
    if(selectedExchange.is_snap_trade){
      redirectExchange(params).then((res)=>{
        setOpen(true);
        //history.push("/add-brokerage");
      })
      //addExchange(params);
    }
    else{
      addExchange(params);
    }
  };

  const onSuccessFn = (res) => {
    //snapTradeSuccessAction(snapSuccessRedirectUrl)
    window.location.href= snapSuccessRedirectUrl
    resetExchange();
  };

  const onErrorFn = (msg: string) => {
    setOpen(false);
    resetExchange();
    toast.error("ERROR: " + msg.detail, {
      toastId: "error"
    });
  };
  const onExitFn = () => {
    setOpen(false)
    resetExchange();
  };


  const clickDelete = () => {
    deleteExchange(deleteId);
    setDeleteId(0);
  };
  const [feeItems, setFeeItems] = useState([
  ]);

  

  const connectedItems = connectedExchanges.map((item, index) => {
    return (
      <tr  key={index}>
        <td className="text-left">
          <img src={item.image} height={20} className="mr-2" />
          {item.name}
        </td>
        <td  className="text-truncate">
          {item.id==16?"********************":item.api_key}
        </td>
        <td className="text-left">
          <a
            href="#"
            className="danger"
            onClick={() => {
              setDeleteId(item.id);
            }}
          >
            <i className="far fa-trash-alt fa-lg text-danger"></i>
          </a>
        </td>
      </tr>
    );
  });
  

  const disconnectedItems = disconnectedExchanges.map((item, index) => {
    return (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    );
  });

  return (
    <>
    <Row>
      <Col xl={12}>
    <h5>{t("add-new-exch")}</h5>
      <div className="card-white">
          <div className="cw-body addnewexchange-body">
              <div className="round-bg">
                <SnapTradeReact
                  loginLink={snapTradeIframeUrl}
                  isOpen={open}
                  close={() => onExitFn()}
                  onSuccess={onSuccessFn}
                  onError={onErrorFn}
                  onExit={onExitFn}
                />
                 <LeftRightForm 
                    left= {t("exchange")}
                    right={<>
                      <Form.Control
                        as="select"
                        onChange={(e) => setExchangeId(Number(e.target.value))}
                        value={exchangeId}
                        className="select-normal small-select"
                      >
                        {disconnectedItems}
                      </Form.Control>
                      <p className="center mt-2 italic"><i className="fa fa-exclamation-circle exclamation" aria-hidden="true"></i> A broker marked with a beta tag indicates a newly launched service on StockHero</p>
                  
                      </>}
                  ></LeftRightForm>
                  {!selectedExchange.need_auth ? (
                  <>
                  <Form.Group as={Row}>
                    <Form.Label column sm={labelWidth}>
                      {t("api-key")}
                    </Form.Label>
                    <Col sm={12 - labelWidth}>
                      <Form.Control
                        as="input"
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                        className="input-text"
                      />
                    </Col>
                  </Form.Group> 
                  <Form.Group as={Row}>
                    <Form.Label column sm={labelWidth}>
                      {t("api-secret")}
                    </Form.Label>
                    <Col sm={12 - labelWidth}>
                      <Form.Control
                        as="input"
                        value={apiSecret}
                        onChange={(e) => setApiSecret(e.target.value)}
                        className="input-text"
                      />
                    </Col>
                  </Form.Group> </>):(<></>)}
                  {selectedExchange.require_param ? (
                    <Form.Group as={Row}>
                      <Form.Label column sm={labelWidth}>
                        {capitalize(selectedExchange.require_param || "")}
                      </Form.Label>
                      <Col sm={12 - labelWidth}>
                        <Form.Control
                          as="input"
                          value={passphrase}
                          onChange={(e) => setPassphrase(e.target.value)}
                          className="input-text"
                        />
                      </Col>
                    </Form.Group>
                  ) : (
                    <></>
                  )}
                
                  <Row className="justify-content-center mt-4">
                  
                    <Button onClick={clickAdd} variant="success">
                      {addExchangeStart ? "Updating..." : (selectedExchange.need_auth ? t("authenticate"):t("add-exchange"))}
                    </Button>
                  </Row>
                  {selectedExchange.need_auth ?<>
                  <Row className="justify-content-center mt-2">
                    <p>{t("tradestation")} {selectedExchange.name}</p>
                  </Row></>:<></>
                  }
                  <Row className="justify-content-center mt-2">
                    <Alert
                      show={addExchangeSuccess || addExchangeError != null}
                      variant={addExchangeError ? "danger" : "success"}
                    >
                      {addExchangeError || "Brokerage added!"}
                    </Alert>
                  </Row>
                  {selectedExchange && !selectedExchange.is_snap_trade && 
                  <div style={{ textAlign: "right", marginTop: 20 }}>
                    <a href={selectedExchange.doc_link} target="_blank">
                      * {t("how-to-connect")} {selectedExchange.name}?
                    </a>
                  </div> }
                  
                </div>
          </div>
      </div>
      </Col>
    </Row>
   
      <Row className="mt-4">
        <Col xl={6}>
          <h5>{t("connected-exgs")}</h5>
            <div className="mt-4 bot-tab">
              <div className="">
                    <div className="deals-table">
                        <table className="">
                            <tbody>
                                <tr valign="top">
                                    <th>{t("exchange")}</th>
                                    <th>{t("api-key")}</th>
                                    <th>{t("action")}</th>
                                </tr>
                                {connectedItems}
                            </tbody>
                        </table>
                        <NoData count={connectedExchanges.length} />
                  </div>
              </div>
            </div>
        </Col>
        <TransactionFee></TransactionFee>
      </Row>

      <Modal show={deleteId > 0} onHide={() => setDeleteId(0)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("warning")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("delete-api-warning")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={clickDelete}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(null, {
  getExchanges,
  getExchangePairs,
  addExchange,
  deleteExchange,
  resetExchange,
  redirectExchange,
  snapTradeSuccessAction
})(AddApiPanel);
