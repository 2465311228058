import * as ApiDetail from './ApiDetail';

export const Financials = {
  name: 'Financials',
  subData: [
    ApiDetail.latestPrice,
    ApiDetail.coinInfo,
    ApiDetail.priceChanged,
    ApiDetail.convert,
    ApiDetail.fcas,
  ],
};

export const Eth2 = {
  name: 'Ethereum 2.0',
  subData: [ApiDetail.tvlStaked, ApiDetail.totalValidators],
};

export const Institutions = {
  name: 'Institutions',
  subData: [
    ApiDetail.purposeBtcFlow,
    ApiDetail.grayscaleBtcFlow,
    ApiDetail.grayscaleHolding,
    ApiDetail.grayscalePremium,
    ApiDetail.grayscaleAum,
    ApiDetail.grayscalePrice,
    ApiDetail.purposeBtcQty,
    ApiDetail.btcFundQty,
    ApiDetail.btcFundFlow,
    ApiDetail.btcFundPremiumToNav,
    ApiDetail.btcFundAum,
    ApiDetail.btcFundPrice,
  ],
};

export const Exchanges = {
  name: 'Brokerages',
  subData: [ApiDetail.exchangeInfo],
};

export const Mining = {
  name: 'Mining',
  subData: [ApiDetail.miningDifficulty, ApiDetail.miningHashrate],
};

export const Addresses = {
  name: 'Addresses',
  subData: [
    ApiDetail.uniqueSender,
    ApiDetail.uniqueReceiver,
    ApiDetail.activeSenderReceiver,
    ApiDetail.uniqueTotal,
  ],
};

export const DataGroup = [
  Financials,
  Institutions,
  Eth2,
  Exchanges,
  Mining,
  Addresses,
];
