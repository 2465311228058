import {
  GET_EXCHANGES,
  ADD_EXCHANGE_ERROR,
  ADD_EXCHANGE_RESET,
  ADD_EXCHANGE_START,
  ADD_EXCHANGE_SUCCESS,
  DELETE_EXCHANGE_ERROR,
  DELETE_EXCHANGE_SUCCESS,
  GET_PAPER_SETTING,
  UPDATE_PAPER_ERROR,
  UPDATE_PAPER_RESET,
  UPDATE_PAPER_START,
  UPDATE_PAPER_SUCCESS,
  GET_SETTINGS,
  GET_CURRENCIES,
  UPDATE_CURRENCY_SUCCESS,
  SELECT_EXCHANGE_ID,
  GET_FILTERS,
  CHANGE_TIMEZONE,
  UPDATE_SUBSCRIPTION,
  GET_USER_REFERRALS,
  TOGGLE_NAV,
  GET_PAPER_WALLET,
  UPDATE_USER_ROLE,
  GET_GA_CODE,
  UPDATE_GA_SETTING,
  GA_CONFIRM,
  UPDATE_USER_PROFILE_START,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_SUCCESS,
  SELECTED_THEME,
  TAKE_TOUR,
  GET_INTRO_TOUR_SETTING,
  NEW_USER_INTRO_POPUP,
  CHANGE_RISK_FREE_RATE,
  SET_EXCHANGE_REDIRECT_URL,
  SET_EXCHANGE_LOGIN_URL
} from "./types";

import {
  getExchangesUrl,
  addExchangeUrl,
  deleteExchangeUrl,
  getPaperSettingUrl,
  updatePaperSettingUrl,
  getSettingsUrl,
  updateNotificationUrl,
  updateOrderFilledAlertUrl,
  getCurrenciesUrl,
  updateCurrencyUrl,
  getFilterUrl,
  postSubscribe,
  getSubscription,
  postCancelSubscription,
  postContinueSubsciption,
  getUserReferralUrl,
  updateCurrentExchange,
  updateSettings,
  postContactFormUrl,
  paperWalletUrl,
  accountTransferUrl,
  accountDepositUrl,
  accountWithdrawUrl,
  updateUserRoleUrl,
  paypalSubscribeUrl,
  getGACodeUrl,
  enableGACodeUrl,
  checkGACodeUrl,
  profileUpdate,
  cryptoSubscribeUrl
} from "./const";

import network from "./network";
import axios from "axios";
import { AccountType, SmsCountriesCode, UserRole } from "../Const";
import { getExchangePairs } from "./bot-action";

export const getExchanges = () => (dispatch, getState) => {
  network.get(getExchangesUrl, {}, dispatch, getState).then((res) => {
    const { exchanges } = res.data;
    dispatch({
      type: GET_EXCHANGES,
      payload: exchanges,
    });
  });
};

export const addExchange = (params) => (dispatch, getState) => {
  dispatch({
    type: ADD_EXCHANGE_START,
  });
  network
    .post(addExchangeUrl, params, dispatch, getState)
    .then((res) => {
      const { code, message,trade_station_login_url } = res.data;
      if (code == 200) {
        if(trade_station_login_url){
          window.location = trade_station_login_url
          return false
        }
        dispatch({
          type: ADD_EXCHANGE_SUCCESS,
        });
        dispatch(getExchanges());
        setTimeout(() => {
          dispatch({
            type: ADD_EXCHANGE_RESET,
          });
        }, 1000);
      } else {
        dispatch({
          type: ADD_EXCHANGE_ERROR,
          payload: message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: ADD_EXCHANGE_ERROR,
        payload: String(err),
      });
    });
};
export const redirectExchange = (params) => (dispatch, getState) => {
  dispatch({
    type: ADD_EXCHANGE_START,
  });
  return new Promise((resolve, reject) => {
  network
    .post(addExchangeUrl, params, dispatch, getState)
    .then((res) => {
      const { code, message,trade_station_login_url,redirect_url } = res.data;
      if (code == 200) {
        if(trade_station_login_url){
           dispatch({
            type: SET_EXCHANGE_REDIRECT_URL,
            payload: redirect_url,
           });
           dispatch({
            type: SET_EXCHANGE_LOGIN_URL,
            payload: trade_station_login_url,
           });
          resolve(trade_station_login_url);
          //window.location = trade_station_login_url
          //return false
        }
        // dispatch({
        //   type: ADD_EXCHANGE_SUCCESS,
        // });
        // dispatch(getExchanges());
        // setTimeout(() => {
        //   dispatch({
        //     type: ADD_EXCHANGE_RESET,
        //   });
        // }, 1000);
      } else {
        dispatch({
          type: ADD_EXCHANGE_ERROR,
          payload: message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: ADD_EXCHANGE_ERROR,
        payload: String(err),
      });
    });
  })
    
};

export const snapTradeSuccessAction = (url,params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params,
      })
      .then((res) => {
        console.log(res)
        resolve(res);
      })
      .catch((err) => {
        console.log(err)
        if (err.response && err.response.status === 401) {
          console.log(err)
        }
        reject(String(err));
      });
  });
};

export const deleteExchange = (id) => (dispatch, getState) => {
  network
    .post(deleteExchangeUrl, { id: String(id) }, dispatch, getState)
    .then((res) => {
      const { code, message } = res.data;
      if (code == 200) {
        dispatch({
          type: DELETE_EXCHANGE_SUCCESS,
          payload: Number(id),
        });
        dispatch(getExchanges());
        setTimeout(() => {
          dispatch({
            type: ADD_EXCHANGE_RESET,
          });
        }, 1000);
      } else {
        dispatch({
          type: DELETE_EXCHANGE_ERROR,
          payload: message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: DELETE_EXCHANGE_ERROR,
        payload: String(err),
      });
    });
};

export const resetExchange = () => (dispatch) => {
  dispatch({
    type: ADD_EXCHANGE_RESET,
  });
};

export const getPaperSetting = () => (dispatch, getState) => {
  network.get(getPaperSettingUrl, {}, dispatch, getState).then((res) => {
    const { balances } = res.data;
    dispatch({
      type: GET_PAPER_SETTING,
      payload: balances,
    });
  });
};

export const updatePaperSetting = (params) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_PAPER_START,
  });
  network
    .post(updatePaperSettingUrl, params, dispatch, getState)
    .then((res) => {
      const { code, message } = res.data;
      if (code == 200) {
        dispatch({
          type: UPDATE_PAPER_SUCCESS,
        });
        dispatch(getPaperSetting());
        setTimeout(() => {
          dispatch({
            type: UPDATE_PAPER_RESET,
          });
        }, 1000);
      } else {
        dispatch({
          type: UPDATE_PAPER_ERROR,
          payload: message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_PAPER_ERROR,
        payload: String(err),
      });
    });
};

export const resetPaperSetting = () => (dispatch) => {
  dispatch({
    type: UPDATE_PAPER_RESET,
  });
};

export const getSettings = () => (dispatch, getState) => {
  network.get(getSettingsUrl, {}, dispatch, getState).then((res) => {
    const { settings } = res.data;
    dispatch({
      type: GET_SETTINGS,
      payload: settings,
    });
  });
};
export const getUserSettings = (name) => (dispatch, getState) => {
  network.get(updateSettings, {name:name}, dispatch, getState).then((res) => {
    const { setting } = res.data;
    dispatch({
      type: GET_INTRO_TOUR_SETTING,
      payload: setting?parseInt(setting.value):1,
    });
    dispatch({
      type: NEW_USER_INTRO_POPUP,
      payload: setting?parseInt(setting.value):1,
    });
  });
};

export const updateNotificationStatus = (status) => (dispatch, getState) => {
  network
    .post(updateNotificationUrl, { status }, dispatch, getState)
    .then((res) => {});
};
export const updateEmailAlertStatus = (status) => (dispatch, getState) => {
  network
    .post(updateOrderFilledAlertUrl, { status }, dispatch, getState)
    .then((res) => {});
};
export const updateSmsAlertStatus = (status,countryName,phoneNumber) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
  dispatch({
    type: UPDATE_USER_PROFILE_START
  });
  network
    .post(updateSettings, { 
      name: 'order_filled_sms_alert',
      value: status,
      value2:SmsCountriesCode[countryName]+phoneNumber,
      value3:SmsCountriesCode[countryName],
      value4:countryName
    }, dispatch, getState)
    .then((res) => {
      dispatch({
        type: UPDATE_USER_PROFILE_ERROR
      });
      resolve()
    });
  })
};

export const getCurrencies = () => (dispatch, getState) => {
  network.get(getCurrenciesUrl, {}, dispatch, getState).then((res) => {
    const { currencies, user_currency } = res.data;
    dispatch({
      type: GET_CURRENCIES,
      payload: { currencies, currency: user_currency },
    });
  });
};

export const updateCurrency = (id) => (dispatch, getState) => {
  network
    .post(updateCurrencyUrl, { currency_id: id }, dispatch, getState)
    .then((res) => {
      const { code, user } = res.data;
      if (code == 200) {
        dispatch({
          type: UPDATE_CURRENCY_SUCCESS,
          payload: user.currency,
        });
      }
    });
};

export const selectExchange = (id) => (dispatch, getState) => {
  network
    .post(updateCurrentExchange, { exchange_id: id }, dispatch, getState)
    .then();
    dispatch({
      type: SELECT_EXCHANGE_ID,
      payload: id,
    });
    dispatch(getExchangePairs(id));
};

export const getFilters = () => (dispatch, getState) => {
  network.get(getFilterUrl, {}, dispatch, getState).then((res) => {
    const { filters } = res.data;
    dispatch({
      type: GET_FILTERS,
      payload: filters,
    });
  });
};

export const changeTimezone = (timezone) => (dispatch) => {
  dispatch({
    type: CHANGE_TIMEZONE,
    payload: timezone,
  });
};

export const subscribe =
  (paymentMethodId, priceId, cardHolder, tier) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      network
        .post(
          postSubscribe,
          {
            product_id: priceId,
            payment_method_id: paymentMethodId,
            card_holder: cardHolder,
            tier,
          },
          dispatch,
          getState
        )
        .then((res) => {
          const { code, message, user_subscription,order_id } = res.data;
          if (code == 200) {
            dispatch({
              type: UPDATE_SUBSCRIPTION,
              payload: user_subscription,
            });
            resolve(order_id);
          } else {
            reject(message);
          }
        })
        .catch((err) => {
          reject(String(err));
        });
    });
  };

export const cancelSubscription = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(postCancelSubscription, {}, dispatch, getState)
      .then((res) => {
        const { code, message, user_subscription } = res.data;
        if (code == 200) {
          dispatch({
            type: UPDATE_SUBSCRIPTION,
            payload: user_subscription,
          });
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const continueSubscription = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(postContinueSubsciption, {}, dispatch, getState)
      .then((res) => {
        const { code, message, user_subscription } = res.data;
        if (code == 200) {
          dispatch({
            type: UPDATE_SUBSCRIPTION,
            payload: user_subscription,
          });
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const checkSubscription = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .get(getSubscription, {}, dispatch, getState)
      .then((res) => {
        const { code, message, user_subscription } = res.data;
        if (code == 200) {
          dispatch({
            type: UPDATE_SUBSCRIPTION,
            payload: user_subscription,
          });
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const getUserReferrals = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .get(getUserReferralUrl, {}, dispatch, getState)
      .then((res) => {
        const {
          code,
          message,
          refer_friends,
          refer_binance_connected,
          binance_reward,
          binance_doge_wallet,
          refer_history,
        } = res.data;
        if (code == 200) {
          dispatch({
            type: GET_USER_REFERRALS,
            payload: {
              refer_friends,
              refer_binance_connected,
              binance_reward,
              binance_doge_wallet,
              refer_history,
            },
          });
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const toggleNav = (expand) => (dispatch) => {
  dispatch({
    type: TOGGLE_NAV,
    payload: expand,
  });
};

export const updateDogeAddress = (addr) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(
        updateSettings,
        {
          name: "binance_wallet",
          value: addr,
        },
        dispatch,
        getState
      )
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const updateContactForm = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(postContactFormUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const getPaperWallet = (account) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .get(paperWalletUrl, { account }, dispatch, getState)
      .then((res) => {
        const {
          code,
          message,
          coins,
          pairs,
          risk,
          total_balance_btc,
          total_equity_btc,
          total_debt_btc,
        } = res.data;

        if (code == 200) {
          let payload;
          switch (account) {
            case AccountType.spot:
              payload = {
                coins,
                totalSpot: total_balance_btc,
              };
              break;
            case AccountType.cross:
              payload = {
                crossCoins: coins,
                risk,
                totalCross: total_balance_btc,
                totalEquityCross: total_equity_btc,
                totalDebtCross: total_debt_btc,
              };
              break;
            case AccountType.isolated:
              payload = {
                pairs,
                totalIsolated: total_balance_btc,
                totalEquityIsolated: total_equity_btc,
                totalDebtIsolated: total_debt_btc,
              };
              break;
          }
          dispatch({
            type: GET_PAPER_WALLET,
            payload: payload,
          });
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const paperTransfer = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(accountTransferUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          Object.values(AccountType).forEach((account) =>
            dispatch(getPaperWallet(account))
          );
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const paperDeposit = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(accountDepositUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          dispatch(getPaperWallet(AccountType.spot));
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const paperWithdraw = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(accountWithdrawUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          dispatch(getPaperWallet(AccountType.spot));
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const updateUserRole = (role) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_USER_ROLE,
    payload: role,
  });
  return new Promise((resolve, reject) => {
    network
      .post(updateUserRoleUrl, { role }, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          resolve();
        } else {
          reject(message);
          dispatch({
            type: UPDATE_USER_ROLE,
            payload: role === UserRole.normal ? UserRole.beta : UserRole.normal,
          });
        }
      })
      .catch((err) => {
        reject(String(err));
        dispatch({
          type: UPDATE_USER_ROLE,
          payload: role === UserRole.normal ? UserRole.beta : UserRole.normal,
        });
      });
  });
};

export const paypalSubscribe = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(paypalSubscribeUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          resolve(res.data.order_id);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};
export const cryptoSubscription = (params) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(cryptoSubscribeUrl, params, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          resolve(res.data.order_id);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};
export const getGACode = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .get(getGACodeUrl, {}, dispatch, getState)
      .then((res) => {
        const { code, message, google2fa_secret, inline, setting } = res.data;
        if (code === 200) {
          dispatch({
            type: GET_GA_CODE,
            payload: {
              gaCode: google2fa_secret,
              gaQrCode: inline,
            },
          });
          dispatch({
            type: UPDATE_GA_SETTING,
            payload: setting != null && setting.value != 0,
          });
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const updateGASetting = (gaCode, enabled) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(
        enableGACodeUrl,
        { otp: gaCode, setting_value: enabled },
        dispatch,
        getState
      )
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          dispatch(getGACode());
          dispatch({
            type: GA_CONFIRM,
          });
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const verifyGaCode = (gaCode) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(checkGACodeUrl, { otp: gaCode }, dispatch, getState)
      .then((res) => {
        const { code, message, result } = res.data;
        if (code === 200 && result == 1) {
          resolve();
        } else {
          reject("Code is invalid.");
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const changeName = (params)=>(dispatch,getState)=>{
  dispatch({
    type: UPDATE_USER_PROFILE_START
  });
  return new Promise((resolve,reject)=>{
      network
      .post(profileUpdate,params,dispatch,getState)
      .then((res)=>{
        const { code, message } = res.data;
        if (code === 200) {
          resolve();
          dispatch({
            type: UPDATE_USER_PROFILE_SUCCESS,
            payload:res.data.user
          });
        } else {
          dispatch({
            type: UPDATE_USER_PROFILE_ERROR
          });
          reject(message);
        }
        
      }).catch((err)=>{
        dispatch({
          type: UPDATE_USER_PROFILE_ERROR
        });
        reject('Error updating name');
      })
  });
}
export const switchTheme = (theme) => (dispatch, getState) => {
  network
    .post(updateSettings, { name: 'theme',value: theme}, dispatch, getState)
    .then((res) => {
      const { code, user } = res.data;
      if (code == 200) {
        dispatch({
          type: SELECTED_THEME,
          payload: theme,
        });
      }
    });
};
export const hideIntroPopup = (payload) => (dispatch, getState) => {
  network
    .post(updateSettings, { name: 'taketour',value: payload}, dispatch, getState)
    .then((res) => {
      const { code, user } = res.data;
      if (code == 200) {
        dispatch({
          type: GET_INTRO_TOUR_SETTING,
          payload: payload,
        });
      }
    });
};
export const userSetting = (name,val) => (dispatch, getState) => {
  network
    .post(updateSettings, { name: name,value: val}, dispatch, getState)
    .then((res) => {
      const { code, user } = res.data;
      if (code == 200) {
        
      }
    });
};
export const updateTransactionFee = (params) => (dispatch, getState) => {
  return new Promise((resolve,reject)=>{
        network
        .post(updateSettings, params, dispatch, getState)
        .then((res) => {
          const { code, user } = res.data;
          if (code == 200) {
            resolve(code)
          }
          else{
            reject()
          }
        });
  })
  
};
export const updateFormula = (params)=>(dispatch,getState)=>{
  // dispatch({
  //   type: UPDATE_USER_PROFILE_START
  // });
  return new Promise((resolve,reject)=>{
      network
      .post(updateSettings,params,dispatch,getState)
      .then((res)=>{
        const { code, setting ,value} = res.data;
        if (code === 200) {
          //alert(code)
          dispatch({
            type: CHANGE_RISK_FREE_RATE,
            //payload: setting.risk_free_rate,
            payload: value,
          });
          resolve();
          
        } else {
          reject('Success');
        }
        
      }).catch((err)=>{
        console.log(err)
        reject('Error updating name');
      })
  });
}