import React, { useEffect, useState } from "react";
import ModalContainer from "../../../common/ModalContainer";
import "./IndicatorSetting.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ADD_TV_ALERTS_ENTRY, ADD_TV_ALERTS_EXIT, CHANGE_ENTRY_NUM, CHANGE_EXIT_NUM, EDITING_TV_ALERT, FINISHED_TV_SETTING_MODAL } from "../../../../actions/create-bot-type";
import { useDispatch, useSelector } from "react-redux";
import useInput from "../../../hooks/useIndicatorInput";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { BotType } from "../../../../Const";
import IsRequiredModule from "./IsRequiredModule";
import useCheckbox from "../../../hooks/useCheckbox";
import { restrictAlphabets } from "../../../../utils/Utils";



const TvAlertSettingModal = (props) => {
  const {t} = useTranslation()
  const { onHide,isEntry,newBot } = props;
  const dispatch = useDispatch();
  const alertUrl = "https://web.stockhero.ai/tvapi"

  const isGridBot = newBot.botType===BotType.grid
 
  const tvAlerts = isEntry ? newBot.tvAlertsEntry : newBot.tvAlertsExit;
  const editingTvAlert = useSelector(
    (state) => state.createBot.editingTvAlert
  );
  const [isRequired,bindIsRequired, setIsRequired] = useCheckbox(
    editingTvAlert.valid_sec?true:false
  );
  const [validity, bindValidity, setValidity] = useInput(editingTvAlert.valid_sec, 0);
  
  const tvAlertString = editingTvAlert && {
      checksum:editingTvAlert.checksum,
      type:isEntry?"entry":"exit",
      order:editingTvAlert.order
  }
  const saveTvAlert = ()=>{
      dispatch({
        type:isEntry?ADD_TV_ALERTS_ENTRY:ADD_TV_ALERTS_EXIT,
        payload: {...editingTvAlert,valid_sec:isRequired?validity:0}
      })
      dispatch({
        type: isEntry ? CHANGE_ENTRY_NUM : CHANGE_EXIT_NUM,
        payload: 1,
      });
      onHide()
  }
  const changeOrder = (val)=>{
    dispatch({
        type:EDITING_TV_ALERT,
        payload:{
          ...editingTvAlert,order:val,is_compulsory:isRequired?1:0
        }
    });
  }
  const clickCopy = (type) => {
    switch (type) {
        case "url":
            copy(alertUrl);
            break;
        case "checksum":
            copy(JSON.stringify(tvAlertString));
            break;
    
        default:
            break;
    }
    
    toast.success(t("copied-msg"));
  };
  return (
    <ModalContainer
      show={true}
      onHide={onHide}
      title={t("tv-setting")}
      size="lg"
    >
      <div className="indicator-setting">
        
        <Col xl={12}
        className=""
        
        >
        <Form.Group>
            <p>{t("tv-indicator-msg")}</p>
            <div className="d-flex justify-content-between">
              <div><p className="tv-alert-url">{alertUrl}</p></div>
              <div><span className="alert-copy" onClick={()=>clickCopy("url")}><i className={`far fa-copy`} ></i></span></div>
            </div>
            
        </Form.Group>
        {(isEntry && !isGridBot) && 
          <Row>
            <Col xl={12}>
            <div className="d-flex justify-content-between">
                <div>
                 <Form.Label>{t("sel-order-name")}</Form.Label>
                 </div>
                 <div>
                    <select className="form-control select-normal tv-select-order" value={editingTvAlert?editingTvAlert.order:(isEntry?"base":"exit")} onChange={(e)=>changeOrder(e.target.value)}>
                        <option value={`base`} key={1}>Base</option>
                        <option value={`extra`} key={2}>Extra</option>
                    </select>
                 </div>
            
            </div>
            </Col>
          </Row>
            
        }
        <div className="d-flex mt-4">
        <div>
        <input type="checkbox" id="required_check" {...bindIsRequired} className="custom-checkbox"  
                checked={isRequired}>
            </input>
            <label for="required_check" className="checkbox-label">{t("required")}</label>
        </div>
      </div>
      {isRequired?<>
        <div className="w-100 d-flex justify-content-between align-items-center">
            <div><label>{t("Validity Period")}</label></div>
            <div><input type="number" {...bindValidity} className="form-control input-text tv-select-order" onKeyPress={(e) => {
                        restrictAlphabets(e);
                      }}/></div>
          </div>
      </>:<></>}
        <Row className="mt-4">
          <Col xl={12}>
          <div className="tv-string d-flex justify-content-between">
            <div className="w-100">{JSON.stringify(tvAlertString)}</div>
            <div><span className="alert-copy" onClick={()=>clickCopy("checksum")}><i className={`far fa-copy`} ></i></span></div>
          </div>
          </Col>
        </Row>
       <hr></hr>
        <Form.Group>
            {t("tv-indicator-note")}
        </Form.Group>
        </Col>
        <Row>
          <Col xl={12}>

          <div className="w-100 d-flex justify-content-around">
            <Button onClick={()=>onHide()} variant="" className="save-cancel-btn">
            {t("cancel")}
            </Button>
            <Button onClick={()=>saveTvAlert()} variant="" className="save-cancel-btn">
              {t("save")}
              </Button>
          </div>
          </Col>
          
        </Row>
        
        
        
      </div>
    </ModalContainer>
  );
};

export default TvAlertSettingModal;
