import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { formatDate, milisecOf, typeNameOrder } from "../../../utils/Utils";
import CustomPagination from "../../common/CustomPagination";

const TradeTab = (props) => {
  const { deals,startItem } = props;
  const itemsPageNum = 2;
  //const [startItem, setStartItem] = useState(0);
  
  deals.forEach((item) => {
    item.closed_orders.sort((order1, order2) => {
      return milisecOf(order1.date) - milisecOf(order2.date);
    });
  });
  deals.sort((item1, item2) => {
    return (
      milisecOf(item2.closed_orders[0]&&item2.closed_orders[0].date) -
      milisecOf(item1.closed_orders[0]&&item1.closed_orders[0].date)
    );
  });
  // const paginate = (selectedExchangeId,itemsCountPerPage,num) => {
  //   setStartItem(num)
  // }
  //const pageItems = deals.slice(startItem, startItem + itemsPageNum);
  const pageItems = deals;
  const tradeItems = pageItems.map((item, index) => {
    const orderNum = item.closed_orders.length;
    return item.closed_orders.map((order, id) => {
      return (
        <tr key={index + " - " + id}>
          {id == 0 ? (
            <td rowSpan={orderNum}>{startItem + index }</td>
          ) : (
            <></>
          )}
          <td>
            {formatDate(order.date, "YYYY-MM-DD HH:mm:ss", "DD MMM YYYY HH:mm")}
          </td>
          <td>{typeNameOrder(order.name)}</td>
          <td>{order.description}</td>
        </tr>
      );
    });
  });
  return (
    <div className="mt-4 bot-tab">
    <div className="" >
          {/* <h2>Deals in Progress</h2> */}
          <br/>
          <div className="deals-table">
              <table className="">
                  <tbody>
                      <tr valign="top">
                      <th>Date</th>
                      <th>Type</th>
                      <th>Detail</th>
                      </tr>
                      {tradeItems}
                  </tbody>
              </table>
          </div>
    </div>
    
  </div>
  );
};

export default TradeTab;
