import React, { useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import NoData from "../common/NoData";
import { getExchanges, updateTransactionFee } from "../../actions/setting-action";
import { toast } from "react-toastify";
import { restrictAlphabets } from "../../utils/Utils";

const TransactionFee = (props)=>{
    const {updateTransactionFee,getExchanges} = props
    const {t} = useTranslation()
    const exchanges = useSelector((state) => state.settings.exchanges) || [];
    const exchangesWithFees = exchanges.filter((item) => item.is_connected && item.id != 0).map((item)=>{
        return(
          {
            name:item.name,
            exchangeId:item.id,
            unit:item.transaction_fee.unit?item.transaction_fee.unit:"net",
            fee:item.transaction_fee.fee?item.transaction_fee.fee:"",
            changed:false
          }
        )
      })
    
    const [connectedExchangesFeeItems,setItemsFee ] = useState(exchangesWithFees)
    const connectedItemsTransactionFees = connectedExchangesFeeItems && connectedExchangesFeeItems.map((item, index) => {
    return (
        <tr  key={index}>
        <td className="text-left">
            <img src={item.image} height={20} className="mr-2" />
            {item.name}
        </td>
        
        <td  className="text-truncate">
            <>
            <div className="d-flex justify-content-between align-items-center">
            <div><select
                    as="select"
                    onChange={(e) => handleChange(item.exchangeId,"unit",e.target.value,index)}
                    value={item.unit??"net"}
                    className="form-control select-normal"
                >
                    <option value="net">$</option>
                    <option value={'percentage'}>%</option>
            </select></div>
            <div><input type="text" className="form-control input-text" onChange={(e) => handleChange(item.exchangeId,"fee",e.target.value,index)} value={item.fee}  onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}></input></div>
            </div>
            
            </>
        </td>
        </tr>
    );
    });
    const handleChange = (exchangeId, type, value, index) => {
        const updatedItems = connectedExchangesFeeItems.map((item) => {
          if (item.exchangeId === exchangeId) {
            return {
              ...item,
              [type]: value,
              changed:true
            };
          }
          return item;
        });
        setItemsFee(updatedItems);
      };
    const clickSubmit = ()=>{
       connectedExchangesFeeItems.filter((item)=>item.changed).map((item)=>{
        let param = { name: "transaction_fee",value: item.exchangeId,value2:item.unit,value3:item.fee}
        updateTransactionFee(param).then((res)=>{
            getExchanges()
            toast.success("updated")
        })
       }) 
    }
    console.log(connectedExchangesFeeItems)
    return(
        <Col xl={6}>
          <h5>{t("Exchange Transaction Fee")}</h5>
            <div className="mt-4 bot-tab">
              <div className="">
                    <div className="deals-table">
                        <table className="">
                            <tbody>
                                <tr valign="top">
                                    <th>{t("exchange")}</th>
                                    <th>{t("Transaction Fee")}</th>
                                </tr>
                                {connectedItemsTransactionFees}
                            </tbody>
                        </table>
                        
                        <NoData count={connectedItemsTransactionFees.length} />
                        {connectedItemsTransactionFees.length > 0 && 
                        <div className="text-center mt-2">
                        <button type="button"  className="btn blue-btn" onClick={()=>clickSubmit()}>SUBMIT</button>
                        </div>}
                  </div>
                  
              </div>
              
            </div>
        </Col>
    )
}

export default connect(null,{updateTransactionFee,getExchanges})(TransactionFee)