import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const MenuItems = (props)=>{
    const {side} = props;
    const breaker = side?'':<br/>
    const isActive = (route) => {
        return route == props.url ? "active" : "inactive";
      };
    const isFuture = useSelector((state)=>state.settings.isFuture)||false;
    return (
        <>
            {/* <li className="top-menu-li"><Link to="/dashboard" className={isActive("dashboard")}><img src="/img/menu-home.svg" alt=""/>{breaker}<span>Dashboard</span></Link></li> */}
            <li className="top-menu-li"><Link to="/bots"  className={isActive("bots")}><img src="/img/menu-bot.svg" alt=""/>{breaker}<span>Bots</span></Link></li>
            {/* <li className="top-menu-li"><Link to="/deals" className={isActive("deals")}><img src="/img/menu-deals.svg" alt=""/>{breaker}<span>Deals</span></Link></li>
            <li className="top-menu-li"><Link to="/portfolio" className={isActive("portfolio")}><img src="/img/menu-portfolio.svg" alt=""/>{breaker}<span>Portfolio</span></Link></li>
            <li className="top-menu-li"><Link to="/marketplace" className={isActive("marketplace")}><img src="/img/menu-marketplace.svg" alt=""/>{breaker}<span>Marketplace</span></Link></li> */}
            {/* <li className="top-menu-li"><Link to="/price-alert" className={isActive("price-alert")}><img src="/img/menu-pricealert.svg" alt=""/>{breaker}<span>Price Alert</span></Link></li> */}
            {/* <li className="top-menu-li"><Link to="/affiliate" className={isActive("affiliate")}><img src="/img/menu-affiliate.svg" alt=""/>{breaker}<span>Affiliate</span></Link></li> */}
            {/* <li className="top-menu-li"><a href="https://blog.stockhero.ai/" target="_blank" className={isActive("latest")}><img src="/img/menu-latest.svg" alt=""/>{breaker}<span>Latest</span></a></li>
            <li className="top-menu-li"><Link to="/artificial-intelligence" className={isActive("artificial-intelligence")}><img src="/img/ai.svg" alt=""/>{breaker}<span>AI</span></Link></li> */}
        </>
    )
}

export default MenuItems;