import React, { useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, InputGroup, Row, Col } from "react-bootstrap";

import TutorialPopup from "../../help/TutorialPopup";
import * as HelpContent from "../../../utils/HelpContent";
import { CHANGE_FUND, CHANGE_ORDER_TYPE, CHANGE_ORDER_TYPE_EXIT, SET_TRADE_EXTENDED_HOURS } from "../../../actions/create-bot-type";
import {  isLong, isMarginShort, restrictAlphabets } from "../../../utils/Utils";
import { formatNumberToString } from "../../../utils/Utils";
import NormalParamsView from "./normal-bot/NormalParamsView";
import { BotType, CreateBotModules, OrderTypeModule } from "../../../Const";
import DcaParamsView from "./dca-bot/DcaParamsView";
import PriceBotParamsView from "./price-bot/PriceBotParamsView";
import BooleanView from "./BooleanView";
import SellBotParamsView from "./sell-bot/SellBotParamsView";
import QuickStartBotParamsView from "./quick_start/QuickStartBotParamsView";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ExtendedTradingHours from "./modules/ExtendedTradingHours";
import TwentyFourHoursTrading from "./modules/TwentyFourHoursTrading";
import DisableFractionalPurchase from "./modules/DisableFractionalPurchase";

const TradeParamsView = (props) => {
  const { newBot, editing,t } = props;
  const fund = newBot.initFund;
  const isSellBot = newBot.botType == BotType.sell
  const strategy = isSellBot?"Short":newBot.strategy
  const selectedExchange = newBot.exchange;
  const currentCoin = (isMarginShort(strategy)|| isLong(strategy))
    ? newBot.quoteCoin
    : newBot.baseCoin;
  const balances = useSelector((state) => state.bots.balances);
  const pairData = useSelector((state) => state.bots.pairData);
  const balance =
    balances && balances.find((item) => item.coin === currentCoin);
  const dispatch = useDispatch();
  const getParamsView = ()=>{
    switch (newBot.botType) {
      case BotType.dca:
        return <DcaParamsView newBot={newBot} currentCoin={currentCoin} t={t}/>
      case BotType.price :
        return <PriceBotParamsView newBot={newBot} t={t}/>
      case BotType.sell :
        return <SellBotParamsView newBot={newBot} t={t}/>
      case BotType.quick_start :
      return <QuickStartBotParamsView newBot={newBot} t={t}/>
      default:
        return <NormalParamsView newBot={newBot} t={t} />
    }
  }
  useEffect(() => {
    if (balance && !editing) {
      //fundChanged(balance.free);
    }
  }, [balance]);

  const fundChanged = (val) => {
    dispatch({
      type: CHANGE_FUND,
      payload: val,
    });
  };
  const orderTypeChanged = (orderType) => {
    dispatch({
      type: CHANGE_ORDER_TYPE,
      payload: orderType,
    });
  };
  const exitOrderTypeChanged = (orderType) => {
    dispatch({
      type: CHANGE_ORDER_TYPE_EXIT,
      payload: orderType,
    });
  };
  

  return (
    <>
      
          {!newBot.dcaNoLimitFund   && (
            <>
            <Row className="d-flex">
              <Col xl={4} className="cb-form-label">
              <Form.Label>
              { isSellBot?t("amount-to-sell"):<>{t("fund-alloc")}<TutorialPopup content={t("initFund-help")} /></> }
                
              </Form.Label>
              </Col>
              <Col xl={8}>
              <label data-text={currentCoin} className="input-gp-label">
                  <Form.Control
                      as="input"
                      type="number"
                      placeholder="1000"
                      value={fund}
                      onChange={(e) => {
                        fundChanged(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        restrictAlphabets(e);
                      }}
                      className="input-text input-gp-text"
                    ></Form.Control>
                </label>
                    
                    <p style={{ fontSize: 13, marginTop: 10 }}>
                    {pairData && pairData.native_coin !='USD' ? <>{t("available")}: {formatNumberToString(balance && balance.free,2)}{" "} 
                    {currentCoin} {", "} {formatNumberToString(pairData && pairData.native_balance)}{" "} {pairData && pairData.native_coin} (~{pairData && (pairData.native_balance * pairData.native_quote_rate)} {" "} USD)</>:<>{t("available")}: {formatNumberToString(balance && balance.free,2)}{" "} 
                    {currentCoin} {" "}</>}  {selectedExchange.id==0 &&  <Link to="/setting?loc=5"><i className="fas fa-sliders-h" style={{fontSize:"15px",marginLeft:"10px"}}></i></Link>}
                  </p>
              </Col>
          </Row>
            </> )}
            {(OrderTypeModule[newBot.botType]) && (
              <>
            <div style={{ marginTop: 20 }}>
            <BooleanView
                title={t("entry-order-type")}
                firstButton="limit"
                secondButton="market"
                selected={newBot.tradeOrderType}
                onSelect={orderTypeChanged}
                tutorial={<TutorialPopup content={t("orderType")} />}
              />
           
            </div>
            <div style={{ marginTop: 20 }}>
               <BooleanView
                title={t("exit-order-type")}
                firstButton="limit"
                secondButton="market"
                selected={newBot.tradeOrderTypeExit}
                onSelect={exitOrderTypeChanged}
                tutorial={<TutorialPopup content={t("orderType")} />}
              />
            </div>
            </>
            )}
            {getParamsView()}
            <br></br>
           <ExtendedTradingHours/>
           {newBot.is247?
           <TwentyFourHoursTrading></TwentyFourHoursTrading>:<></>
           }
           {newBot.botType != BotType.simple ? <DisableFractionalPurchase></DisableFractionalPurchase>:<></>}
    </>
  );
};

export default connect(null)(TradeParamsView);
