import React from "react";
import { Card, Accordion, Form } from "react-bootstrap";
import TutorialPopup from "../../../help/TutorialPopup";
import * as HelpContent from "../../../../utils/HelpContent";
import { DcaType, DcaTypeName } from "../../../../Const";
import DcaTimeEntry from "../dca-bot/DcaTimeEntry";
import DcaIndicatorEntry from "../dca-bot/DcaIndicatorEntry";
import DcaPercentEntry from "../dca-bot/DcaPercentEntry";
import { useDispatch } from "react-redux";
import { CHANGE_DCA_TYPE, CHANGE_FREQUENCY } from "../../../../actions/create-bot-type";
import "./DcaEntryView.scss";
import LeftRightForm from "../modules/LeftRightForm";

const DcaEntryView = (props) => {
  const { newBot ,t} = props;

  const dispatch = useDispatch();
  const changeDcaType = (type) =>{
    dispatch({
      type: CHANGE_DCA_TYPE,
      payload: type,
    });
    if(type=="buy_down" || type=="sell_up"){
      dispatch({
        type: CHANGE_FREQUENCY,
        payload: 5,
      });
    }
    
  }
   

  const options = Object.keys(DcaType).map((el, id) => (
    <option key={id} value={DcaType[el]}>
      {DcaTypeName[el]}
    </option>
  ));

  let entryComp;
  switch (newBot.dcaType) {
    case DcaType.time:
      entryComp = <DcaTimeEntry newBot={newBot} t={t}/>;
      break;
    case DcaType.indicator:
      entryComp = <DcaIndicatorEntry newBot={newBot} isEntry={true} t={t}/>;
      break;
    case DcaType.buyDown:
      entryComp = <DcaPercentEntry newBot={newBot} isBuy={true} t={t}/>;
      break;
    case DcaType.sellUp:
      entryComp = <DcaPercentEntry newBot={newBot} isBuy={false} t={t}/>;
      break;
    default:
      break;
  }
  return (
    <>
        <div>
          <LeftRightForm 
            left={<Form.Label>{t("sel-entry-type")}</Form.Label>}
            right={ <><select
              value={newBot.dcaType}
              onChange={(e) => changeDcaType(e.target.value)}
              className="form-control select-normal dca-entry-select mb-4"
            >
              {options}
            </select>
            </>
          }
          ></LeftRightForm>
          {entryComp}
        </div>
        
    </>
  );
};

export default DcaEntryView;
