import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { NEW_USER_INTRO_POPUP } from "../../../actions/types";
import CustomCheckbox from "../../bots/new-bot/modules/CustomCheckbox";
import { useTranslation } from "react-i18next";
import { hideIntroPopup } from "../../../actions/setting-action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Tab4 = (props)=>{
    const {hideIntroPopup} = props
    const dispatch = useDispatch()
    const {t}= useTranslation()
    const takeTour = useSelector((state) => state.settings.takeTour);
    //const [dontShowAgain,setDontShowAgain] = useState(1);
    const history = useHistory()
    const exitWizard = ()=>{
        history.push("/bots");
        dispatch({
          type:NEW_USER_INTRO_POPUP,
          payload:0
        })
        hideIntroPopup(0)
      }
    
    return(<>
       <div className="tab qs-tab-div">
            <Row className="qs-tab-des-div">
                <Col className="text-left">
                    <h3>Step 4: Success</h3>
                    <br/>
                    <p>Congratulations! You have successfully created your very first bot. You can find this bot in the Bots page under the Active tab. You can view or edit the bot’s settings as well.</p>
                    <br/>
                    <p>You can also visit our Bots Marketplace to deploy proven strategies setup by other experienced users.</p>
                    <br/>
                    <br/><br/>
                </Col>
            </Row>
            <Row className="qs-tab-button-div">
                <Col xl={6} className="mt-2">
                    <Link to="/marketplace"><button type="button" id="prevBtn" className="btn full-btn blue-btn" >Go to Bots Marketplace</button></Link>
                </Col>
                <Col xl={6} className="mt-2">
                    <button type="button" id="nextBtn" className="btn full-btn" onClick={()=>exitWizard()} >Exit Quick Start</button>
                   {/* <div className="mt-4 ml-2">
                    <CustomCheckbox 
                            val={dontShowAgain} 
                            checked={dontShowAgain} 
                            callback={clickDontShowAgain}
                            label={t("Do not show Quick Start again")}
                            id={'d'}
                            >
                        </CustomCheckbox>
                   </div> */}
                    
                </Col>
            </Row>
          
        </div>
    </>)
}
export default connect(null,{hideIntroPopup}) (Tab4);