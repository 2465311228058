import React from "react";
import CustomCheckbox from "./CustomCheckbox";
import { SET_TRADE_EXTENDED_HOURS, SET_TRADE_EXTENDED_HOURS_RENT } from "../../../../actions/create-bot-type";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isPro } from "../../../../utils/SubUtils";
import { eligibleExtendedHours } from "../../../../utils/Utils";
import LeftRightForm from "./LeftRightForm";

const ExtendedTradingHours = (props)=>{
    const {rent} = props
    const newBot = useSelector((state) => state.createBot.newBot);
    const rentBot = useSelector((state) => state.createBot.rentBot);
    const {t} = useTranslation()
    const selectedExchange = newBot.exchange;
    const subscription = useSelector((state) => state.auth.user.user_subscription) || {};
    const extendedTradingHour = parseInt(rent?rentBot.extendedTradingHour:newBot.extendedTradingHour)?1:0;
    const dispatch = useDispatch()
    const setExtededTradeHours = (val) => {
        dispatch({
          type: rent?SET_TRADE_EXTENDED_HOURS_RENT:SET_TRADE_EXTENDED_HOURS,
          payload: val?1:0,
        });
      };
    return(<>
        {(isPro(subscription) && eligibleExtendedHours(selectedExchange)) && 
        
            <LeftRightForm 
            left={<></>}
            right={ <CustomCheckbox 
                val={extendedTradingHour} 
                checked={extendedTradingHour} 
                callback={setExtededTradeHours}
                label={t("trade-extended-hours")}
                id={'trade-extended-hours'}
                >
                </CustomCheckbox>}
            >
            </LeftRightForm>
        
           }
    </>)
}

export default ExtendedTradingHours;