import React from "react";
import { Form, Button } from "react-bootstrap";

const LitePlanDetails = (props) => {
  const {t} = props;

  return (
    <>
        <h6 className="text-row">
            <i className="fas fa-check item-check"></i>{t("free-info-1")}
          </h6>
          <h6 className=" text-row">
            <i className="fas fa-check item-check"></i>{t("free-info-2")}
          </h6>
          <h6 className=" text-row">
            <i className="fas fa-check item-check"></i>{t("free-info-3")}
          </h6>
          <h6 className=" text-row">
            <i className="fas fa-check item-check"></i>{t("free-info-4")}
          </h6>
          {/* <h6 className="promotion-text">
            <a href="https://www.stockhero.ai/pricing" target="_blank">{t("free-info-5")}</a>
         </h6> */}
    </>
  );
};

export default LitePlanDetails;
