import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Card,
} from "react-bootstrap";
import { signUp, resetSignUp } from "../../actions/auth-action";
import { Link, Redirect } from "react-router-dom";
import "./SignUp.scss";
import useInput from "../hooks/useInput";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Header from "../common/HeaderGuest";
import Footer from "../common/Footer";
import { t } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import HeaderGuest from "../common/HeaderGuest";
import GuestContainer from "./GuestContainer";

const SignUp = (props) => {
  const { signUp, resetSignUp } = props;
  const { search } = useLocation();
  const query = queryString.parse(search);
  const {t} = useTranslation()

  const signUpError = useSelector((state) => state.auth.signUpError);
  const signUpRedirect = useSelector((state) => state.auth.signUpRedirect);
  const signUpStart = useSelector((state) => state.auth.signUpStart);
  const signUpSuccess = useSelector((state) => state.auth.signUpSuccess);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [referralCode, bindReferralCode] = useState(query.ref || "");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    resetSignUp();
    return () => {
      resetSignUp();
    };
  }, []);

  const handleLength = (callback,value,maxLength)=>{
    return callback(value.slice(0, maxLength));
  }

  const clickSignUp = (e) => {
    e.preventDefault();
    signUp({
      first_name: firstName,
      email,
      password,
      password_confirmation: confirmPass,
      referral_code: referralCode,
      from: "web",
    });
  };
  if (signUpRedirect) {
    return <Redirect to="/verify-otp" />;
  }
  return (
    <>
     <GuestContainer>

     <Row className="justify-content-center auth-panel">
          <Col className="auth-panel-content">
            <div className="signup-panel text-center">
              <img src="/images/named-logo.png"  className="mb-3 auth-logo"></img>
              <h6 className="header-text">{t("create-new-acc")}</h6>
              <Form>
                <Form.Group controlId="formBasicEmail" className="ch-form pos-relative mt-4">
                <img src="/images/user-icon.png"  className="input-icon"></img>
                  <input
                    type="input"
                    placeholder={t("first-name")}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    onKeyDown={(e)=>handleLength(setFirstName,e.target.value,100)}
                    className="form-input input-with-icon auth-input"
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail" className="ch-form pos-relative mt-4">
                <img src="/images/email-icon.png"  className="input-icon"></img>
                  <input
                    type="email"
                    placeholder={t("email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e)=>handleLength(setEmail,e.target.value,200)}
                    className="form-input input-with-icon auth-input"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="ch-form pos-relative">
                  <img src="/images/password-icon.png"  className="input-icon"></img>
                  <input
                    type="password"
                    placeholder={t("Password")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e)=>handleLength(setPassword,e.target.value,50)}
                    className="form-input input-with-icon password-bg auth-input"
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className="ch-form pos-relative">
                  <img src="/images/password-icon.png"  className="input-icon"></img>
                  <input
                    type="password"
                    placeholder={t("re-pass")}
                    value={confirmPass}
                    onChange={(e) => setConfirmPass(e.target.value)}
                    onKeyDown={(e)=>handleLength(setConfirmPass,e.target.value,50)}
                    className="form-input input-with-icon password-bg auth-input"
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className="ch-form pos-relative">
                  <img src="/images/referral-icon.png"  className="input-icon"></img>
                  <input
                    type="input"
                    placeholder={t("ref-code")}
                    value={referralCode}
                    onChange={(e) => bindReferralCode(e.target.value)}
                    disabled={query.ref != null}
                    onKeyDown={(e)=>handleLength(bindReferralCode,e.target.value,10)}
                    className="form-input input-with-icon password-bg auth-input"
                  />
                </Form.Group>
               
                <input type="checkbox" id="myCheckbox" className="custom-checkbox"  
                  value={checked}
                  onChange={(e) => setChecked(e.target.checked)}>
                </input>
                <label for="myCheckbox" className="checkbox-label">{
                    <p>
                      <Trans i18nKey={"i-agree"}>
                      <a
                        href="https://www.stockhero.ai/terms.html"
                        target="_blank"
                      >
                      </a>
                      </Trans>
                     
                      
                    </p>
                  }</label>
               
                <div className="my-3">
                  <button type="submit" onClick={clickSignUp} className={"btn blue-btn capitalize"} disabled={!checked}>
                  {signUpStart ? "SUBMITTING..." : "SIGN UP"}
                  
                  </button>
                </div>
                <Alert
                  show={signUpError != null || signUpSuccess}
                  variant={signUpSuccess ? "success" : "danger"}
                  className="mt-2"
                >
                 {signUpError || t("acc-created-msg")}
                </Alert>
              </Form>
              <div className="text-center mt-2 capitalize"></div>
              

              <div className="text-center mt-4 mb-4">
                
              <span className="mr-3 mb-4">{t("have-acc")}</span>
                <Link to="/login" className="link-text">{t("login")}</Link>
              </div>
            </div>
          </Col>
        </Row>
     </GuestContainer>
    </>
  );
};

export default connect(null, { signUp, resetSignUp })(SignUp);
