import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  getBots,
  getIndicators,
  getRentalBots,
  getSubscription
} from "../../actions/bot-action";

import "./Bots.scss";

import ContentContainer from "../common/ContentContainer";

import { getBotDetail } from "../../actions/bot-action";

import {
  resetBotConfig,
  updateEditBotConfig,
  updatePresetConfig,
  updateCopyBotConfig,
  updateEditRentBotConfig
} from "../../actions/create-bot-action";

import { useTranslation } from "react-i18next";
import BotList from "./BotList";
import BotPageOverview from "./BotPageOverview";

const Bots = (props) => {
  const {
    getSubscription,
  } = props;
  const { t } = useTranslation()

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <div>
      <ContentContainer url="bots" title={t("bots")} showChat={true}>
      <div className="dashboard-content">
        {/* <BotPageOverview t={t}></BotPageOverview> */}
        <BotList t={t}></BotList>
      </div>
      </ContentContainer>
    </div>
  );
};

export default connect(null, {
  getBots,
  getSubscription,
  updateEditBotConfig,
  updateEditRentBotConfig,
  resetBotConfig,
  updatePresetConfig,
  getIndicators,
  updateCopyBotConfig,
  getBotDetail,
  getRentalBots
})(Bots);
