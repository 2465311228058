import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Footer.scss";

const Footer = () => {
  const {t} = useTranslation()
  return (
    <footer>
      <div className="footer-left">
        <div className="footer-logo"><a href="/"><img src="/img/logo.png" alt="" width={50}/>StockHero</a></div>
        <div className="footer-divider"></div>
        <div className="footer-slogan">Start Trading with Bots Without Coding</div>    
        <div></div> 
      </div>
      <div className="copyright">© All Rights Reserved by Novum Global Ventures</div>
      <div></div>
    </footer>
  );
};

export default Footer;
