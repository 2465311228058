// Auth
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_IN_START = "SIGN_IN_START";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";
export const SIGN_UP_START = "SIGN_UP_START";
export const SIGN_UP_REDIRECT = "SIGN_UP_REDIRECT";
export const RESET_SIGNUP = "RESET_SIGNUP";
export const RESET_SIGNIN = "RESET_SIGNIN";
export const UPDATE_PASS_SUCCESS = "UPDATE_PASS_SUCCESS";
export const UPDATE_PASS_ERROR = "UPDATE_PASS_ERROR";
export const UPDATE_PASS_START = "UPDATE_PASS_START";
export const UPDATE_PASS_RESET = "UPDATE_PASS_RESET";
export const GET_OTP_START = "GET_OTP_START";
export const GET_OTP_SUCCESS = "GET_OTP_SUCCESS";
export const GET_OTP_ERROR = "GET_OTP_ERROR";
export const GET_OTP_RESET = "GET_OTP_RESET";
export const RESET_PASS_START = "RESET_PASS_START";
export const RESET_PASS_SUCCESS = "RESET_PASS_SUCCESS";
export const RESET_PASS_ERROR = "RESET_PASS_ERROR";
export const RESET_PASS_RESET = "RESET_PASS_RESET";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const GA_CONFIRM = "GA_CONFIRM";

// Bot
export const BOT_LIST = "BOT_LIST";
export const BOT_DETAIL = "BOT_DETAIL";
export const RENT_BOT_DETAIL = "RENT_BOT_DETAIL";
export const ACTIVE_BOT = "ACTIVE_BOT";
export const BOT_PROFIT = "BOT_PROFIT";
export const HIDE_GRAPH = "HIDE_GRAPH";

export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_BOT_CONFIGS = "GET_BOT_CONFIGS";
export const GET_PAIRS = "GET_PAIRS";
export const GET_INDICATORS = "GET_INDICATORS";
export const GET_BALANCE = "GET_BALANCE";
export const GET_ACCOUNT_PAIR = "GET_ACCOUNT_PAIR";
export const GET_BACKTEST_SUCCESS = "GET_BACKTEST";

export const GET_PORTFOLIO = "GET_PORTFOLIO";
export const PORTFOLIO_DETAIL = "PORTFOLIO_DETAIL";

export const GET_DASHBOARD = "GET_DASHBOARD";
export const DASHBOARD_ASSETS = "DASHBOARD_ASSETS";
export const GET_TOP_BOTS = "GET_TOP_BOTS";
export const GET_DAILY_PNL = "GET_DAILY_PNL";

export const GET_DEALS = "GET_DEALS";
export const GET_DEAL_TRADES = "GET_DEAL_TRADES";
export const DEAL_DETAIL = "DEAL_DETAIL";
export const CLOSE_DEAL_SUCCESS = "CLOSE_DEAL_SUCCESS";
export const CLOSE_DEAL_ERROR = "CLOSE_DEAL_ERROR";
export const START_CLOSE_DEAL = "START_CLOSE_DEAL";
export const RESET_DEAL_DETAIL = "RESET_DEAL_DETAIL";

export const GET_DISCOVER = "GET_DISCOVER";

// Settings
export const GET_EXCHANGES = "GET_EXCHANGES";
export const ADD_EXCHANGE_START = "ADD_EXCHANGE_START";
export const ADD_EXCHANGE_SUCCESS = "ADD_EXCHANGE_SUCCESS";
export const ADD_EXCHANGE_ERROR = "ADD_EXCHANGE_ERROR";
export const ADD_EXCHANGE_RESET = "ADD_EXCHANGE_RESET";
export const DELETE_EXCHANGE_SUCCESS = "DELETE_EXCHANGE_SUCCESS";
export const DELETE_EXCHANGE_ERROR = "DELETE_EXCHANGE_ERROR";
export const GET_PAPER_SETTING = "GET_PAPER_SETTING";
export const UPDATE_PAPER_START = "UPDATE_PAPER_START";
export const UPDATE_PAPER_SUCCESS = "UPDATE_PAPER_SUCCESS";
export const UPDATE_PAPER_ERROR = "UPDATE_PAPER_ERROR";
export const UPDATE_PAPER_RESET = "UPDATE_PAPER_RESET";
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_INTRO_TOUR_SETTING = "GET_INTRO_TOUR_SETTING";
export const GET_CURRENCIES = "GET_CURRENCIES";
export const UPDATE_CURRENCY_SUCCESS = "UPDATE_CURRENCY_SUCCESS";
export const SELECT_EXCHANGE_ID = "SELECT_EXCHANGE_ID";
export const GET_FILTERS = "GET_FILTERS";
export const CHANGE_TIMEZONE = "CHANGE_TIMEZONE";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const GET_USER_REFERRALS = "GET_USER_REFERRALS";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
export const GET_GA_CODE = "GET_GA_CODE";
export const UPDATE_GA_SETTING = "UPDATE_GA_SETTING";
export const SELECT_EXCHANGE = "SELECT_EXCHANGE";
export const UPDATE_USER_PROFILE_START = "UPDATE_USER_PROFILE_START";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const SELECTED_THEME = "SELECTED_THEME";
export const TAKE_TOUR = "TAKE_TOUR";
export const TAKE_TOUR_START = "TAKE_TOUR";
export const TAKE_TOUR_END = "TAKE_TOUR_END";
export const SET_JOYRIDE_STEP = "SET_JOYRIDE_STEP";
export const SET_TOUR_SIMPLE_BOT_WINDOW = "SET_TOUR_SIMPLE_BOT_WINDOW";
export const CLOSE_TOUR_SIMPLE_BOT_WINDOW = "CLOSE_TOUR_SIMPLE_BOT_WINDOW";
export const NEW_USER_INTRO_POPUP = "NEW_USER_INTRO_POPUP";
export const APP_LANGUAGE = "APP_LANGUAGE";

// Terminal
export const GET_TICKERS = "GET_TICKERS";
export const SELECT_PAIR = "SELECT_PAIR";
export const CHANGE_ACCOUNT = "CHANGE_ACCOUNT";
export const RESET_TICKERS = "RESET_TICKERS";

export const TOGGLE_NAV = "TOGGLE_NAV";
export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ORDER_LIST_OPEN = "GET_ORDER_LIST_OPEN";
export const GET_POSITIONS = "GET_POSITIONS";
export const GET_ORDER_HISTORY = "GET_ORDER_HISTORY";
export const GET_SPOT = "GET_SPOT";
export const GET_CROSS = "GET_CROSS";
export const GET_ISOLATED = "GET_ISOLATED";
export const GET_PAPER_WALLET = "GET_PAPER_WALLET";
export const GET_EXECUTED_ORDERS = "GET_EXECUTED_ORDERS";

// Data
export const GET_DATA_EXCHANGE = "GET_DATA_EXCHANGE";
export const GET_DATA_SYMBOL = "GET_DATA_SYMBOL";
export const GET_DATA_CURRENCIES = "GET_DATA_CURRENCIES";

// Affiliate
export const GET_AFFILIATE_RANK = "GET_AFFILIATE_RANK";
export const GET_AFFILIATE_REWARD_HISTORY = "GET_AFFILIATE_REWARD_HISTORY";
export const GET_AFFILIATE_POINT_HISTORY = "GET_AFFILIATE_POINT_HISTORY";
export const GET_AFFILIATE_REWARD_REDEEM_HISTORY =
  "GET_AFFILIATE_REWARD_REDEEM_HISTORY";
export const GET_AFFILIATE_SOCIAL_POSTS = "GET_AFFILIATE_SOCIAL_POSTS";
export const GET_AFFILIATE_POINT_REDEEM_HISTORY =
  "GET_AFFILIATE_POINT_REDEEM_HISTORY";


//Marketplace

export const SET_LIBRARY_IMAGE = "SET_LIBRARY_IMAGE";
export const SET_UPLOADED_IMAGE = "SET_UPLOADED_IMAGE";
export const SELECT_LIBRARY_IMAGE = "SELECT_LIBRARY_IMAGE";
export const SET_DISPLAY_TEMPLATE_IMAGE = "SET_DISPLAY_TEMPLATE_IMAGE";
export const GET_RENT_BOTS_AVAILABLE = "GET_RENT_BOTS_AVAILABLE";
export const SET_PREVIEW_UPLOAD_IMAGE = "SET_PREVIEW_UPLOAD_IMAGE";
export const RESET_TEMPLATE_MODAL = "RESET_TEMPLATE_MODAL";
export const SET_BOT_SELECTED = "SET_BOT_SELECTED";
export const BOT_TEMPLATE_TITLE="BOT_TEMPLATE_TITLE"
export const BOT_TEMPLATE_DESCRIPTION="BOT_TEMPLATE_DESCRIPTION"
export const BOT_SUBSCRIPTION_MONTHLY="BOT_SUBSCRIPTION_MONTHLY"
export const BOT_SUBSCRIPTION_QUARTERLY="BOT_SUBSCRIPTION_QUARTERLY"
export const BOT_SUBSCRIPTION_HALFYEARLY="BOT_SUBSCRIPTION_HALFYEARLY"
export const BOT_SUBSCRIPTION_ANNUAL="BOT_SUBSCRIPTION_ANNUAL"
export const BOT_SUBSCRIPTION_ONETIME="BOT_SUBSCRIPTION_ONETIME"
export const LIST_MARKETPLACE_TEMPLATES="LIST_MARKETPLACE_TEMPLATES"
export const SET_TEMPLATE_DETAIL="SET_TEMPLATE_DETAIL"
export const OWNER_TEMPLATES="OWNER_TEMPLATES"
export const TOP_TEMPLATES="TOP_TEMPLATES"
export const SHOW_IMAGE_MODAL="SHOW_IMAGE_MODAL"
export const SET_BOT_TEMPLATE_EDIT="SET_BOT_TEMPLATE_EDIT"
export const BOT_RENT_LIST="BOT_RENT_LIST"
export const SET_TEMPLATE_OWNER_DATA="SET_TEMPLATE_OWNER_DATA"
export const SUBSCRIBE_TEMPLATE="SUBSCRIBE_TEMPLATE"
export const GET_MARKETPLACE_SUMMARY="GET_MARKETPLACE_SUMMARY"
export const SET_PAYMENT_HISTORY="SET_PAYMENT_HISTORY"
export const SET_TUTORIAL_POPUP="SET_TUTORIAL_POPUP"

//Price alert
export const PRICE_ALERT_LIST= "PRICE_ALERT_LIST"
export const PRICE_ALERT_PAIR= "PRICE_ALERT_PAIR"

//Bot Modules
export const FUND_ALLOCATION="FUND_ALLOCATION"

export const SET_DAISY_CHAIN_BOTS = "SET_DAISY_CHAIN_BOTS";
export const CHANGE_RISK_FREE_RATE = "CHANGE_RISK_FREE_RATE";

export const SET_EXCHANGE_REDIRECT_URL = "SET_EXCHANGE_REDIRECT_URL";
export const SET_EXCHANGE_LOGIN_URL = "SET_EXCHANGE_LOGIN_URL";


//Pagination
export const BOT_PAGE_ACTIVE="BOT_PAGE_ACTIVE"
export const BOT_PAGE_INACTIVE="BOT_PAGE_INACTIVE"
export const BOT_PAGE_RENT="BOT_PAGE_RENT"
export const BOTS_ACTIVE_TAB="BOTS_ACTIVE_TAB"
export const BOTS_SORT="BOTS_SORT"
export const BOTS_ORDER_BY="BOTS_ORDER_BY"
export const DEALS_PAGE_OPEN="DEALS_PAGE_OPEN"
export const DEALS_PAGE_CLOSE="DEALS_PAGE_CLOSE"
export const DEALS_ACTIVE_TAB="DEALS_ACTIVE_TAB"
export const RESET_ALL_PAGINATION="RESET_ALL_PAGINATION"

//AI
export const AI_QUESTIONS_LIST = "AI_QUESTIONS_LIST"
export const AI_TRENDING_LIST = "AI_TRENDING_LIST"

//Popups

export const SHOW_TIGER_BROKER_MODAL="SHOW_TIGER_BROKER_MODAL"
