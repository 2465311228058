import React from "react";
import AffiliateLayout from "../common/AffliateLayout";
import "./ReferNow.scss";
import { connect, useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import SocialPost from "./SocialPost";
import SendEmail from "./SendEmail";
import { useTranslation } from "react-i18next";

const ReferNow = (props) => {
  const [setLoading, bindLoading] = useLoading();
  const {t} = useTranslation()
  const [setShowSocialPost, bindShowSocialPost] = useLoading(false);
  const code = useSelector((state) => state.auth.user.referral_code);
  const link = "https://pro.stockhero.ai/referral?ref=" + code;
  const shareMessage = t("aff-ref-now-1")

  const clickCopy = () => {
    copy(link);
    toast.success("Copied Link");
  };
  const copyCode = () => {
    copy(code);
    toast.success("Copied Code");
  };
  return (
    <Loading {...bindLoading}>
      <AffiliateLayout>
        <div className="refer-now">
          <div className="left">
            <p className="header1">{t("aff-ref-now-2")}</p>
            <p className="header2">{t("aff-ref-now-3")}</p>

            <div className="code-section">
              <div>
                <p className="header3">QR Code</p>
                <QRCode value={link} size={160} />
              </div>
              <div className="link">
                <button className="btn w-100" onClick={clickCopy}>
                {t("aff-ref-now-4")}
                </button>
                <button className="btn code" onClick={copyCode}>
                  {code}
                </button>
              </div>
            </div>
            <p className="frag2">{t("aff-ref-now-5")}</p>
            <div>
              <FacebookShareButton url={link} quote={shareMessage}>
                <span className="btn facebook">
                  <i className="fab fa-facebook social"></i>{t("aff-ref-now-6")}
                </span>
              </FacebookShareButton>
              <TwitterShareButton url={link} quote={shareMessage}>
                <span className="btn">
                  <i className="fab fa-twitter social"></i>{t("aff-ref-now-7")}
                </span>
              </TwitterShareButton>
            </div>
            <p className="frag3">{t("aff-ref-now-8")}
              
            </p>
            <button className="btn" onClick={() => setShowSocialPost(true)}>
            {t("aff-ref-now-9")}
            </button>
            <p className="frag3">{t("aff-ref-now-10")}
              
            </p>
            <SendEmail setLoading={setLoading} />
          </div>
          <div className="right">
            <img src="/images/pp2.png" width={400} />
          </div>
        </div>
        <SocialPost
          bindShowSocialPost={bindShowSocialPost}
          setLoading={setLoading}
        />
      </AffiliateLayout>
    </Loading>
  );
};

export default connect(null)(ReferNow);
