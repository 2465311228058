import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Modal, Row, Col, Button, Accordion, Form, Table } from "react-bootstrap";

import Loading from "../../common/Loading";
import useLoading from "../../hooks/useLoading";
import { decimalOf, formatDate, isLong, normalizeTime } from "../../../utils/Utils";
import { cancelRental } from "../../../actions/rent-bot-action";
import { toast } from "react-toastify";
import { getRentalBots } from "../../../actions/bot-action";
import { MaxBotPerRent } from "../../../Const";
import CancelRentalConfirm from "./CancelRentalConfirm";
import { Link } from "react-router-dom";
import RenewBotRentCryptoModal from "../subscription/RenewBotRentCryptoModal";




const RentBotViewDetail = (props) => {
  const {
    onHide,
    bot,
    cancelRental,
    getRentalBots,
    t
    
  } = props;
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const rent =  useSelector((state) => state.bots.rent) || {};
  const [setLoading, bindLoading] = useLoading(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showCryptoPayment, setShowCryptoPayment] = useState(false);
  const [network, setNetwork] = useState('ERC');

  const botItems= rent.bots.map((item,key)=>{
    return (
      <tr key={key}>
        <td>
          {key+1}
        </td>
        <td>
          {item.name}
        </td>
        <td><img src={item.exchange.image} width={20} height={20} className="mr-2"></img>{item.exchange.name}</td>
        <td>{item.pair.from +'/'+ item.pair.to}</td>
        <td> <span className={isLong(item.strategy) ? "long" : "short"}>{item.strategy}</span></td>
        <td>{item.status}</td>
      </tr>
    )
  })

  const dispatch = useDispatch();
  const clickDeleteRental = ()=>{
    setLoading(true)
    cancelRental(bot.id).then(()=>{
      setLoading(false)
      onHide()
      getRentalBots()
      toast.success("Subscription Cancelled!");
    }).catch((err)=>{
      setLoading(false)
      toast.error(err);
    })
  }

  return (
    <>
      <Modal show={true} onHide={onHide} size="xl" dialogClassName="modal-90w">
        
        <Modal.Body>
        <Modal.Header closeButton className="align-items-center">
        <Modal.Title>
           
           {bot.template.title}
          
        </Modal.Title>
        </Modal.Header>
        
        <Row>
          <Col lg={12}>
        <div className="mt-4 bot-tab">
          
         {/* <h2>{bot.template.title}</h2> */}
          <div className="" >
                <div className="deals-table">
                    <table className="">
                        <tbody>
                        <tr>
                          <th>{t("return")}</th>
                          <th>{t("w-l-ratio")}</th>
                          <th>{t("total-deals")}</th>
                          <th>{t("bots-created")}</th>
                          <th>{t("sub-type")}</th>
                          <th>{t("sub-fee")}</th>
                          <th>{t("p-method")}</th>
                          <th>{t("ex-date")}</th>
                          
                        </tr>
                      <tr>
                        <td>
                          {rent.avg_return && rent.avg_return.toFixed(3)}%
                        </td>
                        <td>{rent.wl_ratio}%</td>
                        <td>{rent.total_deal}</td>
                        
                        <td>
                          {rent.bots && rent.bots.length} of {MaxBotPerRent}
                        </td>
                        <td>
                          {rent.type}
                        </td>
                        <td>
                          US${rent.fee}
                        </td>
                        <td>
                          {rent.payment_method}
                        </td>
                        <td>
                          {formatDate(rent.end)}
                        </td>
                        
                      </tr>
                </tbody>
              </table>
              </div>
              </div>
            </div>
            </Col>
            </Row>

            <Row>
              <Col lg={12}>
              {(rent.bots && rent.bots.length > 0) && (<>
              <div className="mt-4 bot-tab">
          
              <h2>Bots From this Rental</h2>
                 <div className="deals-table">
                     <table className="">
                         <tbody>
            
                            <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Bot Name</th>
                            <th scope="col">Exchange</th>
                            <th scope="col">Pair</th>
                            <th scope="col">Strategy</th>
                            <th scope="col">Status</th>
                            </tr>
                          {botItems}
                        </tbody>
              </table>
              </div>
              </div>
              </>
            )}
              </Col>
            
            </Row>
            <Row className="my-4 justify-content-center">
              {(rent.payment_method=='usdt' && (rent.status=='renting' || rent.status=='expired') )&& 
            <Button variant="success" className="btn-primary mr-2" onClick={()=>setShowCryptoPayment(true)}>
             Renew
            </Button>}
              <Button
                variant="danger"
                onClick={()=>setShowConfirm(true)}
                className="mr-4"
              >
                Cancel Rental
              </Button>
            </Row>
        </Modal.Body>
      </Modal>
      <RenewBotRentCryptoModal
          show={showCryptoPayment}
          monthly={true}
          plan={rent.type}
          template={bot.template}
          rent={rent}
          onHide={() => setShowCryptoPayment(false)}
          // sendEmail={() => setShowContactForm(true)}
      />
      {showConfirm && <CancelRentalConfirm onHide={()=>setShowConfirm(false)} onConfirm={clickDeleteRental} bot={bot}></CancelRentalConfirm> }
      <Loading {...bindLoading} />
    </>
  );
};

export default connect(null, {cancelRental,getRentalBots
})(RentBotViewDetail);
