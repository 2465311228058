import React from "react";
import { useDispatch } from "react-redux";
import { UPDATE_FIXED_TP, UPDATE_FIXED_TSL } from "../../../../actions/create-bot-type";
import { isLong, restrictAlphabets } from "../../../../utils/Utils";
import { Col, Form, Row } from "react-bootstrap";

const FixedTP = (props) => {
  const { newBot } = props;
  const tps = newBot.fixedTP;
  const long  = isLong(newBot.strategy)
  const dispatch = useDispatch();
  if (!tps) {
    updateTrailingTP({ triggerAt: 2, stopAt: 1 });
  }
  const updateTrailingTP = (tps) => {
    dispatch({
      type: UPDATE_FIXED_TP,
      payload: tps,
    });
  };
  const trailing = tps || { triggerAt: 2, stopAt: 1 };
  const changeStopAt = (val) => {
    if(val<=100){
      updateTrailingTP({
        triggerAt: trailing.triggerAt,
        stopAt: val,
      });
    }
    
  };
  const changeTriggerAt = (val) => {
    updateTrailingTP({
      triggerAt: val,
      stopAt: trailing.stopAt,
    });
  };
  return (
    <Row>
      <Col xl={12} className=" mt-4">

      <div className="trailing-item d-flex align-items-center">
        <div>{long?"Sell":"Buy"}</div>
        <div> 
            <label data-text={'%'} className="dca input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={trailing.stopAt}
                  onChange={(e) => changeStopAt(e.target.value)}
                  className="input-text input-gp-tex dca-subseq-text"
                ></Form.Control>
            </label>
          </div>
        
        <div> of asset at every</div>
        <div> 
            <label data-text={'%'} className="dca input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={trailing.triggerAt}
                  onChange={(e) => changeTriggerAt(e.target.value)}
                  min={0}
                  onKeyPress={(e) => {
                    restrictAlphabets(e);
                  }}
                  className="input-text input-gp-tex dca-subseq-text"
                ></Form.Control>
            </label>
          </div>
        <div> {long?"above":"below"} {long?"Buy":"Sell"}</div>
        <div>&nbsp; Price</div>
        
      </div>
      </Col>
      </Row>
  );
};

export default FixedTP;
