import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Table, Form } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { SUB_METHOD, SUB_STATUS, SUB_TIER, SUB_TYPE } from "../../Const";
import PremiumInfo from "../subscription/PremiumInfo";
import FreeInfo from "../subscription/FreeInfo";
import { checkSub } from "../../utils/SubUtils";
import { capitalize, formatDate, milisecOf } from "../../utils/Utils";
import Switch from "react-switch";
import CustomPagination from "../common/CustomPagination";
import Loading from "../common/Loading";
import useLoading from "../hooks/useLoading";
import { toast } from "react-toastify";
import "./SubscriptionPanel.scss";

import {
  checkSubscription,
  cancelSubscription,
  continueSubscription,
} from "../../actions/setting-action";
import ConfirmCancelDialog from "./ConfirmCancelDialog";
import ContinueSubscriptionDialog from "./ContinueSubscriptionDialog";
import { useTranslation } from "react-i18next";

const SubscriptionPanel = (props) => {
  const { checkSubscription, cancelSubscription, continueSubscription } = props;
  const {t} = useTranslation()
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  const [isYearly, setIsYearly] = useState(true);
  
  const [startItem, setStartItem] = useState(0);
  const [setLoading, bindLoading] = useLoading(false);
  const itemPerPage = 5;
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  const {
    subMethod,
    subType,
    subPremium,
    subStatus,
    subEndDate,
    subInvoices,
    subDayLeft,
    subTier,
  } = checkSub(subscription);
  const [plan, setPlan] = useState("yearly");

  const showCancelBtn =
    subPremium &&
    [SUB_METHOD.stripe].includes(subMethod) &&
    subStatus == SUB_STATUS.active;

  subInvoices.sort((item1, item2) => {
    const date1 = item1.time;
    const date2 = item2.time;
    return milisecOf(date2) - milisecOf(date1);
  });

  const clickCancel = () => {
    setShowCancelDialog(false);
    setLoading(true);
    cancelSubscription()
      .then(() => {
        setLoading(false);
        toast.info(t("plan-cancelled"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(String(err));
      });
  };

  const clickContinue = () => {
    if (subStatus != SUB_STATUS.canceled) return;
    setShowContinueDialog(false);
    setLoading(true);
    continueSubscription()
      .then(() => {
        setLoading(false);
        toast.info(t("plan-continued"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(String(err));
      });
  };

  useEffect(() => {
    checkSubscription().then((res) => {});
  }, [checkSubscription]);

  useEffect(() => {
    if (subPremium) {
      setIsYearly(subType == SUB_TYPE.yearly);
    }
  }, [subPremium]);
  const changeStartItem= (electedExchangeId,itemsCountPerPage,num)=>{
    setStartItem(num)
  }
  const txs =
    subInvoices &&
    subInvoices.slice(startItem, startItem + itemPerPage).map((item, index) => {
      return (
        <tr key={index}>
          <td>{formatDate(item.time)}</td>
          <td>{item.txId}</td>
          <td>{item.amount}</td>
        </tr>
      );
    });
  const [activeTab, setActiveTab] = useState(2);
  const changeTab = (index,tab) => {
    setActiveTab(index);
    setPlan(plans[index])
  };
  const tabTitles = [t("Monthly"), t("Quarterly"),t("yearly")];
  const plans = ["monthly","quarterly","yearly"];
  const tabHeaders = tabTitles.map((item, index) => {
    return (
      
        <a
          onClick={() => changeTab(index,index==0?'open':'close')}
          className={(index == activeTab ? "list-tab tab-active" : "list-tab tab-inactive" )}
          key={index}
        >
          {item}
        </a>
      
    );
  });

  return (
    <>
    <Row>
      <Col xl={12}>
          <h5>{t("my-plan")}{" "}</h5>
          
         
            <div className="">
              <h5 className="d-flex justify-content-between">
                
                {showCancelBtn ? (
                  <div className="d-inline mr-5">
                    <Button
                      variant="outline-danger"
                      onClick={() => setShowCancelDialog(true)}
                    >
                      {t("cancel-renewal")}
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </h5>
              
                
                <div className="deals-table">
                <table className="">
                    <tbody>
                        <tr valign="top">
                        <th>{t("curr-plan")}</th>
                        <th>{t("d-remain")}</th>
                        <th>{t("next-pay")}</th>
                        <th></th>
                        </tr>
                        <tr>
                      <td>
                        <h6>
                          {subPremium
                            ? capitalize(subTier) + " " + capitalize(subType)
                            : t("free")}
                        </h6>
                      </td>
                      <td>
                        <h6>{subDayLeft}</h6>
                      </td>
                      <td>
                        <h6
                          className={
                            subStatus == SUB_STATUS.canceled ? "text-danger" : ""
                          }
                        >
                          {subStatus == SUB_STATUS.canceled
                            ? t("cancelled")
                            : subEndDate}
                        </h6>
                      </td>
                      <td width={300}>
                      <a href="https://billing.stripe.com/p/login/9AQ00C81bd6EcF27ss" className="btn" target="_blank">Manage Payment Details</a>
                      </td>
                    </tr>
                    </tbody>
                </table>
              </div>
          </div>
      </Col>
    </Row>
    <br></br>
    <Row className="mt-4">
      <Col xl={12}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div><h5>{t("sub-plans")}</h5></div>
          <div className="">
            {tabHeaders}
          </div>
        </div>
          </Col>
        </Row>
        <Row className="sub-plans-div">
         
            <Col className="sub-col no-pad" xl={4} xs={12}>
              <PremiumInfo
                isYearly={isYearly}
                subStatus={subStatus}
                subPremium={subPremium}
                tier={SUB_TIER.lite}
                subTier={subTier}
                plan={plan}
                onUpgrade={() => setShowContinueDialog(true)}
                t={t}
              />
            </Col>
            <Col className="sub-col no-pad" xl={4} xs={12}>
              <PremiumInfo
                isYearly={isYearly}
                subStatus={subStatus}
                subPremium={subPremium}
                tier={SUB_TIER.premium}
                subTier={subTier}
                plan={plan}
                onUpgrade={() => setShowContinueDialog(true)}
                t={t}
              />
            </Col>
            <Col className="sub-col no-pad" xl={4} xs={12}>
              <PremiumInfo
                isYearly={isYearly}
                subStatus={subStatus}
                subPremium={subPremium}
                tier={SUB_TIER.pro}
                subTier={subTier}
                plan={plan}
                onUpgrade={() => setShowContinueDialog(true)}
                t={t}
              />
            </Col>

    </Row>
    <br></br>
    <br></br>
    <br></br>
    
        
       
        {txs != null && txs.length > 0 ? (
          <div className="card-white">
          <div className="cw-body settings-body">
            <div className="sub-panel">
          <div className="sub-row">
            <h5>{t("payment-history")}</h5>
            <Table borderless responsive>
              <thead>
                <tr>
                  <th>{t("date")}</th>
                  <th>Tx ID</th>
                  <th>{t("price")}</th>
                </tr>
              </thead>
              <tbody>{txs}</tbody>
            </Table>
            <CustomPagination
              startItem={startItem}
              onChange={changeStartItem}
              itemsCountPerPage={itemPerPage}
              totalCount={subInvoices.length || 0}
            />
          </div>
          </div></div></div>
        ) : (
          <></>
        )}
      <ConfirmCancelDialog
        show={showCancelDialog}
        onHide={() => setShowCancelDialog(false)}
        confirmed={() => clickCancel()}
      />
      <ContinueSubscriptionDialog
        show={showContinueDialog}
        onHide={() => setShowContinueDialog(false)}
        confirmed={() => clickContinue()}
      />
      <Loading {...bindLoading} />
    </>
  );
};

export default connect(null, {
  checkSubscription,
  cancelSubscription,
  continueSubscription,
})(SubscriptionPanel);
