import React from "react";
import { Col, Row } from "react-bootstrap";

const Tab1 = (props)=>{
    const {next} = props;
    return(<>
        <div className="tab qs-tab-div">
            <Row className="qs-tab-des-div">
                <Col className="text-left">
                    <h2>Step 1: Connect Your Brokerage (optional)</h2>
                    <br/>
                    <p>In order for StockHero to run automated trades, you need to connect your brokerage with StockHero.</p>
                    <p>Please go to our <a href="/setting?loc=3" className="link"><b>Brokerage API</b></a> page to add your brokerage. After you have added your brokerage, return to this Quick Start wizard.</p>
                    <br/>
                </Col>
            </Row>
            <Row className="qs-tab-button-div">
                <Col xl={6} className="mt-2">
                    <button type="button"  className="btn full-btn blue-btn" onClick={next}>I have connected StockHero to my exchange</button>
                </Col>
                <Col xl={6} className="mt-2">
                    <button type="button"  className="btn full-btn" onClick={next}>Skip this step</button>
                </Col>
            </Row>
          
        </div>
    </>)
}
export default Tab1;