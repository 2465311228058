import {
    SET_LIBRARY_IMAGE,LIST_MARKETPLACE_TEMPLATES, SET_TEMPLATE_DETAIL, OWNER_TEMPLATES, SET_TEMPLATE_OWNER_DATA, GET_MARKETPLACE_SUMMARY, SET_PAYMENT_HISTORY, TOP_TEMPLATES, SET_TUTORIAL_POPUP
} from '../actions/types';
import { roundNumber, formatDate, compareDate } from "../utils/Utils";
const initState = {
    templates:[],
    owner_templates:[],
    template:{},
    template_traded_stocks:[],
    marketplaceDashboard:{},
    show_marketplace_popup:1
}
const marketplaceReducer = (state = initState,{type,payload})=>{
    switch (type) {
        case LIST_MARKETPLACE_TEMPLATES:
            return {
                ...state,
                templates: {
                    data:payload.templates,
                    total: payload.total,
                    my_template_count: payload.my_template_count,
                    startItem: payload.start,
                },
                featuredTemplates:payload.featuredTemplates
                
            };
        case OWNER_TEMPLATES:
            return {
                ...state,
                owner_templates: {
                    data:payload.templates,
                    total: payload.total,
                    startItem: payload.start,
                    my_template_count: payload.my_template_count,
                }
            };
        case TOP_TEMPLATES:
            return {
                ...state,
                top_templates:payload.templates
            };
        case SET_TEMPLATE_DETAIL:
            return {
                ...state,
                template: payload.template,
                template_traded_stocks: payload.template_traded_stocks,
            };
        case SET_TEMPLATE_OWNER_DATA:
            return {
                ...state,
                marketplaceDashboard: payload,
            };
        case SET_PAYMENT_HISTORY:
            return {
                ...state,
                paymentHistoryPending: payload.pendings,
                paymentHistoryPaid: payload.paids,
            };
        case SET_TUTORIAL_POPUP:
            return {
                ...state,
                show_marketplace_popup:payload
            };
        case GET_MARKETPLACE_SUMMARY:
            const { earning, interval, renter } = payload;
            const fixedEarnings = earning
                .sort((item1, item2) => {
                return compareDate(item1.time, item2.time) ? -1 : 1;
                })
                .map((item) => {
                var format = "DD MMM HH:mm";
                // if (["1M", "3M", '1Y', 'ALL'].includes(interval)) {
                //   format = "DD MMM";
                // }
                return {
                    time: formatDate(item.time, undefined, format),
                    value: roundNumber(item.value, 2),
                };
                });
            const fixedRenters = renter
                .sort((item1, item2) => {
                return compareDate(item1.time, item2.time) ? -1 : 1;
                })
                .map((item) => {
                return roundNumber(item.value, 2);
                });
            return {
                ...state,
                ...payload,
                earning: fixedEarnings,
                renters: fixedRenters,
            };
        default:
            return state;
      }
}

export default marketplaceReducer;