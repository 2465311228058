import React from "react";


const GuestContainer = (props)=>{
    return (
        <div className="guest-bg">
            {props.children}
        </div>
    )
}

export default GuestContainer;