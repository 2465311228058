import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_BOT_NAME, SET_RENT_BOT_NAME } from "../../../../actions/create-bot-type";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BackButton from "../../../common/BackButton";

const BotTitle = (props)=>{
    const {rent} = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const newBot = useSelector((state) => state.createBot.newBot);
    const rentBot = useSelector((state) => state.createBot.rentBot);
    const botName = rent?rentBot.name:newBot.name;
    const [editName, setEditName] = useState(false);
    const setBotName = (val) => {
        dispatch({
          type: rent?SET_RENT_BOT_NAME: SET_BOT_NAME,
          payload: val,
        });
      };
    
    return(
        <>
        <Col xl={12} className="mb-4">
               <BackButton ></BackButton>
                <div className="bot-title-div text-center">
                {!editName ? (
                    <span className="bot-name-text">{botName}</span>
                    ) : (
                    <div className="text-center margin-auto inline-flex ">
                            <Form.Control
                        value={botName}
                        onChange={(e) => setBotName(e.target.value)}
                        className="bot-title text-center"
                    />
                    </div>
                    
                    )}{" "}
                        <a className="ml-3 bn-edit-icon" onClick={() => setEditName(!editName)}>
                        {!editName ?<img src="/images/icons/edit.svg"></img>:<i className={"far fa-check-circle bn-tick"}></i>}
                        {/* <i className={editName ? "far fa-check-circle bn-tick" : "far fa-edit"}></i> */}
                    </a>
                </div>
            </Col>
        </>
    )
}

export default BotTitle;