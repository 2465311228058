import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_BOT_NAME } from "../../../../actions/create-bot-type";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import AssetView from "../AssetView";
import GridBotParamsView from "../grid-bot/GridBotParamsView";
import TradeParamsView from "../TradeParamsView";
import { BotType } from "../../../../Const";

const AssetParamSection = (props)=>{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const newBot = useSelector((state) => state.createBot.newBot);
    const tabTitles = [t("asset"), t("t-params")];
    const tourClasses = ["bot-asset-tour", "trade-params-tour"];
    const [activeTab, setActiveTab] = useState(0);
    const [selectedTab, getSelectedTab] = useState('open');
    const changeTab = (index,tab) => {
        setActiveTab(index);
        getSelectedTab(tab)
    };
    const tabHeaders = tabTitles.map((item, index) => {
        return (
          
            <a
              onClick={() => changeTab(index,index==0?'open':'close')}
              className={(index == activeTab ? "list-tab tab-active" : "list-tab tab-inactive" )+ " "+ tourClasses[index]}
              key={index}
            >
              {item}
            </a>
          
        );
    });
    const tabs = [
        <AssetView newBot={newBot} t={t}/>,
        newBot.botType === BotType.grid ? (
            <GridBotParamsView editing={newBot.isEditing}
            newBot={newBot} t={t}/>
          ) : (
            <TradeParamsView
            editing={newBot.isEditing}
            newBot={newBot}
            t={t}
          />
        )
    ];
    return(
        <>
        <Col xl={6} xs={12} className="asset-param-div">
            <Row>
            <Col xl={12} className="section-tabs-bar">{tabHeaders}</Col>
            </Row>
            <div className="card-white cb-section-1">
                <div className="cw-body">
                    {tabs[activeTab]}
                </div>
            </div>
        </Col> 
        </>
    )
}

export default AssetParamSection;