import React from "react";
import {
  DcaTime,
  DcaType,
  DcaTypeName,
  DcaTypePercentage,
  Frequencies,
  FrequencyValues,
  WeekTime,
  WeekTimeName,
  BotTypeName,
  TakeProfitType,
  TakeProfitTypeName
} from "../../../Const";
import { Col, Row, Table } from "react-bootstrap";
import {
  capitalize,
  getKeyByValue,
  getMonthDay,
  isLong,
} from "../../../utils/Utils";
import LeftRightForm from "../new-bot/modules/LeftRightForm";

const DcaBotSetting = (props) => {
  const { bot,t } = props;
  const exchangePair = bot.exchange_pair || {};
  const exchange = exchangePair.exchange || {};
  const pair = exchangePair.pair || {};
  const indicators = bot.bot_indicators || [];
  const dcaSetting = bot.dca_setting || {};
  const limitOrder = bot.extra_order_percentage || 0;
  const initFund = bot.initial_fund || 0;
  let dcaType = dcaSetting.entry_type || DcaType.time;
  if (dcaType === DcaTypePercentage) {
    dcaType = isLong(bot.strategy) ? DcaType.buyDown : DcaType.sellUp;
  }
  const tps = bot.tps || [];
  let takeProfit = (bot.profit || 0).toFixed(2) + "%";
  if (tps.length) {
    takeProfit =
    tps[0].tp_type === TakeProfitType.fixedTP
        ? TakeProfitTypeName.fixedTP
        : TakeProfitTypeName.variableTP;
  }

  const dcaTypeName = DcaTypeName[getKeyByValue(DcaType, dcaType)];
  let dcaSettingHead;
  let dcaSettingValue;
  let martingale;
  switch (dcaType) {
    case DcaType.time:
      dcaSettingHead = (
        <>
          <th>Time Buy</th>
        </>
      );
      let time = dcaSetting.value;
      if (time === DcaTime.weekly) {
        time =
          "Every " + WeekTimeName[getKeyByValue(WeekTime, dcaSetting.value2)];
      } else if (time === DcaTime.monthly) {
        time = "Every " + getMonthDay(dcaSetting.value2) + " of month";
      } else {
        time = capitalize(time);
      }
      dcaSettingValue = (
        <>
          <td>{time}</td>
        </>
      );
      break;
    case DcaType.indicator:
      const entryIndicators =
        indicators &&
        indicators
          .filter((item) => item.type == "entry")
          .map((item) => item.name)
          .join(", ");
      const frequency =
        Frequencies[getKeyByValue(FrequencyValues, bot.frequency)];
      martingale =
        dcaSetting.value3 == 0 ? "Disabled" : dcaSetting.value3 + "%";
      dcaSettingHead = (
        <>
          <th>{t("indicators")}</th>
          <th>{t("frequency")}</th>
          <th>{t("martingale")}</th>
        </>
      );
      dcaSettingValue = (
        <>
          <td>{entryIndicators}</td>
          <td>{frequency}</td>
          <td>{martingale}</td>
        </>
      );
      break;
    case DcaType.buyDown:
    case DcaType.sellUp:
      let side = dcaType === DcaType.buyDown ? "Buy" : "Sell";
      martingale =
        dcaSetting.value3 == 0 ? "Disabled" : dcaSetting.value3 + "%";
      dcaSettingHead = (
        <>
          <th>{t("trigger-price")}</th>
          <th>{t("subsequent")} {side}</th>
          <th>Martingale</th>
        </>
      );

      dcaSettingValue = (
        <>
          <td>
            {dcaSetting.value} {pair.to}
          </td>
          <td>{dcaSetting.value2}%</td>
          <td>{martingale}</td>
        </>
      );
      break;
    default:
      break;
  }

  return (
    
      <div className="card-white mpd-card">
          <div className="cw-body bot-detail">
              <LeftRightForm 
                  left={t("exchange")}
                  right={<div>
                    <img
                      src={exchange.image}
                      width={20}
                      height={20}
                      style={{ marginRight: 10 }}
                    />
                    <span style={{ fontWeight: "bold" }}>{exchange.name}</span>
                  </div>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("pair")}
                  right={<>{pair && `${pair.from}/${pair.to}`}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("bot-type")}
                  right={<>{bot && BotTypeName[bot.type]}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("fund")}
                  right={<>{initFund == 0 ? t("no-limit") : initFund + " " + pair.to}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("ord-am")}
                  right={<>{bot.base_order_percentage} {pair.to}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
              <LeftRightForm 
                  left={t("DCA Type")}
                  right={<>{dcaTypeName}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
                <LeftRightForm 
                  left={dcaSettingHead}
                  right={<>{dcaSettingValue}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
                <LeftRightForm 
                  left={t("no-of-ord")}
                  right={<>{limitOrder === 0 ? t("no-limit") : limitOrder}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
                <LeftRightForm 
                  left={t("tp")}
                  right={<>{takeProfit}</>}
                  leftCol={4}
                  rightCol={8}
                ></LeftRightForm>
          </div>
      </div>
  );
};

export default DcaBotSetting;
