let websocket;
const connect = (pairs, callback) => {
  console.log("New Binance connect");
  const validPairs = pairs.map((el) => `${el.from}${el.to}`.toUpperCase());
  websocket = new WebSocket("wss://socket.polygon.io/stocks");
  
  websocket.addEventListener('open', () => {
    console.log('Connected!')
    websocket.send(`{"action":"auth","params":"NHYJ6VWKxbJksdSTLgk5yq5JRO4qPrwK"}`)
    websocket.send(`{"action":"subscribe","params":"A.*"}`)
  })
  websocket.addEventListener("message", (ev) => {
    const data = JSON.parse(ev.data);
    console.log(data)
    if (data.id == null) {
      console.log("here")
      const tickers = data
        .map(adaptTicker)
        .filter((el) => validPairs.includes(el.s));
      console.log(tickers)
      callback(tickers);
    }
  });
  
  // websocket.addEventListener("open", () => {
  //   const sub = {
  //     method: "SUBSCRIBE",
  //     params: ["!ticker@arr"],
  //     id: 1,
  //   };
  //   websocket.send(JSON.stringify(sub));
  // });
  // websocket.addEventListener("open", () => {
  //   const sub = {"action":"auth","params":"NHYJ6VWKxbJksdSTLgk5yq5JRO4qPrwK"}
  //   websocket.send(JSON.stringify(sub));
  // });
};

const adaptTicker = (ticker) => {
  return {
    s: ticker.s,
    c: ticker.c,
    p: ticker.p,
    P: ticker.P,
    v: ticker.v,
    l: ticker.l,
    h: ticker.h,
  };
};

const disconnect = () => {
  websocket && websocket.close();
  websocket = null;
  console.log("Disconnected Binance");
};

export default { connect, disconnect };
