import React from "react";
import DcaExitView from "../dca-bot/DcaExitView";
import PriceExitView from "../price-bot/PriceExitView";
import GridExitView from "../grid-bot/GridExitView";
import SellBotExitView from "../sell-bot/SellBotExitView";
import ExitConditionsView from "../ExitConditionsView";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BotType } from "../../../../Const";

const ExitView = (props)=>{
    const newBot = useSelector((state) => state.createBot.newBot);
    const { t } = useTranslation();
    const getExitView = ()=>{
        switch (newBot.botType) {
          case BotType.dca:
            return <DcaExitView newBot={newBot} t={t} />
          case BotType.price :
            return <PriceExitView newBot={newBot} t={t}/>
          case BotType.grid :
            return <GridExitView newBot={newBot} t={t}/>
          case BotType.sell :
            return <SellBotExitView newBot={newBot} t={t}/>
          default:
            return <ExitConditionsView newBot={newBot} t={t} editing={newBot.isEditing}/>
        }
      }
    return(<>
        {getExitView()}
    </>)
}

export default ExitView;