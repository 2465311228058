import React, { useState } from "react";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { checkSub, isPremium, isPremiumOrPro } from "../../../utils/SubUtils";
import useInput from "../../hooks/useInput";

const BotTradedStocks = (props)=>{
    const {template,t}=props
    const TradedStocksByTemplate = useSelector((state)=>state.marketplace.template_traded_stocks) || [];
    const subscription =
    useSelector((state) => state.auth.user.user_subscription) || {};
    const premiumOrPro = isPremiumOrPro(subscription);
    const tradedStocks = TradedStocksByTemplate && TradedStocksByTemplate.map((item, index) => {
        return(
            <div className="traded-stocks-items" key={index}>{item.from}</div>
        )
    })
    return (
        <>
        {premiumOrPro?<><div className="round-bg mt-3">
            <Col xs={12} className="no-padding">
            <Row className="center-align">
                <Col className="">
                    <div>
                    <div className="card-white mpd-card">
                    <div className="card-heading">
                                    <div className="cw-title">{t("traded-stocks")}</div>
                                    <hr></hr>
                                </div>
                        <div className="cw-body pad-top-0">
                            <div className="d-flex traded-stocks-div  w-100">
                                {tradedStocks}
                            </div>
                            
                        </div>
                        
                    </div>
                   
                </div>
               
                </Col>
                
            </Row>
            </Col>
            </div></>:<></>}
            
        </>
    );
}
export default connect(null,{})(BotTradedStocks)