import React, { useState, useMemo, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, Row, Col } from "react-bootstrap";
import BooleanView from "./BooleanView";
import Select from "react-select";
import "./AssetView.scss";
import TutorialPopup from "../../help/TutorialPopup";
import { CHANGE_PAIR, CHANGE_STRATEGY } from "../../../actions/create-bot-type";
import { BotType, StrategyModule } from "../../../Const";
import { getBalance, getPairBalance } from "../../../actions/bot-action";
import { Trans } from "react-i18next";
import SymbolSearchBox from "./modules/SymbolSearchBox";
import LeftRightForm from "./modules/LeftRightForm";

const AssetView = (props) => {
  const { newBot,getBalance,getPairBalance,t } = props;
  const [baseCoin, setBaseCoin] = useState("usd");
  const usdPairs = useSelector((state) => state.bots.usdPairs);
  const btcPairs = useSelector((state) => state.bots.btcPairs);
  const ethPairs = useSelector((state) => state.bots.ethPairs);
  const strategy = newBot.strategy;
  const dispatch = useDispatch();
  const selectedExchange = newBot.exchange;
  const pairList = useMemo(() => {
    return baseCoin === "usd"
      ? usdPairs
      : baseCoin === ""
      ? btcPairs
      : ethPairs;
  }, [baseCoin]);

  const pairItems =
    (pairList &&
      pairList.map((item) => ({
        value: item.id,
        label: `${item.pair.from}/${item.pair.to}`,
        from: item.pair.from,
        to: item.pair.to,
        is247: item.pair.is_247
      }))) ||
    [];
  const pair = newBot.pair;
  const selectedPair = {
    value: newBot.exchangePairId,
    label: `${pair.from}/${pair.to}`,
    from: pair.from,
    to: pair.to,
    is247: pair.is_247,
  };

  // const handleSelectPair = (item) => {
  //   getBalance(selectedExchange.id)
  //   const newPair = pairList.find((el) => item.value == el.id) || {};
  //   console.log(newPair)
  //   getPairBalance(selectedExchange.id,newPair.pair_id,'spot');
  //   dispatch({
  //     type: CHANGE_PAIR,
  //     payload: newPair,
  //   });
  // };
  const handleSelectPair = (item) => {
    getBalance(selectedExchange.id)
    const newPair = item || {};
    getPairBalance(selectedExchange.id,newPair.pair_id,'spot');
    dispatch({
      type: CHANGE_PAIR,
      payload: newPair,
    });
  };
  useEffect(() => {
    getBalance(selectedExchange.id)
    getPairBalance(selectedExchange.id,pair.id,'spot');
  }, [selectedExchange.id]);

  const strategyChanged = (strategy) => {
    dispatch({
      type: CHANGE_STRATEGY,
      payload: strategy,
    });
  };
  useEffect(() => {
    if(selectedExchange.id ==18 && strategy=="Short"){
      strategyChanged("Margin Short")
    }
  }, [strategy]);

  return (
    <>
          <Row className="d-flex">
              <Col xl={4} className="cb-form-label">
                <Form.Label>
                {t("exchange")} <TutorialPopup content={t("exchange-help")} />
                </Form.Label>
              </Col>
              <Col xl={8}>
                <div className="mt-1 mb-3">
                  {/* <img
                    src={selectedExchange.image}
                    width={20}
                    height={20}
                    style={{ marginRight: 10 }}
                  /> */}
                  <span style={{ fontWeight: "bold", fontSize: 16 }}>
                    {selectedExchange.name}
                  </span>
                </div>
              </Col>
          </Row>
          

          <Row className="d-flex mt-2">
              <Col xl={4} className="cb-form-label">
                <Form.Label className="bot-asset-tour">
                  {t("t-pair")} <TutorialPopup content={t("tradingpair-help")} />
                </Form.Label>
              </Col>
              <Col xl={8}>
                <div style={{ maxWidth: "80%" }}>
                   <SymbolSearchBox selectedExchange={selectedExchange} selected={handleSelectPair} selectedPair={selectedPair}></SymbolSearchBox>
                </div>
                <Form.Label className="mt-2">
                  <Trans i18nKey={'miss-pair-note'}><a className="link" href="mailto:admin@stockhero.ai"></a></Trans>
               </Form.Label>
              </Col>
          </Row>
          {(selectedExchange.id !=17 && selectedExchange.id !=18)? <>
            {(StrategyModule[newBot.botType]) && (
              <div style={{ marginTop: 20 }}>
                <BooleanView
                  title={`Strategy`}
                  firstButton="Long"
                  secondButton="Short"
                  selected={newBot.strategy}
                  onSelect={strategyChanged}
                  tutorial={<TutorialPopup content={t("strategy-help")} />}
                />
              </div>
            )}
            </>:<>
            <LeftRightForm
              //left={<> {t("Strategy")} <TutorialPopup content={t("strategy-help")} /></>}
              left={<>  <Form.Label className="bot-asset-tour">
              {t("Strategy")} <TutorialPopup content={t("strategy-help")} />
            </Form.Label></>}
              right={<> <div className="d-flex justify-content-between bw-option-div-1">
                
              <span
               className={strategy == "Long" ?"success" : ""}
               onClick={() => strategyChanged("Long")}
             >
               LONG 
             </span>
             <span
               className={strategy == "Short" ?"success" : ""}
               onClick={() => strategyChanged("Short")}
             >
               SHORT
             </span>
             <span
               className={strategy == "Margin Short" ?"success" : ""}
               onClick={() => strategyChanged("Margin Short")}
             >
               MARGIN SHORT
             </span>
           </div>
              </>}
            ></LeftRightForm> </> }
          
    </>
  );
};

export default connect(null,{getBalance,getPairBalance})(AssetView);
