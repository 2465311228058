import React, { useEffect, useState } from "react";
import {
  LOWER_RANGE_BUY_PRICE,
  NUMBER_OF_ORDERS,
  PER_ORDER_AMOUNT,
  UPPER_RANGE_SELL_PRICE,
} from "../../../../actions/create-bot-type";
import TutorialPopup from "../../../help/TutorialPopup";
import * as HelpContent from "../../../../utils/HelpContent";
import { useDispatch, useSelector } from "react-redux";
import { formatNumberToString, isLong, restrictAlphabets } from "../../../../utils/Utils";
import { Frequencies, FrequencyValues,FrequencyValuesSimple,FrequenciesSimple,BotType, SUB_TIER, FrequencyValuesPro, FrequenciesPro } from "../../../../Const";
import { checkSub } from "../../../../utils/SubUtils";
import { Accordion, Card, Form, InputGroup } from "react-bootstrap";
import LeftRightForm from "../modules/LeftRightForm";

const GridBotParamsView = (props) => {
  const { newBot,editing,t } = props;
  const fund = newBot.initFund;

  const currentCoin = isLong(newBot.strategy)
    ? newBot.quoteCoin
    : newBot.baseCoin;
  const baseCoin = newBot.baseCoin;
  const balances = useSelector((state) => state.bots.balances);
  const pairData = useSelector((state) => state.bots.pairData);
  const [buySideFundReq,setBuySideFundReq] = useState(0);
  const [sellSideFundReq,setSellSideFundReq] = useState(0);
  const balance =
    balances && balances.find((item) => item.coin === currentCoin);
  const baseCoinBalance =
    balances && balances.find((item) => item.coin === baseCoin);
  const dispatch = useDispatch();
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  
 

  // const fundChanged = (val) => {
  //   dispatch({
  //     type: CHANGE_FUND,
  //     payload: val,
  //   });
  // };

  const upperRangeSellPriceChanged = (val) => {
    dispatch({
      type: UPPER_RANGE_SELL_PRICE,
      payload: val,
    });
  };

  const lowerRangeBuyPriceChanged = (val) => {
    dispatch({
      type: LOWER_RANGE_BUY_PRICE,
      payload: val,
    });
    calculateFundRequired(newBot.gridOrderNum,newBot.gridOrderVol,val)
  };

  const noOfordersChanged = (val) => {
    dispatch({
      type: NUMBER_OF_ORDERS,
      payload: val,
    });
  };
  const perOrderAmountChanged = (val) => {
    dispatch({
      type: PER_ORDER_AMOUNT,
      payload: val,
    });
  };
  const calculateFundRequired = (orderNum,orderVol,lowerRange) => {
    if(orderNum && orderVol){
      let total = orderNum*orderVol;
      let sellBal = 0;
      setBuySideFundReq(total)
      if(pairData.current_price && pairData.current_price > 0){
        sellBal = ((orderVol/pairData.current_price)*orderNum).toFixed(4)
      }
      calculateSellSide(orderNum,orderVol,lowerRange)
    }
  };
  const calculateSellSide = (orderNum,orderVol,lowerRangeVal)=>{
      let currentPrice = pairData.current_price;
      let lowerRange = lowerRangeVal?lowerRangeVal:newBot.gridLowerRange;
      if(!currentPrice || !lowerRange)
      return false;
      let qty = 0 ;
      let totalSellSideQty = 0 ;
      let buyPrice = 0 ;
      let stepPrice = (currentPrice * lowerRange)/100;
      for (let index = 0; index < orderNum; index++) {
        buyPrice =  currentPrice-stepPrice
        qty = orderVol/buyPrice
        totalSellSideQty += qty
        currentPrice = buyPrice
      }
      setSellSideFundReq(totalSellSideQty.toFixed(4))
  }
  
   useEffect(() => {
    if (balance && editing) {
      if(editing){
        calculateFundRequired(newBot.gridOrderNum,newBot.gridOrderVol)
      }
    }
  }, [balance]);
  const formatBaseOrder = (value) =>
    `${((fund * value) / 100).toFixed(4)} ${currentCoin} (${value}%)`;
  //const formartFrequency = (value) => tradingFreqencies[value];
  return (
    <div>


            <LeftRightForm
              left={<><Form.Label className="multi-label">
              <span>{t("up-r-sell-price-limit")} </span><span>{t("ab-mid-price")}<TutorialPopup content={t("gridMidPrice")} /></span>
            </Form.Label></>}
              right={<label data-text={'%'} className="input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={newBot.gridUpperRange}
                  onChange={(e) => {
                    upperRangeSellPriceChanged(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    restrictAlphabets(e);
                  }}
                  className="input-text input-gp-text"
                ></Form.Control>
            </label>}
             leftCol={6}
             rightCol={6}
            >
            </LeftRightForm>
            <LeftRightForm
              left={<> <Form.Label className="multi-label">
              <span>{t("lower-r-buy-price-limit")} </span><span>{t("below-mid-price")}</span>
            </Form.Label></>}
              right={<label data-text={'%'} className="input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={newBot.gridLowerRange}
                  onChange={(e) => {
                    lowerRangeBuyPriceChanged(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    restrictAlphabets(e);
                  }}
                  className="input-text input-gp-text"
                ></Form.Control>
            </label>}
             leftCol={6}
             rightCol={6}
            >
            </LeftRightForm>
            <LeftRightForm
              left={<Form.Label>
                {t("no-of-orders")}
              </Form.Label>}
              right={<label  className="input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={newBot.gridOrderNum}
                    onChange={(e) => {
                      noOfordersChanged(e.target.value);
                    }}
                    onKeyUp={(e)=>{
                      calculateFundRequired(e.target.value,newBot.gridOrderVol)
                    }}
                    onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}
                  className="input-text input-gp-text"
                ></Form.Control>
            </label>}
             leftCol={6}
             rightCol={6}
            >
            </LeftRightForm>

            <LeftRightForm
              left={ <Form.Label>
                {t("per-order-amount")}
                </Form.Label>}
              right={<label data-text={currentCoin} className="input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={newBot.gridOrderVol}
                    onChange={(e) => {
                      perOrderAmountChanged(e.target.value);
                    }}
                    onKeyUp={(e)=>{
                      calculateFundRequired(newBot.gridOrderNum,e.target.value)
                    }}
                    onKeyPress={(e) => {
                      restrictAlphabets(e);
                    }}
                  className="input-text input-gp-text"
                ></Form.Control>
            </label>}
             leftCol={6}
             rightCol={6}
            >
            </LeftRightForm>
            <LeftRightForm
              left={ <Form.Label>
                {t("total-fund-req")}
                </Form.Label>}

              right={<div>
                <p style={{ fontSize: 13, marginTop: 10 }}>
                {t("buy-side")}: {buySideFundReq}{" "}
                {currentCoin}
              </p>
              <p style={{ fontSize: 13, marginTop: 10 }}>
                {t("fund-available")}: {formatNumberToString(balance && balance.free)}{" "}
                {currentCoin}
              </p>
              </div>}
               leftCol={6}
             rightCol={6}
            >

            </LeftRightForm>
              <hr></hr>
              
            <LeftRightForm 
            
            left={<Form.Label>
              {t("total-fund-req")}
              </Form.Label>}

            right={<div>
              <p style={{ fontSize: 13, marginTop: 10 }}>
              {t("sell-side")}: {sellSideFundReq}{" "}
                {baseCoin}
              </p>
              <p style={{ fontSize: 13, marginTop: 10 }}>
              {t("fund-available")}: {formatNumberToString(baseCoinBalance && baseCoinBalance.free)}{" "}
                {baseCoin}
              </p>
            </div>}
            leftCol={6}
            rightCol={6}
            >
            </LeftRightForm>
            <Form.Group>
              
              
              
            </Form.Group>
    </div>
            
    
  );
};

export default GridBotParamsView;
