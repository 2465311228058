import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import ContentContainer from "../common/ContentContainer";
import { useTranslation } from "react-i18next";
import { getTrending, saveQuestion, submitQuery } from "../../actions/ai-action";
import { Button, Col, Form } from "react-bootstrap";
import useLoading from "../hooks/useLoading";
import Loading from "../common/Loading";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { checkSub, isPremium } from "../../utils/SubUtils";
import { SUB_TIER } from "../../Const";
import { toast } from "react-toastify";
import ObsceneWordFilter from "../common/ObsceneWordFilter";
import NonPremiumPrompt from "./NonPremiumPrompt";
import TooltipContent from "../tour/TooltipContent";
import TutorialPopup from "../help/TutorialPopup";
import useInput from "../hooks/useInput";
import AiDisclaimer from "./AiDisclaimer";


const ArtificialIntelligence = (props) => {
  const {submitQuery,getTrending,saveQuestion} = props
  const { t } = useTranslation()
  const trending = useSelector((state)=>state.data.trending) || []
  const [question,setQuestion] = useState("")
  const [questionAsked,setQuestionAsked] = useState("")
  const [answer,setAnswer] = useState("Your answer will appear here!")
  const [clearInput,setClearInput] = useState(false)
  const [setLoading, bindLoading] = useLoading(false);
  const subscription =
    useSelector((state) => state.auth.user.user_subscription) || {};
  const isPaid = isPremium(subscription);
  const { subPremium, subTier } = checkSub(subscription);
  const eligible = isPaid && subTier !== SUB_TIER.lite
  useEffect(()=>{
    setClearInput(false);
  },[question])
  const submitQues = ()=>{
    if(eligible){
      if(question.length < 10){
        toast.error("Your query must have a minimum character of 10")
        return false
      }
      setLoading(true)
      submitQuery(question).then((res)=>{
        setClearInput(true)
        setQuestion("")
        //let boldText = res.answer.replace(/\n\*/g, '<br><br/>');
        let boldText = res.answer.replace(/\*\*(.*?)\*\*/g, (_, word) => `<strong>${word}</strong>`).replace(/\*([^*])/, '$1').replace(/\n/g, '<br><br/>');
        setAnswer(boldText)
        setQuestionAsked(res.question)
        setLoading(false)
      }).catch((err)=>{
        setLoading(false)
        console.log(err)
      })
    }
    else{
      toast.error("This feature only available to Premium and Professional plan subscribers! ")
    }
    
  }
  useEffect(()=>{
    getTrending()
  },[])
  const saveQa = ()=>{
    saveQuestion(questionAsked,answer).then((res)=>{
      toast.success('Question saved successfully!')
    }).catch((err)=>{
      toast.error("Failed to save the question. Please try again later.");
    })
  }
  const checkEnterPressed = (event)=>{
    var code = event.keyCode || event.which;
    if(code === 13) { //13 is the enter keycode
      submitQues()
    } 
  }
  const trendingRows = trending && trending.map((trending,id)=>{
    return(
      <span className="trending" key={id}>{trending}</span>
    )
  })
  return (
    <ContentContainer url="artificial-intelligence" title={t("AI")} showExchange={true}>
      <Loading {...bindLoading}>
      <div className="dashboard-content">
      <div className="chart-container ai-query">
          <div className="ai-query-div">
              <div className="row d-flex jcb align-items-center">
                <div className="w-80"> 
                {/* <input type="text" className="form-control input-text" 
                placeholder="Enter your query..." 
                value={question} 
                onChange={(e)=>setQuestion(e.target.value)}
                onKeyUp={(e)=>checkEnterPressed(e)}
                ></input> */}
                <ObsceneWordFilter setQuestion={setQuestion} clearInput={clearInput}>
                    {(question, handleInputChange) => (
                      <input
                        type="text"
                        className="form-control input-text"
                        placeholder="Enter your query..."
                        value={question}
                        onChange={handleInputChange}
                        onKeyUp={(e)=>checkEnterPressed(e)}
                      />
                    )}
                    
                  </ObsceneWordFilter>
                </div>
                <div>
                  <div className="ai-query-submit" >
                      <img src="/images/icons/right-arrow.svg" className="add-bot-icon" onClick={()=>submitQues()}></img>
                  </div>
                </div>
              </div>
              </div>
              <div className="d-flex align-items-center ml-4">
                <div className="mr-2">Trending:<TutorialPopup content={'Displays the companies most queried by users'}></TutorialPopup></div>
                <div className="d-flex w-80 trending-section-div">
                  {trendingRows}
                </div>
              </div>
                   
        </div>
        <br></br>
        <br></br>
        <div className="row">
       
         <Col className="ai-answer-content-div">
            <div className="card-white cb-section-1">
                <div className="cw-body ai-answer-div">
                  {questionAsked &&<h6>Q.&nbsp;{questionAsked}</h6> }
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html:answer}}></div>
                  {questionAsked &&
                  <AiDisclaimer></AiDisclaimer>
                  }
                </div>
               
            </div>
            <div className="text-right">
            <Button
                  type="submit"
                  onClick={()=>saveQa()}
                  className="blue-btn mt-4 mr-2"
                >
                 <i className="far fa-save"></i>  Save
              </Button>
              <Link to={'/artificial-intelligence/saved'}><Button
                  type="submit"
                  //onClick={clickGetOtp}
                  className="blue-btn mt-4"
                >
                 View Saved
        </Button></Link>
        
            </div>
            
         </Col>
         
        </div>
     </div>
     
     </Loading>
     <NonPremiumPrompt showNoAccessPrompt={!eligible}></NonPremiumPrompt>
    </ContentContainer>
  );
};

export default connect(null,{submitQuery,getTrending,saveQuestion})(ArtificialIntelligence);
