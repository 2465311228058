import React from "react";
import { useDispatch } from "react-redux";
import { UPDATE_VARIABLE_TSL } from "../../../../actions/create-bot-type";
import { Button, Col, Form, Row } from "react-bootstrap";
import { isLong } from "../../../../utils/Utils";

const VariableTSL = (props) => {
  const { newBot } = props;
  const tsl = newBot.variableTSL;
  const long  = isLong(newBot.strategy)
  const dispatch = useDispatch();
  const updateTSL = (list) => {
    dispatch({
      type: UPDATE_VARIABLE_TSL,
      payload: list,
    });
  };

  const addVariabletSL = () => {
    updateTSL([...tsl, { triggerAt: 2, stopAt: 1 }]);
  };
  const tslItems = tsl.map((item, id) => {
    const changeStopAt = (val) => {
      const tempTsl = [...tsl];
      const trailing = tempTsl[id];
      trailing.stopAt = val;
      updateTSL(tempTsl);
    };

    const changeTriggerAt = (val) => {
      const tempTsl = [...tsl];
      const trailing = tempTsl[id];
      trailing.triggerAt = val;
      updateTSL(tempTsl);
    };

    const deleteTSL = () => {
      updateTSL(tsl.filter((_, index) => index !== id));
    };
    return (<>
      <Row key={id} className="trailing-item">
      <Col xl={12} className=" mt-4">

      <div className="trailing-item d-flex align-items-center">
        <div> <i
          className="fas fa-trash-alt text-danger"
          style={{ marginRight: 10, fontSize: 13 }}
          onClick={deleteTSL}
        ></i></div>
        <div>{id + 1}. Set{" "}</div>
        <div> 
            <label data-text={'%'} className="dca input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={item.stopAt}
                  onChange={(e) => changeStopAt(e.target.value)}
                  min={0}
                  className="input-text input-gp-tex dca-subseq-text"
                ></Form.Control>
            </label>
          </div>
        
        <div>{long?"below":"above"} {" "} current price when price hits{" "}</div>
        <div> 
            <label data-text={'%'} className="dca input-gp-label">
              <Form.Control
                  as="input"
                  type="number"
                  value={item.triggerAt}
                  onChange={(e) => changeTriggerAt(e.target.value)}
                  min={0}
                  className="input-text input-gp-tex dca-subseq-text"
                ></Form.Control>
            </label>
          </div>
        <div> {long?"above":"below"} {long?"Buy":"Sell"} Price</div>
        
      </div>
      </Col>
      </Row>
      </>
    );
  });
  return (
    <div>
      {tslItems}
      {tslItems.length <= 9 && (
        <div className="d-flex justify-content-end">
        <span className="add-ind-button"  onClick={() => addVariabletSL() }> 
          Add
        </span>
    </div> 
      )}
    </div>
  );
};

export default VariableTSL;
