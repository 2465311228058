import { DASHBOARD_ASSETS, GET_DAILY_PNL, GET_DASHBOARD, GET_TOP_BOTS, RESET_ALL_PAGINATION } from "./types";
import { getDashboardUrl, getTopBotUrl, getDailyPnlUrl, assetsUrl } from "./const";
import network from "./network";

export const getDashboard = (exchangeId) => (dispatch, getState) => {
  network
    .get(
      getDashboardUrl,
      {
        exchange_id: exchangeId,
      },
      dispatch,
      getState
    )
    .then((res) => {
      const { active, reserved, available } = res.data;
      dispatch({
        type: GET_DASHBOARD,
        payload: { active, reserved, available },
      });
    });
};
export const getDashboardAssets = (exchangeId,type) => (dispatch, getState) => {
  network
    .get(
      assetsUrl,
      {
        exchange_id: exchangeId
      },
      dispatch,
      getState
    )
    .then((res) => {
      //const { active, reserved, available } = res.data;
      dispatch({
        type: DASHBOARD_ASSETS,
        payload: res.data,
      });
    });
};

export const getTopBots = (timeFrame) => (dispatch, getState) => {
  network
    .get(
      getTopBotUrl,
      {
        time_frame: timeFrame,
      },
      dispatch,
      getState
    )
    .then((res) => {
      const { bots } = res.data;
      dispatch({
        type: GET_TOP_BOTS,
        payload: bots,
      });
    });
};

export const getDailyPnl = (exchange_id) => (dispatch, getState) => {
  network
    .get(
      getDailyPnlUrl,
      {
        exchange_id,
      },
      dispatch,
      getState
    )
    .then((res) => {
      const { apy, pnl, chart_data, code } = res.data;
      if (code == 200) {
        dispatch({
          type: GET_DAILY_PNL,
          payload: { apy, pnl, chart_data },
        });
      }
    });
};
export const resetPagination = () => (dispatch, getState) => {
  dispatch({
    type: RESET_ALL_PAGINATION
  });
};
