import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Line } from "react-chartjs-2";
import { connect, useSelector } from "react-redux";
import { getPortfolio } from "../../actions/portfolio-action";

const PortfolioChart = (props)=>{
    const {data,getPortfolio} = props;
    const {timeframe,selectedExchangeId}=data;
    const {
        equities,
        volumes,
      } = useSelector((state) => state.portfolio);
    useEffect(() => {
        getPortfolio(timeframe, selectedExchangeId);
    }, [timeframe, selectedExchangeId]);
    const portfolioValues = equities && equities.map((item) => item.value);
    const graphLabel =
    equities &&
    equities.map((item, index) => {
      return item.time + " UTC";
    });
    let series = [
        {
            name: "Portfolio",
            data: portfolioValues
        },
        {
            name: "Volumes",
            data: volumes
        }
    ]
    let options =  {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          },
        },
        tooltip: {
            fillSeriesColor: true,
            x: {
                show: false,
            }
        },
        colors: ['#07dff7', '#66DA26'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        markers: {
            size: 5,
        },
        legend: {
            show: false,
            labels:{
                colors:"#fff"
            }
        },
        grid: {
          row: {
            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
          xaxis: {
            lines: {
              show: false,
            }
          },
          yaxis: {
            lines: {
              show: false,
            }
          },
        },
        xaxis: {
          categories: graphLabel,
          labels: {
            style: {
                colors: '#fff',
            },
          }
        },
        yaxis: [
          {
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: "#fff"
            },
            labels: {
              style: {
                colors: "#fff"
              },
              formatter: (value) => { return value.toFixed(0) },
            },
            min:portfolioValues && Math.min(...portfolioValues),
            max:portfolioValues && Math.max(...portfolioValues)
          },
          {
            opposite: true,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: "#fff"
            },
            labels: {
              style: {
                colors: "#fff"
              },
              formatter: (value) => { return value.toFixed(0) },
            },
            min:volumes && Math.min(...volumes),
            max:volumes && Math.max(...volumes)
            
          }
        ]
      }
    return(
        <div id="chart">
            <ReactApexChart options={options} series={series} type="line" height={350} />
        </div>
    )
}

export default connect(null,{getPortfolio}) (PortfolioChart)