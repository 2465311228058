import React from "react";
import { Form, Button } from "react-bootstrap";
import { Trans } from "react-i18next";

const PremiumDetails = (props) => {
  const {t} = props;
  const botName = "Market Neutral Bot"

  return (
    <>
        <h6 className="text-row">
            <i className="fas fa-check item-check"></i>{" "}
            15 {t("active-bots")}
        </h6>
        <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        5 {t("price-alerts")}
        </h6>
        <h6 className="text-row">
        <i className="fas fa-check item-check"></i> {t("premium-info-1")}
        </h6>
        
        <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        {t("premium-info-2")}
        </h6>
        <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        {t("premium-info-3")}
        </h6>
        <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        {t("premium-info-4")}
        </h6>
        <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        {t("premium-info-5")}
        </h6>
        {/* <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        <Trans i18nKey={"premium-info-6"}>
              <a href="https://www.stockhero.ai/pricing/#moneyback" target="_blank"></a>
          </Trans>
        </h6> */}
        {/* <h6 className="text-row">
        <i className="fas fa-check item-check"></i>{" "}
        <Trans i18nKey={"premium-info-7"}>
              <a href="https://blog.stockhero.ai/market-neutral-bot-wins-best-performing-bot-with-nearly-100-win-loss-ratio/" target="_blank">{{botName}}</a>
          </Trans>
        </h6> */}
          {/* <h6 className="promotion-text">
            <a href="https://www.stockhero.ai/pricing" target="_blank">{t("free-info-5")}</a>
         </h6> */}
       
    </>
  );
};

export default PremiumDetails;
