import React, { useState } from "react";
import { Table, Badge, Button, Modal } from "react-bootstrap";
import { formatDate, isLong, milisecOf } from "../../utils/Utils";
import {useSelector } from "react-redux";
import { BotType, DealStatus } from "../../Const";
import CustomPagination from "../common/CustomPagination";
import NoData from "../common/NoData";
import "./DealTab.scss";
import DealWarning from "./DealWarning";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DealTab = (props) => {
  const { deals, completed, onShowDetail,refreshDeal,t } = props;

  const [showConfirm, setShowConfirm] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [refreshId, setRefreshId] = useState('');
  const clickRefresh = (id)=>{
    setShowLoading(true)
    setRefreshId(id)
    refreshDeal(id).then(()=>{
      setShowLoading(false)
    }).catch((error)=>{
      setShowLoading(false)
    })
  }
  //const [rangeItem, bindPagination] = usePagination(0, 2);
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  deals.forEach((item) => {
    item.closed_orders.sort((order1, order2) => {
      return milisecOf(order1.date) - milisecOf(order2.date);
    });
  });
  deals.sort((item1, item2) => {
    if (
      item1.closed_orders &&
      item2.closed_orders &&
      item1.closed_orders.length &&
      item2.closed_orders.length
    ) {
      const lastIndex1 = item1.closed_orders.length - 1;
      const lastIndex2 = item2.closed_orders.length - 1;
      return (
        milisecOf(item2.closed_orders[lastIndex2].date) -
        milisecOf(item1.closed_orders[lastIndex1].date)
      );
    } else {
      return -1;
    }
  });
  const dealItems = deals.map((item, index) => {
    const status = item.status_key.toLowerCase();
    let statusVariant = [
      //DealStatus.stopLoss,
      //DealStatus.stoppingLoss,
      DealStatus.error,
    ].includes(status)
      ? "danger"
      : status == DealStatus.waiting
      ? "secondary"
      : item.net_profit >= 0
      ? "success"
      : "danger";
    const numberOrder = item.closed_orders.length;
    return (
      <tr key={index}>
        <td className="text-left"><img src={item.bot_rent_id?`/images/icons/bots/svg/rent.svg`:`/images/icons/bots/svg/${item.bot_type}.svg`} className="bot-icon-list"></img>{item.bot_name}</td>
        <td>
          <img src={item.pair.base_image} width={20} className="mr-1" />
          <span className="mx-2">
            {item.quote_name}/{item.base_name}
          </span>
          <span className={isLong(item.strategy) ? "long" : "short"}>
            {item.strategy}
          </span>
        </td>
        <td>
          {item.executed_vol?item.executed_vol.toFixed(2):0} {item.base_name}
        </td>
        {typeof item.net_profit === 'string'?<>
          <td className={item.net_profit >= 0 ? "text-success" : "text-danger"}>
          {item.net_profit} {item.base_name} (
          {item.profit_value}%){" "}
          {item.note && <DealWarning note={item.note} />}
        </td></>:<>
        <td className={item.net_profit >= 0 ? "text-success" : "text-danger"}>
          {item.net_profit?item.net_profit.toFixed(2):0} {item.base_name} (
          {item.profit_value?item.profit_value.toFixed(2):0}%){" "}
          {item.note && <DealWarning note={item.note} />}
        </td></>}
        
        <td>
          <span  className={`px-3 py-2 w-100 ${statusVariant}`}>
            {item.status_name || "Error"}
          </span>
        </td>
        {completed ? (
          <td>
            {(item &&
              item.closed_orders &&
              item.closed_orders.length &&
              formatDate(
                item.closed_orders[numberOrder - 1].date,
                "YYYY-MM-DD HH:mm:ss",
                "DD MMM YYYY HH:mm"
              )) ||
              "-"}
          </td>
        ) : (
          <></>
        )}
        <td>
          <Link to={`/deal-detail/${item.id}`}>
          <img src="/images/icons/icon-right.svg" ></img>
          </Link>
          {!completed &&<>
          <a href="#" onClick={() => clickRefresh(item.id)} className='ml-1'>
          {!(showLoading && refreshId==item.id) && <img src="/images/icons/icon-refresh.svg" ></img>}
          </a>
          { (showLoading && refreshId==item.id) && <i className="fas fa-sync fa-spin reload-icon"></i> }
          </>
          }
        </td>
      </tr>
    );
  });
  return (
    <div className="mt-4 bot-tab">
      <div className="" >
            <div className="deals-table">
                <table className="">
                    <tbody>
                        <tr valign="top">
                            <th>{t("bn")}</th>
                            <th>{t("pair")}</th>
                            <th>{t("volume")}</th>
                            <th>{completed ? t("return") :t("un-return")}</th>
                            <th>{completed ? t("exit-cond") :t("status")}</th>
                            {completed ? <th>{t("completed")}</th> : <></>}
                            <th>{t("action")}</th>
                        </tr>
                        {dealItems}
                    </tbody>
                </table>
                <NoData count={deals.length} />
                <ConfirmDialog show={showConfirm} onHide={() => setShowConfirm(false)} t={t} />
          </div>
      </div>
    </div>
   
    
  );
};

export default DealTab;

const ConfirmDialog = (props) => {
  const {t} = props
  const clickClose = () => {
    props.onHide();
  };
  const clickCancel = () => props.onHide();
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>{t("confirm-dialog")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("deal-close-warn")}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={clickClose}>
          {t("close")}
        </Button>
        <Button variant="info" onClick={clickCancel}>
        {t("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
