import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Form, InputGroup } from "react-bootstrap";
import Switch from "react-switch";
import { connect, useSelector } from "react-redux";
import { getGACode, updateGASetting } from "../../actions/setting-action";
import useInput from "../hooks/useInput";
import Loading from "../common/Loading";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import "./SecurityPanel.scss";
import { useTranslation } from "react-i18next";

const SecurityPanel = (props) => {
  const { getGACode, updateGASetting } = props;
  const {t} = useTranslation()
  const [isOn, setIsOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const gaCode = useSelector((state) => state.settings.gaCode) || "";
  const gaQrCode = useSelector((state) => state.settings.gaQrCode) || "";
  const gaEnabled = useSelector((state) => state.settings.gaEnabled) || false;
  const [code, bindCode, resetCode] = useInput("");
  useEffect(() => {
    getGACode();
  }, []);
  useEffect(() => {
    setIsOn(gaEnabled);
  }, [gaEnabled]);
  const clickUpdateGASetting = () => {
    setLoading(true);
    updateGASetting(code, isOn ? 1 : 0)
      .then(() => {
        setLoading(false);
        resetCode();
        toast.success(
          `Google Authenticator has been ${isOn ? "enabled" : "disabled"}`
        );
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const clickCopy = () => {
    copy(gaCode);
    toast.success("Copied code.");
  };
  return (
    <Loading show={loading} onHide={() => setLoading(false)}>
      <div className="card-white">
        <div className="cw-body settings-body">
      <div className="security-panel round-bg">
        <Row>
          <Col>
            <h5>{t("google-auth")}</h5>
            <p>{gaEnabled ? t("2fa-enabled") : t("2fa-disabled")}</p>
          </Col>
          <Col className="text-right pr-5">
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              checked={isOn}
              onChange={setIsOn}
            />
          </Col>
        </Row>
        {!gaEnabled && isOn && (
          <div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <h5>{t("instruction")}</h5>
              <br />
              <p>1. {t("2fa-instruction")}</p>
              <br></br>
              <p style={{ paddingLeft: 30 }}>
                <a
                  href="https://apps.apple.com/vn/app/google-authenticator/id388497605"
                  target="_blank"
                  style={{ marginRight: 30 }}
                >
                  <span className="appstore-item">
                    <img
                      src="/images/icons/apple-white.png"
                      alt=""
                      width={20}
                      height={25}
                    />
                    <span className="appstore-text">Appstore</span>
                  </span>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
                  target="_blank"
                >
                   <span className="appstore-item">
                    <img
                      src="/images/google.png"
                      alt=""
                      width={20}
                      height={25}
                    />
                    <span className="appstore-text">Google Play</span>
                  </span>
                </a>
              </p>
              <p>
              <br></br>
                2. {t("2fa-instruction-2")}
              </p>
              <div style={{ marginBottom: 20 }}>
                <div>
                  <img src={gaQrCode} width={200} />
                </div>
                <Row>
                  <Col xs={6}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div><Form.Control value={gaCode} disabled /></div>
                      <div> <Button variant="" onClick={clickCopy} className="small-btn">
                          Copy
                        </Button></div>
                      
                    </div>
                  </Col>
                </Row>
              </div>
              <p>3. {t("2fa-instruction-3")}</p>
              <Row>
                <Col xs={6}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div><Form.Control {...bindCode} /></div>
                    <div> <Button variant="" onClick={clickUpdateGASetting} className="small-btn">
                        {t("submit")}
                      </Button></div>
                    
                    
                  </div>
                </Col>
              </Row>
              <br />
            </div>
          </div>
        )}
        {gaEnabled && !isOn && (
          <div>
            <br />
            <Col xs={6}>
                  <p>{t("2fa-instruction-4")}</p>
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <div><Form.Control {...bindCode} /></div>
                    <div> <Button variant="" onClick={clickUpdateGASetting} className="small-btn">
                        {t("submit")}
                      </Button></div>
                    
                    
                  </div>
                </Col>
          </div>
        )}
      </div>
      </div>
      </div>
    </Loading>
  );
};

export default connect(null, { getGACode, updateGASetting })(SecurityPanel);
