import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import SpotOverview from "../dashboard/SpotOverview";
import PortfolioChart from "../dashboard/PortfolioChart";
import ChartTimeFrames from "../dashboard/ChartTimeFrames";
import DistributionPanel from "./DistributionPanel";

const PortfolioOverview = (props)=>{

    const {t,coins} = props;
    const [showGraph, setShowGraph] = useState(false);
    const [timeframe, setTimeframe] = useState("1M");
    const toggleGraph = () => {
        setShowGraph(!showGraph);
      };
    const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;

    return (
        <>
            <div className="chart-container">
                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div className="panel">
                    <div className="panel-heading " role="tab" id="headingOne">
                    <div className="panel-title">
                        <div className="chart-arrow">
                        <a
                        
                            role="button"
                            data-toggle="collapse"
                            data-target="#graphCollapse"
                            aria-expanded="false"
                            aria-controls="graphCollapse"
                            onClick={toggleGraph}
                        ><i className="fas fa-chevron-down"></i></a>
                        </div>
                        <div className="row">
                            <SpotOverview data={{ t,
                                timeframe
                                }}/>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className={`transition-div ${showGraph ? "show" : ""}`} id="graphCollapse">
                    <div className="panel-body">
                        <div className="row">
                        <div className="col-xl-6 col-lg-6 v-pad chart-graph">

                            <PortfolioChart data={{timeframe,selectedExchangeId}}/>
                            <ChartTimeFrames timeframe={timeframe} setTimeframe={setTimeframe}/>
                            
                            <div className="graph-desc">
                            <ul className="green-bullet">
                                <li>Portfolio Value</li>
                            </ul>
                            <ul>
                                <li>Trading Volume</li>
                            </ul>
                            </div>
                            <div style={{ clear: "both" }}></div>
                        </div>
                        <div className="col-xl-6 col-lg-6 v-pad text-center">
                            <h3 className="portfolio-heading">Portfolio Distribution</h3>
                            <DistributionPanel  t={t} />
                        </div>
                       
                        </div>
                    </div>
                    </div>
                </div>
                </div>
        </>
    );

}
export default connect(null,{})(PortfolioOverview)